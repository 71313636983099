import React, { useEffect, useState } from 'react'
import './TransactionStyles.css'
import TableHeading from '../../Components/TableHeading'
import TableData from '../../Components/TableData'
import profil1 from '../../images/Ellipse 991.png'
import profil2 from '../../images/Ellipse 992.png'
import profil3 from '../../images/Ellipse 993.png'
import Pagination from '../../Components/Pagination'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import dateIcon from '../../images/Parent/uil_calender.png'
// import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Layout from './Layout'

export default function Transactions() {


        //simulation des donnees de tableau
        const data1 = {
            id: 1441,
            student: {
                name: 'Florence Dawg',
                profil: profil1
            },
            date: '12 Juin 2024',
            achat: 'Plan Standard',
    
            montant: 133,
            transactionID: 125734904,
            type: 'Crédit',
            status: 'Succes'
        }
        const data2 = {
            id: 1590,
            student: {
                name: 'Maxime Cat',
                profil: profil2
            },
            date: '12 Juin 2024',
            achat: 'Plan Standard',
            montant: 133,
            transactionID: 125734904,
            type: 'Crédit',
            status: 'Echoué'
        }
        const data3 = {
            id: 1261,
            student: {
                name: 'Albert Horse',
                profil: profil3
            },
            date: '12 Juin 2024',
            achat: 'Plan Standard',
            montant: 57,
            transactionID: 125734904,
            type: 'Debit',
            status: 'En cours'
        }
    
        // mixage des donnees
        const data = [data1, data2, data3, data2, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3]
    
    
        //pagination
        const [currentPage, setCurrentPage] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(10);
        const [itemsPages, setItemsPages] = useState({});
        const [loading, setLoading] = useState(true); // État de chargement
    
        useEffect(() => {
            const totalPages = Math.ceil(data.length / itemsPerPage);
            let current = 0;
    
            const newItemsPages = {};
    
            for (let index = 1; index <= totalPages; index++) {
                newItemsPages[index] = data.slice(current, current + itemsPerPage);
                current += itemsPerPage;
            }
    
            setItemsPages(newItemsPages);
            setLoading(false); // Indiquer que le chargement est terminé
        }, [itemsPerPage]);
    
        const [filter, setFilter] = useState({
            transaction_id: 'desc',
            montant: 'desc',
            type: 'desc',
            status: 'desc'
        })
    
        const TransactionFilter = ({filterTitle}) =>{
    
            const varTitle = filterTitle.toLowerCase().replaceAll(' ', '_')
            const changeSelfFilter = () => {
                const status = filter[varTitle] == 'asc' ? 'desc' : 'asc'
                setFilter(filter=>({
                    ...filter,
                    [varTitle]: status
                }))
                // console.log(varTitle)
            }
            return(
                <div onClick={()=>changeSelfFilter()} className='transactionListFilter'>
                    <span>{filterTitle}</span>
                    {
                        filter[varTitle] == 'asc' ? <IoIosArrowUp/> : <IoIosArrowDown/> 
                    }
                </div>
            )
        }
    
        // date filter settings
        // const [displayedDate, setDisplayedDate] = useState(['25 octobre 2024', '31 decembre 2024'])
        // const [isOpen, setIsOpen] = useState(false)
        // const [startDate, setStartDate] = useState(null)
        // const [endDate, setEndDate] = useState(null)
        // const [selectedDate, setSelectDate] = useState(undefined)
        // const moisEnFrancais = {
        //     Jan: "Janvier",
        //     Feb: "Février",
        //     Mar: "Mars",
        //     Apr: "Avril",
        //     May: "Mai",
        //     Jun: "Juin",
        //     Jul: "Juillet",
        //     Aug: "Août",
        //     Sep: "Septembre",
        //     Oct: "Octobre",
        //     Nov: "Novembre",
        //     Dec: "Décembre"
        //   };
    
        // useEffect(()=>{
        //     if(selectedDate){
        //         const startDate = String(selectedDate[0]).split(' ') 
        //         const endDate =  String(selectedDate[1]).split(' ')
        // //  alert(selectedDate)
        //         // alert(startDay, startMonth, startYear)
        //         const dates = [`${startDate[2]} ${moisEnFrancais[startDate[1]]} ${startDate[3]}`, `${endDate[2]} ${moisEnFrancais[endDate[1]]} ${endDate[3]}`]
        //         setDisplayedDate(dates)
                    
        //         }
          
        // },[selectedDate])
    
        // const handleDateChange = (date) => {
        //     if (!startDate) {
        //         setStartDate(date);
        //       } else if (!endDate) {
        //         if (date && date > startDate) {
        //           setEndDate(date);
        //           setIsOpen(false); 
        //           setSelectDate([startDate, date])
        //         } else {
        //           alert("La date de fin doit être après la date de début.");
        //         }
        //       } else {
        //         setStartDate(date);
        //         setEndDate(null);
        //       }
        // };
    
    
        // export data functions
        const extractDataToPDF = () => {
            const invoice = document.getElementById("transactionTableContainer");
            html2canvas(invoice, { scale: 2 }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              const pdf = new jsPDF("p", "mm", "a4");
              const pageWidth = pdf.internal.pageSize.getWidth();
              const pageHeight = pdf.internal.pageSize.getHeight();
              const margin = 10; // Définir la marge à 10 mm
              // Calculer la largeur et la hauteur de l'image avec les marges
              const imgWidth = pageWidth - margin * 2; // Ajuster pour laisser une marge de chaque côté
              const imgHeight = (canvas.height * imgWidth) / canvas.width;
              // Position avec la marge en haut
              const xPos = margin;
              const yPos = margin;
              pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
              pdf.save("transactions.pdf");
            });
            
          };
    
        return(
    
            <Layout>
                <div className='adminTransactionList'>
                <header>
                    <div className="transactionListFilters">
                        <TransactionFilter filterTitle={'Transaction ID'}/>
                        <TransactionFilter filterTitle={'Status'}/>
                        <TransactionFilter filterTitle={'Type'}/>
                        <TransactionFilter filterTitle={'Montant'}/>

                        {/* version mobile */}
                        <select name="" id="">
                            <option value="">Transaction</option>
                            <option value="">Status</option>
                            <option value="">Type</option>
                            <option value="">Montant</option>
                        </select>
    
                        <div className="transactionDateFilter" >
                        
                            <span>Date</span>
                            <img src={dateIcon} />
                            {/* onClick={()=> setIsOpen(true)} */}
                            {/* {isOpen && (
                                    <div>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        // startDate={startDate}
                                        // endDate={endDate}
                                        // selectsRange
                                        inline
                                    />
                                    </div>
                                )} */}
                        </div>
    
                        <div className='transactionFilterButton'>
                            Filter
                        </div>
                    </div>

                    
    
                    <div className="exportDataButton" onClick={extractDataToPDF}>
                        <div>
                            <span>Exporter données</span>               
                            <IoIosArrowDown/>             
                        </div>
                        
                    </div>
                </header>
    
                <div className="transactionTableContainer" id='transactionTableContainer'>
                    <table>
                        <thead>
                            <TableHeading value={'ID'}/>
                            <TableHeading value={'Etudiant'}/>
                            <TableHeading value={'Date'}/>
                            <TableHeading value={'Achat'}/>
                            <TableHeading value={'Montant'}/>
                            <TableHeading value={'ID transaction'}/>
                            <TableHeading value={'Type'}/>
                            <TableHeading value={'Status'}/>
                        </thead>
    
                        <tbody>
    
                        {
                            loading ? ( // Afficher le loader si les données sont en cours de chargement
                            <tr>
                                <td style={{ textAlign: 'center', width: '100%' }}>
                                    Chargement des données...
                                </td>
                            </tr>
                            ) : (
                            
                                itemsPages[currentPage]?.map((data, index) => 
                                    <tr key={data.id} className={index%2 == 0 ? 'OddTableRow' : ''}>
                                        <TableData  value={data.id}/>
                                        <TableData 
                                            className={'composedTD'}  
                                            value={
                                                <>
                                                    <img src={data.student.profil} alt="profil" />
                                                    <span>{data.student.name}</span>
                                                </>
                                            }
                                        />
                                        <TableData  value={data.date}/>
                                        <TableData  value={data.achat}/>
                                        <TableData  
                                            value={
                                                <>
                                                    <span style = {{fontWeight: 'bold'}}>{data.montant}</span>
                                                    <span style = {{fontWeight: 'bold'}}> €</span>
                                                </>
                                            } 
                                        />
                                        <TableData  value={data.transactionID}/>
                                        <TableData  value={data.type}/>
                                        <TableData          
                                            value={
                                                <span className={`${data.status == 'Succes' && 'successStatusTD'} ${data.status == 'Echoué' && 'failStatusTD'} ${data.status == 'En cours' && 'pendingStatusTD'}`} >
                                                    {data.status}
                                                </span>
                                            }
                                        />
                                    </tr>
                                )
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination itemsPages ={itemsPages} setItemsPerPage={setItemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
    
            </div>
            </Layout>
    
        )
    
}


