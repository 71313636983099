
import bg from "../../images/dash.png"
import bg2 from "../../images/motpasse.png"
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo-colorful.png";
import './RenitialiserMotPasse.css'
import rectangle from "../../images/Rectangle 18.png";
import laptop from "../../images/laptop-with-login-password-form-screen 1.png"
export default function RenitialiserMotPasse(){
  const navigate=useNavigate()
  const handleBackClick = () => {
    navigate(-1); // Retourne à la page précédente
  };
    return   <Container fluid>
    <Row>
    <Col className='RegisterStu' xl={6} lg={6} md={12} sm={12} xs={12}  style={{
  backgroundImage: `url(${bg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100vh",
 
}}>
     <Row  >
       <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-1 " >
       <img src={logo} className="img-fluid"/>
       </Col>
    </Row>
    <Row>
       <Col xl={11} lg={11} md={12} sm={12} xs={12} className="student-login-container-form  p-xl-2 p-lg-0 p-3" >
       <Row className="mt-4 mt-xl-0 mt-lg-0" >
       <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
       <p className="d-flex align-items-center" style={{marginBottom:"-50px"}}><svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z" stroke="#2E2E2E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.2602 15.5297L9.74023 11.9997L13.2602 8.46973" stroke="#2E2E2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> <span  className="dos-renitialisation"> PRECEDENT</span> </p>
       </Col>
    </Row>
        <Row>
               <Col  xl={12} lg={12} md={12} sm={12} xs={12} >
               <h1 className="title-Renitialiser mt-5">Rénitialiser le mot de passe</h1>
               </Col>
        </Row>
         <Row>
               <Col  xl={12} lg={12} md={12} sm={12} xs={12}  >
               <p className='student-login-p1 mt-1' style={{lineHeight:"20px",wordSpacing:"1px"}}>Nous vous enverrons un e-mail pour réinitialiser le mot de passe sur votre identifiant de messagerie enregistré</p>
               </Col>
        </Row>
       
      <Row>
        <Form className="student-login-form mt-4">
              
              <Row className=''>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                  <Form.Group controlId="Id">
                   <Form.Label>Identifiant de courrier électronique <span className='star'>*</span></Form.Label>
                   <Form.Control type="text"placeholder="abc@gmail.com" name="id"  />
                  
               </Form.Group>
                  </Col>
              </Row>
              
             
             



              
              <Row className='mt-3'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} className='suivant-login-container' >
                 
                  <button 
                   type="submit" 
                   className='button-Register'
                   
                  
                   aria-label="Submit"
               > 
               
            <>
              Soumettre  
              <svg  viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="Register-fleche">
                  <rect y="0.5" width="29" height="29" rx="14.5" fill="white"/>
                  <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </>
           

               </button>
                  </Col>
                 
              </Row>
               
           </Form>

        </Row>
               
       </Col>
    </Row> 

       </Col> 
       <Col xl={6} lg={6} md={12} sm={12} xs={12} className='student-login-image d-none d-xl-block d-lg-block' style={{overflowX:"hidden"}} >
                <Row> 
                <Col xl={12} lg={12} md={12} sm={12} xs={12}> 
                <p className="student-login-para">Embarquez pour une aventure mondiale : <br/>
                    Explorez l'étranger<br/>
                    Étudiez les <br/>
                    opportunités <br/>
                    avec nous !</p>
                </Col>
                </Row>
                <Row className=''> 
                <Col xl={12} lg={12} md={12} sm={12} xs={12} > 
                <div className="rectangle-wrap">
                <img src={rectangle} className='image-rectangle-pass'/>
                <img src={laptop} className='image-laptop-student'/>
                </div>   
                </Col>
                </Row>
               
                </Col>
    </Row>

       
</Container>
}