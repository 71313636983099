import React, { useEffect, useState } from "react";
// import './RechercheLogement.css'
import { PersonnalInfos } from "../RechercheLogement/RechercheLogement";
import FileToDownload from "../FileToDownload";
import SelectLabel from "../../../Components/SelectLabel";
import InputLabel from "../../../Components/InputLabel";
import axios from "axios";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5";
import api from "../../api/axiosInstance";
import findCitiesOfCountry from "../../../Layout/findCitiesOfCountry";
import countries from "../../../Layout/COUNTRY.json";
import { success, error } from "../../utils/alerts";


export default function ServiceBilleterie() {
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [data, setData] = useState({
    fistname: "",
    lastname: "",
    phone: "",
    birthPlace: "",
    birthDate: "",
    email: "",
    nationnality: "",
    departureCountry: "",
    departureCity: "",
    arrivalCountry: "",
    arrivalCity: "",
    departureDate: "",
    arrivalDate: "",
    passagersCount: "",
    travelClass: "",
    preferedAirline: "",
    specialNeeds: "",
    passport: "",
    documentAdditionel: "",
    payementPreuve: "",
    preuveAcceptance: "",
    number_of_passengers: "",
    // Files
    passport: null,
    documentAdditionel: null,
    payementPreuve: null,
    preuveAcceptance: null

  });
  const {
    departureCountry,
    departureCity,
    arrivalCountry,
    arrivalCity,
    departureDate,
    arrivalDate,
    passagersCount,
    preferedAirline,
    specialNeeds,
    travelClass,
  } = data;

  const [villesDepart, setVillesDepart] = useState([]);
  const [villesArrivee, setVillesArrivee] = useState([]);

  const updateField = (e) => {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };



  
  const navigate = useNavigate();

  function renameFile(file, newName) {
    return new File([file], newName, { type: file.type });
  }

  function updatePassport(value) {
    setData({ ...data, passport: value })
  }
  function updateDocument(value) {
    setData({ ...data, documentAdditionel: value })
  }
  function updatePayement(value) {
    setData({ ...data, payementPreuve: value })
  }
  function updateAcceptance(value) {
    setData({ ...data, preuveAcceptance: value })
  }

 
  // Load cities when a country is selected
  const handleCitiesOfSelectedCountry = async (e, state) => {
    const countryName = e.target.value;
    if (state === 'depart')
      setVillesDepart(findCitiesOfCountry(countryName))
    else if (state === 'arrivee')
      setVillesArrivee(findCitiesOfCountry(countryName))

  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    // Fetch existing ticketing data
    const fetchTicketingData = async () => {
      try {
        const response = await api
          .get("/student/ticketing/")
          .then((res) => res.data);

        setData({
          firstname: response.first_name,
          lastname: response.last_name,
          phone: response.phone_number,
          birthPlace: response.birth_place,
          birthDate: response.birth_date,
          email: response.email,
          nationnality: response.nationality,
          departureCountry: response.departure_country,
          departureCity: response.departure_city,
          arrivalCountry: response.arrival_country,
          arrivalCity: response.destination_city,
          departureDate: response.departure_date,
          arrivalDate: response.return_date,
          passagersCount: response.number_of_passengers,
          travelClass: response.travel_class,
          preferedAirline: response.preferred_airlines,
          specialNeeds: response.special_requests,
          passport: response.files.passport_copy || null,
          documentAdditionel: response.files.additional_pdf || null,
          payementPreuve: response.files.payment_proof || null,
          preuveAcceptance: response.files.preliminary_acceptance || null,
        });
      } catch (error) {
        console.error("Error fetching ticketing data:", error);
      }
    };

    fetchTicketingData();
  }, []);

  // Handle submission of ticketing form (POST or PUT)
  const handleTicketingSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // files
    
    if (data.preuveAcceptance && data.preuveAcceptance.name) {
      const newName = data.preuveAcceptance.name.slice(0, 95) + data.preuveAcceptance.name.slice(-5);
      const renamedFile = renameFile(data.preuveAcceptance, newName);
      formData.append("files.preliminary_acceptance", renamedFile);
    } else {
      console.error("Admission Proof File est invalide ou vide.");
    }

    if (data.passport && data.passport.name) {
      const newName = data.passport.name.slice(0, 95) + data.passport.name.slice(-5);
      const renamedFile = renameFile(data.passport, newName);
      formData.append("files.passport_copy", renamedFile);
    } else {
      console.error("Passport File est invalide ou vide.");
    }

    if (data.documentAdditionel && data.documentAdditionel.name) {
      const newName = data.documentAdditionel.name.slice(0, 95) + data.documentAdditionel.name.slice(-5);
      const renamedFile = renameFile(data.documentAdditionel, newName);
      formData.append("files.additional_pdf", renamedFile);
    } else {
      console.error("Document Additionnel File est invalide ou vide.");
    }
    if (data.payementPreuve && data.payementPreuve.name) {
      const newName = data.payementPreuve.name.slice(0, 95) + data.payementPreuve.name.slice(-5);
      const renamedFile = renameFile(data.payementPreuve, newName);
      formData.append("files.payment_proof", renamedFile);
    } else {
      console.error("Paiement Proof File est invalide ou vide.");
    }




    try {
      let response;


      // Create new ticketing information
      response = await api.post("/student/ticketing/", formData);
     if (response.status >= 200 && response.status < 300) {
            let msg = response.data?.data?.message || "Action réussie";
            success(msg);
            navigate("/etudiant/prestations-de-service");
          }
    } catch (error) {
      // Error handling
      if (error?.response) {
        if (error?.response?.status === 409) {
          console.error(
            "A ticketing entry already exists. Please update existing information."
          );
        } else {
          console.error("API Response Error:", error?.response?.data);
          console.error("Status Code:", error?.response?.status);
        }
      } else if (error.request) {
        console.error("No response received:", error?.request);
      } else {
        console.error("Configuration Error:", error?.message);
      }
      throw error;
    }
  };

  return (
    <form
      className="serviceBilleterie animParent rechercheLogement"
      onSubmit={handleTicketingSubmit}
    >
      <div className="backSectionButton">
        <Button
          value={
            <>
              <IoArrowBackCircle />
              <span>Retour</span>
            </>
          }
          onClick={() => navigate("/etudiant/prestations-de-service")}
        />
      </div>
      <PersonnalInfos
        title={"Service de billeterie"}
        data={data}
        updateField={updateField}
        setData={setData}
      />
      <section className="detailsVoyageSection">
        <h1 className="student-attestation-title">Détails du Voyage</h1>
        <div className="detailsVoyageForm">
          <SelectLabel
            label={"Pays de départ"}
            name={"departureCountry"}
            onChange={(e) => handleCitiesOfSelectedCountry(e, 'depart')}
          >
            <option selected value="" disabled>
              Sélectionnez le pays de départ
            </option>
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </SelectLabel>
          {
            villesDepart.length > 0 ?
              <SelectLabel
                label={"Ville de départ"}
                name="departure_city"
                onChange={(e) => updateField(e)}
              >
                <option value="" selected disabled>
                  Sélectionnez la ville de départ
                </option>
                {
                  villesDepart.map((ville) => (
                    <option key={ville} value={ville}>
                      {ville}
                    </option>
                  ))}
              </SelectLabel> :
              <InputLabel
                label={"Ville de départ"}
                name="departure_city"
                placeholder={'Entrez la ville de depart'}
                value={departureCity}
                onChange={(e) => updateField(e)}
              />
          }
          <SelectLabel
            label={"Pays de destination"}
            name={"arrivalCountry"}
            onChange={(e) => handleCitiesOfSelectedCountry(e, 'arrivee')}
          >
            <option selected value="" disabled>
              Sélectionnez le pays de destination
            </option>
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </SelectLabel>
          {
            villesArrivee.length > 0 ?
              <SelectLabel
                label={"Ville de destination"}
                name="destination_city"
                onChange={(e) => updateField(e)}
              >
                <option selected value="" disabled>
                  Sélectionnez la ville de destination
                </option>
                {
                  villesArrivee.map((ville) => (
                    <option key={ville} value={ville}>
                      {ville}
                    </option>
                  ))}
              </SelectLabel> :

              <InputLabel
                label={"Ville de destination"}
                name="destination_city"
                placeholder={'Entrez la ville de destination'}
                value={arrivalCity}
                onChange={(e) => updateField(e)}
              />
          }
          {/* <InputLabel label={'Pays de destination'} name={'arrivalCountry'} placeholder = {'Entrez la ville de destination'} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Ville de destination'} name={'arrivalCity'} placeholder = {'Entrez la ville de destination'} onChange = {(e)=> updateField(e)}/> */}
          <InputLabel
            label={"Date de départ"}
            name="departure_date"
            value={departureDate}
            type="date"
            placeholder={""}
            onChange={(e) => updateField(e)}
          />
          <InputLabel
            label={"Date de retour"}
            name="return_date"
            value={arrivalDate}
            type="date"
            placeholder={""}
            onChange={(e) => updateField(e)}
          />
          <SelectLabel
            label="Nombre de passagers"
            name="number_of_passengers"
            onChange={(e) => updateField(e)}
            value={passagersCount} // Définit la valeur sélectionnée actuelle
          >
            <option value="" disabled>
              Sélectionnez le nombre de passagers
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </SelectLabel>
          <SelectLabel
            label={"Classe de voyage"}
            name="travel_class"
            onChange={(e) => updateField(e)}
            value={travelClass} // Définit la valeur sélectionnée actuelle

          >
            <option selected value="" disabled>
              Sélectionnez la classe de voyage
            </option>
            <option value="economique">Économique</option>
            <option value="premium_economique">Premium Économique</option>
            <option value="business">Affaires</option>
            <option value="first_class">Première Classe</option>
          </SelectLabel>
          <InputLabel
            label={"Compagnies aériennes préférées(facultative)"}
            name={"preferred_airlines"}
            value={preferedAirline}
            type="text"
            placeholder={"Entrez"}
            onChange={(e) => updateField(e)}
          />

          <div className="inputLabel">
            <label htmlFor="specialNeeds" className="inputLabelLabel">
              Demandes Spéciales
            </label>
            <textarea
              name={"special_requests"}
              id="specialNeeds"
              value={specialNeeds}
              className="inputLabelInput"
            ></textarea>
          </div>
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Copie du passeport"}
            acceptanceText={"Passeport, ID Carte"}
            fieldName="files[passport_copy]"
            fichier={data.passport}
            setFichier={updatePassport}
          />
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Document additionnel"}
            acceptanceText={"Document Pdf"}
            fieldName="files[additional_pdf]"
            fichier={data.documentAdditionel}
            setFichier={updateDocument}
          />
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Preuve de paiement"}
            acceptanceText={"Document Pdf"}
            fieldName="files[additional_pdf]"
            fichier={data.payementPreuve}
            setFichier={updatePayement}
          />
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={" Accord préliminaire"}
            acceptanceText={"Document Pdf"}
            fieldName="files[additional_pdf]"
            fichier={data.preuveAcceptance}
            setFichier={updateAcceptance}
          />
        </div>
      </section>
      <div className="submitSectionButton">
        <button type="submit" className="buttonComponent">
          <span>Soumettre</span>
          <svg
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="profil-fleche"
          >
            <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
            <path
              d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
              stroke="#015192"
              strokeWidth="1.375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </form>
  );
}
