import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from '../api/axiosInstance';
import { success, error } from "../utils/alerts";
import bg from "../../images/dash.png"
import bg2 from "../../images/motpasse.png"
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../images/logo-colorful.png";



export default function VerificationEmail(){
  
      const navigate = useNavigate();
      const [loading, setLoading] = useState(false);
      const location = useLocation();
      const {email} = location.state || {};

      const handleResendClick = async () => {
        setLoading(true);

        try {
          const endPoint = "resend-verification/"
          const userInput = {
            email
          }
          alert("userInput")

          const response = await api.post(endPoint, JSON.stringify(userInput))
          if (response.status === 200) {
            let msg = response.data.data.message
            success(msg);

          }

        } catch (err) {
          
          const errorObject = err.response?.data?.data;

          if (err.response.status === 400) {
            
            error((errorObject?.email && errorObject.email[0]) || errorObject?.error || "An unexpected error occurred")

          } else if (err.response.status === 404) {

            error(errorObject.error)

          } else {
           
            error("Something went wrong")
          }

        } finally {

          setLoading(false);
        }
      };

      const handleConnectionClick = () => {
        navigate("/etudiant/register")
      }
    
      return ( <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h1>Email envoyé</h1>
          <p>Veuillez vérifier votre email pour continuer.</p>
          <div>
            <button onClick={handleResendClick} style={{ marginRight: '10px' }} disabled={loading}>
              {
                loading ? (
                  <span>Loading...</span>
                ): (
                  <span>Renvoyer l'email</span>
                )
              }
            </button>
            <button onClick={handleConnectionClick}>
              Connexion
            </button>
          </div>
        </div>
      );
    };