import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./profile.css";
import { useParams, useNavigate } from "react-router-dom";
import successImage from "../../images/success-icon.png";
import errorImage from "../../images/error-icon.png";
import document from "../../images/document.png";
import phone from "../../images/icon-tel.png";
import ModifyImage from "../../images/icon-image.png";
import deconnecter from "../../images/icon-deconnecter.png";
import mail from "../../images/icon-mail.png";
import modify from "../../images/icon-modify.png";
import Profile from "../../images/image-profile.png";
import axios from "axios";
import { success, error as showError } from "../utils/alerts";

function Prameter() {
  const [profileImage, setProfileImage] = useState(Profile);
  const fileInputRef = useRef(null);
  const [cardNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiration, setExpiration] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [navbarTitle, setNavbarTitle] = useState("Parameter");
  const { section } = useParams();
  const [activeSection, setActiveSection] = useState(section || "Documents");

  const navigate = useNavigate();

  const firstname = localStorage.getItem("firstname");
  const lastname = localStorage.getItem("lastname");
  const picture = localStorage.getItem("picture");
  const email = localStorage.getItem("email");

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation simple
    if (
      cardNumber.length < 16 ||
      cvv.length < 3 ||
      !expiration.match(/^\d{2}\/\d{2}$/)
    ) {
      setShowErrorModal(true);
    } else {
      setShowSuccessModal(true);
    }
  };
  const handleLogout = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken || !accessToken) {
      showError("Aucun token de session trouvé. Veuillez vous reconnecter.");
      navigate("/etudiant/login");
      return;
    }

    try {
      const response = await axios.post(
        "/logout/",
        { refresh: refreshToken },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 204 || response.status === 200) {
        localStorage.clear();
        success("Déconnexion réussie !");
        navigate("/etudiant/login");
      } else {
        throw new Error("Échec de la déconnexion, statut : " + response.status);
      }
    } catch (err) {
      console.error("Erreur de déconnexion :", err);
      showError("Impossible de se déconnecter");
    }
  };

  const renderModalContent = (isSuccess) => {
    return (
      <div className="text-center">
        <img
          src={isSuccess ? successImage : errorImage}
          alt={isSuccess ? "Succès" : "Erreur"}
          className="img-msg"
        />
        <p className="p-msg">
          {isSuccess
            ? "Votre carte de débit a été ajoutée avec succès !"
            : "Les informations de la carte de débit que vous avez saisies ne sont pas correctes. Veuillez saisir la bonne information."}
        </p>
        <Button
          className="btn-msg"
          onClick={() => {
            if (isSuccess) {
              // Logique pour retourner à l'accueil
              window.location.href = "/";
            } else {
              setShowErrorModal(false);
            }
          }}
        >
          {isSuccess ? "Retour à l'accueil" : "Réessayer"}
        </Button>
      </div>
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case "Documents":
        return (
          <div className="documents">
            <div className="doc-col1">
              <img src={document} alt="document" />
            </div>
            <div className="doc-col2">
              <h3>Documents de visa</h3>
              <p>vos documents personnels, certificats d'études</p>
              <a href="#">Voir &gt;&gt;</a>
            </div>
          </div>
        );
      case "motdepasse":
        return (
          <Form>
            <Form.Group controlId="newPassword" className="row mb-3">
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className="label-form">
                  Mot de passe actuel
                </Form.Label>
                <Form.Control
                  type="password"
                  className="input"
                  placeholder="Entrez votre mot de passe actuel"
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className="label-form">
                  Confirmez le mot de passe
                </Form.Label>
                <Form.Control
                  type="password"
                  className="input"
                  placeholder="Entrez le nouveau mot de passe"
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className="label-form">
                  Nouveau mot de passe
                </Form.Label>
                <Form.Control
                  type="password"
                  className="input"
                  placeholder="Confirmez le mot de passe"
                />
              </Col>
            </Form.Group>
            <Button className="btn-sauveguard" type="submit">
              Sauvegarder
            </Button>
          </Form>
        );
      case "Modepaiement":
        return (
          <div>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="newPayment" className="row mb-3">
                <Col xs={12} md={6} className="mb-4">
                  <Form.Label className="label-form">
                    Numéro de carte
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="input"
                    placeholder="xxxx  xxxx  xxxx  xxxx"
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-4">
                  <Form.Label className="label-form">Expiration</Form.Label>
                  <Form.Control
                    type="text"
                    className="input"
                    placeholder="MM/AA"
                    value={expiration}
                    onChange={(e) => setExpiration(e.target.value)}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-4">
                  <Form.Label className="label-form">CVV</Form.Label>
                  <Form.Control
                    type="text"
                    className="input"
                    placeholder="XXX"
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)}
                  />
                </Col>
              </Form.Group>
              <Button className="btn-sauveguard" type="submit">
                Sauvegarder
              </Button>
            </Form>

            {/* Modal for error message */}
            <Modal
              show={showErrorModal}
              onHide={() => setShowErrorModal(false)}
              className="modal-msg"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                {renderModalContent(false)} {/* Contenu pour l'erreur */}
              </Modal.Body>
            </Modal>

            {/* Modal for success message */}
            <Modal
              show={showSuccessModal}
              onHide={() => setShowSuccessModal(false)}
              className="modal-msg"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                {renderModalContent(true)} {/* Contenu pour le succès */}
              </Modal.Body>
            </Modal>
          </div>
        );
      default:
        return null;
    }
  };
  // Update title when activeSection changes
  useEffect(() => {
    if (activeSection === "motdepasse") {
      setNavbarTitle("Profil");
    } else {
      setNavbarTitle("Parameter");
    }
  }, [activeSection]);

  return (
    <>
      <Container fluid className="container-profile animParent">
        <Col>
          <div className="header">
            <div className="d-flex align-items-center gap-3 mb-3">
              <div className="col1">
                <div className="position-relative">
                  <img
                    src={picture || profileImage}
                    alt="profil"
                    className="img-fluid rounded-circle profile-image"
                  />
                  <span
                    className="img-overlay"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <img
                      src={ModifyImage}
                      alt="modify"
                      className="img-icon img-fluid rounded-circle"
                    />
                  </span>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
              </div>
              <div className="col2">
                <h1 id="nom">
                  {firstname} {lastname}
                </h1>
                <p>
                  <img src={mail} alt="mail" className="icon-profile" /> {email}
                </p>
                <p>
                  <img src={phone} alt="tel" className="icon-profile" />{" "}
                  +3345678902
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-start mb-3">
              <img
                src={deconnecter}
                alt="déconnecter"
                id="deconnecter"
                className="me-2"
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              />
              <button className="btn-modify">
                <img src={modify} alt="modify" /> Modifier
              </button>
            </div>
          </div>
          <hr />
          <Row xs={12} md={7}>
            <div className="nav-buttons d-flex align-items-center ">
              <a
                onClick={() => setActiveSection("Documents")}
                className={`${
                  activeSection === "Documents"
                    ? "nav-button-active"
                    : "nav-button-inactive"
                } nav-link-margin`}
              >
                Documents
              </a>

              <a
                onClick={() => setActiveSection("Modepaiement")}
                className={`${
                  activeSection === "Modepaiement"
                    ? "nav-button-active"
                    : "nav-button-inactive"
                } nav-link-margin`}
              >
                Mode de paiement
              </a>

              <a
                onClick={() => setActiveSection("motdepasse")}
                className={`${
                  activeSection === "motdepasse"
                    ? "nav-button-active"
                    : "nav-button-inactive"
                } nav-link-margin`}
              >
                Changer le mot de passe
              </a>
            </div>
          </Row>
          <Row xs={12} md={9}>
            <div className="p-3">{renderSection()}</div>
          </Row>
        </Col>
      </Container>
    </>
  );
}

export default Prameter;
