export default function SelectLabel({
  name,
  label,
  options = [],
  children,
  ...props
}) {
  return (
    <div className="selectLabel">
      {label && (
        <label htmlFor={name} className="selectLabelLabel">
          {label}
        </label>
      )}
      <select name={name} className="selectLabelInput" {...props}>
        {children ? (
          children
        ) : (
          <>
            <option value=""></option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </>
        )}
      </select>
    </div>
  );
}
