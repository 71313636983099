import React from 'react'

function TelephoneSVG() {
  return (
    
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" fill="none" viewBox="0 0 13 17">
  <path fill="url(#a)" d="M9.43 16.793c-.629-.135-1.464-.553-2.628-1.578-1.415-1.25-2.48-2.351-3.737-4.293C1.854 9.047 1.318 7.886.679 5.747-.043 3.33.403 2.243.734 1.807c.394-.52.866-.78 1.455-1.012A5.813 5.813 0 0 1 3.217.504a7.51 7.51 0 0 0 .098-.02c.175-.038.44-.094.721.088.188.12.337.317.562.66.46.704.984 2.164 1.143 2.859.107.469.177.778.11 1.093-.08.37-.327.614-.619.873l-.16.14c-.315.269-.389.35-.39.524 0 .35.322 1.46 1.014 2.53.691 1.068 1.55 1.779 1.87 1.923.165.074.272.039.66-.144l.172-.08c.398-.181.709-.303 1.07-.226h.001c.314.068.553.262.95.582.517.418 1.672 1.494 2.126 2.205.222.346.34.562.373.783.05.332-.111.55-.217.695l-.058.08a5.827 5.827 0 0 1-.692.815c-.453.439-.886.761-1.523.906a2.222 2.222 0 0 1-.998.003Z"/>
  <defs>
    <linearGradient id="a" x1="-.242" x2="15.148" y1="-1.562" y2="9.352" gradientUnits="userSpaceOnUse">
      <stop stop-color="#015192"/>
      <stop offset="1" stop-color="#00A5BC"/>
    </linearGradient>
  </defs>
</svg>

    
  )
}

export default TelephoneSVG
