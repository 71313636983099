// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleFAQADM{
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 700;
font-size: 29px;
line-height: 120%;
/* identical to box height, or 35px */
color: #000000;

}
.adminFAQ{
padding-top: 7%;
padding-left: 5%;
padding-right: 5%;
padding-bottom: 5%;
}`, "",{"version":3,"sources":["webpack://./src/AdminModule/Faq_Adm/styles.css"],"names":[],"mappings":"AAAA;AACA,gCAAgC;AAChC,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,qCAAqC;AACrC,cAAc;;AAEd;AACA;AACA,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,kBAAkB;AAClB","sourcesContent":[".titleFAQADM{\nfont-family: 'Plus Jakarta Sans';\nfont-style: normal;\nfont-weight: 700;\nfont-size: 29px;\nline-height: 120%;\n/* identical to box height, or 35px */\ncolor: #000000;\n\n}\n.adminFAQ{\npadding-top: 7%;\npadding-left: 5%;\npadding-right: 5%;\npadding-bottom: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
