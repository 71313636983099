export default function SelectLabel({name, label, options = [], onChange, children, ...props}){
    return(
        <div className="selectLabel">
            {label && <label htmlFor={name} className='selectLabelLabel'>{label}</label>}
            <select name={name} className='selectLabelInput' onChange={onChange} {...props}>
                {
                    children ?
                    children :
                    <>
                        <option value=''></option>
                        {options.map(option =>
                            <option key={option} value={option}>{option}</option>
                        )}
                    </>
                }
            </select>
        </div>
    )
}