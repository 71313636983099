import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import "./PrestationsServices.css";

import { Link } from 'react-router-dom';

export default function PrestationsServices(){

     return   <>  

                <Row className="mt-5 animParent prestation-service-group-card">  
                  <Col className="student-prestations-container1" > 
                            <Row>  
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p className='dashboard-student-Subtitle-service'> 
                                        Certificat de cession <br/>irrévocable (AVI)  
                                    </p>  
                                </Col> 
                             </Row> 
                             <Row className="mt-4"> 
                             <Col xl={6} lg={6} md={6} sm={6} xs={6} >
                                <p className='student-dashbord-number'> 
                                        01
                                        </p>  
                                </Col> 
                                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                <Link to="/parent/prestations-de-service/attestation-de-virement" className="student-dashboard-abonner" > 
                                        S'abonner
                                        </Link>  
                                </Col> 
                             </Row> 
                    </Col>  
                    <Col className="student-prestations-container2"> 
                            <Row> 
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p className='dashboard-student-Subtitle-service'> 
                                    Assurance tous risques  
                                    </p>  
                                </Col> 
                             </Row> 
                             <Row className="mt-4"> 
                             <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='student-dashbord-number'> 
                                        02
                                        </p>  
                                </Col> 
                                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                <Link to="/parent/prestations-de-service/assurance-completes" className="student-dashboard-abonner" > 
                                        S'abonner
                                        </Link>   
                                </Col> 
                             </Row> 
                    </Col>  
                    <Col className="student-prestations-container3"> 
                            <Row> 
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p className='dashboard-student-Subtitle-service'> 
                                    Billetterie <br/>Services
                                    </p>  
                                </Col> 
                             </Row> 
                             <Row className="mt-4"> 
                             <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='student-dashbord-number'> 
                                        03
                                        </p>  
                                </Col> 
                                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                <Link  to="/parent/prestations-de-service/services-de-billeterie" className="student-dashboard-abonner" > 
                                        S'abonner
                                        </Link>  
                                </Col> 
                             </Row> 
                    </Col>   
                    <Col className="student-prestations-container4"> 
                            <Row> 
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p className='dashboard-student-Subtitle-service'> 
                                   Recherche de <br/>
                                            logement
                                    </p>  
                                </Col> 
                             </Row> 
                             <Row className="mt-4"> 
                             <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='student-dashbord-number'> 
                                        04
                                        </p>  
                                </Col> 
                                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                <Link  to="/parent/prestations-de-service/recherche-de-logement" className="student-dashboard-abonner" > 
                                        S'abonner
                                        </Link>  
                                </Col> 
                             </Row> 
                    </Col>  
                 </Row> 


                   </> 


            










 }