import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Pagination from '../../Components/Pagination'
import "./Dash.css"
import action from "../../images/action.png"
import imageTab from "../../images/profildashtab.png"
import down from "../../images/down.png"

function TableDash() {
    // État pour savoir si tous les checkboxes sont cochés
    const [selectAll, setSelectAll] = useState(false);
    // État pour chaque étudiant pour savoir s'il est sélectionné ou non
    const [selectedStudents, setSelectedStudents] = useState(
        Array(6).fill(false) // 6 est le nombre d'étudiants
    );

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true); // État de chargement

    useEffect(() => {
        const totalPages = Math.ceil(studentsData.length / itemsPerPage);
        let current = 0;

        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = studentsData.slice(current, current + itemsPerPage);
            current += itemsPerPage;
        }

        setItemsPages(newItemsPages);
        setLoading(false); // Indiquer que le chargement est terminé
    }, [itemsPerPage]);

    const studentsData = [
        {
          name: "Guy Hawkins",
          email: "guyhawkins@gmail.com",
          phone: "+33 475 466733",
        },
        {
          name: "Devon Lane",
          email: "devonlane@gmail.com",
          phone: "+33 475 66333",
        },
        {
          name: "Darlene Robertson",
          email: "darleneroberston@gmail.com",
          phone: "+33 475 66333",
        },
        {
          name: "Eleanor Pena",
          email: "eleanorp@gmail.com",
          phone: "+33 475 66333",
        },
        {
          name: "Darlene Robertson",
          email: "darlener@gmail.com",
          phone: "+33 475 66333",
        },
        {
          name: "Darlene Robertson",
          email: "darlener@gmail.com",
          phone: "+33 475 66333",
        }
    ];

    // Fonction pour cocher/décocher tous les checkboxes
    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setSelectedStudents(Array(studentsData.length).fill(newSelectAll));
    };

    // Fonction pour cocher/décocher un seul checkbox
    const handleSelectStudent = (index) => {
        const newSelectedStudents = [...selectedStudents];
        newSelectedStudents[index] = !newSelectedStudents[index];
        setSelectedStudents(newSelectedStudents);

        // Si un seul étudiant n'est pas sélectionné, on désélectionne "selectAll"
        if (newSelectedStudents.includes(false)) {
            setSelectAll(false);
        } else {
            setSelectAll(true);
        }
    };

    return (
        <>
            <Table responsive className='tableDash'>
                <table className='tableDash'>
                    <thead>
                        <tr className='trHead'>
                            <th className='thDash etuTab'>
                                <span className='thDash-stu'>
                                    <input
                                        type='checkbox'
                                        className='inpStuTab'
                                        checked={selectAll}
                                        onChange={handleSelectAll} // Gérer la sélection de tous les étudiants
                                    />
                                    Etudiant
                                    <img className='downTabl' src={down}/> 
                                </span>
                            </th>
                            <th className='thDash emailTab'>Email ID</th>
                            <th className='thDash numTab'>Numero de Telephone</th>
                            <th className='thDash numTab'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        loading ? ( // Afficher le loader si les données sont en cours de chargement
                            <tr>
                                <td style={{ textAlign: 'center', width: '100%' }}>
                                    Chargement des données...
                                </td>
                            </tr>
                        ) : (
                            studentsData.map((val, index) => (
                                <tr key={index} className='trDash'>
                                    <td className='textTabDashname'>
                                        <input
                                            type='checkbox'
                                            className='inpStuTab'
                                            checked={selectedStudents[index]}
                                            onChange={() => handleSelectStudent(index)} // Gérer la sélection d'un étudiant
                                        />
                                        <img src={imageTab} className='imgDashTab' alt='profile'/>
                                        {val.name}
                                    </td>
                                    <td className='textTabDash'>{val.email}</td>
                                    <td className='textTabDash'>{val.phone}</td>
                                    <td className='textTabDash'>
                                        <img className='imgTabAction' src={action} alt='action'/>
                                    </td>
                                </tr>
                            ))
                            )
                        }
                    </tbody>
                </table>
            </Table>
            <Pagination itemsPages={itemsPages} setItemsPerPage={setItemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </>

    );
}

export default TableDash;
