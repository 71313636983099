// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes myAnim {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.adminAVI{
    background-color: white;
    border-radius: 1em;
    overflow-x: auto;
  scrollbar-width: none;
  animation: myAnim 1s ease 0s 1 normal ; /* Application de l'animation */
}

.adminAVI::-webkit-scrollbar{
    display: none;
}

.adminAVItable{
    margin-top: 10px;
    padding-left: 10px;
    border-collapse: collapse;
}

.adminAVItable tbody tr{
    transition: all .3s;
}

.adminAVItable tbody tr:hover {
    background-color: #f3f3f5;
}

.tableDataActions{
    display: flex;
    width: 80px;
    gap: 5px;
    align-items: center;
}

.tableDataAction{
    width: 16px;
    height: 16px;
}


`, "",{"version":3,"sources":["webpack://./src/AdminModule/Avi_Adm/AdminAVI.css"],"names":[],"mappings":";AACA;IACI;QACI,UAAU;QACV,2BAA2B;IAC/B;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ;AACA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;EAClB,qBAAqB;EACrB,sCAAsC,EAAE,+BAA+B;AACzE;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["\n@keyframes myAnim {\n    0% {\n        opacity: 0;\n        transform: translateX(50px);\n    }\n    100% {\n        opacity: 1;\n        transform: translateX(0);\n    }\n}\n.adminAVI{\n    background-color: white;\n    border-radius: 1em;\n    overflow-x: auto;\n  scrollbar-width: none;\n  animation: myAnim 1s ease 0s 1 normal ; /* Application de l'animation */\n}\n\n.adminAVI::-webkit-scrollbar{\n    display: none;\n}\n\n.adminAVItable{\n    margin-top: 10px;\n    padding-left: 10px;\n    border-collapse: collapse;\n}\n\n.adminAVItable tbody tr{\n    transition: all .3s;\n}\n\n.adminAVItable tbody tr:hover {\n    background-color: #f3f3f5;\n}\n\n.tableDataActions{\n    display: flex;\n    width: 80px;\n    gap: 5px;\n    align-items: center;\n}\n\n.tableDataAction{\n    width: 16px;\n    height: 16px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
