import React from 'react'
import {Container,Row,Col, Table} from "react-bootstrap"
import "../Student.css"
import profileImg from "../../../images/profileStu.png"
import LittleStudent from '../../../images/littleStudent'
import LittleUni from '../../../images/littleUni'
import LocationSVG from '../../../images/Location'
import DeleteSVG from '../../../images/DeleteSVG'
import TelephoneSVG from '../../../images/TelephoneSVG'
import MailSvg from '../../../images/MailSvg'
import Gender from '../../../images/Gender'
import CakeSvg from '../../../images/CakeSvg'
import EyeSvg from '../../../images/EyeSvg'
import task from '../../../images/tasks.png'
import folder from "../../../images/folders.png"
import download from "../../../images/download.png"
import eye from "../../../images/eye.png"
import PaymentHistoric from '../../../Components/PaymentHistoric/PaymentHistoric'
import { useNavigate } from 'react-router'

function DétailEtudiant() {
    const paiements = [
        {
          numero: "01",
          nomDuService: "AVI",
          dateDePaiement: "Carte de crédit",
          statutDuPaiement: "Succès",
          montant: 500.00
        },
        {
          numero: "02",
          nomDuService: "Assurance",
          dateDePaiement: "Carte de crédit",
          statutDuPaiement: "Échoué",
          montant: 500.00
        }
      ];
      const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Retourne à la page précédente
  };
        
  return (
    <div>
        <Row>
            <Col>
                <span className='RouteStu'>
                    <p className='routeStu'>Étudiant</p>
                    <p className='RouteStuRoute'>/ Détail Étudiant</p>
                </span>
            </Col>
            <Col>
                <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
            </Col>
        </Row>
        <Row className='ContentDetailStu'>
            <Row className='row1etu'>
                <Col sm={2} className='proStu'>
                    <div className='bgImgStu'>
                        <img src={profileImg} className='profileStu'/>
                    </div>
                </Col>
                <Col sm={4} className='secondProStu'>
                    <span>
                    <p className='titleStu'>Minerva McGonagall</p>
                    <span className='sousStu'>
                      
                       <LittleStudent/>
                       
                       <p className="schoolY">Étudiant    Année scolaire: 2020</p>
                    </span>
                    <span className='sousStu'>
                       
                       <LittleUni/>
                       
                       <p className="schoolY">Université de Paris</p>
                    </span>
                    <span style={{display:"flex"}}>
                        <button className='locationStu'>
                            <span className='locationSvg'>
                                <LocationSVG/>
                            </span>
                            
                            Italie
                           
                        </button>
                        <button className='deleteBtnStu'>
                            <span className='locationSvg'>
                                <DeleteSVG/>
                            </span>
                            Supprimer l'utilisateur
                        </button>
                    </span>
                    </span>
                </Col>
                <Col sm={2}  className='persCol'>
                    <span>
                    <span className='persSpan'>
                        <p className='personal'>INFORMATIONS </p>
                        <span className='svgPers'>
                            <span className='spanSvg'>
                                <TelephoneSVG/>
                            </span>
                            <p className='personalsous'>(567) 466 5665</p>
                        </span>
                        <span className='svgPers'>
                            <span className='spanSvg'>
                            <MailSvg/>
                            </span>
                            
                            <p className='personalsous'>mmc@gmail.com</p>
                        </span>
                        <span className='svgPers'>
                            <span className='spanSvg'>
                                <Gender/>
                            </span>
                            <p className='personalsous'>Homme</p>
                        </span>
                        <span className='svgPers'>
                            <span className='spanSvg'>
                                <CakeSvg/>
                            </span>
                            <p className='personalsous'>30 Juin 1990</p>
                        </span>
                    </span>
                    </span>
                </Col>
                <Col sm={3} className='fourthCol'>
                    <div className='fourthPers' >
                        <div className='div1Four'>
                            <p className='textPers'>
                                Votre Plan de Forfait
                            </p>
                            <button className='btnViewPers'>
                               <span className='svgEye'>
                               <EyeSvg/>
                               </span>
                                Voir
                            </button>
                        </div>
                        <div>
                            <img src={task} className='taskImg' />
                        </div>
                    </div>
                </Col>

            </Row>
            <Row>
                <Col className='UrDoc'>
                    <Row>
                    <p className='Doc'>
                        Vos Documents
                    </p>
                    </Row>
                    <Row>
                        <Col sm={4} className='ColDoc'>
                            <div className='DocInp'>
                                <span className='foldInp'>
                                    <img src={folder} />
                                </span>
                                <span className='PDown'>
                                    <p>Certificat 1.00</p>
                                </span>
                                <span className='downl'>
                                    <div>
                                        <img src={download}  />
                                    </div>
                                    <div  className='DOWNIMG'>
                                    <img src={eye} />
                                    </div>
                                </span>
                            </div>
                        </Col>
                        <Col sm={4} className='ColDoc'>
                            <div className='DocInp'>
                                <span className='foldInp'>
                                    <img src={folder} />
                                </span>
                                <span className='PDown'>
                                    <p>Certificat 2.00</p>
                                </span>
                                <span className='downl'>
                                    <div>
                                        <img src={download}  />
                                    </div>
                                    <div  className='DOWNIMG'>
                                    <img src={eye} />
                                    </div>
                                </span>
                            </div>
                        </Col>
                        <Col sm={4} >
                            <div className='DocInp'>
                                <span className='foldInp'>
                                    <img src={folder} />
                                </span>
                                <span className='PDown'>
                                    <p>Certificat 4.00</p>
                                </span>
                                <span className='downl'>
                                    <div>
                                        <img src={download}  />
                                    </div>
                                    <div  className='DOWNIMG'>
                                    <img src={eye} />
                                    </div>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='RowTrans'>
                <Row>
                    <Col>
                        <p className='transAEtu'>Transactions</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='ColTrans'>
                    {/* <Table responsive>
                        <table className='tablTrans'>
                            <tr>
                                <th className='thTrans'>Numéro</th>
                                <th className='thTrans'>Nom du Service</th>
                                <th className='thTrans'>Date de Paiement</th>
                                <th className='thTrans'>Statut du Paiement</th>
                                <th className='thTrans'>Montant</th>
                                <th className='thTrans'>Détails</th>
                            </tr>

                        
                            {
                                paiements.map((val,index)=>{
                                    return(
                                        <tr className='trTrans'>
                                            <td className='tdTrans'>{val.numero} </td>
                                            <td className='tdTrans'>{val.nomDuService} </td>
                                            <td className='tdTrans'>{val.dateDePaiement} </td>
                                            <td className='tdTrans'>{val.statutDuPaiement} </td>
                                            <td className='tdTrans'>{val.montant} </td>
                                            <td className='tdTrans'> ... </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </Table> */}
                    <PaymentHistoric/>
                    </Col>
                </Row>
            </Row>

        </Row>

    </div>
  )
}

export default DétailEtudiant
