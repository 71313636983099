// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQQuestion{


font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 21px;
display: flex;
align-items: center;

color: #000000;


}
.FAQResponse{


font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 26px;
/* or 200% */
display: flex;
align-items: center;

color: #474747;


}`, "",{"version":3,"sources":["webpack://./src/Components/style.css"],"names":[],"mappings":"AAAA;;;AAGA,gCAAgC;AAChC,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,aAAa;AACb,mBAAmB;;AAEnB,cAAc;;;AAGd;AACA;;;AAGA,gCAAgC;AAChC,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,YAAY;AACZ,aAAa;AACb,mBAAmB;;AAEnB,cAAc;;;AAGd","sourcesContent":[".FAQQuestion{\n\n\nfont-family: 'Plus Jakarta Sans';\nfont-style: normal;\nfont-weight: 500;\nfont-size: 17px;\nline-height: 21px;\ndisplay: flex;\nalign-items: center;\n\ncolor: #000000;\n\n\n}\n.FAQResponse{\n\n\nfont-family: 'Plus Jakarta Sans';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 13px;\nline-height: 26px;\n/* or 200% */\ndisplay: flex;\nalign-items: center;\n\ncolor: #474747;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
