import TableData from "../../Components/TableData";
import TableHeading from "../../Components/TableHeading";

import EditIcon from "../../images/AVI/edit.png"
import DeleteIcon from "../../images/AVI/trash.png"
import './AdminAVI.css'
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import { Col, Container, Row, Table } from "react-bootstrap";
// import { useState } from "react";
// import { useEffect } from "react";
// import ReactPaginate from "react-paginate";


export default function AdminAVI() {
    const data = Array(53).fill([
        'U221F939167S',
        'Robert Fox',
        '12 May 1994',
        'Robertf@gmail.com',
        'Greenland',
        'University of Paris',
        '2020',
        'Male',
        '1695 6000 0145 6567 7889 0',
        '12 May 2026',
        '0598',
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true); // État de chargement

    useEffect(() => {
        const totalPages = Math.ceil(data.length / itemsPerPage);
        let current = 0;

        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = data.slice(current, current + itemsPerPage);
            current += itemsPerPage;
        }

        setItemsPages(newItemsPages);
        setLoading(false); // Indiquer que le chargement est terminé
    }, [itemsPerPage]);


    return (

       
                <div className="adminAVI">
                    <Table responsive>
                <table className="adminAVItable">
                    
                    <thead>
                        <tr>
                            <TableHeading value={'UFS ID ETUDIANT'} />
                            <TableHeading value={'Nom du candidat'} />
                            <TableHeading value={'Date de naissance'} />
                            <TableHeading value={'Email ID'} />
                            <TableHeading value={'Lieu de naissance'} />
                            <TableHeading value={'Université'} />
                            <TableHeading value={'Année Scolaire'} />
                            <TableHeading value={'Genre'} />
                            <TableHeading value={'Status Candidat'} />
                            <TableHeading value={'Date de validité'} />
                            <TableHeading value={'Clef RIB'} />
                            <TableHeading value={'Actions'} />
                        </tr>
                    </thead>

                    <tbody>
                        {loading ? ( // Afficher le loader si les données sont en cours de chargement
                            <tr>
                                <td style={{ textAlign: 'center', width: '100%' }}>
                                    Chargement des données...
                                </td>
                            </tr>
                        ) : (
                            itemsPages[currentPage]?.map((data, index) => (
                                <tr key={index}>
                                    <TableData value={data[0]} />
                                    <TableData value={data[1]} />
                                    <TableData value={data[2]} />
                                    <TableData value={data[3]} />
                                    <TableData value={data[4]} />
                                    <TableData value={data[5]} />
                                    <TableData value={data[6]} />
                                    <TableData value={data[7]} />
                                    <TableData value={data[8]} />
                                    <TableData value={data[9]} />
                                    <TableData value={data[10]} />
                                    <TableData
                                        value={
                                            <div className="tableDataActions">
                                                <Link to={'/administrateur/avi/editer-compte'}>
                                                    <img src={EditIcon} alt="edit" className="tableDataAction" />

                                                </Link>
                                                <img src={DeleteIcon} alt="delete" className="tableDataAction" />
                                            </div>
                                        }
                                    />
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                </Table>

                <Pagination
                    itemsPages={itemsPages}
                    setItemsPerPage={setItemsPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            
            </div>
            
    );
}






