// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageAdm{
    animation: myAnim 1s ease 0s 1 normal ; /* Application de l'animation */

}`, "",{"version":3,"sources":["webpack://./src/AdminModule/Message/style.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC,EAAE,+BAA+B;;AAE3E","sourcesContent":[".messageAdm{\n    animation: myAnim 1s ease 0s 1 normal ; /* Application de l'animation */\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
