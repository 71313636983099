import React from 'react'

function Gender() {
  return (
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.625 5.625C6.43125 5.625 7.1875 5.88125 7.79375 6.31875L10.9875 3.125H8.125V1.875H13.125V6.875H11.875V4.00625L8.68125 7.1875C9.11875 7.8125 9.375 8.5625 9.375 9.375C9.375 10.3696 8.97991 11.3234 8.27665 12.0267C7.57339 12.7299 6.61956 13.125 5.625 13.125C4.63044 13.125 3.67661 12.7299 2.97335 12.0267C2.27009 11.3234 1.875 10.3696 1.875 9.375C1.875 8.38044 2.27009 7.42661 2.97335 6.72335C3.67661 6.02009 4.63044 5.625 5.625 5.625ZM5.625 6.875C4.96196 6.875 4.32607 7.13839 3.85723 7.60723C3.38839 8.07607 3.125 8.71196 3.125 9.375C3.125 10.038 3.38839 10.6739 3.85723 11.1428C4.32607 11.6116 4.96196 11.875 5.625 11.875C6.28804 11.875 6.92393 11.6116 7.39277 11.1428C7.86161 10.6739 8.125 10.038 8.125 9.375C8.125 8.71196 7.86161 8.07607 7.39277 7.60723C6.92393 7.13839 6.28804 6.875 5.625 6.875Z" fill="url(#paint0_linear_1302_15434)"/>
<defs>
<linearGradient id="paint0_linear_1302_15434" x1="0.742188" y1="1.01257" x2="13.9521" y2="6.66989" gradientUnits="userSpaceOnUse">
<stop stop-color="#015192"/>
<stop offset="1" stop-color="#00A5BC"/>
</linearGradient>
</defs>
</svg>


  )
}

export default Gender
