import React, { useEffect, useState } from 'react'
import avatar from "../../images/Avatar.png"
import { Col,Row, Container, Table } from 'react-bootstrap';
import "./Parent.css"
import show from "../../images/ShowEtu.png"
import delet from "../../images/deleteEtu.png"
import edit from "../../images/editEtu.png"
import { useNavigate } from 'react-router';
import Pagination from '../../Components/Pagination';
function AdminTable() {
    const navigate=useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true); // État de chargement

    useEffect(() => {
        const totalPages = Math.ceil(contacts.length / itemsPerPage);
        let current = 0;

        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = contacts.slice(current, current + itemsPerPage);
            current += itemsPerPage;
        }

        setItemsPages(newItemsPages);
        setLoading(false); // Indiquer que le chargement est terminé
    }, [itemsPerPage]);
    const contacts = [
        {
          Sno: "01",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "France",
          Role: "View - Edit",
          Action: "robert@gmail.com"
        },
        {
          Sno: "02",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "Greenland",
          Role: "View - Edit",
          Action: "robert@gmail.com"
        },
        {
          Sno: "03",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "India",
          Role: "View - Edit",
          Action: "robert@gmail.com"
        },
        {
          Sno: "04",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "Germany",
          Role: "View - Edit",
          Action: "robert@gmail.com"
        },
        {
          Sno: "05",
          Name: "Avo Oganda",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "Canada",
          Role: "View",
          Action: "robert@gmail.com"
        },
        {
          Sno: "06",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "France",
          Role: "View - Edit",
          Action: "robert@gmail.com"
        },
        {
          Sno: "07",
          Name: "Ali Ansari",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "Italy",
          Role: "View - Edit",
          Action: "robert@gmail.com"
        },
        {
          Sno: "08",
          Name: "Randy Orton",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "UAE",
          Role: "View",
          Action: "robert@gmail.com"
        },
        {
          Sno: "09",
          Name: "Andrew Lisakev",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "France",
          Role: "View - Edit",
          Action: "robert@gmail.com"
        }
      ];
      
    const handleEdit=()=>{
      navigate('modifier')
  }
  const handleAjouter=()=>{
    navigate('ajouter')
  }
  return (
    <div>
        <Row>
            <Col sm={12} className='btnflex1'>
            <button className='addStu' onClick={handleAjouter}> Ajouter Un Nouveau </button>
            </Col>
        </Row>
        <Row>
            <Col className='TableParent'>
            <Table responsive>
                <table className='TableParent'>
                    <tr>
                        <th className='ThParent'>S.NO</th>
                        <th className='ThParent'>NOM</th>
                        <th className='ThParent'>NUMERO DE TELEPHONE</th>
                        <th className='ThParent'>EMAIL ID</th>
                        <th className='ThParent'>COUNTRY</th>
                        <th className='ThParent'>Role</th>
                        <th className='ThParent'>ACTION</th>
                    </tr>
                    {
                      loading ? ( // Afficher le loader si les données sont en cours de chargement
                          <tr>
                              <td style={{ textAlign: 'center', width: '100%' }}>
                                  Chargement des données...
                              </td>
                          </tr>
                      ) : (
                        contacts.map((val,index)=>{
                            return(
                                <tr key={index}>
                                <td className='TdParent NO'>{val.Sno} </td>
                                <td className='TdParent NAME'>
                                    <img src={avatar}/>
                                        <span className='spantdname'>
                                        {val.Name}
                                        </span> 
                                    </td>
                                <td className='TdParent PH'>{val.PhoneNumber} </td>
                                <td className='TdParent EM'>{val.EmailID} </td>
                                <td className='TdParent CT'>{val.Country} </td>
                                <td className='TdParent REL'>{val. Role} </td>
                                <td className='TdParent ACT'>
                                    <img src={show}  className='actionEtu'/>
                                    <img src={edit} onClick={handleEdit} className='actionEtu'/>
                                    <img src={delet} className='actionEtu'/>
                                </td>
                            </tr>
                            )                            
                        })
                      )
                    }
                </table>
            </Table>
            <Pagination itemsPages={itemsPages} setItemsPerPage={setItemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            </Col>
        </Row>
    </div>
  )
}

export default AdminTable
