import { Row, Col, Form, Button } from "react-bootstrap";

import api from "../api/axiosInstance";

import CustomCountrySelect from "../Layouts/CountrySelect";
import { useState, useEffect, useMemo } from "react";
import "./AssuranceCompletes.css";
import axios from "axios";
import nationalities from "../Layouts/nationalities.json";
import FileUpload from "../Layouts/FileUpload";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router";
import { error ,success} from "../utils/alerts";
const icon = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.5"
      width="39"
      height="39"
      rx="10"
      fill="url(#paint0_linear_991_5415)"
      fill-opacity="0.1"
    />
    <path
      d="M16.749 24.5V18.5M16.749 18.5L14.749 20.5M16.749 18.5L18.749 20.5"
      stroke="url(#paint1_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.75 17.5V22.5C29.75 27.5 27.75 29.5 22.75 29.5H16.75C11.75 29.5 9.75 27.5 9.75 22.5V16.5C9.75 11.5 11.75 9.5 16.75 9.5H21.75"
      stroke="url(#paint2_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.75 17.5H25.75C22.75 17.5 21.75 16.5 21.75 13.5V9.5L29.75 17.5Z"
      stroke="url(#paint3_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_991_5415"
        x1="-0.37396"
        y1="10.8019"
        x2="37.6153"
        y2="11.1255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_991_5415"
        x1="14.685"
        y1="20.0849"
        x2="18.5815"
        y2="20.107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_991_5415"
        x1="9.43002"
        y1="14.783"
        x2="28.9117"
        y2="14.949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_991_5415"
        x1="21.622"
        y1="11.6132"
        x2="29.4147"
        y2="11.6796"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
    </defs>
  </svg>
);

export default function AssurancesCompletes() {
  const [isUpdateForm, setIsUpdateForm] = useState(true);
  const [showInputAssurance, setShowInputAssurance] = useState(false);

  const [lieux, setLieux] = useState([]);

  const [showInputBeneficiary, setShowInputBeneficiary] = useState(false);
  const navigate = useNavigate();

  //useState pour Backend
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [tel, setTel] = useState("");
  const [codeCountry, setCodeCountry] = useState("+33");
  const [pays, setPays] = useState("");
  const [dateN, setDateN] = useState("");
  const [lieu, setLieu] = useState("");
  const [adresse, setAdresse] = useState("");
  const [nationality, setNationality] = useState("");

  const [dateDebut, setDateDebut] = useState("");
  const [dure, setDuree] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const [otherInsurance, setOtherInsurance] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [otherBeneficiary, setOtherBeneficary] = useState("");
  const [montant, setMontant] = useState("");
  const [modePaiement, setModePaiement] = useState("");
  const [numcarte, setNumCarte] = useState("");
  const [dateExp, setDateExp] = useState("");
  const [cvv, setCvv] = useState("");

  const [passport, setPassport] = useState("");
  const [justificatif, setJustificatif] = useState("");

  const insuranceDurations = [1, 2, 3, 6, 12];

  const insuranceTypes = [
    "Assurance santé internationale",
    "Assurance voyage",
    "Assurance responsabilité civile",
    "Assurance accident de la vie",
    "Assurance scolaire",
    "Assurance équipement personnel",
    "Assurance des prêts étudiants",
    "Assurance annulation de scolarité",
    "Assurance habitation temporaire",
    "Assurance protection juridique",
 
  ];

  const beneficiaries = [
    { label: "Conjoint(e)", value: "conjoint" },
    { label: "Parents", value: "parents" },
    { label: "Frères et sœurs", value: "freres_soeurs" },
    { label: "Proches (famille étendue)", value: "proches" },
    { label: "Organisation caritative", value: "organisation_caritative" },
    { label: "Succession", value: "succession" },
  ];

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");

        const countryData = response.data.map((country) => ({
          name: country.translations.fra.common,

          code: country.name.common,
        }));

        countryData.sort((a, b) => a.name.localeCompare(b.name));

        setLieux(countryData);
      } catch (error) {
        console.error("Erreur lors de la récupération des pays:", error);
      }
    };

    fetchCountries();
  }, []);

  function handleCodeCountry(c) {
    setCodeCountry(c);
  }

  function handleAssurance(e) {
    const selectedAssurance = e.target.value;
    setSelectedInsurance(selectedAssurance);
    setShowInputAssurance(selectedAssurance === "Autre");
  }

  function handleBeneficiary(e) {
    const selectedBeneficie = e.target.value;
    setSelectedBeneficiary(selectedBeneficie);
    setShowInputBeneficiary(selectedBeneficie === "Autre");
  }
  function updateJustificatif(valeur){
         setJustificatif(valeur)
  }
  function updatePassport(valeur){
    setPassport(valeur)
}
function renameFile(file, newName) {
  return new File([file], newName, { type: file.type });
}

const handleAssuranceClick = async () => {
  try {
    // Construction des données du formulaire à soumettre
    const formData = new FormData();

    // Ajout des valeurs de formulaire aux données FormData
    formData.append("first_name", prenom);
    formData.append("last_name", nom);
    formData.append("phone_number", tel);
    formData.append("place_of_birth", lieu);
    formData.append("date_of_birth", dateN);
    formData.append("nationality", nationality || "");
    formData.append("country_code", codeCountry);
    formData.append("country", pays);
    formData.append("start_date", dateDebut);
    formData.append("insurance_duration", dure);
    formData.append("insurance_type", selectedInsurance !== "Autre" ? selectedInsurance : otherInsurance);
    formData.append("beneficiaries", selectedBeneficiary !== "Autre" ? selectedBeneficiary : otherBeneficiary);
    formData.append("insured_amount", montant);

    // Ajout du fichier de passeport si disponible
    if (passport && passport.name) {
      const newName = passport.name.slice(0, 95) + passport.name.slice(-5);
      const renamedFile = renameFile(passport, newName);
      formData.append("files.passport_copy", renamedFile);
    } else {
      console.error("Admission Proof File est invalide ou vide.");
    }

    // Ajout du fichier justificatif de l'acceptation préliminaire
    if (justificatif && justificatif.name) {
      const newName = justificatif.name.slice(0, 95) + justificatif.name.slice(-5);
      const renamedFile = renameFile(justificatif, newName);
      formData.append("files.preliminary_acceptance", renamedFile);
    } else {
      console.error("Admission Proof File est invalide ou vide.");
    }
console.log("data envoyé au backend",formData)
    // Soumission des données avec l'appel POST à l'API
    const response = await api.post("/student/insurance/", formData);

    if (response.status >= 200 && response.status < 300) {
      let msg = response.data?.data?.message || "Action réussie";
      success(msg);
      navigate("/etudiant/prestations-de-service");
    }
  } catch (err) {
    console.error("Une erreur est survenue:", err);

   
  }

  
};

  // Fetch insurance data on component mount
  useEffect(() => {
    const fetchInsuranceData = async () => {
      try {
        const response = await api.get("/student/insurance/");
        const insuranceData = response.data;

      console.log(insuranceData)
        setPrenom(insuranceData.first_name || "");
        setNom(insuranceData.last_name || "");
        setTel(insuranceData.phone_number || "");
        setCodeCountry(insuranceData.country_code || "+33");
        setPays(insuranceData.country || "");
        setDateN(insuranceData.date_of_birth || "");
        setLieu(insuranceData.place_of_birth || "");
        setAdresse(insuranceData.address || "");
        setNationality(insuranceData.nationality || "");

        // Données spécifiques à l'assurance
        setDateDebut(insuranceData.start_date || "");
        setDuree(insuranceData.insurance_duration || "");
        setSelectedInsurance(insuranceData.insurance_type || "");
       setOtherInsurance(insuranceData.insurance_type || "");
        setSelectedBeneficiary(insuranceData.beneficiaries|| "");
        setOtherBeneficary(insuranceData.beneficiaries|| "");
        setMontant(insuranceData.insured_amount|| "");
        setModePaiement(insuranceData.payment_method || "");

        // Documents
        setPassport(insuranceData.files.passport_copy || "");
        setJustificatif(insuranceData.files.preliminary_acceptance || "");
      } catch (err) {
        console.error(
          "Erreur lors de la récupération des données d'assurance."
        );
      }
    };

    fetchInsuranceData();
  }, []);
  useEffect(() => {
   
    if (selectedInsurance && !insuranceTypes.includes(selectedInsurance)) {
      
      setSelectedInsurance("Autre");
      setShowInputAssurance(true);
       
    } else {
      setShowInputAssurance(false);
    }
  }, [selectedInsurance, insuranceTypes]);

  useEffect(() => {
    
    if (
      selectedBeneficiary &&
      !beneficiaries.some((b) => b.value === selectedBeneficiary)
    ) {
      
      setSelectedBeneficiary("Autre");
      setShowInputBeneficiary(true);
     
    } else {
      setShowInputBeneficiary(false);
    }
  }, [selectedBeneficiary, beneficiaries]);
  
  return (
    <div className="animParent">
      <Row className="mt-4 mb-3 d-flex justify-content-end button-retour-container">
        <button
          onClick={() =>
            navigate("/StudentModule/AssurancesCompletes/AssurancesCompletes")
          }
          type="submit"
          className="button-retour-prestation"
        >
          <IoArrowBackCircle className="retour-assurance-icon" />
          Retour
        </button>
      </Row>

      <Row
        className="student-assurance-container mt-3"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Assurances Complètes</h3>
          <Form className="mt-4">
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="prenom"
                    value={prenom}
                    placeholder="Entrez votre prénom"
                    onChange={(e) => {
                      setPrenom(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Nom de famille
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    value={nom}
                    placeholder="Entrez votre nom de famille"
                    onChange={(e) => {
                      setNom(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Numéro de téléphone</Form.Label>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                      <CustomCountrySelect codeCountry={handleCodeCountry} dialCode={codeCountry} />
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                      <Form.Control
                        type="text"
                        name="tel"
                        value={tel}
                        placeholder="XXX XXX XXXX"
                        onChange={(e) => {
                          setTel(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>

              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Lieu de naissance
                  </Form.Label>
                  <Form.Select
                    name="lieu"
                    value={lieu}
                    defaultValue=""
                    onChange={(e) => {
                      setLieu(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Sélectionnez le lieu de naissance
                    </option>
                    {lieux.map((country) => (
                      <option key={country.code} value={country.code} selected={country.code.toLowerCase()===pays.toLowerCase()}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Date de naissance</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateN"
                    value={dateN}
                    onChange={(e) => {
                      setDateN(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Adresse résidentielle
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lieu"
                    value={lieu}
                    placeholder="Adresse résidentielle"
                    onChange={(e) => {
                      setAdresse(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Nationalité</Form.Label>
                  <Form.Select
                    name="lieu"
                    defaultValue=""
                    onChange={(e) => {
                      setNationality(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Sélectionnez votre nationalité
                    </option>
                    {nationalities.map((country) => (
                      <option
                        key={country.nationality}
                        value={country.nationality}
                        selected={country.nationality.toLowerCase()===nationality.toLowerCase()}
                      >
                        {country.nationality}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row
        className="student-assurance-container2 mt-3"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">
            Information sur l'assurance
          </h3>
          <Form className="mt-4">
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Date de début</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateDebut"
                    value={dateDebut}
                    onChange={(e) => {
                      setDateDebut(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Durée d'assurance
                  </Form.Label>
                  <Form.Select
                    value={dure}
                    onChange={(e) => {
                      setDuree(e.target.value);
                    }}
                  >
                    <option>Sélectionner</option>
                    {insuranceDurations.map((insurance) => {
                      return (
                        <option value={insurance} key={insurance} selected={insurance==dure}>
                          {insurance} Mois
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Type d'assurance</Form.Label>
                  <Form.Select onChange={handleAssurance} name="assurance">
                    <option value="">Sélectionnez le type d'assurance</option>
                    {insuranceTypes.map((insurance, index) => {
                      return (
                        <option value={insurance} key={index} selected={insurance==selectedInsurance}>
                          {insurance}
                        </option>
                      );
                    })}
                    <option value="Autre"  selected={selectedInsurance=="Autre"}>
                          Autre
                        </option>
                  </Form.Select>
                  {showInputAssurance && (
                    <Form.Control
                      type="text"
                      name="AutreAssurance"
                      value={otherInsurance}
                      placeholder="Entrez un type d'assurance"
                      className="mt-3"
                      onChange={(e) => {
                        setOtherInsurance(e.target.value);
                      }}
                    />
                  )}
                </Form.Group>
              </Col>

              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Les bénéficiaires
                  </Form.Label>
                  <Form.Select onChange={handleBeneficiary} name="beneficie">
                    <option value="">Sélectionnez les bénéficiaires</option>
                    {beneficiaries.map((b, index) => {
                      return (
                        <option value={b.value} key={index} selected={b.value==selectedBeneficiary}>
                          {b.label}
                        </option>
                      );
                    })}
                      <option value="Autre"  selected={selectedBeneficiary=="Autre"}>
                          Autre
                        </option>
                  </Form.Select>
                  {showInputBeneficiary && (
                    <Form.Control
                      type="text"
                      name="AutreBeneficiary"
                      value={otherBeneficiary}
                      placeholder="Entrez les bénéficiares"
                      className="mt-3"
                      onChange={(e) => {
                        setOtherBeneficary(e.target.value);
                      }}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Montant assuré</Form.Label>
                  <Form.Control
                    type="text"
                    name="montant"
                    value={montant}
                    placeholder="Entrez le montant assuré"
                    onChange={(e) => {
                      setMontant(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row className="student-assurance-container3 mt-4 ">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Documents à télécharger</h3>
          <Row className="mt-4">
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">Passeport</h3>
              <div className="attestation-save-container ">
                <FileUpload
                  svgIcon={icon}
                  label="Télécharger"
                  acceptedTypes=".png, .jpeg, .pdf"
                  existingFile={passport}
                 setExistingFile={updatePassport}
                />

                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
            </Col>

            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">
                Justificatif de domicile
              </h3>

              <div className="attestation-save-container ">
                <FileUpload
                  svgIcon={icon}
                  label="Télécharger"
                  acceptedTypes=".png, .jpeg, .pdf"
                  existingFile={justificatif}
                 setExistingFile={updateJustificatif}
                />

                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        className="student-assurance-container4 mt-3"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Mode de paiement</h3>
          <Form className="mt-4">
            <Row className=" mt-3">
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Sélectionnez le mode de paiement</Form.Label>
                  <Row className=" mt-3">
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Form.Check
                        type="radio"
                        label="Carte de crédit"
                        name="mode"
                        value="carte"
                        className="label-student-attestation"
                        onChange={(e) => setModePaiement(e.target.value)}
                      />
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Form.Check
                        type="radio"
                        label="PayPal"
                        name="mode"
                        value="paypal"
                        className="label-student-attestation"
                        onChange={(e) => setModePaiement(e.target.value)}
                      />
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Form.Check
                        type="radio"
                        label="Virement"
                        name="mode"
                        value="virement"
                        className="label-student-attestation"
                        onChange={(e) => setModePaiement(e.target.value)}
                      />
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Form.Check
                        type="radio"
                        label="Preuve du paiement"
                        name="mode"
                        value="preuve"
                        className="label-student-attestation"
                        onChange={(e) => setModePaiement(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            {modePaiement === "carte" && (
              <>
                {" "}
                <Row className=" mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-save-title">
                      Carte de crédit
                    </h3>
                  </Col>
                </Row>
                <Row className=" mt-2">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Numéro de carte</Form.Label>
                      <Form.Control
                        type="text"
                        name="numCarte"
                        placeholder="1234 5678 5677 5678"
                        onChange={(e) => setNumCarte(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label className="label-student-attestation">
                        Date d'expiration
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dateExp"
                        placeholder="MM/YY"
                        onChange={(e) => setDateExp(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className=" mt-3">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>CVV</Form.Label>
                      <Form.Control
                        type="text"
                        name="cvv"
                        placeholder="123"
                        onChange={(e) => setCvv(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Col>
      </Row>

      <Row
        className="mt-4 mb-3 d-flex justify-content-end submit-button-container"
        style={{ height: "53px" }}
      >
        <button
          onClick={handleAssuranceClick}
          type="submit"
          className="button-assurance"
        >
          Soumettre
          <svg
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="profil-fleche"
          >
            <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
            <path
              d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
              stroke="#015192"
              strokeWidth="1.375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Row>
    </div>
  );
}
