
import Faq from '../../Components/Faq';
import { Col, Container, Row } from 'react-bootstrap';
import "./styles.css"
export default function AdminFAQ(){


    const AdminFAQStyles = {
        backgroundColor: 'rgba(255, 255, 255, .61)',
        borderRadius: '1em',
        
    }

    const data = [
        {
            question: 'Comment je télécharge l’application Universe France success ?',
            response: "Je peux télécharger l’application Universe France success sur mon téléphone depuis l’App Store et Google Play Store. Si mon téléphone n'a pas accès à l'une de ces plateformes, je ne peux pas installer l’application."
        },
        {
            question: "Comment créer mon espace sur l’application Universe France success ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        {
            question: "L’inscription en ligne est-elle payante ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        {
            question: "Devrai-je créer un compte sur l’application Universe France success si j’ai déjà ouvert auparavant un espace sur espace.Universe France success.com ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        
    ]

    return(
        <div className='messageAdm'>
                <Col className="adminFAQ" style={AdminFAQStyles}>
                <h4 className='titleFAQADM'>Aide A l'acces et A la Connexion</h4>
                <Faq data={data}/>

                <h4 className='titleFAQADM'>Compte de Paiement</h4>
                
                <Faq data={data}/>

                <h4 className='titleFAQADM'>Remise de Cheque</h4>
                <Faq data={data}/>
                
                </Col>
        </div>
    )
}