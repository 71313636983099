import { Row, Col, Form } from 'react-bootstrap';

import { useState,useEffect } from 'react';
import axios from 'axios';
import "./Profil.css";
import { useRef } from 'react';
import CustomCountrySelect from '../../StudentModule/Layouts/CountrySelect';

export default function ProfilParent(){

  const formRef = useRef(null);

 const [countries,setCountries]=useState([]);
 
 
 const [villes,setVilles]=useState("");
 const [villesDestination,setVillesDestination]=useState([])
 
 const [showInputSecteur, setShowInputSecteur] = useState(false);



 const [showInputDiplome, setShowInputDiplome] = useState(false);


 //useState pour backend
 const [prenom,setPrenom]=useState("");
 const [nom,setNom]=useState("");
 const [tel,setTel]=useState("");
 const [codeCountry,setCodeCountry]=useState("");
 const [pays,setPays]=useState("");
 const [dateN,setDateN]=useState("");
 const [ville,setVille]=useState("");
 const [paysOrigin,setPaysOrigin]=useState("");
 const [email,setEmail]=useState("");
 const [nomPere,setNomPere]=useState("");
 const [emailPere,setEmailPere]=useState("");
 const [telPere,setTelPere]=useState("");
 const [codeCountryPere,setCodeCountryPere]=useState("");

 const [nomMere,setNomMere]=useState("");
 const [emailMere,setEmailMere]=useState("");
 const [telMere,setTelMere]=useState("");
 const [codeCountryMere,setCodeCountryMere]=useState("");

 const [diplome, setDiplome] = useState('');
 const [autreDiplome, setAutreDiplome] = useState('');
 const [secteurSouhaite, setSecteurSouhaite] = useState('');
 const [autreSecteur, setAutreSecteur] = useState('');
 const [paysDestination,setPaysDestination]=useState("");
 const [villeDestination,setVilleDestination]=useState("");

 const [university,setUniversity]=useState("");
 const [annee,setAnnee]=useState("");
   

    function handleCodeCountry(c){
        setCodeCountry(c);
    }

    function handleCodeCountryPere(c){
      setCodeCountryPere(c)
    }

    function handleCodeCountryMere(c){
      setCodeCountryMere(c)
    }



    const secteurs = [
        "Informatique et Technologie",
        "Ingénierie",
        "Santé et Médecine",
        "Éducation et Formation",
        "Marketing et Communication",
        "Finance et Comptabilité",
        "Arts et Culture",
        "Sciences Sociales et Comportementales",
        "Commerce et Ventes",
        "Droit et Juridique",
        "Architecture et Urbanisme",
        "Hôtellerie et Restauration",
        "Tourisme et Loisirs",
        "Agriculture et Environnement",
        "Transports et Logistique",
        "Énergie et Environnement",
        "Industrie et Fabrication",
        "Recherche et Développement",
        "Pharmaceutique et Biotechnologie",
        "Autre"
      ];
    
      const diplomes = [
        "Aucun diplôme",
        "Baccalauréat",
        "Licence",
        "Master",
        "Doctorat",
        "Diplôme d'ingénieur",
        "Diplôme de technicien",
        "BTS/DUT",
        "Certificat professionnel",
        "Autre"
      ];
      const handleChangeSecteur = (e) => {
        const selectedSecteur = e.target.value;
        setSecteurSouhaite(selectedSecteur);
        setShowInputSecteur(selectedSecteur === "Autre"); // Affiche l'input si "Autre" est sélectionné
      };
    
      const handleInputChangeSecteur = (e) => {
        setAutreSecteur(e.target.value);
      };

      const handleChangeDiplome = (e) => {
        const selectedDiplome = e.target.value;
        setDiplome(selectedDiplome);
        setShowInputDiplome(selectedDiplome === "Autre"); // Affiche l'input si "Autre" est sélectionné
      };
    
      const handleInputChangeDiplome = (e) => {
        setAutreDiplome(e.target.value);
      };
    

      const handleSubmit = (e) => {
       e.preventDefault();
       console.log("student: ",prenom,nom,codeCountry,tel,pays,dateN,ville,paysOrigin,email)
       console.log("pere: ",nomPere,codeCountryPere,telPere,emailPere)
       console.log("Mere: ",nomMere,codeCountryMere,telMere,emailMere)

       console.log(diplome,secteurSouhaite,paysDestination,villeDestination,university,annee)

      };











    //Récupération des pays
   useEffect(() => {
        const fetchCountries = async () => {
          try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            
           
            const countryData = response.data
              .map(country => ({
                name: country.translations.fra.common, 
                code: country.name.common,
              }))
              .sort((a, b) => a.name.localeCompare(b.name)); 
    
            setCountries(countryData);
          } catch (error) {
            console.error('Erreur lors de la récupération des pays:', error);
          }
        };
    
        fetchCountries();
      }, []);
    
    
      const handlePays = async (e) => {
        const countryName = e.target.value; 
        setPays(countryName);
    
        if (countryName) {
            console.log(countryName)
          try {
            
            const citiesResponse = await axios.post('https://countriesnow.space/api/v0.1/countries/cities', {
             country: countryName 
            });
            
            const cities = citiesResponse.data.data; 
            const sortedCities = cities.sort((a, b) => a.localeCompare(b)); // Sort cities
    
            setVilles(sortedCities); // Set the sorted cities in state
          } catch (error) {
            console.error('Erreur lors de la récupération des villes:', error);
          }
        } else {
          setVilles([]); 
        }
      };
    
      const handlePaysDestination = async (e) => {
        const countryName = e.target.value; 
        setPaysDestination(countryName);
    
        if (countryName) {
            console.log(countryName)
          try {
            
            const citiesResponse = await axios.post('https://countriesnow.space/api/v0.1/countries/cities', {
             country: countryName 
            });
            
            const cities = citiesResponse.data.data; 
            const sortedCities = cities.sort((a, b) => a.localeCompare(b)); // Sort cities
    
            setVillesDestination(sortedCities); // Set the sorted cities in state
          } catch (error) {
            console.error('Erreur lors de la récupération des villes:', error);
          }
        } else {
          setVillesDestination([]); 
        }
      };
    


             return<>
                <Row  className="student-profil-container mt-3">
                <Col xl={12} lg={12} md={12} sm={12} xs={12}  >
                <h3 className="student-profil-coordonnees-title">Mes coordonnées</h3>
                <Form className='mt-4' ref={formRef}>
                <Row className=' mt-3'>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                    <Form.Group>
                            <Form.Label>
                              Prénom
                            </Form.Label>
                            <Form.Control type="text" name="prenom" placeholder='Entrez votre prénom' onChange={(e)=>{setPrenom(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                              Nom de famille
                            </Form.Label>
                            <Form.Control type="text" name="nom"  placeholder='Entrez votre nom de famille' onChange={(e)=>{setNom(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                </Row>
                <Row  className=' mt-3'>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label>
                              Numéro de téléphone
                            </Form.Label>
                            <Row>
                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                            <CustomCountrySelect codeCountry={handleCodeCountry}/>
                            </Col>
                            <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                            <Form.Control type="text" name="tel" placeholder='XXX XXX XXXX' onChange={(e)=>{setTel(e.target.value)}}/>
                            </Col>
                            </Row>
                    </Form.Group>
                    </Col>

                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                             Pays
                            </Form.Label>
                            <Form.Select name="pays" defaultValue="" onChange={handlePays}>
                                <option value="" >
                                    Sélectionnez le pays
                                </option>
                                {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                            ))}
                          
                            </Form.Select>

                    </Form.Group>
                    </Col>
                   
                </Row>
                <Row  className=' mt-3'>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label>
                             Date de naissance
                            </Form.Label>
                            <Form.Control type="date" name="dateN" onChange={(e)=>{setDateN(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                              Ville
                            </Form.Label>
                            <Form.Select name="ville" defaultValue=""  onChange={(e)=>{setVille(e.target.value)}}>
                                <option value="" >
                                    Sélectionnez la ville
                                </option>
                                {villes.length>0 && villes.map((ville) => (
                              <option key={ville} value={ville}>
                              {ville}
                            </option>
                            ))}
                          
                            </Form.Select>

                    </Form.Group>
                    </Col>
                </Row>
                <Row  className=' mt-3'>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label>
                            Pays d'origine
                            </Form.Label>
                            <Form.Select name="paysOrigin" defaultValue="" onChange={(e)=>{setPaysOrigin(e.target.value)}}>
                                <option value="" >
                                    Sélectionnez le pays d'origine
                                </option>
                                {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                            ))}
                          
                            </Form.Select>
                    </Form.Group>

                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                              Adresse Email
                            </Form.Label>
                            <Form.Control type="email" name="email" placeholder='abc@gmail.com' onChange={(e)=>{setEmail(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                </Row>
                </Form>

                </Col>

       
                </Row>

                <Row className="student-profil-container2 mt-4" > 
                <Col xl={12} lg={12} md={12} sm={12} xs={12}  >
                <h3 className="student-profil-parents-title">les détails de mes parents</h3>
                <Form className='mt-5'>
                <Row className=' mt-3'>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Form.Group>
                            <Form.Label>
                            Nom du père
                            </Form.Label>
                            <Form.Control type="text" name="nomPere" placeholder="Entrez le nom de votre père" onChange={(e)=>{setNomPere(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                              Adresse email du père
                            </Form.Label>
                            <Form.Control type="text" name="nom" placeholder='abc@gmail.com' onChange={(e)=>{setEmailPere(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                             Numéro de téléphone du père
                            </Form.Label>
                            <Row>
                            <Col xl={4} lg={4} md={3} sm={12} xs={12}>
                            <CustomCountrySelect codeCountry={handleCodeCountryPere} className="profil-selectCountry"/>
                            </Col>
                            <Col xl={8} lg={8} md={9} sm={12} xs={12}>
                            <Form.Control type="text" name="tel" placeholder='XXX XXX XXXX' onChange={(e)=>{setTelPere(e.target.value)}}/>
                            </Col>
                            </Row>

                    </Form.Group>
                    </Col>
                </Row>
              
                <Row className=' mt-3'>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Form.Group>
                            <Form.Label>
                            Nom de la mère
                            </Form.Label>
                            <Form.Control type="text" name="nomMere" placeholder="Entrez le nom de votre mère" onChange={(e)=>{setNomMere(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                              Adresse email de la mère
                            </Form.Label> 
                            <Form.Control type="email" name="emailMere" placeholder='abc@gmail.com' onChange={(e)=>{setEmailMere(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                             Numéro de téléphone de la mère
                            </Form.Label>
                            <Row>
                            <Col xl={4} lg={4} md={3} sm={12} xs={12}>
                            <CustomCountrySelect codeCountry={handleCodeCountryMere} />
                            </Col>
                            <Col xl={8} lg={8} md={9} sm={12} xs={12}>
                            <Form.Control type="text" name="tel" placeholder='XXX XXX XXXX' onChange={(e)=>{setTelMere(e.target.value)}}/>
                            </Col>
                            </Row>

                    </Form.Group>
                    </Col>
                </Row>
              
                
                </Form>

                </Col>

       
                
            </Row>

            <Row  className="student-profil-container3 mt-4">
                <Col xl={12} lg={12} md={12} sm={12} xs={12}  >
                <h3 className="student-profil-coordonnees-title">Mon Curriculum</h3>
                <Form className='mt-4'>
                <Row className=' mt-3'>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                    <Form.Group>
                            <Form.Label>
                            Dernier diplôme obtenu
                            </Form.Label>
                            <Form.Select value={diplome} onChange={handleChangeDiplome}>
          <option value="">Choisissez le dernier diplôme obtenu</option>
          {diplomes.map((diplome, index) => (
            <option key={index} value={diplome}>
              {diplome}
            </option>
          ))}
        </Form.Select>
        {showInputDiplome && <Form.Control type="text" name="autreDiplome" placeholder='Entrez le dernier diplôme obtenu' onChange={handleInputChangeDiplome} className='mt-3'/>}

                    </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                            Secteur souhaité
                            </Form.Label>
                            <Form.Select value={secteurSouhaite} onChange={handleChangeSecteur}>
          <option value="">Choisissez un secteur</option>
          {secteurs.map((secteur, index) => (
            <option key={index} value={secteur}>
              {secteur}
            </option>
          ))}
        </Form.Select>
        {showInputSecteur &&   <Form.Control type="text" name="autreSecteur" placeholder='Entrez le secteur souhaité' onChange={handleInputChangeSecteur} className='mt-3'/>}
                    </Form.Group>
                    </Col>
                </Row>
                <Row  className=' mt-3'>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label>
                            Pays de Destination
                            </Form.Label>
                            <Form.Select name="paysDestination" defaultValue="" onChange={handlePaysDestination}>
                                <option value="" >
                                    Sélectionnez le pays de destination
                                </option>
                                {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                            ))}
                          
                            </Form.Select>

                    </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                            Ville de destination
                            </Form.Label>
                           
                            <Form.Select name="villeDestination" defaultValue=""  onChange={(e)=>{setVilleDestination(e.target.value)}}>
                                <option value="" >
                                    Sélectionnez la ville de destination
                                </option>
                                {villesDestination.length>0 && villesDestination.map((ville) => (
                              <option key={ville} value={ville}>
                              {ville}
                            </option>
                            ))}
                          
                            </Form.Select>

                           
                    </Form.Group>
                    </Col>

                  
                </Row>
                <Row  className=' mt-3'>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label>
                            Université ou établissement ciblé
                            </Form.Label>
                            <Form.Control type="text" name="university" placeholder="Entrez université ou établissement ciblé" onChange={(e)=>{setUniversity(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                    
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                            <Form.Label className="label-student-attestation">
                            Année Scolaire
                            </Form.Label>
                            <Form.Control name="pays" type="text" placeholder="Entrez l'année scolaire" onChange={(e)=>{setAnnee(e.target.value)}}/>

                    </Form.Group>
                    </Col>
                </Row>

                
         
                </Form>

                </Col>

       
                </Row>
                <Row className='mt-4 mb-3 d-flex justify-content-end' >
                        

                        <button type="submit" className="button-profil" onClick={handleSubmit}>Enregistrer<svg  viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="profil-fleche">
                            <rect y="0.5" width="29" height="29" rx="14.5" fill="white"/>
                            <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg></button>

                </Row>
         
                </>             

}