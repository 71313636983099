import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./Parent.css"
import LocationSVG from '../../images/Location'
import profile from "../../images/profParent.png"
import CakeSvg from '../../images/CakeSvg'
import LittleStudent from '../../images/littleStudent'
import Gender from '../../images/Gender'
import MailSvg from '../../images/MailSvg'
import Live from '../../images/Live.png'
import heure from '../../images/heure.png'
import telecharger from '../../images/telecharger.png'
import TelephoneSVG from '../../images/TelephoneSVG'
import { useNavigate } from 'react-router'
function Parent() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Retourne à la page précédente
  };
  return (
    <div>
      <Row>
        <Col>
                <span className='RouteStu'>
                    <p className='routeStu'>Parents</p>
                    <p className='RouteStuRoute'>/ Parent Détails </p>
                </span>
        </Col>
      <Col>
      <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
    </Col> 
      </Row>
      <Row className='ParentRow'>
        <Row className='headerParent'>
          <Col sm={2} className='RickImg'>
            <span className='parentCercle'>
              <img src={profile} className='imgProParent'/>
            </span>
          </Col>
          <Col sm={3}  className='secondParent'>
            <span >
              <p className='parentNom'>Rick Gourd</p>
              <p className='sousNomParent'>Brother</p>
              <button className='locationStu'>
                <span className='locationSvg'>
                  <LocationSVG/>
                 </span>
                Italy
              </button>
            </span>
          </Col>
          <Col sm={7} className='persPartWidth '>
           <span>
            <p className='InfoTextPers'>PERSONAL INFORMATION</p>
            <Row>
            <Col sm={6} className='TelLeftCol'>
              <span  className='persParent'>
                <span>
                  <TelephoneSVG/>
                </span>
                <p className='textPersParent'>(567) 466 5665</p>
              </span>
              <span  className='persParent'>
                <span>
                  <MailSvg/>
                </span>
                <p className='textPersParent'>mmc@gmail.com</p>
              </span>
            </Col>
            <Col className='TelLeftCol' sm={5}>
              <span  className='persParent'>
                <span className='secondSvgPers'>
                  <Gender/>
                </span>
                <p className='textPersParent'>Male</p>
              </span>
              <span  className='persParent'>
                <span className='secondSvgPers'>
                  <CakeSvg/>
                </span>
                <p className='textPersParent'>30 june 1990</p>
              </span>
            </Col>
            </Row>
            
           </span>
          </Col>
        </Row>
        <Row className='Row'>
          <Col>
            <p className='RecentPar'>Activités Récente</p>
          </Col>
        </Row>
        <Row>
          <Col className='supportCol'>
            <div className='supportDiv'>
              <img src={Live} className='supportImg' />
              <div className='suppTextlive' >
                <p className='supportLive'>Assistance en direct</p>
                <p className='sousSupportLive'>Rick gourd has started the live support chat</p>
              </div>
            </div>
            <div className='rightLive' >
              <img src={heure} className='heureRight'/>
              <p className='rightsupport'>
              Aujourd'hui, 11:10 am
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='supportCol'>
            <div className='supportDiv1'>
              <img src={telecharger} className='supportImg' />
              <div className='suppTextlive' >
                <p className='supportLive'>Télécharger le PDF</p>
                <p className='sousSupportLive'>Rick Gourd a téléchargé le PDF - Ressources et documentation</p>
              </div>
            </div>
            <div className='rightLive' >
              <img src={heure} className='heureRight'/>
              <p className='rightsupport1'>
              Hier, 11:10 am
              </p>
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default Parent
