import React from 'react'

function LittleStudent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
    <path fill="#000" d="m15.884 4.1-6.75-2.25a.422.422 0 0 0-.268 0L2.116 4.1a.422.422 0 0 0-.288.4v5.625a.422.422 0 1 0 .844 0v-5.04l2.72.907a4.36 4.36 0 0 0 1.569 6.295c-1.354.46-2.531 1.377-3.375 2.672a.422.422 0 0 0 .703.459C5.377 13.752 7.093 12.797 9 12.797c1.907 0 3.623.955 4.71 2.621a.422.422 0 0 0 .704-.461c-.844-1.293-2.02-2.21-3.375-2.672a4.359 4.359 0 0 0 1.566-6.295l3.279-1.09a.422.422 0 0 0 0-.8Zm-3.368 4.337a3.516 3.516 0 1 1-6.284-2.165l2.634.878c.087.029.18.029.268 0l2.634-.878a3.5 3.5 0 0 1 .748 2.165ZM9 6.306 3.586 4.5 9 2.694 14.414 4.5 9 6.306Z"/>
  </svg>
  
  )
}

export default LittleStudent
