import PaymentHistoric from "../../Components/PaymentHistoric/PaymentHistoric";


export default function ParentPaymentHistoric(){  

    return(
            <PaymentHistoric/>
            
    )
}

