import React from 'react'
import Button from '../../Components/Button'
import { useNavigate } from 'react-router'

export default function TransactionDetails() {

    const RefundInfosItem = ({title, value}) =>{
        return(
            <div className='refundInfosItem'>
                <span className='refundInfosItemTitle'>{title}</span>
                <span className='refundInfosItemValue'>{value}</span>
            </div>
        )
    }

    const navigate = useNavigate()

    return (
        <main className='adminTransactionDetails' >
            <div className='backButton' onClick={()=>navigate(-1)}>Retour</div>
            <div className="refundInfosItems">
                <RefundInfosItem title={'Nom'} value={'Florence Dawg'}/>
                <RefundInfosItem title={'Date de demande remboursement'} value={'12 Decembre 2024'}/>
                <RefundInfosItem title={'Date d\'activation'} value={'01 Janvier 2025'}/>
                <RefundInfosItem title={'Montant'} value={'80 €'}/>
                <RefundInfosItem title={'Status remboursement'} value={'Partiellement remboursés'}/>
            </div>
            <Button value={'Générer remboursement'}/>

        </main >
    )
}
