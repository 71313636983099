
// import { Accordion } from "react-bootstrap";

// export default function Faq({data}) {

   
//     return(
               
//         <Accordion defaultActiveKey="0">
//             {
//                 data.map((data, index) => (
                    
//                     <Accordion.Item eventKey={index}>
//                         <Accordion.Header>{data.question}</Accordion.Header>
//                         <Accordion.Body>{data.response}</Accordion.Body>
//                     </Accordion.Item>
//                 ))
//             }                   
//         </Accordion>
           
//     )
// }

import bottomArrow from '../images/AdminLayout/Sidebar/Vector.png'
import { useState } from "react";
import "./style.css"
export default function Faq({data}) {

    const [activeIndex, setActiveIndex] = useState(undefined)
    const showMaskResponse = index => activeIndex == index ? setActiveIndex(null) : setActiveIndex(index)
    
    return(
        data.map((data,index) => (
            <div className="FAQ">
                <div className="FAQQuestion" onClick={()=> showMaskResponse(index)}>
                    {data.question}
                    <img  src={bottomArrow} alt="" />
                </div>
                {
                    activeIndex == index &&
                    <div className="FAQResponse">
                        {data.response}
                    </div>
                }
            </div>
        ))
    )
}