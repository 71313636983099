import React, { useState } from 'react';
import "./Price.css";
import { Container, Row, Col } from "react-bootstrap";
import check from "../../images/check.png"
function PriceAdmin() {
  const [activeItem, setActiveItem] = useState('Mensuel');
  const handleItemClick = (newHeadings) => {
    setActiveItem(newHeadings); // Update active item
  };

  const DataPlan = [
   
    {
      name: "Forfait Pro",
      price: "60 €",
      billingFrequency: "mois",
      billed: "facturé mensuellement",
      features: [
        "1 RIB français + 1 demande pour tous vos paiements liés à votre projet d'études en France avant votre arrivée.",
        "Paiement de vos frais d'inscription (caution) depuis votre pays d'origine",
        "Paiement de la réservation de votre hébergement depuis votre pays d'origine"
      ],
    },
    {
      name: "Forfait illimité",
      price: "120 €",
      billingFrequency: "mois",
      billed: "facturé mensuellement",
      features: [
        "1 RIB français + 1 demande pour tous vos paiements liés à votre projet d'études en France avant votre arrivée.",
        "Paiement de vos frais d'inscription (caution) depuis votre pays d'origine",
        "Paiement de la réservation de votre hébergement depuis votre pays d'origine"
      ],
    }
  ];

  return (
    <>
      <Row>
        <p className='pBuy'>Acheter un abonnement</p>
        <p className='SouspBuy'>Choisissez le plan qui vous convient.</p>
        <div className='divBtnPri'>
          <span className='spanTab'>
            <button className={`submenu ${activeItem === 'Mensuel' ? 'active' : ''}`} onClick={() => handleItemClick('Mensuel')}>
              Mensuel
            </button>
            <button className={`submenu ${activeItem === 'Annuel' ? 'active' : ''}`} onClick={() => handleItemClick('Annuel')}>
              Annuel
            </button>
          </span>
        </div>
        <div className='centerdivprix'>
        <div className='ChoosePlane'>
          <Row className='RowPrix'>
          <Col sm={4} className='CardPlan1' >
                <span className='headerPlan'>
                  <p className='titleFor'>Plan Stander</p>
                  {/* Conditionally rendering plan.description if it exists */}
                  <p className='rightPlan'>Le plus populaire</p>
                </span>
                <span className='headerPlan'>
                  <p className='prixPlan'>30 € </p>
                  <span className='headSide'>
                    <p className='sidePrix'> mois</p>
                    <p className='sidePrix'>facturé mensuellement</p>
                  </span>
                  
                </span>
                
                  
                    <span className='checkFeat'>
                      <img className='imgCheck' src={check} />
                      <p className='textCheck' >1 RIB français + 1 demande pour tous vos paiements liés à votre projet d'études en France avant votre arrivée.</p>
                    </span>
                    <span className='checkFeat'>
                      <img className='imgCheck' src={check} />
                      <p className='textCheck' >Paiement de vos frais d'inscription (caution) depuis votre pays d'origine</p>
                    </span>
                    <span className='checkFeat'>
                      <img className='imgCheck' src={check} />
                      <p className='textCheck' >Paiement de la réservation de votre hébergement depuis votre pays d'origine</p>
                    </span>
                   
                <span>
                  <button className='btnForfait'>
                  Choisir un forfait
                  </button>
                </span>
              </Col>
            {DataPlan.map((plan, index) => (
              <Col sm={4} className='CardPlan' key={index}>
                <span className='headerPlan'>
                  <p className='titleFor'>{plan.name}</p>
                  {/* Conditionally rendering plan.description if it exists */}
                  {plan.description && <p className='rightPlan'>{plan.description}</p>}
                </span>
                <span className='headerPlan'>
                  <p className='prixPlan'>{plan.price} </p>
                  <span className='headSide'>
                    <p className='sidePrix'> {plan.billingFrequency}</p>
                    <p className='sidePrix'>{plan.billed}</p>
                  </span>
                  
                </span>
                
                  {plan.features.map((feature, featureIndex) => {
                    return(
                      <span className='checkFeat'>
                      <img className='imgCheck' src={check} />
                      <p className='textCheck' key={featureIndex}>{feature}</p>
                    </span>
                    )
                  })}
                <span>
                  <button className='btnForfait'>
                  Choisir un forfait
                  </button>
                </span>
              </Col>
            ))}
          </Row>
        </div>
        </div>
      </Row>
    </>
  );
}

export default PriceAdmin;
