
import Message from "../../Components/Message/Message";
import { Col, Container, Row } from "react-bootstrap";

export default function StudentMessage(){
    

    return(
        <Container fluid >
            <Row className="mt-4">
             
            <Col className={`studentMessage`}>
                <Message leftMessageColor="rgba(144, 134, 221, 0.15)" rightMessageColor="#8B5CE5"/>


            </Col>
               
            </Row>
        </Container>
       
    )
}