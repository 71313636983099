import { useState } from "react"

import AdminSettingsLayout from "../AdminSettingsLayout"
import InputLabel from "../../../Components/InputLabel"
import Button from "../../../Components/Button"
import { Col, Container, Row } from "react-bootstrap"
import './ResetPassword.css'

export default function ResetPassword(){

    const [data, setData] = useState({
        newPassword: '',
        confirmPassword: ''
    })

    const updateFieldValue = e => setData(data => ({...data, [e.target.name]: e.target.value}))

    return(
        <Container>
            <Row>
            <Col>
            
                <AdminSettingsLayout mainTitle={'Réinitialisation de Mot de passe'}>
                    <div className="resetPassword">
                        <InputLabel name={'newPassword'} label={'Nouveau mot de passe'} onChange={e=>updateFieldValue(e)} placeholder={'Entrez le nouveau mot de passe'}/>
                        <InputLabel name={'confirmPassword'} label={'Confirmation mot de passe'} onChange={e=>updateFieldValue(e)} placeholder={'Confirmez le mot de passe'}/>
                    </div>
                    <div className="resetPasswordButton" >
                        <Button value={'Sauvegarder'}/>
                    </div>
                </AdminSettingsLayout>
            
                </Col>
            </Row>
        </Container>
    )
  
  }
  
