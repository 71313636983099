import React, { useEffect, useState } from 'react'
import "./Student.css"
import {useNavigate} from "react-router-dom"
import {Container,Col,Row, Table} from "react-bootstrap"
import profile from "../../images/etuAdm.png"
import show from "../../images/ShowEtu.png"
import delet from "../../images/deleteEtu.png"
import edit from "../../images/editEtu.png"
import Pagination from '../../Components/Pagination'
function Student() {
  const navigate=useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true); // État de chargement
    const [showDelete,setShowDelete]=useState(false)
    const [selectedElement,setSelectedElement]=useState([]);
    const [isChecked,setIsChecked]=useState(false)
    useEffect(() => {
        const totalPages = Math.ceil(dataStudent.length / itemsPerPage);
        let current = 0;

        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = dataStudent.slice(current, current + itemsPerPage);
            current += itemsPerPage;
        }

        setItemsPages(newItemsPages);
        setLoading(false); 
    }, [itemsPerPage]);
  const dataStudent = [
    {
      id: "01",
      name: "Robert Fox",
      dob: "12 May 1994",
      university: "University of Paris",
      schoolYear: "2020",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "France",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "02",
      name: "Robert Fox",
      dob: "30 May 1994",
      university: "University of Paris",
      schoolYear: "2005",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "Greenland",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "03",
      name: "Robert Fox",
      dob: "12 July 1994",
      university: "Paris-Sorbonne University",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "India",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "04",
      name: "Robert Fox",
      dob: "30 July 1994",
      university: "University of Paris",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "Germany",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "05",
      name: "Avo Oganda",
      dob: "12 Oct 1994",
      university: "Paris-Sorbonne University",
      schoolYear: "2020",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "Canada",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "06",
      name: "Robert Fox",
      dob: "30 Oct 1990",
      university: "Paris-Sorbonne University",
      schoolYear: "2005",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "France",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "07",
      name: "Ali Ansari",
      dob: "12 July 1992",
      university: "University of Paris",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "Italy",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "08",
      name: "Randy Orton",
      dob: "30 July 1990",
      university: "Paris-Sorbonne University",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "UAE",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "09",
      name: "Andrew Lisa",
      dob: "12 Oct 1993",
      university: "Paris-Sorbonne University",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "France",
      actionEmail: "robert@gmail.com"
    }
  ];

  const [elementsAfterDelete,setElementsAfterDelete]=useState(dataStudent)

  const handleDetail=()=>{
      navigate('details')
  }
  const handleEdit=()=>{
    navigate('modifier')
}
const handleAjouter=()=>{
  navigate('ajouter')
}

function handleChangeCheckbox(e){
  setIsChecked(e.target.checked)
}

function handleChangeCheckbox2(id){
  if(selectedElement.length> 0){
          const element=selectedElement.find((ele)=>{
            return ele.id===id
          })
          
          if(element==undefined){
            const newEle=dataStudent.find((ele)=>{
              return ele.id==id
            })
            setSelectedElement([...selectedElement,newEle])
          }
          else {
            const students=selectedElement.filter((stu)=>{
              return stu.id!=id
            })
            setSelectedElement(students)
          }
  }
  else{
    const newEle=dataStudent.find((ele)=>{
      return ele.id==id
    })
    setSelectedElement([...selectedElement,newEle])
  }


}
useEffect(()=>{
 
  if(isChecked){
    setSelectedElement(dataStudent)
  }
 else{
  setSelectedElement([])
 }
},[isChecked])


useEffect(()=>{
 
  if(selectedElement.length> 0){
    setShowDelete(true)
  }
  else{
    setShowDelete(false)
  }
 
},[selectedElement])

function handleDeleteMany(){
   
    const elementAfterDelete = dataStudent.filter((stu) => {
    
      const ele = selectedElement.find((etu) => etu.id === stu.id);
  
     
      return ele === undefined;
    });
  
    
    setElementsAfterDelete(elementAfterDelete);
  
}

  return (
    <div>
      <Row >
        <Col sm={12} className='btnflex'>
          <button className='addStu' onClick={handleAjouter}> Ajouter Un Nouveau </button>
        </Col>
      </Row>
     
      <Row className="mt-4" style={{marginBottom:"-15px"}}>
        <div className='d-flex align-items-center justify-content-between'>
       

        {showDelete && <div className='d-flex align-items-center ' onClick={handleDeleteMany}>
          <img src={delet} className='actionEtu' style={{marginTop:"-15px"}}/>
          <p  className="para-etu-admin"> Supprimer </p>
          </div>} 
        <div className='d-flex align-items-center ' >
        <p style={{marginRight:"15px",marginLeft:"15px"}} className="para-etu-admin"> Total: {dataStudent.length} </p>
        <p style={{marginRight:"20px"}} className="para-etu-admin"> Comptes supprimés: {selectedElement.length} </p>
        </div>
       
        </div>
      </Row>
      <Row >
        <Col sm={12} className='TabStuAdm'>
          <Table responsive>
            <table className='tableEtuAdm' >
              <tr>
              <th className='tdEtuAdm' > <input type="checkbox" name="all" onChange={handleChangeCheckbox}/> </th>
                <th className='thEtu NO'>S.NO</th>
                <th className='thEtu Nom'>Name</th>
                <th className='thEtu Dob'>Date De Naissance</th>
                <th className='thEtu Uni'>Université</th>
                <th className='thEtu YR'>Année Scolaire</th>
                <th className='thEtu M'>Genre</th>
                <th className='thEtu Phone'>Telephone</th>
                <th className='thEtu Email'>Email ID</th>
                <th className='thEtu Country'>Pays</th>
                <th className='thEtu'>Action</th>
              </tr>
              {
                loading ? ( // Afficher le loader si les données sont en cours de chargement
                    <tr>
                        <td style={{ textAlign: 'center', width: '100%' }}>
                            Chargement des données...
                        </td>
                    </tr>
                ) : (
                elementsAfterDelete.map((val,index)=>{
                  return(

                    <tr className='trEtuAdm' key={index}>
                      <td className='tdEtuAdm'> <input checked={selectedElement.some(ele => ele.id === val.id)}  type="checkbox" name="userSelected" onChange={()=> {handleChangeCheckbox2(val.id)}}/> </td>
                      <td className='tdEtuAdm'>{val.id} </td>
                      <td className='nameTh'> 
                      <img src={profile}  className='etuimg'/>
                      {val.name} </td>
                      <td className='tdEtuAdm'>{val.dob} </td>
                      <td className='tdEtuAdm'>{val.university} </td>
                      <td className='tdEtuAdm'>{val.schoolYear} </td>
                      <td className='tdEtuAdm'>{val.gender} </td>
                      <td className='tdEtuAdm'>{val.phoneNumber} </td>
                      <td className='tdEtuAdm'>{val.email} </td>
                      <td className='tdEtuAdm'>{val.country} </td>
                      <td className='tdEtuAdm'>
                        <img src={show} onClick={handleDetail} className='actionEtu'/>
                        <img src={edit} onClick={handleEdit} className='actionEtu'/>
                        <img src={delet} className='actionEtu'/>
                      </td>
                    </tr>
                  )
                })
                )
              }
            </table>
          </Table>
          <Pagination itemsPages={itemsPages} setItemsPerPage={setItemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </Col>
      </Row>
    </div>
  )
}

export default Student
