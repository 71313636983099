import { Container, Row, Col, Form, Button } from "react-bootstrap";
import CustomCountrySelect from "../../StudentModule/Layouts/CountrySelect";
import { useState, useEffect, useRef } from "react";
import "./Profil.css";
import { success, error } from "../../StudentModule/utils/alerts";
import { useNavigate } from "react-router-dom";
import api from "../../StudentModule/api/axiosInstance";
import countries from '../../Layout/COUNTRY.json'
import findCitiesOfCountry from "../../Layout/findCitiesOfCountry";


export default function Profil() {
  const formRef = useRef(null);
  const [villes, setVilles] = useState("");
  const [villesDestination, setVillesDestination] = useState([]);
  const [showInputSecteur, setShowInputSecteur] = useState(false);
  const [showInputDiplome, setShowInputDiplome] = useState(false);

  const navigate = useNavigate();

  // Profile States
  const [username, setUsername] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [tel, setTel] = useState("");
  const [codeCountry, setCodeCountry] = useState("");
  const [pays, setPays] = useState("");
  const [dateN, setDateN] = useState("");
  const [ville, setVille] = useState("");
  const [paysOrigin, setPaysOrigin] = useState("");
  const [email, setEmail] = useState("");
  const [nomPere, setNomPere] = useState("");
  const [emailPere, setEmailPere] = useState("");
  const [telPere, setTelPere] = useState("");
  const [codeCountryPere, setCodeCountryPere] = useState("");
  const [nomMere, setNomMere] = useState("");
  const [emailMere, setEmailMere] = useState("");
  const [telMere, setTelMere] = useState("");
  const [codeCountryMere, setCodeCountryMere] = useState("");
  const [diplome, setDiplome] = useState("");
  const [autreDiplome, setAutreDiplome] = useState("");
  const [secteurSouhaite, setSecteurSouhaite] = useState("");
  const [autreSecteur, setAutreSecteur] = useState("");
  const [paysDestination, setPaysDestination] = useState("");
  const [villeDestination, setVilleDestination] = useState("");
  const [university, setUniversity] = useState("");
  const [annee, setAnnee] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    // if (!accessToken) {
    //   navigate("/etudiant");
    // }
  }, [navigate]);

  function handleCodeCountry(c) {
    setCodeCountry(c);
  }

  function handleCodeCountryPere(c) {
    setCodeCountryPere(c);
  }

  function handleCodeCountryMere(c) {
    setCodeCountryMere(c);
  }

  const secteurs = [
    "Informatique et Technologie",
    "Ingénierie",
    "Santé et Médecine",
    "Éducation et Formation",
    "Marketing et Communication",
    "Finance et Comptabilité",
    "Arts et Culture",
    "Sciences Sociales et Comportementales",
    "Commerce et Ventes",
    "Droit et Juridique",
    "Architecture et Urbanisme",
    "Hôtellerie et Restauration",
    "Tourisme et Loisirs",
    "Agriculture et Environnement",
    "Transports et Logistique",
    "Énergie et Environnement",
    "Industrie et Fabrication",
    "Recherche et Développement",
    "Pharmaceutique et Biotechnologie",
    "Autre",
  ];

  const diplomes = [
    "Aucun diplôme",
    "Baccalauréat",
    "Licence",
    "Master",
    "Doctorat",
    "Diplôme d'ingénieur",
    "Diplôme de technicien",
    "BTS/DUT",
    "Certificat professionnel",
    "Autre",
  ];
  const handleChangeSecteur = (e) => {
    const selectedSecteur = e.target.value;
    setSecteurSouhaite(selectedSecteur);
    setShowInputSecteur(selectedSecteur === "Autre"); // Affiche l'input si "Autre" est sélectionné
  };

  const handleInputChangeSecteur = (e) => {
    setAutreSecteur(e.target.value);
  };

  const handleChangeDiplome = (e) => {
    const selectedDiplome = e.target.value;
    setDiplome(selectedDiplome);
    setShowInputDiplome(selectedDiplome === "Autre"); // Affiche l'input si "Autre" est sélectionné
  };

  const handleInputChangeDiplome = (e) => {
    setAutreDiplome(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      "student: ",
      prenom,
      nom,
      codeCountry,
      tel,
      pays,
      dateN,
      ville,
      paysOrigin,
      email
    );
    console.log("pere: ", nomPere, codeCountryPere, telPere, emailPere);
    console.log("Mere: ", nomMere, codeCountryMere, telMere, emailMere);

    console.log(
      diplome,
      secteurSouhaite,
      paysDestination,
      villeDestination,
      university,
      annee
    );
    try {
      await updateProfileFully();
      console.log("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handlePays = async (e) => {
    const countryName = e.target.value;
    setPays(countryName);

    
    setVilles(findCitiesOfCountry(countryName));
  };

  const handlePaysDestination = async (e) => {
    const countryName = e.target.value;
    setPaysDestination(countryName);

    
    setVillesDestination(findCitiesOfCountry(countryName));
    
  };

  // Fetch profile on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const response = await api.get("/student/profile/");
        const profileData = response.data;

        // Set state based on API response
        setUsername(profileData.username || "");
        setPrenom(profileData.first_name || "");
        setNom(profileData.last_name || "");
        setTel(profileData.phone_number || "");
        setCodeCountry(profileData.country_code || "");
        setPays(profileData.country || "");
        setDateN(profileData.date_of_birth || "");
        setVille(profileData.town || "");
        setPaysOrigin(profileData.nationality || "");
        setEmail(profileData.email || "");
        setNomPere(profileData.guardian.father_name || "");
        setEmailPere(profileData.guardian.father_email || "");
        setTelPere(profileData.guardian.father_phone_number || "");
        setCodeCountryPere(profileData.guardian.father_country_code || "");
        setNomMere(profileData.guardian.mother_name || "");
        setEmailMere(profileData.guardian.mother_email || "");
        setTelMere(profileData.guardian.mother_phone_number || "");
        setCodeCountryMere(profileData.guardian.mother_country_code || "");
        setDiplome(profileData.program.last_degree_obtained || "");
        setVilleDestination(profileData.program.destination_city || "");
        setPaysDestination(profileData.program.destination_country || "");
        setSecteurSouhaite(profileData.program.desired_sector || "");
        setUniversity(profileData.program.university_institution || "");
        setAnnee(profileData.program.academic_year || "");
      } catch (err) {
        // Show error toast
        error("Erreur lors de la récupération du profil.");
      }
    };

    fetchProfile();
  }, []);

  // Method for full profile update with enhanced error logging
  const updateProfileFully = async () => {
    try {
      const profileData = {
        first_name: prenom,
        last_name: nom,
        phone_number: tel,
        country_code: codeCountry,
        country: pays,
        date_of_birth: dateN,
        town: ville,
        nationality: paysOrigin,
        email: email,
        username: username,
        guardian: {
          father_name: nomPere,
          father_email: emailPere,
          father_phone_number: telPere,
          father_country_code: codeCountryPere,
          mother_name: nomMere,
          mother_email: emailMere,
          mother_phone_number: telMere,
          mother_country_code: codeCountryMere,
        },
        program: {
          last_degree_obtained: diplome,
          destination_city: villeDestination,
          destination_country: paysDestination,
          desired_sector: secteurSouhaite,
          university_institution: university,
          academic_year: annee,
        },
      };

      console.log("Attempting full profile update:", profileData);

      const response = await api.put("/student/profile/", profileData);

      success("Profil mis à jour avec succès.");
      console.log("Full profile update successful:", response.data);
      return response.data;
    } catch (err) {
      console.error("Erreur lors de la mise à jour complète du profil:", err);
      error("Erreur lors de la mise à jour du profil.");
      throw err;
    }
  };
  
  // Method for partial profile update with enhanced error logging
  const updateProfilePartially = async () => {
    try {
      const profileData = {};
      if (prenom) profileData.first_name = prenom;
      if (nom) profileData.last_name = nom;
      if (tel) profileData.phone_number = tel;
      if (codeCountry) profileData.country_code = codeCountry;
      if (pays) profileData.country = pays;
      if (dateN) profileData.date_of_birth = dateN;
      if (ville) profileData.town = ville;
      if (paysOrigin) profileData.nationality = paysOrigin;
      if (email) profileData.email = email;

      const guardianData = {};
      if (nomPere) guardianData.father_name = nomPere;
      if (emailPere) guardianData.father_email = emailPere;
      if (telPere) guardianData.father_phone_number = telPere;
      if (codeCountryPere) guardianData.father_country_code = codeCountryPere;
      if (nomMere) guardianData.mother_name = nomMere;
      if (emailMere) guardianData.mother_email = emailMere;
      if (telMere) guardianData.mother_phone_number = telMere;
      if (codeCountryMere) guardianData.mother_country_code = codeCountryMere;

      if (Object.keys(guardianData).length > 0) {
        profileData.guardian = guardianData;
      }

      const programData = {};
      if (diplome) programData.last_degree_obtained = diplome;
      if (villeDestination) programData.destination_city = villeDestination;
      if (paysDestination) programData.destination_country = paysDestination;
      if (secteurSouhaite) programData.desired_sector = secteurSouhaite;
      if (university) programData.university_institution = university;
      if (annee) programData.academic_year = annee;

      if (Object.keys(programData).length > 0) {
        profileData.program = programData;
      }

      console.log("Attempting partial profile update:", profileData);

      const response = await api.patch("/student/profile/", profileData);

      success("Profil mis à jour partiellement avec succès.");
      console.log("Partial profile update successful:", response.data);
      return response.data;
    } catch (err) {
      console.error("Erreur lors de la mise à jour partielle du profil:", err);
      error("Erreur lors de la mise à jour partielle du profil.");
      throw err;
    }
  };


  return (
    < div className="animParent">
      <Row className="student-profil-container  mt-3">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-profil-coordonnees-title">Mes coordonnées</h3>
          <Form className="mt-4" ref={formRef}>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="prenom"
                    value={prenom}
                    placeholder="Entrez votre prénom"
                    onChange={(e) => {
                      setPrenom(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Nom de famille
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    value={nom}
                    placeholder="Entrez votre nom de famille"
                    onChange={(e) => {
                      setNom(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Numéro de téléphone</Form.Label>
                  <Row>
                    <Col xl={4} lg={4} md={3} sm={12} xs={12} style={{marginTop:"1px"}}>
                      <CustomCountrySelect codeCountry={handleCodeCountry} />
                    </Col>
                    <Col xl={8} lg={8} md={9} sm={12} xs={12}>
                      <Form.Control
                        type="text"
                        name="tel"
                        placeholder="XXX XXX XXXX"
                        onChange={(e) => {
                          setTel(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>

              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Pays
                  </Form.Label>
                  <Form.Select
                    name="pays"
                    defaultValue=""
                    onChange={handlePays}
                  >
                    <option value="">Sélectionnez le pays</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Date de naissance</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateN"
                    value={dateN}
                    onChange={(e) => {
                      setDateN(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Ville
                  </Form.Label>
                  {
                    villes.length > 0 ?
                    <Form.Select
                    name="ville"
                    defaultValue=""
                    onChange={(e) => {
                      setVille(e.target.value);
                    }}
                  >
                    <option value="">Sélectionnez la ville</option>
                    {villes.length > 0 &&
                      villes.map((ville) => (
                        <option key={ville} value={ville}>
                          {ville}
                        </option>
                      ))}
                  </Form.Select> :
                  <Form.Control
                  type="email"
                  name="ville"
                  value={ville}
                  placeholder="Entrez la ville"
                    onChange={(e) => {
                      setVille(e.target.value);
                    }}
                />
                  }
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Pays d'origine</Form.Label>
                  <Form.Select
                    name="paysOrigin"
                    defaultValue=""
                    onChange={(e) => {
                      setPaysOrigin(e.target.value);
                    }}
                  >
                    <option value="">Sélectionnez le pays d'origine</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Adresse Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={email}
                    placeholder="abc@gmail.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row className="student-profil-container2 mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-profil-parents-title">
            les détails de mes parents
          </h3>
          <Form className="mt-5">
            <Row className=" mt-3">
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Nom du père</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomPere"
                    value={nomPere}
                    placeholder="Entrez le nom de votre père"
                    onChange={(e) => {
                      setNomPere(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Adresse email du père
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    value={emailPere}
                    placeholder="abc@gmail.com"
                    onChange={(e) => {
                      setEmailPere(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Numéro de téléphone du père
                  </Form.Label>
                  <Row>
                    <Col xl={5} lg={5} md={3} sm={12} xs={12}>
                      <CustomCountrySelect
                        codeCountry={handleCodeCountryPere}
                        className="profil-selectCountry"
                      />
                    </Col>
                    <Col xl={7} lg={7} md={9} sm={12} xs={12}>
                      <Form.Control
                        type="text"
                        name="tel"
                        value={telPere}
                        placeholder="XXX XXX XXXX"
                        onChange={(e) => {
                          setTelPere(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <Row className=" mt-3">
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Nom de la mère</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomMere"
                    value={nomMere}
                    placeholder="Entrez le nom de votre mère"
                    onChange={(e) => {
                      setNomMere(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Adresse email de la mère
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="emailMere"
                    value={emailMere}
                    placeholder="abc@gmail.com"
                    onChange={(e) => {
                      setEmailMere(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Numéro de téléphone de la mère
                  </Form.Label>
                  <Row>
                    <Col xl={5} lg={5} md={3} sm={12} xs={12}>
                      <CustomCountrySelect
                        codeCountry={handleCodeCountryMere}
                      />
                    </Col>
                    <Col xl={7} lg={7} md={9} sm={12} xs={12}>
                      <Form.Control
                        type="text"
                        name="telMere"
                        value={telMere}
                        placeholder="XXX XXX XXXX"
                        onChange={(e) => {
                          setTelMere(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row className="student-profil-container3 mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-profil-coordonnees-title">Mon Curriculum</h3>
          <Form className="mt-4">
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Dernier diplôme obtenu</Form.Label>
                  <Form.Select value={diplome} onChange={handleChangeDiplome}>
                    <option value="">
                      Choisissez le dernier diplôme obtenu
                    </option>
                    {diplomes.map((diplome, index) => (
                      <option key={index} value={diplome}>
                        {diplome}
                      </option>
                    ))}
                  </Form.Select>
                  {showInputDiplome && (
                    <Form.Control
                      type="text"
                      name="autreDiplome"
                      placeholder="Entrez le dernier diplôme obtenu"
                      onChange={handleInputChangeDiplome}
                      className="mt-3"
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Secteur souhaité
                  </Form.Label>
                  <Form.Select
                    value={secteurSouhaite}
                    onChange={handleChangeSecteur}
                  >
                    <option value="">Choisissez un secteur</option>
                    {secteurs.map((secteur, index) => (
                      <option key={index} value={secteur}>
                        {secteur}
                      </option>
                    ))}
                  </Form.Select>
                  {showInputSecteur && (
                    <Form.Control
                      type="text"
                      name="autreSecteur"
                      placeholder="Entrez le secteur souhaité"
                      onChange={handleInputChangeSecteur}
                      className="mt-3"
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Pays de Destination</Form.Label>
                  <Form.Select
                    name="paysDestination"
                    defaultValue=""
                    onChange={handlePaysDestination}
                  >
                    <option value="">
                      Sélectionnez le pays de destination
                    </option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Ville de destination
                  </Form.Label>

                  {
                    villesDestination.length > 0 ?
                    <Form.Select
                    name="villeDestination"
                    defaultValue=""
                    onChange={(e) => {
                      setVilleDestination(e.target.value);
                    }}
                  >
                    <option value="">
                      Sélectionnez la ville de destination
                    </option>
                    {villesDestination.length > 0 &&
                      villesDestination.map((ville) => (
                        <option key={ville} value={ville}>
                          {ville}
                        </option>
                      ))}
                  </Form.Select> :
                  <Form.Control
                      type="text"
                      name="villeDestination"
                      value={villeDestination}
                      placeholder="Entrez la ville de destination"
                      onChange={(e) => {
                        setVilleDestination(e.target.value);
                      }}
                    />
                  }
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Université ou établissement ciblé</Form.Label>
                  <Form.Control
                    type="text"
                    name="university"
                    value={university}
                    placeholder="Entrez université ou établissement ciblé"
                    onChange={(e) => {
                      setUniversity(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Année Scolaire
                  </Form.Label>
                  <Form.Control
                    name="annee"
                    value={annee}
                    type="text"
                    placeholder="Entrez l'année scolaire"
                    onChange={(e) => {
                      setAnnee(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-4 mb-3 d-flex justify-content-end">
        <button type="submit" className="button-profil" onClick={handleSubmit}>
          Enregistrer
          <svg
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="profil-fleche"
          >
            <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
            <path
              d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
              stroke="#015192"
              strokeWidth="1.375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Row>
    </div>
  );
}