import React, { useRef, useState } from 'react'
import flag from "../../images/Flag.png"
import { Col, Container, Row } from 'react-bootstrap'
import "./Profil.css"
import nationalities from "../../StudentModule/Layouts/nationalities.json"
// import CustomCountrySelect from '../../../StudentModule/Layouts/CountrySelect'
import next from "../../images/nextAdd.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomCountrySelect from '../../StudentModule/Layouts/CountrySelect'
import countries from '../../Layout/COUNTRY.json'
function ProfileParentModule() {
    const [codeCountry, setCodeCountry] = useState('+33')
    const updateCodeCountry = (code) => {
        setCodeCountry(code)
    }
  
  return (
        
        <div className='animParent'>
            <Col className="addStu2">
                <Row>
                    <Col sm={6}>
                        <p className='titleEtuP'>Mes coordonnées</p>
                    </Col>
                    

                </Row>
                <Row className='profilformgroup'>
                    <Col >
                        <p className='labelInp1'>Prénom</p>
                        <input type='text' className='inpStu1' placeholder='Entrez votre prénom'/>
                    </Col>
                    <Col  className='leftColProfil'>
                        <p className='labelInp1'>Nom de famille</p>
                        <input type='text' className='inpStu1' placeholder='Entrez votre nom'/>
                    </Col>
                </Row>
                <Row className='profilformgroup'>
                    <Col>
                        <p className='labelInp1'>Numero de téléphone</p>
                        <span className='spanTel'>
                            <CustomCountrySelect codeCountry = {updateCodeCountry}/>
                            <input type='text' placeholder='XXX XXX XXXX' className='inpTelEtuProfil'/>
                        </span>
                    </Col>
                    <Col className='leftColProfil'>
                    <p className='labelInp1'>Ville</p>
                        <input type='text' className='inpStu1' placeholder='Entrez la ville de résidence'/>
                        
                    </Col>
                </Row>
                <Row className='profilformgroup'>
                    <Col>
                    <p className='labelInp1'>Date De Naissance</p>
                        <input type='date' className='inpStu1' placeholder='Entrez votre nom'/>
                        
                    </Col>
                    <Col  className='leftColProfil'>
                    <p className='labelInp1'>Pays de résidence</p>
                        
                        <select className='inpStu1' placeholder='Entrez votre de résidence actuel'>
                            <option value="" disabled>
                                Sélectionnez votre pays de résidence actuel
                            </option>
                            {nationalities.map((country) => (
                            <option key={country.nationality} value={country.nationality}>
                                {country.nationality}
                            </option>
                            ))}
                        </select>
                        
                    </Col>
                </Row>

                <Row className='profilformgroup'>
                    <Col >
                    <p className='labelInp1'>Pays d'origine</p>
                        <select className='inpStu1' placeholder='Entrez votre de résidence actuel'>

                            <option value="">
                                Sélectionnez votre pays d'origine
                            </option>
                            {nationalities.map((country) => (
                            <option key={country.nationality} value={country.nationality}>
                                {country.nationality}
                            </option>
                            ))}
                        </select>
                        
                    </Col>
                    <Col  className='leftColProfil'>
                    <p className='labelInp1'>Adresse Email</p>
                        <input type='email' className='inpStu1' placeholder='abc@gmail.com'/>
                        
                    </Col>
                </Row>
                
                

            </Col>
            <Row className='rowBtnAdd1'>
                    <Col  >
                        
                            <button className='creer1'>
                               Sauvegarder
                                <img src={next} className='imgbtnadd'/>
                            </button>
                       
                    </Col>
                </Row>
        </div>
  )
}

export default ProfileParentModule
