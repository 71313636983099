
import React ,{useState} from 'react';
import './Contact.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import parsePhoneNumber from 'libphonenumber-js';
import tel  from "../../images/iconcontacttel.png";
import mail from "../../images/iconcontactmail.png";
import maps from "../../images/iconcontactmaps.png";
import plus from "../../images/icon-right.png";
import {  Row, Col} from 'react-bootstrap';
import CustomCountrySelect from '../../StudentModule/Layouts/CountrySelect';

const Contact=()=>{
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [country, setCountry] = useState('FR'); // Default to 'FR'
    
 const [codeCountry,setCodeCountry]=useState("");

    const handlePhoneChange = (value) => {
        const valid = isValidPhoneNumber(value);
        setIsValid(valid);

        const parsedNumber = parsePhoneNumber(value || '');
        if (parsedNumber) {
            setCountry(parsedNumber.country);
        }
    };

    const handleManualInputChange = (e) => {
        const inputValue = e.target.value;
        setPhone(inputValue);

        const parsedNumber = parsePhoneNumber(inputValue || '');
        if (parsedNumber) {
            setCountry(parsedNumber.country);
        }

        setIsValid(isValidPhoneNumber(inputValue));
    };

    function handleCodeCountry(c){
        setCodeCountry(c);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // Logique de soumission du formulaire
        console.log({ name, phone, email, message });
    };
    const data=[
        {img: tel,title:'Numéro de téléphone',paragraphe:'+33 7 58 23 21 30'},
        {img: mail,title:'Adresse e-mail',paragraphe:'contact@universfrancesucces.com'},
        {img:maps,title:'Notre emplacement',paragraphe:'2 Avenue du 8 Mai 1945, 95200 Sarcelles'}
    ];
   
    return (
        <>
        <div className="contact container-fluid mb-4">
           <Row className='d-flex  align-items-center p-md-3 contact-subcontainer p-sm-3 p-1'>
    {
        data.map((dat, index) => (

            <Col className='card-contact' key={index}>
                <img src={dat.img} alt='icon' className='img-contact-card' />
                <p className='title-contact-card'>{dat.title}</p>
                <p className='paragraphe-contact-card'>{dat.paragraphe}</p>
            </Col>
           
        ))
    }
</Row>
<Row className='contactMapSection p-md-1 p-2'>
<Col className='leftColCOntact'>
 <div className=''> {/*d-flex flex-wrap justify-content-between align-items-stretch */}
   <div className="form  p-4" style={{ display: 'flex', flexDirection: 'column' }}> {/* col-md-6 */}
        <form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Nom</label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Entrez le nom"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="mb-3">
            <Row>
            <label htmlFor="tel" className="form-label">Numéro de telephone</label>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                    <CustomCountrySelect    codeCountry={handleCodeCountry}/>

                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                    <input
                    type='text'
                    value={phone}
                    onChange={handleManualInputChange}
                    placeholder='XXX XXX XXXX'
                    id='tel-input'
                    className={`form-control ${isValid ? '' : 'is-invalid'}`}
                    style={{ flexGrow: 2, borderRadius: '5px', padding: '8px' }}
                    aria-label="Numéro de téléphone"
                />

                    </Col>
           
            {!isValid && <div className="text-danger">Numéro de téléphone invalide</div>}
            </Row>

            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">E-mail</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Entrez l'e-mail"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="message" className="form-label">Message</label>
                <textarea
                    className="form-control textarea"
                    id="message"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
            </div>
            <div className='d-flex justify-content-start align-items-start'>
            <button type="submit" className="btn-soumettre">Soumettre <img src={plus} alt='icon-right'/></button>
            </div>
        </form>
    </div>
    
</div>
</Col>
<Col className='rightColCOntact'>
<div className='' >{/* d-flex align-items-center col-md-6 p-4 */}
        <iframe 
            
            style={{ flexGrow: 1 ,borderRadius:"25px"}} 
            className='mapContact'
            frameBorder="0" 
            scrolling="no" 
            marginHeight="0" 
            marginWidth="0" 
            src = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2618.7318266135717!2d2.3728728102744006!3d48.97762909219073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66991a75f39f1%3A0xd630a1ce890dd32a!2s2%20Av.%20du%208%20Mai%201945%2C%2095200%20Sarcelles%2C%20France!5e0!3m2!1sfr!2sma!4v1730205616298!5m2!1sfr!2sma">
            <a href="https://www.gps.ie/">gps trackers</a>
        </iframe>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2618.7318266135717!2d2.3728728102744006!3d48.97762909219073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66991a75f39f1%3A0xd630a1ce890dd32a!2s2%20Av.%20du%208%20Mai%201945%2C%2095200%20Sarcelles%2C%20France!5e0!3m2!1sfr!2sma!4v1730205616298!5m2!1sfr!2sma" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
    </div>
</Col>
            {/* // src="https://maps.google.com/maps?width=100%25&amp;height=569&amp;hl=en&amp;q=60%20Rue%20de%20Strasbourg,%20%C3%8Ele-de-France,%20France+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" */}

</Row>
                
            </div>
            </>
    );
};
export default Contact;