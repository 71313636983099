import React from 'react'

function Price() {
  return (
    <div>
      Price
    </div>
  )
}

export default Price
