import { Container, Row, Col, Form, Spinner ,InputGroup} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import CustomCountrySelect from '../Layouts/CountrySelect';
import logo from "../../images/logo-colorful.png";
import bg from "../../images/dash.png"
import "./Login.css";
import { Link, useNavigate } from 'react-router-dom';
import api from '../api/axiosInstance';
import {success, error} from '../utils/alerts';
import extractError from '../utils/extractError';
import axios from 'axios';
import girl from "../../images/young-woman-holding-tablet-work 1.png"
import rectangle from "../../images/Rectangle 18.png"
import plane from "../../images/plane2.png"

export default function Register() {

    const navigate = useNavigate();

    const [nom,setNom]=useState("");
    const [prenom, setPrenom] = useState("");
    const [email,setEmail]=useState("");
   
    const [pays,setPays]=useState("");
    const [ville, setVille] = useState("");
    const [dateN,setDateN]=useState("");
    const [password,setPassword]=useState("");
    const [agree,setAgree]=useState("off")
    const [phoneNumber, setPhoneNumber] = useState('');
    const [codeCountry,setCodeCountry]=useState("+33"); 
    const[confPass,setConfPass]=useState("")
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});


    const[countries,setCountries]=useState([]);
    const [cities,setCities]=useState([]);
   
    const openEye=<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
    const closedEye=<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
    //Récupération des pays
   useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        
       
        const countryData = response.data
          .map(country => ({
            name: country.translations.fra.common, 
            code: country.name.common,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)); 

        setCountries(countryData);
      } catch (error) {
        console.error('Erreur lors de la récupération des pays:', error);
      }
    };

    fetchCountries();
  }, []);


  // Load cities when a country is selected
  const handlePays = async (e) => {
    const countryName = e.target.value; 
    setPays(countryName);

    if (countryName) {
        console.log(countryName)
      try {
        
        const citiesResponse = await axios.post('https://countriesnow.space/api/v0.1/countries/cities', {
         country: countryName 
        });
        
        const cities = citiesResponse.data.data; 
        const sortedCities = cities.sort((a, b) => a.localeCompare(b)); // Sort cities

        setCities(sortedCities); // Set the sorted cities in state
      } catch (error) {
        console.error('Erreur lors de la récupération des villes:', error);
      }
    } else {
      setCities([]); 
    }
  };

    function handleCodeCountry(c){
        setCodeCountry(c);
}



       async function handleSubmit(e){
       e.preventDefault();
       let currentErrors = {};
      
       

       const validNamePattern = /^[A-Za-z\- ]+$/; 
       const validPrenomPattern = /^[A-Za-z\- ]+$/;
       const validVillePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/;
       const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
       const phonePattern = /^\d{9,15}$/;
       const datePattern = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
       const countryPattern = /^[a-zA-ZÀ-ÿ_\s-]+$/;
       const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;



       if (!nom.trim()) {
         currentErrors.nom = "Veuillez entrer votre nom (pas d'espaces vides)";
       } else if (!validNamePattern.test(nom)) {
         currentErrors.nom = "Le nom ne doit contenir que des lettres et des apostrophes.";
       } else {
        delete currentErrors.nom;
       }

       if (!prenom.trim()) {
         currentErrors.prenom = "Veuillez entrer votre nom (pas d'espaces vides)";
       } else if (!validPrenomPattern.test(prenom)) {
        currentErrors.prenom = "Le prénom ne doit contenir que des lettres et des apostrophes.";
       } else {
        delete currentErrors.prenom;
       }

       if (!ville.trim()) {
        currentErrors.ville = "Veuillez sélectionner une ville";
       }  else {
        delete currentErrors.ville;
       }

       if (!email.trim()) {
       currentErrors.email = "Veuillez entrer une adresse e-mail valide";
       } else if (!emailPattern.test(email)) {
         currentErrors.email = "Adresse e-mail invalide";
       } else {
        delete currentErrors.email;
       }
      
       if (!phoneNumber.trim()) {
         currentErrors.phoneNumber = "Veuillez entrer un numéro de téléphone valide";
       } else if (!phonePattern.test(phoneNumber)) {
         currentErrors.phoneNumber = "Le numéro de téléphone doit contenir uniquement des chiffres";
       } else {
        delete currentErrors.phoneNumber;
       }
     
       if (!pays.trim()) {
         currentErrors.pays = "Veuillez sélectionner un pays ";
       }  else {
        delete currentErrors.pays;
       }

       if (!dateN.trim()) {
         currentErrors.dateN = "Veuillez entrer une date valide";
       } else if (!datePattern.test(dateN)) {
        currentErrors.dateN = "La date est invalide";
       } else {
        delete currentErrors.dateN;
       }

       if (!password) {
         currentErrors.password = "Veuillez entrer un mot de passe";
       } else if (!passwordPattern.test(password)) {
         currentErrors.password = "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.";
       } else {
        delete currentErrors.password;
       }
       if(!confPass){
        currentErrors.confPass = "Veuillez confirmer votre mot de passe";
       }else if (confPass!==password) {
        currentErrors.confPass = "Les mots de passe ne correspondent pas.";
      } else {
       delete currentErrors.confPass;
      }

       if(agree!="on"){
         currentErrors.agree = "Veuillez accepter les termes et conditions pour continuer.";
       } else {
        delete currentErrors.agree;
       }

       setErrors(currentErrors);
       
       
      if (Object.keys(currentErrors).length > 0) {
          return; 
      } 

      try {
        setLoading(true);
        const endPoint = "register/";
        const userInput = {
          email,
          password,
          role: "student",
          first_name: prenom,
          last_name: nom,
          country_code: codeCountry,
          phone_number: phoneNumber,
          country: pays,
          date_of_birth: dateN,
          town: ville
        }
        const response = await api.post(endPoint, JSON.stringify(userInput))
        if (response.status === 201) {
          let msg = response.data.data.message
          success(msg);
          navigate("/verificationEmail", {state: userInput})
        }

      } catch (err) {

        if (err.response && err.response.status === 400) {
          const errorObject = err.response.data.data;
          const errorMessages = extractError(errorObject);
          errorMessages.forEach(message => {
            error(message);
          });

        } else {

          error("Something Went Wrong");
        
        }

      } finally {

        setLoading(false);
      }

   }

   const [showPassword, setShowPassword] = useState(false);

  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const [showConfPassword, setShowConfPassword] = useState(false);

  
  const toggleConfPasswordVisibility = () => {
    setShowConfPassword(!showConfPassword);
  };

      return <Container fluid >
             <Row  >
             <Col className='RegisterStu' xl={6} lg={6} md={12} sm={12} xs={12}  style={{backgroundImage: `url(${bg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",height:"100vh"}}>
              <Row  >
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <img src={logo} className="img-fluid logo-student-login"/>
                </Col>
             </Row>
             <Row>
                <Col xl={11} lg={11} md={12} sm={12} xs={12} className="student-login-container-form " >
                 <Row>
                        <Col  xl={12} lg={12} md={12} sm={12} xs={12} >
                        <h1 className="student-login-title">S'INSCRIRE</h1>
                        </Col>
                 </Row>
                  <Row>
                        <Col  xl={12} lg={12} md={12} sm={12} xs={12}  >
                        <p className='student-login-p1' style={{marginBottom:"8px"}}>Veuillez remplir le formulaire pour vous inscrire</p>
                        </Col>
                 </Row>
                 <Row>
                        <Col  xl={12} lg={12} md={12} sm={12} xs={12} >
                        <p className='student-login-p2'>
                        Vous avez déjà un compte?  <Link to="/etudiant/login"> . Se connecter</Link>
                        </p>
                        </Col>
                 </Row>
               <Row  style={{ maxHeight: "45vh", overflowY: "auto",overflowX:"hidden" }}>
                 <Form className="student-login-form">
                       
                       <Row className=''>
                           <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                           <Form.Group controlId="Nom">
                            <Form.Label>Nom <span className='star'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Entrez votre nom" name="nom" onChange={(e)=>{setNom(e.target.value)}} isInvalid={errors?.nom ? true : false}/>
                            <Form.Control.Feedback type="invalid">
                              {
                                errors?.nom && errors.nom
                              }
                            </Form.Control.Feedback>
                        </Form.Group>
                           </Col>
                       </Row>
                        <Row className='mt-2'>
                           <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                           <Form.Group controlId="prénom">
                            <Form.Label>prénom <span className='star'>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Entrez votre prénom" name="prénom" onChange={(e)=>{setPrenom(e.target.value)}} isInvalid={errors?.prenom ? true : false}/>
                            <Form.Control.Feedback type="invalid">
                              {
                                errors?.prenom && errors.prenom
                              }
                            </Form.Control.Feedback>
                        </Form.Group>
                           </Col>
                       </Row>
                       <Row className='mt-2'>
                           <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                           <Form.Group controlId="Id">
                                <Form.Label>Email <span className='star'>*</span></Form.Label>
                                <Form.Control type="email" placeholder="abc@gmail.com" name="email" onChange={(e)=>{setEmail(e.target.value)}} isInvalid={errors?.email ? true : false}/>
                                <Form.Control.Feedback type="invalid">
                                  {
                                    errors?.email && errors.email
                                  }
                                </Form.Control.Feedback>
                            </Form.Group>
                           </Col>
                       </Row>

                        <Row className='mt-2'>
                       <Form.Label>Numéro de téléphone <span className='star'>*</span></Form.Label>
                           <Col xl={3} lg={4} md={3} sm={12} xs={12} xxs={12} >
                           <CustomCountrySelect className="flagSelect" codeCountry={handleCodeCountry}/>
                           </Col>
                           <Col xl={9} lg={8} md={9} sm={12} xs={12} >
                           <Form.Control type="text" placeholder="XXX  XXX  XXXX" name="tel" onChange={(e)=>{setPhoneNumber(e.target.value)}} isInvalid={errors?.phoneNumber ? true : false}/>
                            
                           </Col>
                           <Form.Control.Feedback type="invalid">
                              {
                                errors?.phoneNumber && errors.phoneNumber
                              }
                            </Form.Control.Feedback>
                       </Row>

                        <Row className='mt-2'>
                           <Col xl={7} lg={7} md={12} sm={12} xs={12} className="student-login-input-pays" >
                           <Form.Group controlId="Pays"  className="pays">
                            <Form.Label>Pays <span className='star'>*</span></Form.Label>
                            <Form.Select name="pays" defaultValue="" onChange={handlePays} isInvalid={errors?.pays ? true : false}>
                                <option value="" disabled>
                                    Sélectionnez le pays
                                </option>
                                {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                            ))}
                          
                            </Form.Select>


                            <Form.Control.Feedback type="invalid">
                              {
                                errors?.pays && errors.pays
                              }
                            </Form.Control.Feedback>
                            </Form.Group>
                           </Col>

                           <Col xl={5} lg={5} md={12} sm={12} xs={12} className="mt-2 mt-xl-0 mt-lg-0">
                           <Form.Group controlId="Ville">
                            <Form.Label>Ville <span className='star'>*</span></Form.Label>
                            <Form.Select name="villeDestination" defaultValue="" onChange={(e)=>{setVille(e.target.value)}}   isInvalid={errors?.ville ? true : false}>
                                <option value="" disabled>
                                    Sélectionnez la ville
                                </option>
                                {cities.length>0 && cities.map((ville) => (
                              <option key={ville} value={ville}>
                              {ville}
                            </option>
                            ))}
                          
                            </Form.Select>




                            <Form.Control.Feedback type="invalid">
                              {
                                errors?.ville && errors.ville
                              }
                            </Form.Control.Feedback>
                        </Form.Group>
                      
                           </Col>
                       </Row>
                      <Row className='mt-2'>
                           <Col xl={12} lg={12} md={12} sm={12} xs={12} className="student-login-input-date ">
                           <Form.Group controlId="date" className="date"  >
                            <Form.Label>Date de naissance <span className='star'>*</span></Form.Label>
                            <Form.Control 
                                type="date" 
                            
                               
                                name="dateN" 
                                placeholder="AAAA-MM-JJ" 
                                onChange={(e) => { setDateN(e.target.value) }} 
                                isInvalid={errors?.dateN ? true : false}
                            
                            />
                            <Form.Control.Feedback type="invalid">
                              {
                                errors?.dateN && errors.dateN
                              }
                            </Form.Control.Feedback>
                        
                            </Form.Group>

                           
                           </Col>
                       </Row>
                       <Row className='mt-2'>
  <Col xl={12} lg={12} md={12} sm={12} xs={12} >
    <Form.Group controlId="password">
      <Form.Label>Créer un mot de passe <span className='star'>*</span></Form.Label>
      <div className="d-flex align-items-center justify-content-between "   style={{position:"relative"}}>
        <Form.Control
          type={showPassword ? "text" : "password"} 
          placeholder="**************"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isInvalid={errors?.password ? true : false}
        
        />
        <p onClick={togglePasswordVisibility} className="eye-password-student"  style={{
            position: "absolute",
            right: "0.75rem", 
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "#888888"
          }} >
          {showPassword ? closedEye : openEye} 
        </p>
        </div>
      <Form.Control.Feedback type="invalid">
        {errors?.password && errors.password}
      </Form.Control.Feedback>
    </Form.Group>
  </Col>
</Row>


                       <Row className='mt-2'>
                           <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                           <Form.Group controlId="password">
                                <Form.Label >Confirmer le mot de passe <span className='star'>*</span></Form.Label>
                                <div className="d-flex align-items-center justify-content-between "   style={{position:"relative"}}>
        <Form.Control
          type={showConfPassword ? "text" : "password"} 
          placeholder="**************"
          value={confPass}
          onChange={(e) => setConfPass(e.target.value)}
         isInvalid={errors?.confPass ? true : false} 
        
        />
        <p onClick={toggleConfPasswordVisibility} className="eye-password-student"  style={{
            position: "absolute",
            right: "0.75rem", 
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "#888888"
          }} >
          {showConfPassword ? closedEye : openEye} 
        </p>
        </div>
                                <Form.Control.Feedback type="invalid">
                              {
                                errors?.confPass && errors.confPass
                              }
                            </Form.Control.Feedback>
                            </Form.Group>
                           </Col>
                       </Row>

                       <Row className="student-login-checkbox-global-container mt-2">
                       <div className='student-login-checkbox-container mx-2'>
                           <Form.Check 
                                    type="checkbox" 
                                    name="agree" 
                                
                                    onChange={(e)=>{agree === "off" ? setAgree("on") : setAgree("off")}}
                                    isInvalid={errors?.agree ? true : false}
                                />
                           
                           <Form.Label className="label-checkbox-login" >J'accepte les termes et conditions</Form.Label>  
                           </div>
                            <Form.Control.Feedback type="invalid" className="d-block">
                            {
                              errors?.agree && errors.agree
                            }
                          </Form.Control.Feedback>
                       </Row>
                       <Row className='mt-2'>
                           <Col xl={12} lg={12} md={12} sm={12} xs={12} className='suivant-login-container' >
                           
                           <button 
                            type="submit" 
                            className='button-Register'
                            onClick={handleSubmit}
                            disabled={loading}
                            aria-label="Submit"
                        > 
                          {
                            !loading ? (
                              <>
                              suivant
                                <svg className="Register-fleche" width="29" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                      <rect y="0.5" width="29" height="29" rx="14.5" fill="white"/>
                                      <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                              </>
                            ) : (
                              <Spinner animation="border" size="md"/>
                            )
                          }

                        </button>
                           </Col>
                          
                       </Row>
                        
                    </Form>

                 </Row>
                        
                </Col>
             </Row> 

                </Col> 
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className='student-login-image d-none d-xl-block d-lg-block' style={{overflowX:"hidden"}} >
                <Row> 
                <Col xl={12} lg={12} md={12} sm={12} xs={12}> 
                <p className="student-login-para">Embarquez pour une aventure mondiale : <br/>
                    Explorez l'étranger<br/>
                    Étudiez les <br/>
                    opportunités <br/>
                    avec nous !</p>
                </Col>
                </Row>
                <Row className=''> 
                <Col xl={12} lg={12} md={12} sm={12} xs={12}> 
                <img src={rectangle} className='image-rectangle-student'/>
                <img src={plane} className='image-plane-student'/>

                </Col>
                </Row>
                <img src={girl} className='girl-image'/>
                </Col>
             </Row>

                
      </Container>
}

