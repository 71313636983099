import React, { useState } from "react";
import './notification.css';
import  iconDocument from '../../images/document-logo.png';
import  iconSpam from '../../images/icon-spam.png';
import  iconBank from '../../images/icon-bank.png';
import {  Row, Col, Container} from 'react-bootstrap';

function NotificationList() {
  // State to track notifications
  const [notifications, setNotifications] = useState([
    { id: 1, message: "Votre candidature a été soumise", date: "12 mars 2024", icon: iconDocument, isRead: false },
    { id: 2, message: "Votre document est en cours de révision", date: "12 mars 2024", icon: iconDocument, isRead: false },
    { id: 3, message: "Rendez-vous pour un entretien de visa le 23 juin 2024", date: "12 décembre 2023", icon: iconSpam, isRead: false },
    { id: 4, message: "20 mars: Date limite de soumission des documents", date: "10 mars 2023", icon: iconSpam, isRead: false },
    { id: 5, message: "Le compte bloqué a été ouvert avec succès", date: "10 mars 2023", icon: iconBank, isRead: false },
  ]);

 
  const markAllAsRead = () => {
    setNotifications(prevNotifications => 
      prevNotifications.map(notification => ({ ...notification, isRead: true }))
    );
  };

  return (
    
    <Container fluid className="container-notif animParent mt-4">
      <div className="d-flex justify-content-between align-items-start mb-3">
        <h2 id="title-notif">Nouveau pour vous</h2>
        <button onClick={markAllAsRead} className="btn btn-link" id="p-notif">
          Marquer tout comme lu
        </button>
      </div>
      
      <div className="w-100">
        {notifications.map(({ id, message, date, icon, isRead }, index) => (
          <div className="notification-bar row d-flex justify-content-between align-items-start" key={id}>
            <div className="col-2 col-sm-1">
              <img src={icon} alt="logo" className="logo-notif img-fluid" />
            </div>
            <div className="message-notif col-8 col-sm-10">
              <p className="p-bold">{message}</p>
              <p className="p-fit">{date}</p>
            </div>
            <div className="col-2 col-sm-1">
             
              {!isRead && index < 2 && <div className="notification-circle"></div>}
            </div>
          </div>
        ))}
      </div>
    </Container>
    
  );
}

export default NotificationList;