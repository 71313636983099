import { useState } from "react";
import InputLabel from "../../../Components/InputLabel";
import SelectLabel from "../../../Components/SelectLabel";

import './EditAccountAVI.css'
import Button from "../../../Components/Button";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

export default function EditAccountAVI(){

    const [data, setData] = useState({
        lastname: '',
        firstname: '',
        birthDate: '',
        bic: '',
        birthPlace: '',
        status: '',
        bankAccount: '',
        acceptance: '',
        university: '',
        schoolYear: '',
        sexe: '',
        officeTicket: '',
        accountNumber: '',
        rib: '',
    })

    const updateField = e => {
        setData(data => ({
            ...data,
            [e.target.name] : e.target.value
        }))
    }

    
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // Retourne à la page précédente
    };
    
    return(
        <>
        <Col>
            <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
        </Col> 
                <Col className="editAccountAVI">
                <h2 className="editAccountAVITitle">
                    Compte AVI
                </h2>

                <div className="editAccountAVIMain">
                    <InputLabel name={'lastname'} label={'Nom du candidat'} placeholder = {'Entrez le nom'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'firstname'} label={'Prenom du candidat'} placeholder = {'Entrez le prenom'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'birthDate'} label={'Date de naissance'} type="date" onChange = {e=> updateField(e)}/>
                    <InputLabel name={'bic'} label={'BIC'} placeholder = {'RGFBCIL'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'birthPlace'} label={'Lieu de naissance'} placeholder = {'Entrez le lieu de naissance'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'status'} label={'Statut du candidat'} placeholder = {'Entrez le status'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'bankAccount'} label={'Compte bancaire'} placeholder = {'Entrez le compte bancaire'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'acceptance'} label={'Acceptance'} placeholder = {'Acceptance'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'university'} label={'Université'} placeholder = {'Entrez le nom de l\'université'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'schoolYear'} label={'Année d\'études'} placeholder = {'2020-2023'} onChange = {e=> updateField(e)}/>
                    <SelectLabel name={'sexe'} label={'Genre'} options = {['homme', 'femme']} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'officeTicket'} label={'Ticket d\'office'} placeholder = {'0000'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'accountNumber'} label={'Numero de compte'} placeholder = {'2020-2021'} onChange = {e=> updateField(e)}/>
                    <InputLabel name={'rib'} label={'Clef RIB'} placeholder = {'03'}/>
                </div>

                <Button value={'Sauvegarder'}/>
                </Col>
        </>
    )
}