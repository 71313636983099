// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentFAQ{
    background-color: rgba(255, 255, 255, .61);
    border-radius: 1em;
    padding: 20px 40px;
    overflow-x: hidden;
    /* max-width: 100%; */
}

.parentFAQSections{
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.FAQSectionTitle{
    cursor: pointer;
    border: 1px solid #025B97;
    border-radius: 1.5em;
    /* background: var(--button-color-1); */
    color: #015192;
    padding: 8px 17px;
    font-size: 13px;
    font-weight: 600;
}

.FAQSectionTitleActive{
    background: var(--button-color-1);
    color: white;
    border: none;
    font-weight: 600;
}



/* Responsivity */

@media screen and (max-width: 950px) {
    /* .parentFAQSections{
        max-width: 100%;
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
    }

    .parentFAQSections::-webkit-scrollbar{
        display: none;
    }

    .FAQSectionTitle{
        width: 100%;
        white-space: nowrap;
        scroll-snap-align: start;
    } */
}`, "",{"version":3,"sources":["webpack://./src/StudentModule/Faq/StudentFAQ.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;AACb;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,oBAAoB;IACpB,uCAAuC;IACvC,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;;;AAIA,iBAAiB;;AAEjB;IACI;;;;;;;;;;;;;;;;OAgBG;AACP","sourcesContent":[".parentFAQ{\n    background-color: rgba(255, 255, 255, .61);\n    border-radius: 1em;\n    padding: 20px 40px;\n    overflow-x: hidden;\n    /* max-width: 100%; */\n}\n\n.parentFAQSections{\n    max-width: 100%;\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n\n.FAQSectionTitle{\n    cursor: pointer;\n    border: 1px solid #025B97;\n    border-radius: 1.5em;\n    /* background: var(--button-color-1); */\n    color: #015192;\n    padding: 8px 17px;\n    font-size: 13px;\n    font-weight: 600;\n}\n\n.FAQSectionTitleActive{\n    background: var(--button-color-1);\n    color: white;\n    border: none;\n    font-weight: 600;\n}\n\n\n\n/* Responsivity */\n\n@media screen and (max-width: 950px) {\n    /* .parentFAQSections{\n        max-width: 100%;\n        flex-wrap: nowrap;\n        overflow-x: scroll;\n        scroll-snap-type: x mandatory;\n        scrollbar-width: none;\n    }\n\n    .parentFAQSections::-webkit-scrollbar{\n        display: none;\n    }\n\n    .FAQSectionTitle{\n        width: 100%;\n        white-space: nowrap;\n        scroll-snap-align: start;\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
