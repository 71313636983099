import React from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "../../Components/InputLabel";
import buttonImg from "../../images/nextAdd.png";
import TwoManImg from "../assets/MERE.png";
import { Col, Container, Row } from "react-bootstrap";

export default function LoginParent() {
  const navigate=useNavigate()
  return (
    <div className="parentLogin ">
      <Container className="loginParentContainer">
        <Row>
          <Col className="ColLoginParent" lg={6} md={6}>
            <div className="parentLoginLeftSection parentLoginLeftSectionheight">
              <h1 className="headeLoginPar">Se connecter</h1>
              <div className="parentLoginHeader">
                <p>Veuillez remplir le formulaire pour vous connecter</p>
                <p>
                  Vous n'avez pas de compte ?
                  <span>
                    <Link to={"/parent/register"}>Inscrivez-vous</Link>
                  </span>
                </p>
              </div>

              <div className="parentLoginForm">
                <InputLabel
                  label={"Email*"}
                  placeholder={"Entrez votre email"}
                  name={"email"}
                />
                <InputLabel
                  type="password"
                  label={"Mot de passe*"}
                  placeholder={"**********"}
                  name={"password"}
                />

                <div className="rememberMeForgotPassword">
                  <div className="rememberMe">
                    <input type="checkbox" id="checkbox"></input>
                    <label htmlFor="checkbox">Se Souvenir de moi</label>
                  </div>
                  <h5>Mot de passe oublié</h5>
                </div>
                <div className="parent-login-switch-to-student">
                  <div>
                    Vous n'etes pas parent - <span onClick={()=> navigate('/etudiant/login')}>Espace Etudiant</span>
                  </div>

                </div>
                <button className="parentLoginFormButton" onClick={()=>navigate('/parent/')}>
                  <span>Se connecter</span>
                  <img src={buttonImg} />
                </button>
              </div>
            </div>
          </Col>

          <Col lg={6} md={6} className="rightImgLoginCol">
            <div className="rightSectionImgCard1">
              <img src={TwoManImg} className="bottomImage" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
