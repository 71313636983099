import React from 'react'

function LocationSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" fill="none" viewBox="0 0 11 15">
  <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" d="m5.5 14.009.51-.576a41.838 41.838 0 0 0 1.566-1.893l.383-.505c1.598-2.154 2.398-3.864 2.398-5.127 0-2.698-2.174-4.884-4.857-4.884C2.817 1.024.643 3.21.643 5.908c0 1.263.8 2.973 2.398 5.127l.383.505A44.426 44.426 0 0 0 5.5 14.009Z" clip-rule="evenodd"/>
  <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" d="M5.5 7.905a2.024 2.024 0 1 0 0-4.048 2.024 2.024 0 0 0 0 4.048Z"/>
</svg>

  )
}

export default LocationSVG
