import React, { useState } from 'react'
import './RechercheLogement.css'
import InputLabel from '../../../Components/InputLabel'
import SelectLabel from '../../../Components/SelectLabel'
import FileToDownload from '../FileToDownload'
import nationalities from "../../../StudentModule/Layouts/nationalities.json"

import CustomCountrySelect from '../../../StudentModule/Layouts/CountrySelect'
import Button from '../../../Components/Button'
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from 'react-router'


export default function ParentRechercheLogement() {

    const icon=  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.5" width="39" height="39" rx="10" fill="url(#paint0_linear_991_5415)" fill-opacity="0.1"/>
    <path d="M16.749 24.5V18.5M16.749 18.5L14.749 20.5M16.749 18.5L18.749 20.5" stroke="url(#paint1_linear_991_5415)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.75 17.5V22.5C29.75 27.5 27.75 29.5 22.75 29.5H16.75C11.75 29.5 9.75 27.5 9.75 22.5V16.5C9.75 11.5 11.75 9.5 16.75 9.5H21.75" stroke="url(#paint2_linear_991_5415)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.75 17.5H25.75C22.75 17.5 21.75 16.5 21.75 13.5V9.5L29.75 17.5Z" stroke="url(#paint3_linear_991_5415)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
    <linearGradient id="paint0_linear_991_5415" x1="-0.37396" y1="10.8019" x2="37.6153" y2="11.1255" gradientUnits="userSpaceOnUse">
    <stop stop-color="#015192"/>
    <stop offset="1" stop-color="#003D6E"/>
    </linearGradient>
    <linearGradient id="paint1_linear_991_5415" x1="14.685" y1="20.0849" x2="18.5815" y2="20.107" gradientUnits="userSpaceOnUse">
    <stop stop-color="#015192"/>
    <stop offset="1" stop-color="#003D6E"/>
    </linearGradient>
    <linearGradient id="paint2_linear_991_5415" x1="9.43002" y1="14.783" x2="28.9117" y2="14.949" gradientUnits="userSpaceOnUse">
    <stop stop-color="#015192"/>
    <stop offset="1" stop-color="#003D6E"/>
    </linearGradient>
    <linearGradient id="paint3_linear_991_5415" x1="21.622" y1="11.6132" x2="29.4147" y2="11.6796" gradientUnits="userSpaceOnUse">
    <stop stop-color="#015192"/>
    <stop offset="1" stop-color="#003D6E"/>
    </linearGradient>
    </defs>
    </svg>;


    const [data, setData] = useState({
        fistname: '',
        lastname: '',
        codeCountry: '+33',
        phone: '',
        birthPlace: '',
        birthDate: '',
        email: '',
        nationnality: '',
        hebermentType: '',
        preferedPlace: '',
        movingDate: '',
        sejourDuration: '',
        funds: '',
        specialNeeds: '',
        sejourObject: '',
        workPlace: '',
        occupantCount: '',
    })

    const updateField = e => {
        setData(data => (
            {
                ...data,
                [e.target.name]: e.target.value
            }
        ))
    }

    const navigate = useNavigate()
    
    return (

        <main className="rechercheLogement animParent">
            <div className="backSectionButton">
                <Button 
                    value={
                        <>
                            <IoArrowBackCircle/>
                            <span>Retour</span>
                        </>
                    }
                    onClick = {()=>navigate('/parent/prestations-de-service')}
                />
            </div>

            <PersonnalInfos title={'Recherche de logement'} data={data} setData={setData} updateField ={updateField}/>

            <section className="preferenceHebergementSection">
                <h1 className='preferenceHebergementTitreSection'>Préférences d'hébergement</h1>
                <div className="preferenceHebergementForm">
                    <SelectLabel label={'Type d\'hébergement'} name={'hebermentType'} placeholder = {"Entrez le type d'\hébergement"} onChange = {(e)=> updateField(e)}/>
                    <SelectLabel label={'Lieu préféré'} name={'preferedPlace'} placeholder = {'Entrez votre nom de famille'} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Date d\'emménagement'} name={'movingDate'} type='date' placeholder = {''} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Durée du séjour'} name={'sejourDuration'} placeholder = {'Entrez le lieu de naissance'} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Budget'} name={'funds'} placeholder = {'Entrez votre adresse email'} onChange = {(e)=> updateField(e)}/>
                    <div className="inputLabel">
                        <label htmlFor="specialNeeds" className='inputLabelLabel'>Besoins speciaux</label>
                        <textarea name={'specialNeeds'} id='specialNeeds' className='inputLabelInput'></textarea>
                    </div>
                </div>
            </section>


            <section className="informationComplementaireSection">
                <h1 className='student-attestation-title'>Informations Complémentaires</h1>
                <div className="informationComplementaireForm">
                    <SelectLabel label={'Objet du séjour'} name={'sejourObject'} placeholder = {'Entrez votre nom de famille'} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Université ou lieu de travail'} name={'workPlace'} type='date' placeholder = {''} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Nombre d\'occupants'} name={'occupantCount'} placeholder = {'Entrez le lieu de naissance'} onChange = {(e)=> updateField(e)}/>
                </div>
            </section>


            <section className="documentsATelecharger">
                <h1 className='student-attestation-title'>Documents à télécharger</h1>
                <div className='documentsATelechargerContenu'>
                    <FileToDownload titre={'Preuve d\'identité'} acceptanceText={'Passeport, ID Carte'}/>
                    <FileToDownload titre={'Preuve d\'admission / d\'emploi'} acceptanceText={'Lettre d\'acceptance, Contrat de travail'}/>
                </div>
            </section>

            <div className="submitSectionButton">
                <Button value={
                    <>
                        <span>Soumettre</span>
                        <svg  viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="profil-fleche">
                                <rect y="0.5" width="29" height="29" rx="14.5" fill="white"/>
                                <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </>
                }/>
            </div>
        </main>

    )
}


export function PersonnalInfos({data, updateField, title, setData}){
    const updateCodeCountry = (code) => {
        setData(data => (
            {
                ...data,
                ['codeCountry']: code
            }
        ))
    }
    return(
        <section className="rechercheLogementSection ">
                <h1 className='student-attestation-title'>{title}</h1>
                <div className="rechercheLogementForm">
                    <InputLabel label={'Prénom'} name={'firstname'} value ={data.fistname} placeholder = {'Entrez votre prénom'} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Nom de famille'} name={'lastname'} value ={data.lastname} placeholder = {'Entrez votre nom de famille'} onChange = {(e)=> updateField(e)}/>
                    <div className="composedInput">
                        <div className='inputLabel'>
                            <label htmlFor="codeCountry">Numéro de Téléphone</label>
                            <CustomCountrySelect name = 'codeCountry' codeCountry = {updateCodeCountry}/>
                        </div>
                        <InputLabel label={'Numéro de téléphone'} name={'phone'} placeholder = {'XX-XXX-XXX'} value ={data.phone} onChange = {(e)=> updateField(e)}/>
                        {/* <SelectLabel label={'Numéro de téléphone'} options={['+221','+212','+234']} onChange ={e=>updateField(e)}/> */}
                    </div>
                    <InputLabel label={'Lieu de naissance'} name={'placeBirth'} placeholder = {'Entrez le lieu de naissance'} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Date de naissance'} name={'dateBirth'} type='date' placeholder = {''} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Adresse email'} name={'email'} placeholder = {'Entrez votre adresse email'} onChange = {(e)=> updateField(e)}/>
                    <SelectLabel label={'Nationnalite'}>
                        <option value="" disabled>
                            Sélectionnez votre nationalité
                        </option>
                        {nationalities.map((country) => (
                        <option key={country.nationality} value={country.nationality}>
                        {country.nationality}
                    </option>
                    ))}
                          
                    </SelectLabel>
                </div>
            </section>
    )
}