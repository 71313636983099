import React from 'react'
import './TransactionStyles.css'
import viewIcon from '../../images/view-row.svg'
import editIcon from '../../images/edit-row.svg'
import delIcon from '../../images/del-row.svg'
import { useNavigate } from 'react-router'
import Layout from './Layout'
import TableHeading from '../../Components/TableHeading'
import TableData from '../../Components/TableData'
import profil1 from '../../images/Ellipse 991.png'
import profil2 from '../../images/Ellipse 992.png'
import profil3 from '../../images/Ellipse 993.png'
import Pagination from '../../Components/Pagination'
import { useEffect } from 'react'
import { useState } from 'react'



export default function TransactionRefund() {

        //simulation des donnees de tableau
        const data1 = {
            id: 1441,
            student: {
                name: 'Florence Dawg',
                profil: profil1
            },
            date: '12 Juin 2024',
            requestRefundDate: '12 Juillet 2024',
            planAcivateDate: '12 Aout 2024',
            montant: 133,
            phoneNumber: '+21273456772',
        }
        const data2 = {
            id: 1590,
            student: {
                name: 'Maxime Cat',
                profil: profil2
            },
            date: '12 Juin 2024',
            requestRefundDate: '12 Juillet 2024',
            planAcivateDate: '12 Aout 2024',
            montant: 133,
            phoneNumber: '+21273456772',
        }
        const data3 = {
            id: 1261,
            student: {
                name: 'Albert Horse',
                profil: profil3
            },
            date: '12 Juin 2024',
            requestRefundDate: '12 Juillet 2024',
            planAcivateDate: '12 Aout 2024',
            montant: 57,
            phoneNumber: '+21273456772',
        }
    
        // mixage des donnees
        const data = [data1, data2, data3, data2, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3, data1, data2, data3, data2, data3, data1, data3, data1, data2, data3]
    
    
        //pagination
        const [currentPage, setCurrentPage] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(10);
        const [itemsPages, setItemsPages] = useState({});
        const [loading, setLoading] = useState(true); // État de chargement
    
        useEffect(() => {
            const totalPages = Math.ceil(data.length / itemsPerPage);
            let current = 0;
    
            const newItemsPages = {};
    
            for (let index = 1; index <= totalPages; index++) {
                newItemsPages[index] = data.slice(current, current + itemsPerPage);
                current += itemsPerPage;
            }
    
            setItemsPages(newItemsPages);
            setLoading(false); // Indiquer que le chargement est terminé
        }, [itemsPerPage]);
    
        
        const navigate = useNavigate()
        
    
        return(
    
            <Layout>
                <div className='adminTransactionList'>
    
                    <div className="transactionTableContainer" id='transactionTableContainer'>
                        <table>
                            <thead>
                                <TableHeading value={'ID'}/>
                                <TableHeading value={'Etudiant'}/>
                                <TableHeading value={'Date'}/>
                                <TableHeading value={'Date réclamation'}/>
                                <TableHeading value={'Date activation '}/>
                                <TableHeading value={'Montant'}/>
                                <TableHeading value={'Téléphone'}/>
                                <TableHeading value={'Actions'}/>
                            </thead>

                            <tbody>

                            {
                                loading ? ( // Afficher le loader si les données sont en cours de chargement
                                <tr>
                                    <td style={{ textAlign: 'center', width: '100%' }}>
                                        Chargement des données...
                                    </td>
                                </tr>
                                ) : (
                                
                                    itemsPages[currentPage]?.map((data, index) => 
                                        <tr key={data.id} className={index%2 == 0 ? 'OddTableRow' : ''}>
                                            <TableData  value={data.id}/>
                                            <TableData 
                                                className={'composedTD'}  
                                                value={
                                                    <>
                                                        <img src={data.student.profil} alt="profil" />
                                                        <span>{data.student.name}</span>
                                                    </>
                                                }
                                            />
                                            <TableData  value={data.date}/>
                                            <TableData  value={data.requestRefundDate}/>
                                            <TableData  value={data.planAcivateDate}/>
                                            <TableData  
                                                value={
                                                    <>
                                                        <span style = {{fontWeight: 'bold'}}>{data.montant}</span>
                                                        <span style = {{fontWeight: 'bold'}}> €</span>
                                                    </>
                                                } 
                                            />
                                            <TableData  value={data.phoneNumber}/>
                                            <TableData  className={'refundActionsTD'}
                                                value={
                                                    <div>
                                                        <img src={editIcon} alt="editer" />
                                                        <img src={delIcon} alt="supprimer" />
                                                        <img src={viewIcon} alt="voir details" onClick={()=>navigate(`details-remboursement/${data.id}`)}/>
                                                    </div>
                                                }
                                            />
                                        </tr>
                                    )
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <Pagination itemsPages ={itemsPages} setItemsPerPage={setItemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>

                </div>
            </Layout>
    
        )
    
}
