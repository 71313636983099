import React, { useContext, useState } from "react";
import { PlansContext } from "./ParentPrix";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import checktick from "../../images/check-tick.svg";
import listoption from "../../images/list-option.svg";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";

function Plans_dabonnement() {
  const [activeItem, setActiveItem] = useState('Annuel');
  const handleItemClick = (newHeadings) => {
    setActiveItem(newHeadings); // Update active item
  };
  const { plans, addPlan, deletePlan } = useContext(PlansContext);  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  
  const [newPlan, setNewPlan] = useState({
    title: "",
    
    popular: false,
    lastPrice:"",
    sousTitle:"",
    price: "",
    features: [""]
  });

  const handleAddPlan = () => {
    addPlan(newPlan, activeTab === "home" ? "plans" : "plans2");
    setShowModal(false);
    setNewPlan({
      title: "",
      
      sousTitle: "",
      lastPrice:"",
      price: "",
      popular: false, // Reset to false
      features: [""]
    });
  };
  

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...newPlan.features];
    updatedFeatures[index] = value;
    setNewPlan({ ...newPlan, features: updatedFeatures });
  };

  const addFeature = () => {
    setNewPlan({ ...newPlan, features: [...newPlan.features, ""] });
  };

  const removeFeature = (index) => {
    setNewPlan({
      ...newPlan,
      features: newPlan.features.filter((_, i) => i !== index)
    });
  };

  return (
    <>
      <section className="shop">
        <div className="top-navs">
          
            
              <button className="btnPlan" variant="primary" onClick={() => setShowModal(true)}>
                Ajouter un plan
              </button>
              <div className="wrap-cover">
                <div className="tab-cover">
                <p className='pBuy'>Acheter un abonnement</p>
                  <p className='SouspBuy'>Choisissez le plan qui vous convient.</p>
                  <div className='divBtnPri'>
                    <span className='spanTab'>
                      
                      <button className={`submenu ${activeItem === 'Annuel' ? 'active' : ''}`} onClick={() => handleItemClick('Annuel')}>
                        Annuel
                      </button>
                    </span>
                  </div>
                </div>

                <Row className=" cardplan my-5">
                  {plans.map((plan) => (
                    <Col sm={12} md={5} lg={6} xl={5} xxl={4}   key={plan.id} className="ColPlanPrix">
                      <div
                        className={`plan-first ${plan.popular ? "populr " : ""}`}
                      >
                        
                        <div className="headerPlan">
                        {plan.popular && (
                          <div className="popular">
                            <span>Le plus populaire</span>
                          </div>
                        )}
                        
                        </div>
                       <div className="btnheadplan">
                       <Link
                            to="plans-modify"
                            state={{
                              id: plan.id,
                              title: plan.title,
                              price: plan.price,
                              features: plan.features,
                              lastPrice:plan.lastPrice,
                              popular:plan.popular,
                            }} // Corrected line
                          >
                            <button
                              type="button"
                              className="btnPlanModify "
                            >
                              Modifier
                            </button>
                          </Link>
                          <button
                              type="button"
                              className="btnSupprimerPlan "
                              onClick={() => deletePlan(plan.id, "plans")}
                            >
                              Supprimer
                            </button>
                       </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>
                          <span className="titPricePlan">
                          <p className="titPlan">{plan.price}</p>
                          <p className="LasttitPlan">{plan.lastPrice}</p>
                          </span>
                          <p className="sousTilPlan">{plan.title} </p>
                          </span>
                          
                        </div>
                        <p className="subTitlPlan">{plan.sousTitle}</p>
                        <ul>
                          {plan.features.map((feature, index) => (
                            <li key={index} className="liPlanPrix">
                              <span className="imgspanlist">
                                <img
                                  src={plan.popular ? listoption : checktick}
                                  alt="tick"
                                  className="imglistplan"
                                />
                                <p className="featPlan">{feature}</p>
                              </span>
                              
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            
        </div>
      </section>
    
      <Modal centered className="modelPlanAddUFS" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un nouveau plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label className="d-flex divPlanabo align-items-center">
                <input
                  type="checkbox"
                  className="me-2 checkboxPlan"
                  checked={newPlan.popular}
                  onChange={(e) => setNewPlan({ ...newPlan, popular: e.target.checked })}
                />
                <span className="plusPop1">Le plus populaire</span>
              </label>
            <div className="planModel">
              <input
                type="text"
                className="inpPlantili mb-2"
                value={newPlan.title}
                onChange={(e) => setNewPlan({ ...newPlan, title: e.target.value })}
                placeholder="Titre du plan"
              />
              <input
                type="text"
                className="inpPlantili mb-2"
                value={newPlan.price}
                onChange={(e) => setNewPlan({ ...newPlan, price: e.target.value })}
                placeholder="Prix"
              />
              
            </div>
            <div className="listfeat mb-2">
            <input
                  type="text"
                  className="inpListFeat1 me-2"
                  value={newPlan.lastPrice}
                  onChange={(e) => setNewPlan({ ...newPlan, lastPrice: e.target.value })}
                  placeholder="Ancien Prix"
                />
            </div>

            {newPlan.features.map((feature, index) => (
              <div key={index} className="listfeat mb-2">
                <input
                  type="text"
                  className="inpListFeat me-2"
                  value={feature}
                  onChange={(e) => handleFeatureChange(index, e.target.value)}
                  placeholder={`Feature ${index + 1}`}
                />
                <button
                  className="btnSupPlan"
                  onClick={() => removeFeature(index)}
                >
                  Supprimer
                </button>
              </div>
            ))}
            

            <div className="divajouterfeature">
            <button
              className="ajouterFeature"
              variant="secondary"
              onClick={addFeature}
            >
              Ajouter un feature
            </button>
            </div>
            
          </Modal.Body>
        <Modal.Footer>
          <button className="annulerPlan" onClick={() => setShowModal(false)}>
            Annuler
          </button>
          <button className="savePlan" onClick={handleAddPlan}>
            Enregistrer
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Plans_dabonnement;