// Liste des pays avec codes téléphoniques


export const countryList = [
    { country: "Afghanistan", code: "AF", dialCode: "+93" },
    { country: "Albania", code: "AL", dialCode: "+355" },
    { country: "Algeria", code: "DZ", dialCode: "+213" },
    { country: "Andorra", code: "AD", dialCode: "+376" },
    { country: "Angola", code: "AO", dialCode: "+244" },
    { country: "Antigua and Barbuda", code: "AG", dialCode: "+1-268" },
    { country: "Argentina", code: "AR", dialCode: "+54" },
    { country: "Armenia", code: "AM", dialCode: "+374" },
    { country: "Australia", code: "AU", dialCode: "+61" },
    { country: "Austria", code: "AT", dialCode: "+43" },
    { country: "Azerbaijan", code: "AZ", dialCode: "+994" },
    { country: "Bahamas", code: "BS", dialCode: "+1-242" },
    { country: "Bahrain", code: "BH", dialCode: "+973" },
    { country: "Bangladesh", code: "BD", dialCode: "+880" },
    { country: "Barbados", code: "BB", dialCode: "+1-246" },
    { country: "Belarus", code: "BY", dialCode: "+375" },
    { country: "Belgium", code: "BE", dialCode: "+32" },
    { country: "Belize", code: "BZ", dialCode: "+501" },
    { country: "Benin", code: "BJ", dialCode: "+229" },
    { country: "Bhutan", code: "BT", dialCode: "+975" },
    { country: "Bolivia", code: "BO", dialCode: "+591" },
    { country: "Bosnia and Herzegovina", code: "BA", dialCode: "+387" },
    { country: "Botswana", code: "BW", dialCode: "+267" },
    { country: "Brazil", code: "BR", dialCode: "+55" },
    { country: "Brunei", code: "BN", dialCode: "+673" },
    { country: "Bulgaria", code: "BG", dialCode: "+359" },
    { country: "Burkina Faso", code: "BF", dialCode: "+226" },
    { country: "Burundi", code: "BI", dialCode: "+257" },
    { country: "Cabo Verde", code: "CV", dialCode: "+238" },
    { country: "Cambodia", code: "KH", dialCode: "+855" },
    { country: "Cameroon", code: "CM", dialCode: "+237" },
    { country: "Canada", code: "CA", dialCode: "+1" },
    { country: "Central African Republic", code: "CF", dialCode: "+236" },
    { country: "Chad", code: "TD", dialCode: "+235" },
    { country: "Chile", code: "CL", dialCode: "+56" },
    { country: "China", code: "CN", dialCode: "+86" },
    { country: "Colombia", code: "CO", dialCode: "+57" },
    { country: "Comoros", code: "KM", dialCode: "+269" },
    { country: "Congo (Congo-Brazzaville)", code: "CG", dialCode: "+242" },
    { country: "Costa Rica", code: "CR", dialCode: "+506" },
    { country: "Croatia", code: "HR", dialCode: "+385" },
    { country: "Cuba", code: "CU", dialCode: "+53" },
    { country: "Cyprus", code: "CY", dialCode: "+357" },
    { country: "Czechia", code: "CZ", dialCode: "+420" },
    { country: "Denmark", code: "DK", dialCode: "+45" },
    { country: "Djibouti", code: "DJ", dialCode: "+253" },
    { country: "Dominica", code: "DM", dialCode: "+1-767" },
    { country: "Dominican Republic", code: "DO", dialCode: "+1-809" },
    { country: "Ecuador", code: "EC", dialCode: "+593" },
    { country: "Egypt", code: "EG", dialCode: "+20" },
    { country: "El Salvador", code: "SV", dialCode: "+503" },
    { country: "Equatorial Guinea", code: "GQ", dialCode: "+240" },
    { country: "Eritrea", code: "ER", dialCode: "+291" },
    { country: "Estonia", code: "EE", dialCode: "+372" },
    { country: "Eswatini", code: "SZ", dialCode: "+268" },
    { country: "Ethiopia", code: "ET", dialCode: "+251" },
    { country: "Fiji", code: "FJ", dialCode: "+679" },
    { country: "Finland", code: "FI", dialCode: "+358" },
    { country: "France", code: "FR", dialCode: "+33" },
    { country: "Gabon", code: "GA", dialCode: "+241" },
    { country: "Gambia", code: "GM", dialCode: "+220" },
    { country: "Georgia", code: "GE", dialCode: "+995" },
    { country: "Germany", code: "DE", dialCode: "+49" },
    { country: "Ghana", code: "GH", dialCode: "+233" },
    { country: "Greece", code: "GR", dialCode: "+30" },
    { country: "Grenada", code: "GD", dialCode: "+1-473" },
    { country: "Guatemala", code: "GT", dialCode: "+502" },
    { country: "Guinea", code: "GN", dialCode: "+224" },
    { country: "Guinea-Bissau", code: "GW", dialCode: "+245" },
    { country: "Guyana", code: "GY", dialCode: "+592" },
    { country: "Haiti", code: "HT", dialCode: "+509" },
    { country: "Honduras", code: "HN", dialCode: "+504" },
    { country: "Hungary", code: "HU", dialCode: "+36" },
    { country: "Iceland", code: "IS", dialCode: "+354" },
    { country: "India", code: "IN", dialCode: "+91" },
    { country: "Indonesia", code: "ID", dialCode: "+62" },
    { country: "Iran", code: "IR", dialCode: "+98" },
    { country: "Iraq", code: "IQ", dialCode: "+964" },
    { country: "Ireland", code: "IE", dialCode: "+353" },
    { country: "Israel", code: "IL", dialCode: "+972" },
    { country: "Italy", code: "IT", dialCode: "+39" },
    { country: "Jamaica", code: "JM", dialCode: "+1-876" },
    { country: "Japan", code: "JP", dialCode: "+81" },
    { country: "Jordan", code: "JO", dialCode: "+962" },
    { country: "Kazakhstan", code: "KZ", dialCode: "+7" },
    { country: "Kenya", code: "KE", dialCode: "+254" },
    { country: "Kiribati", code: "KI", dialCode: "+686" },
    { country: "Kuwait", code: "KW", dialCode: "+965" },
    { country: "Kyrgyzstan", code: "KG", dialCode: "+996" },
    { country: "Laos", code: "LA", dialCode: "+856" },
    { country: "Latvia", code: "LV", dialCode: "+371" },
    { country: "Lebanon", code: "LB", dialCode: "+961" },
    { country: "Lesotho", code: "LS", dialCode: "+266" },
    { country: "Liberia", code: "LR", dialCode: "+231" },
    { country: "Libya", code: "LY", dialCode: "+218" },
    { country: "Liechtenstein", code: "LI", dialCode: "+423" },
    { country: "Lithuania", code: "LT", dialCode: "+370" },
    { country: "Luxembourg", code: "LU", dialCode: "+352" },
    { country: "Madagascar", code: "MG", dialCode: "+261" },
    { country: "Malawi", code: "MW", dialCode: "+265" },
    { country: "Malaysia", code: "MY", dialCode: "+60" },
    { country: "Maldives", code: "MV", dialCode: "+960" },
    { country: "Mali", code: "ML", dialCode: "+223" },
    { country: "Malta", code: "MT", dialCode: "+356" },
    { country: "Marshall Islands", code: "MH", dialCode: "+692" },
    { country: "Mauritania", code: "MR", dialCode: "+222" },
    { country: "Mauritius", code: "MU", dialCode: "+230" },
    { country: "Mexico", code: "MX", dialCode: "+52" },
    { country: "Micronesia", code: "FM", dialCode: "+691" },
    { country: "Moldova", code: "MD", dialCode: "+373" },
    { country: "Monaco", code: "MC", dialCode: "+377" },
    { country: "Mongolia", code: "MN", dialCode: "+976" },
    { country: "Montenegro", code: "ME", dialCode: "+382" },
    { country: "Morocco", code: "MA", dialCode: "+212" },
    { country: "Mozambique", code: "MZ", dialCode: "+258" },
    { country: "Myanmar (Burma)", code: "MM", dialCode: "+95" },
    { country: "Namibia", code: "NA", dialCode: "+264" },
    { country: "Nauru", code: "NR", dialCode: "+674" },
    { country: "Nepal", code: "NP", dialCode: "+977" },
    { country: "Netherlands", code: "NL", dialCode: "+31" },
    { country: "New Zealand", code: "NZ", dialCode: "+64" },
    { country: "Nicaragua", code: "NI", dialCode: "+505" },
    { country: "Niger", code: "NE", dialCode: "+227" },
    { country: "Nigeria", code: "NG", dialCode: "+234" },
    { country: "North Korea", code: "KP", dialCode: "+850" },
    { country: "North Macedonia", code: "MK", dialCode: "+389" },
    { country: "Norway", code: "NO", dialCode: "+47" },
    { country: "Oman", code: "OM", dialCode: "+968" },
    { country: "Pakistan", code: "PK", dialCode: "+92" },
    { country: "Palau", code: "PW", dialCode: "+680" },
    { country: "Panama", code: "PA", dialCode: "+507" },
    { country: "Papua New Guinea", code: "PG", dialCode: "+675" },
    { country: "Paraguay", code: "PY", dialCode: "+595" },
    { country: "Peru", code: "PE", dialCode: "+51" },
    { country: "Philippines", code: "PH", dialCode: "+63" },
    { country: "Poland", code: "PL", dialCode: "+48" },
    { country: "Portugal", code: "PT", dialCode: "+351" },
    { country: "Qatar", code: "QA", dialCode: "+974" },
    { country: "Romania", code: "RO", dialCode: "+40" },
    { country: "Russia", code: "RU", dialCode: "+7" },
    { country: "Rwanda", code: "RW", dialCode: "+250" },
    { country: "Saint Kitts and Nevis", code: "KN", dialCode: "+1-869" },
    { country: "Saint Lucia", code: "LC", dialCode: "+1-758" },
    { country: "Saint Vincent and the Grenadines", code: "VC", dialCode: "+1-784" },
    { country: "Samoa", code: "WS", dialCode: "+685" },
    { country: "San Marino", code: "SM", dialCode: "+378" },
    { country: "Sao Tome and Principe", code: "ST", dialCode: "+239" },
    { country: "Saudi Arabia", code: "SA", dialCode: "+966" },
    { country: "Senegal", code: "SN", dialCode: "+221" },
    { country: "Serbia", code: "RS", dialCode: "+381" },
    { country: "Seychelles", code: "SC", dialCode: "+248" },
    { country: "Sierra Leone", code: "SL", dialCode: "+232" },
    { country: "Singapore", code: "SG", dialCode: "+65" },
    { country: "Slovakia", code: "SK", dialCode: "+421" },
    { country: "Slovenia", code: "SI", dialCode: "+386" },
    { country: "Solomon Islands", code: "SB", dialCode: "+677" },
    { country: "Somalia", code: "SO", dialCode: "+252" },
    { country: "South Africa", code: "ZA", dialCode: "+27" },
    { country: "South Korea", code: "KR", dialCode: "+82" },
    { country: "South Sudan", code: "SS", dialCode: "+211" },
    { country: "Spain", code: "ES", dialCode: "+34" },
    { country: "Sri Lanka", code: "LK", dialCode: "+94" },
    { country: "Sudan", code: "SD", dialCode: "+249" },
    { country: "Suriname", code: "SR", dialCode: "+597" },
    { country: "Sweden", code: "SE", dialCode: "+46" },
    { country: "Switzerland", code: "CH", dialCode: "+41" },
    { country: "Syria", code: "SY", dialCode: "+963" },
    { country: "Taiwan", code: "TW", dialCode: "+886" },
    { country: "Tajikistan", code: "TJ", dialCode: "+992" },
    { country: "Tanzania", code: "TZ", dialCode: "+255" },
    { country: "Thailand", code: "TH", dialCode: "+66" },
    { country: "Timor-Leste", code: "TL", dialCode: "+670" },
    { country: "Togo", code: "TG", dialCode: "+228" },
    { country: "Tonga", code: "TO", dialCode: "+676" },
    { country: "Trinidad and Tobago", code: "TT", dialCode: "+1-868" },
    { country: "Tunisia", code: "TN", dialCode: "+216" },
    { country: "Turkey", code: "TR", dialCode: "+90" },
    { country: "Turkmenistan", code: "TM", dialCode: "+993" },
    { country: "Tuvalu", code: "TV", dialCode: "+688" },
    { country: "Uganda", code: "UG", dialCode: "+256" },
    { country: "Ukraine", code: "UA", dialCode: "+380" },
    { country: "United Arab Emirates", code: "AE", dialCode: "+971" },
    { country: "United Kingdom", code: "GB", dialCode: "+44" },
    { country: "United States", code: "US", dialCode: "+1" },
    { country: "Uruguay", code: "UY", dialCode: "+598" },
    { country: "Uzbekistan", code: "UZ", dialCode: "+998" },
    { country: "Vanuatu", code: "VU", dialCode: "+678" },
    { country: "Vatican City", code: "VA", dialCode: "+379" },
    { country: "Venezuela", code: "VE", dialCode: "+58" },
    { country: "Vietnam", code: "VN", dialCode: "+84" },
    { country: "Yemen", code: "YE", dialCode: "+967" },
    { country: "Zambia", code: "ZM", dialCode: "+260" },
    { country: "Zimbabwe", code: "ZW", dialCode: "+263" },
  ];
  