import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Add a request interceptor to dynamically add the access token
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      delete config.headers['Authorization'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Add a response interceptor to handle refresh token
api.interceptors.response.use(
  (response) => {
    // Simply return the response if no errors
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Skip refresh logic if this is a login attempt (i.e., no accessToken)
    const accessToken = localStorage.getItem('accessToken');


    // If the error status is 401 (Unauthorized) and we haven't retried the request yet
    if (error.response.status === 401 && accessToken && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Attempt to get a new access token using the refresh token
        const refreshToken = localStorage.getItem('refreshToken');
        
        const refreshResponse = await axios.post(`${process.env.REACT_APP_API_URL}token/refresh/`, {refresh: refreshToken});

        const newAccessToken = refreshResponse.data.access;
        const newRefreshToken = refreshResponse.data.refresh;

        // Save the new access token to localStorage
        localStorage.setItem('accessToken', newAccessToken);

        // Save the new refresh token to localStorage
        localStorage.setItem('refreshToken', newRefreshToken)

        // Update the Authorization header with the new token
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

        // Retry the original request with the new access token
        return api(originalRequest);

      } catch (refreshError) {
        // If refreshing the token fails, log the user out or handle the failure
        console.error('Refresh token failed', refreshError);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login'; // Redirect to login page
      }
    }

    return Promise.reject(error);
  }
);


export default api;