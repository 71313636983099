import React, { useState } from 'react'
import Price from '../../Components/Price/Price'

export default function StudentPrice() {
  const [options, setOptions] = useState(
    [
        {
            price: 850,
            name: 'Plan Standard',
            features: [
                'AVI',
                'Assurance voyage et habitation',
                'Accueil aéroport',
                'Accompagnement inscription',
                "Conseil et orientation pour une année d'études",
                'Attestation d’hébergement'
            ],
            defineAspopularChoice: true,
        },
        {
            price: 1020,
            name: 'Forfait Illimite',
            features: [
                'AVI',
                'Assurance voyage et habitation',
                'Accueil aéroport',
                'Accompagnement inscription',
                "Conseil et orientation pour une année d'études",
                'Attestation d’hébergement'
            ],
            defineAspopularChoice: false,
        },
        {
            price: 762,
            name: 'Forfait Pro',
            features: [
                'AVI',
                'Assurance voyage et habitation',
                'Accueil aéroport',
                'Accompagnement inscription',
                "Conseil et orientation pour une année d'études",
                'Attestation d’hébergement'
            ],
            defineAspopularChoice: false,
        },
    ]
)
  return (
    <Price options={options}/>
  )
}
