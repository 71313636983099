import React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import './TransactionStyles.css'
import { useEffect } from 'react'

export default function Layout({children}) {
    const [activeMenu, setActiveMenu] = useState('listing')
    const url = useLocation().pathname
    const navigate = useNavigate()
    const switchTo = (url, menu) => {
        setActiveMenu(menu)
        navigate(url)
    }

    useEffect(()=>{
        url.includes('remboursements') ? setActiveMenu('refund') :
        url.includes('dashboard') ? setActiveMenu('dashboard') : 
        setActiveMenu('listing')
    },[])

  return (
    <div className="adminTransactions">
        <header className="transactionHeader">
            <span onClick={()=>switchTo('/administrateur/transactions/dashboard', 'dashboard')} className={`${activeMenu === 'dashboard' && 'headerMenuActive'} `}>Tableau de bord</span>
            <span onClick={()=>switchTo('/administrateur/transactions/', 'listing')} className={`${activeMenu === 'listing' && 'headerMenuActive'} `}>Transactions</span>
            <span onClick={()=>switchTo('/administrateur/transactions/remboursements', 'refund')} className={`${activeMenu === 'refund' && 'headerMenuActive'} `}>Remboursement</span>
        </header>
        <main className="transactionMain">
            
        {
            children
        }
        </main>
    </div>
  )
}
