import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import bg from '../../images/loginAdmin.png'; // Importer l'image
import logo from "../../images/logoLoginAdmin.png";
import loginImgLeft from "../../images/loginImgLeft.png"
import "./Login.css";
import next from "../../images/loginNext.png";
import { useNavigate } from 'react-router';

function LoginAdmin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email, " ", password);
    // You can add additional login logic here
  };
const navigate=useNavigate()
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <Row>
          <Col sm={7} >
           {/* <img src={loginImgLeft}/> */}
          </Col>
          <Col sm={5} className='LoginCardAdm'>
          
          <Row>
              <Col className='logoAdm'>
                <img src={logo} alt='logo UFS' className='logoLogin' />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className='loginAdm'>SE CONNECTER</p>
                <p className='sousLogin'>Veuillez remplir le formulaire pour vous connecter</p>
              </Col>
            </Row>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <label className='labelLoginAdm'>Identifiant de courrier électronique*</label><br />
                  <input
                    className='inpLogAdm'
                    value={email}
                    type='email' // Changed to 'email' for better validation
                    name='email'
                    placeholder='abc@gmail.com'
                    onChange={handleEmail} // Attach the onChange handler
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label  className='labelLoginAdm'>Mot de passe*</label><br />
                  <input
                    className='inpLogAdm'
                    value={password}
                    type='password'
                    name='password'
                    placeholder='************'
                    onChange={handlePassword} // Attach the onChange handler
                  />
                </Col>
              </Row>
              <Row>
                <p onClick={()=>{navigate('/renitialiserMotDePasse')}} className='forgetpwd'>Mot de passe oublié?</p>
              </Row>
              <Row>
                <button type='submit' className='loginBtnAdm' onClick={()=>navigate('/administrateur')}>
                  Se Connecter
                  <img src={next} className='imgLogA' alt='Next' />
                </button>
              </Row>
            </form>
          
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginAdmin;
