import React from 'react'

function LittleUni() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
  <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M1.333 14.667h13M2 8.667v6m12-6v6M5 5.333v9.334m6-9.334v9.334m-9.667-6h3.334m10 0h-3.334m-7-3.334h7.334M8 5.333V3.321m0 0v-1.34c0-.317 0-.475.097-.573.307-.309 1.57.42 2.05.717.405.25.52.748.52 1.196H8Zm0 11.346v-1.333M7 8v.333M9 8v.333m-2 2.334V11m2-.333V11"/>
</svg>

  )
}

export default LittleUni
