import React from 'react'

function CakeSvg() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
  <path fill="url(#a)" d="M8.5 4.25a1.417 1.417 0 0 0 1.417-1.417c0-.269-.071-.517-.206-.73L8.5 0 7.289 2.104c-.135.212-.206.46-.206.73 0 .778.638 1.416 1.417 1.416Zm3.258 7.076L11 10.568l-.765.758c-.92.921-2.535.928-3.463 0l-.758-.758-.772.758c-.46.46-1.077.716-1.729.716a2.46 2.46 0 0 1-1.388-.432v3.265c0 .39.319.708.708.708h11.334a.71.71 0 0 0 .708-.708V11.61a2.46 2.46 0 0 1-1.388.432 2.433 2.433 0 0 1-1.729-.716Zm.992-4.951H9.208V4.958H7.792v1.417H4.25c-1.176 0-2.125.95-2.125 2.125v1.09a1.39 1.39 0 0 0 1.388 1.39c.369 0 .723-.143.978-.405l1.516-1.508 1.508 1.508c.525.525 1.438.525 1.962 0l1.516-1.508 1.51 1.508c.261.263.608.404.977.404a1.39 1.39 0 0 0 1.388-1.388V8.5a2.112 2.112 0 0 0-2.118-2.125Z"/>
  <defs>
    <linearGradient id="a" x1=".841" x2="16.621" y1="-1.195" y2="4.335" gradientUnits="userSpaceOnUse">
      <stop stop-color="#015192"/>
      <stop offset="1" stop-color="#00A5BC"/>
    </linearGradient>
  </defs>
</svg>

  )
}

export default CakeSvg
