
import Message from "../../Components/Message/Message";

export default function ParentMessage(){
    

    return(
             
            <div className={`parentMessage`}>
                    <Message leftMessageColor="rgba(144, 134, 221, 0.15)" rightMessageColor="#8B5CE5"/>

            </div>
               
       
    )
}