import React from 'react'
import ParrainageHelperHeaderImg from '../../../images/Parent/Group 9.png'
import ParrainageImg from '../../../images/Mask group (1).png'
import { IoArrowBackCircle, IoCopyOutline } from 'react-icons/io5'
import { useState } from 'react'
import Button from '../../Button'
import { Navigate, useNavigate } from 'react-router'


export default function ToReferSomeone() {

    const HowItWorkStep = ({stepNumber, title, content}) => {
        return(
            <div className='howItWorkStep'>
                <span>{stepNumber}</span>
                <div>
                    <span className='howItWorkStepTitle'>{title}</span>
                    <span className='howItWorkStepContent'>{content}</span>
                </div>
            </div>
        )
    }

    const referLink = 'www.gmp-auto-ecole'

    const handleCopyClick = () => {
        navigator.clipboard.writeText(referLink)
          .then(() => {
            setShowText({
                beforeCopy: false,
                afterCopy: true
            })
            setTimeout(() => {
                setShowText({
                    beforeCopy: false,
                    afterCopy: false
                })
            }, 3000);
          })
          .catch((error) => {
            console.error("Erreur lors de la copie : ", error);
          });
      };

      const [showText, setShowText] = useState({
        beforeCopy: false,
        afterCopy: false
      })

      const navigate = useNavigate()

  return (
    <div className="refertoSomeone">
        <div className='d-flex justify-content-end mb-2'>
            <Button 
                value={
                    <>
                    <IoArrowBackCircle/>
                    <span>Retour</span>

                    </>
                } 
                onClick={()=>navigate(-1)}
                
            />
        </div>
        <header>
            <h1>Parrainer vos amis afin de reduire le cout de votre licence</h1>
        </header>
        <main>
            <h3>Parrainer vos amis afin de reduire le cout de votre licence</h3>
            <div className='refertoSomeoneMainHeader'>
                <div>50 € pour votre prochaine commande 50 € pour votre filleule</div>
                <img src={ParrainageHelperHeaderImg} alt="" />
            </div>

            <div className='refertoSomeoneMain'>
                <div className='refertoSomeoneMainImgCard'>
                    <img src={ParrainageImg} alt="" />
                </div>
                <div className='refertoSomeoneMainInfos'>
                    <div>
                        <span>Mon code parrainage</span>
                        <span>JPPJZR</span>
                    </div>
                    <div>
                        <span>Mon ticket</span>
                        <span>0 €</span>
                    </div>
                    <div>
                        <span>Mes filleuls</span>
                        <span>- -</span>
                    </div>
                </div>
            </div>

            <div className='refertoSomeoneUnderMain'>
                <h4>Je partage mon lien d'invitation</h4>
                <div>
                    {
                        showText.beforeCopy &&
                        <span style={{position: 'absolute', top:'-20px', right: '10px'}}>copier le lien dans le presse-papier</span>

                    }
                    <input readOnly value={referLink} type="text" />
                    <IoCopyOutline 
                        className = 'copyPasteIcon' 
                        onMouseOver = {()=>
                            setShowText({
                            beforeCopy: true,
                            afterCopy: false
                        })}
                        onMouseLeave = {()=>
                            setShowText({
                            beforeCopy: false,
                            afterCopy: false
                        })}
                        onClick = {handleCopyClick}
                    />
                    {
                        showText.afterCopy && 
                        <span style={{position: 'absolute', bottom:'-20px', right: '10px'}}>lien copié !</span>
                    }
                </div>
            </div>

            <div className='refertoSomeoneFooter'>
                <h5>Comment ça marche ?</h5>
                <div>
                    <HowItWorkStep stepNumber = {1} title = {'Partage ton code de parrainage'} content = {'Pour offrir 50 € à vos amis non inscrits'}/>
                    <HowItWorkStep stepNumber = {2} title = {'Gagne 50 € dans votre compte'} content = {'Pour chaque produit inclant le revue de code achetés par vos amis'}/>
                    <HowItWorkStep stepNumber = {3} title = {'Votre prix bonus est automatiquement utilisé'} content = {'Pour financer vos prochains achats'}/>
                </div>
            </div>
        </main>
    </div>
  )
}
