// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editAccountAVI{
   /* Rectangle 2 */

/* Rectangle 2 */



background: #FFFFFF;
border-radius: 16px;


    padding: 20px;
}

.editAccountAVIMain{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;

}

.editAccountAVITitle{
  margin: 25px 0;
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 20px;
/* or 57% */

color: #000000;


}`, "",{"version":3,"sources":["webpack://./src/AdminModule/Avi_Adm/EditAccountAVI/EditAccountAVI.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;;AAEnB,gBAAgB;;;;AAIhB,mBAAmB;AACnB,mBAAmB;;;IAGf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,QAAQ;;AAEZ;;AAEA;EACE,cAAc;AAChB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,WAAW;;AAEX,cAAc;;;AAGd","sourcesContent":[".editAccountAVI{\n   /* Rectangle 2 */\n\n/* Rectangle 2 */\n\n\n\nbackground: #FFFFFF;\nborder-radius: 16px;\n\n\n    padding: 20px;\n}\n\n.editAccountAVIMain{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 1em;\n\n}\n\n.editAccountAVITitle{\n  margin: 25px 0;\nfont-style: normal;\nfont-weight: 700;\nfont-size: 35px;\nline-height: 20px;\n/* or 57% */\n\ncolor: #000000;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
