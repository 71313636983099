// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetPassword{
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
        
}

.resetPasswordButton{
    padding: 0 20px
}

@media screen and (max-width:785px) {
    .resetPassword{
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/AdminModule/Settings_Adm/ResetPassword/ResetPassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,8BAA8B;;AAElC;;AAEA;IACI;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".resetPassword{\n    display: flex;\n    padding: 0 20px;\n    align-items: center;\n    justify-content: space-between;\n        \n}\n\n.resetPasswordButton{\n    padding: 0 20px\n}\n\n@media screen and (max-width:785px) {\n    .resetPassword{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
