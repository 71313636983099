import React, { createContext, useState } from 'react';

export const PlansContext = createContext();

export function PlansProvider({ children }) {
  const [plans, setPlans] = useState([
    { id: 1, popular: false, price: "762 €",lastPrice:"800€", title: "PACK Essentiel",sousTitle:"Assistance Pré-Départ", features: ["AVI", "Assurance voyage et habitation", "Accueil aéroport", "Accompagnement inscription", "Attestation d’hébergement"] },
    { id: 2, popular: true, price: "850 €",lastPrice:"900 €", title: "pack Sécurité",sousTitle:"Accompagnement Étendu", features: ["AVI", "Assurance voyage et habitation", "Accueil aéroport", "Accompagnement inscription", "Conseil et orientation pour une année d'études","Attestation d’hébergement"] },
    { id: 3, popular: false, price: "1020€",lastPrice:"1300 €", title: "pack Succès",sousTitle:"Services Premium", features: ["AVI", "Attestation d’hébergement et recherche de logement","Assurance voyage et habitation","Réservation de billet", "Accueil aéroport", "Accompagnement inscription", "Conseil et orientation pour une année d'étude"] },

  ]);
  
    const updatePlan = (updatedPlan) => {
      setPlans((prevPlans) =>
        prevPlans.map((plan) => (plan.id === updatedPlan.id ? updatedPlan : plan))
      );
      
    };
  
    const addPlan = (newPlan, listType) => {
      const planWithId = { ...newPlan, id: Date.now() }; // Utilise la valeur de popular existante
      if (listType === "plans") {
        setPlans((prevPlans) => [...prevPlans, planWithId]);
      }
    };
    
    const deletePlan = (id, listType) => {
      if (listType === "plans") {
        setPlans((prevPlans) => prevPlans.filter((plan) => plan.id !== id));
      } 
    };
    
    return (
      <PlansContext.Provider value={{ plans, updatePlan, addPlan, deletePlan }}>
        {children}
      </PlansContext.Provider>
    );
  }