import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./Support.css"
import uplpoad from "../../images/upload.png"
import avatar from "../../images/Avatar.png"
import { useNavigate } from 'react-router'
function SupportTicket() {
  const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // Retourne à la page précédente
    };
   
  return (
    <div className='coantainSup'>
      <Row>
              <Col>
              <span className='RouteStu1'>
                    <p className='routeStu1'>Support Tickets</p>
                    <p className='RouteStuRoute1'>/ #TC-192</p>
                </span> 
              </Col>
              <Col>
                <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
              </Col> 
      </Row>
      <Row className='RowSupport'>
        <Row>
          <Col className='titleCoLSup'>
            <p className='titSup'>
            Je ne parviens pas à changer de forfait de 10 heures
            </p>
            <p className='sousTitSup'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
            </p>
          </Col>

        </Row> 
        <Row>
            <Col className='ColEvi' sm={7} >
              <Row>
              <Col>
              <p className='titSup'>
                Evidence
              </p>
              <div className='supEvidenc'>
                <img src={avatar} className='avatarEvi'/>
                <div className='EviPar'>
                  <div>
                    <p><span className='Evip1'>Santi carloza </span><span className='Evidp2'>a créé le problème</span></p>
                  </div>
                  <div className='divago'>
                  <p className='daysago'> il y'a 2 jours</p>
                  </div>
                </div>
              </div>
              </Col>
              </Row>
              <Row>
                <Col>
                <div className='supEvidenc2'>
                  <div className='Evidenc2'>
                  <img src={avatar} className='avatarEvi'/>
                    <div className='EviPar'>
                      <div>
                        <p><span className='Evip1'>Santi carloza </span></p>
                      </div>
                      <div className='divago'>
                      <p className='daysago2'> il y'a 2 jours</p>
                      </div>
                    </div>

                  </div>
                    <div className='supTextEvi1'>
                      <p className='pEvi1'>
                      Je suis intéressé à acheter l'un de vos forfaits et j'ai besoin de conseils sur la façon de procéder. Pourriez-vous s'il vous plaît m'aider avec les détails suivants :                         </p>
                      <span className='pEvi1'>
                      <ol >
                        <li >
                        Forfaits disponibles : pouvez-vous fournir des informations sur les différents forfaits que vous proposez ?                        </li>
                        <li>
                        Tarification : Quels sont les coûts associés à chaque plan ?                        </li>
                        <li>
                        Options de paiement : quelles méthodes de paiement puis-je utiliser pour acheter un forfait ?                        </li>
                        <li>Processus d'achat : quelles étapes dois-je suivre pour finaliser l'achat ?</li>                        
                        <li>
                        Temps d'activation : combien de temps faut-il pour que le forfait soit activé une fois que j'ai effectué le paiement ?                        </li>
                      </ol>
                      </span>
                      <p className='uploadEvi'>Mise à jour</p>
                      <div className='EviUp'>
                        <img src={uplpoad} />
                        <img src={uplpoad} className='imgUpEvi' />
                        <img src={uplpoad} className='imgUpEvi' />
                      </div>
                    </div>
                    <div className='Evidenc21'>
                      <img src={avatar} className='avatarEvi'/>
                        <div className='EviPar'>
                          <div>
                            <p><span className='Evip1'>Jimmy watt </span></p>
                          </div>
                          <div className='divago'>
                          <p className='daysago2'> il y'a 2 jours</p>
                          </div>
                        </div>
                        </div>
                        <div className='divEvi3'>
                              <p>
                              Bonjour! Je serais heureux de vous aider à acheter un plan. Voici les détails que vous avez demandés :
                              <ol>
                                <li>
                                 Plans disponibles : Nous proposons [Plan A, Plan B, Plan C]. Chaque plan comprend [une brève description de chaque plan].
                                </li>
                                <li>
                                Tarification : Les coûts sont les suivants :                                
                                <ul>
                                  <li>Plan A : X $/mois</li>
                                  <li>
                                  Plan B : Y $/mois
                                  </li>
                                  <li>
                                  Plan C : Z $/mois
                                  </li>
                                </ul>
                                </li>
                                <li>
                                Options de paiement : Vous pouvez payer par carte de crédit, PayPal et virement bancaire.
                                </li>
                                <li>Processus d'achat : Pour acheter un plan, veuillez suivre</li>
                              </ol>
                              </p>

                        </div>
                        <div className='Evidenc21'>
                          <img src={avatar} className='avatarEvi'/>
                       
                          <textarea  placeholder='Laisser Un Commentaire' className='commentEvi'/>
                        </div>
                        
                </div>
                  
                </Col>
              </Row>
            </Col>
            <Col sm={4} className='secondColSup'>
              <Row>
                <Row className='RowSupDet'>
                  <p className='titSup2'>
                  Détails du cessionnaire
                  </p>
                  <p className='soustitsup'>
                  Nom du cessionnaire
                  </p>
                  <p className='Evip1'>
                  Jimmy watt
                  </p>
                </Row>
                <Row className='RowSupDet'>
                  <p className='titSup2'>
                  Détails de l'appelant
                  </p>   
                  <p className='soustitsup'>
                  ticket ID                  </p>
                  <p className='Evip2'>
                  #TC-192 
                  </p>
                  <p className='soustitsup'>
                  Type
                  </p>
                  <p className='Evip2'>
                  Question
                  </p>
                  <p className='soustitsup'>
                  Nom du client
                  </p>
                  <p className='Evip2'>
                  Santi carloza
                  </p>
                </Row>
                <Row className='RowSupDet'>
                  <p className='titSup2'>
                  Autre informations
                  </p>   
                  <div className='divInfoSup'>
                    <p className='soustitsup'>
                      Créer en
                    </p>
                    <p className='rightSupInfo'>
                    12/06/24 . 9:00
                    </p>
                  </div>
                  <div className='divInfoSup'>
                    <p className='soustitsup'>
                      Créer par
                    </p>
                    <div className='rightdivinfo'>
                      <img src={avatar} className='imgInfoSup'/>
                      <p className='namerightInfo'>
                      Santi carloza
                      </p>
                    </div>
                  </div>
                  <div className='divInfoSup'>
                    <p className='soustitsup'>
                    Dernière mise à jour
                    </p>
                    <p className='rightSupInfo'>
                    12/06/24 . 9:00
                    </p>
                  </div>
                  <div className='divInfoSup'>
                    <p className='soustitsup'>
                    Créer par
                    </p>
                    <div className='rightdivinfo'>
                      <img src={avatar} className='imgInfoSup'/>
                      <p className='namerightInfo'>
                      Pradeep
                      </p>
                    </div>
                  </div>
                  <div className='selectSupInfo'>
                    <div>
                      <p className='pselectSupInfo'>En cours</p>
                    </div>
                  </div>
                </Row>
              </Row>
            </Col>
        </Row>
      </Row>
    </div>
  )
}

export default SupportTicket
