import { useState } from "react"
import './Price.css'
import {ReactComponent as Setting} from '../../images/Parent/Vector 154 (Stroke).svg'
import { useLocation, useNavigate } from "react-router"
import Button from "../Button"

export default function Price({options, setEditOption = null, setShowEditPage = null}){

    const [choice, setChoice] = useState('Mensuel')

    const switchChoice = () => {
        choice === 'Mensuel' ? setChoice('Annuel') : setChoice('Mensuel')
    }

    const navigate = useNavigate()

    const isUserAdmin = useLocation().pathname.includes('administrateur')

    const ParentPriceOption = ({option = {}, optionIndex}) => {

        const handleOption = () =>{
            // const slug = option.name.toLowerCase().replaceAll(' ','-')
            // navigate(`/administrateur/prix/modifier/${slug}-${option.price}`)
            setEditOption({
                index: optionIndex,
                option: option
            })
            setShowEditPage(true)

        }

        return(
            <div className={`${option.defineAspopularChoice ? 'parentPriceOptionActive': ''} animParent parentPriceOption`}>
                <div className="parentPriceOptionHeader" style={{alignItems: `${option.defineAspopularChoice ? 'normal' : 'center'}`}}>
                    <h5 className="parentPriceOptionName">{option.name}</h5>
                    
                    <div>
                        {
                            option.defineAspopularChoice &&
                            <div className="parentPriceOptionChildren">Le plus populaire</div>                          
                        }
                        {
                            isUserAdmin &&
                            <Button onClick={handleOption} value={'Modifier'} />
                        }
                    </div>
                    
                </div>
                <div className="parentPriceOptionPriceInfos">
                    <span className="parentPriceOptionPrice">{option.price}</span>
                    <div className="">                   
                        <span>€</span>
                        <div className ={'parentPriceOptionInfos'}>
                            <span>mois</span>
                            <span>facturé mensuellement</span>
                        </div>
                    </div>
                </div>

                <div className="parentPriceOptionsServices">
                    {
                        option.features.map(feature => (
                            <div className="parentPriceOptionsService">
                                <div className="parentPriceOptionsServiceImg">
                                    <Setting/>
                                </div>
                                <p>{feature}</p>
                            </div>
                        ))
                    }
                    {/* <div className="parentPriceOptionsService">
                        <div className="parentPriceOptionsServiceImg">
                            <Setting/>
                        </div>
                        <p>1 French RIB + 1 application for all your payments linked to your study project in France before your arrival.</p>
                    </div>
                    <div className="parentPriceOptionsService">
                        <div className="parentPriceOptionsServiceImg">
                        <Setting/>

                        </div>
                        <p>Paiement de vos frais d'inscription (caution) depuis votre pays d'origine</p>
                    </div>
                    <div className="parentPriceOptionsService">
                        <div className="parentPriceOptionsServiceImg">
                        <Setting/>

                        </div>
                        <p>Payment for booking your accommodation from your country of origin</p>
                    </div> */}
                </div>

                <button className="parentPriceButton">Choisir le forfait</button>
            </div>
        )
    }

    return(
            <main className="parentPrice">

                <div className="parentPriceHeader">
                    <h3>Achetez un abonnement</h3>
                    <p>Choisissez le plan qui vous convient</p>
                </div>

                <div onClick={switchChoice} className={`${choice === 'Mensuel' ? 'mensuel' : 'annuel'} parentPriceChoices`}>
                    <div className="parentPriceChoice">Mensuel</div>
                    <div className="parentPriceChoice">Annuel</div>
                </div>

                <div className="parentPriceOptions">
                    {
                        options.map((option, index) => (
                            <ParentPriceOption option={option} optionIndex={index}/>
                        ))
                    }
                </div>
                
            </main>
    )
}