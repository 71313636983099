// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-notif-success{
   
width:100%;
height: 24px;

font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 24px;

text-align: center;

color: #676767;


}


.button-notif-success{
    width:35%;
    height: 120%;
    background: linear-gradient(106.37deg, #015192 4.32%, #00A5BC 85.87%);
    border-radius: 74px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    text-transform: capitalize;
    color: #FFFFFF;
    border: 0px solid #015192;
  padding: 10px;
}

@media(max-width:768px){
    .button-notif-success{
        width:80%;
        padding: 15px;
    }
}

@media(max-width:425px){
    .message-notif-success{
        font-size: 18px;
    }
    .button-notif-success{
        font-size: 14px;
    }
}

@media(max-width:375px){
    .message-notif-success{
        font-size: 16px;
    }
    .button-notif-success{
        font-size: 12px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/NotificationCreationCompte/NotificationCreationCompte.css"],"names":[],"mappings":"AAAA;;AAEA,UAAU;AACV,YAAY;;AAEZ,gCAAgC;AAChC,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;;AAEjB,kBAAkB;;AAElB,cAAc;;;AAGd;;;AAGA;IACI,SAAS;IACT,YAAY;IACZ,qEAAqE;IACrE,mBAAmB;IACnB,gCAAgC;IAChC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,0BAA0B;IAC1B,cAAc;IACd,yBAAyB;EAC3B,aAAa;AACf;;AAEA;IACI;QACI,SAAS;QACT,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".message-notif-success{\n   \nwidth:100%;\nheight: 24px;\n\nfont-family: 'Plus Jakarta Sans';\nfont-style: normal;\nfont-weight: 600;\nfont-size: 21px;\nline-height: 24px;\n\ntext-align: center;\n\ncolor: #676767;\n\n\n}\n\n\n.button-notif-success{\n    width:35%;\n    height: 120%;\n    background: linear-gradient(106.37deg, #015192 4.32%, #00A5BC 85.87%);\n    border-radius: 74px;\n    font-family: 'Plus Jakarta Sans';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 120%;\n    text-transform: capitalize;\n    color: #FFFFFF;\n    border: 0px solid #015192;\n  padding: 10px;\n}\n\n@media(max-width:768px){\n    .button-notif-success{\n        width:80%;\n        padding: 15px;\n    }\n}\n\n@media(max-width:425px){\n    .message-notif-success{\n        font-size: 18px;\n    }\n    .button-notif-success{\n        font-size: 14px;\n    }\n}\n\n@media(max-width:375px){\n    .message-notif-success{\n        font-size: 16px;\n    }\n    .button-notif-success{\n        font-size: 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
