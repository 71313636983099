import React, { useEffect, useState } from 'react'
// import './RechercheLogement.css'
import { PersonnalInfos } from '../RechercheLogement/RechercheLogement'
import FileToDownload from '../FileToDownload'
import SelectLabel from '../../../Components/SelectLabel'
import InputLabel from '../../../Components/InputLabel'
import axios from "axios";
import Button from '../../../Components/Button'
import { useNavigate } from 'react-router'
import { IoArrowBackCircle } from 'react-icons/io5'

export default function ServiceBilleterie() {

    const [data, setData] = useState({
        fistname: '',
        lastname: '',
        phone: '',
        birthPlace: '',
        birthDate: '',
        email: '',
        nationnality: '',
        departureCountry: '',
        departureCity: '',
        arrivalCountry: '',
        arrivalCity: '',
        departureDate: '',
        arrivalDate: '',
        passagersCount: '',
        travelClass: '',
        preferedAirline: '',
    })

   const[countries,setCountries]=useState([]);
   const [villes,setVilles]=useState([]);


    const updateField = e => {
        setData(data => (
            {
                ...data,
                [e.target.name]: e.target.value
            }
        ))
    }

    const navigate = useNavigate()

    //Récupération des pays
   useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        
       
        const countryData = response.data
          .map(country => ({
            name: country.translations.fra.common, 
            code: country.name.common,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)); 

        setCountries(countryData);
      } catch (error) {
        console.error('Erreur lors de la récupération des pays:', error);
      }
    };

    fetchCountries();
  }, []);

  // Load cities when a country is selected
  const handleCitiesOfSelectedCountry = async (e) => {
    const countryName = e.target.value; 
    // setData(data => (
    //     {
    //         ...data,
    //         [e.target.name]: countryName
    //     }
    // ))
        
    console.log(countryName)
    if (countryName) {
      try {
        
        const citiesResponse = await axios.post('https://countriesnow.space/api/v0.1/countries/cities', {
         country: countryName 
        });
        
        const cities = citiesResponse.data.data; 
        // console.log(cities);
        const sortedCities = cities.sort((a, b) => a.localeCompare(b)); // Sort cities

        setVilles(sortedCities); // Set the sorted cities in state
      } catch (error) {
        console.error('Erreur lors de la récupération des villes:', error);
      }
    } else {
      setVilles([]); 
    }
  };

  useEffect(()=>{
    console.log(data);
  }, [data])
    
    return (
        
        <main className="serviceBilleterie animParent rechercheLogement">

        <div className="backSectionButton">
                <Button 
                    value={
                        <>
                            <IoArrowBackCircle/>
                            <span>Retour</span>
                        </>
                    }
                    onClick = {()=>navigate('/etudiant/prestations-de-service')}
                />
            </div>

            <PersonnalInfos title={'Service de billeterie'} data={data} updateField={updateField} setData={setData}/>

            <section className="detailsVoyageSection">
                <h1 className='student-attestation-title'>Détails du Voyage</h1>
                <div className="detailsVoyageForm">
                    <SelectLabel label={'Pays de départ'} name={'departureCountry'} onChange = {handleCitiesOfSelectedCountry}>
                        <option selected value="" disabled>
                            Sélectionnez le pays de départ
                        </option>
                        {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                        {country.name}
                        </option>
                        ))}
                    </SelectLabel>
                    <SelectLabel label={'Ville de départ'} name={'departureCity'} onChange = {(e)=> updateField(e)}>
                        <option value="" selected disabled>
                            Sélectionnez la ville de départ
                        </option>
                        {villes.length>0 && villes.map((ville) => (
                        <option key={ville} value={ville}>
                        {ville}
                        </option>
                        ))}
                    </SelectLabel>
                    <SelectLabel label={'Pays de destination'} name={'arrivalCountry'} onChange = {(e)=> handleCitiesOfSelectedCountry(e)}>
                        <option selected value="" disabled>
                            Sélectionnez le pays de destination
                        </option>
                        {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                        {country.name}
                        </option>
                        ))}
                    </SelectLabel>
                    <SelectLabel label={'Ville de destination'} name={'arrivalCity'} onChange = {(e)=> updateField(e)}>
                        <option selected value="" disabled>
                            Sélectionnez la ville de destination
                        </option>
                        {villes.length>0 && villes.map((ville) => (
                        <option key={ville} value={ville}>
                        {ville}
                        </option>
                        ))}
                    </SelectLabel>
                    {/* <InputLabel label={'Pays de destination'} name={'arrivalCountry'} placeholder = {'Entrez la ville de destination'} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Ville de destination'} name={'arrivalCity'} placeholder = {'Entrez la ville de destination'} onChange = {(e)=> updateField(e)}/> */}
                    <InputLabel label={'Date de départ'} name={'departureDate'} type='date' placeholder = {''} onChange = {(e)=> updateField(e)}/>
                    <InputLabel label={'Date de retour'} name={'arrivalDate'} type='date' placeholder = {''} onChange = {(e)=> updateField(e)}/>
                    <SelectLabel label={'Nombre de passagers'} name={'passagersCount'} onChange = {(e)=> updateField(e)}>
                    <option selected value="" disabled>
                            Sélectionnez le nombre de passagers
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </SelectLabel>
                    <SelectLabel label={'Classe de voyage'} name={'travelClass'} onChange = {(e)=> updateField(e)}>
                    <option selected value="" disabled>
                            Sélectionnez la classe de voyage
                        </option>
                        <option value="economique">Économique</option>
                        <option value="premium_economique">Premium Économique</option>
                        <option value="business">Affaires</option>
                        <option value="first_class">Première Classe</option>
                    </SelectLabel>
                    <InputLabel label={'Compagnies aériennes préférées(facultative)'} name={'preferedAirline'} type='text' placeholder = {'Entrez'} onChange = {(e)=> updateField(e)}/>

                    <div className="inputLabel">
                        <label htmlFor="specialNeeds" className='inputLabelLabel'>Demandes Spéciales</label>
                        <textarea name={'specialNeeds'} id='specialNeeds' className='inputLabelInput'></textarea>
                    </div>
                </div>
            </section>

            <section className="documentsATelecharger">
                <h1 className='student-attestation-title'>Documents à télécharger</h1>
                <div className='documentsATelechargerContenu'>
                    <FileToDownload titre={'Copie du passeport'} acceptanceText={'Passeport, ID Carte'}/>
                </div>
            </section>

            <div className="submitSectionButton">
                <Button value={
                    <>
                        <span>Soumettre</span>
                        <svg  viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="profil-fleche">
                                <rect y="0.5" width="29" height="29" rx="14.5" fill="white"/>
                                <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </>
                }/>
            </div>

        </main>

    )
}

