import './ChatingInterface.css'
import AudioCall from '../../../images/Message/audio-call.png'
import VideoCall from '../../../images/Message/gala_video.png'
import AudioCallBlack from '../../../images/Message/material-symbols_call.png'
import VideoCallBlack from '../../../images/Message/gala_video-1.png'
import SendIcon from '../../../images/Message/mingcute_send-fill.png'
import UploadFileIcon from '../../../images/Message/Vector.png'
import Media1 from '../../../images/Message/Rectangle 2716.png'
import Media2 from '../../../images/Message/Rectangle 2717.png'
import Media3 from '../../../images/Message/Rectangle 2716.png'
import Media4 from '../../../images/Message/Rectangle 2717.png'
import Profil from '../../../images/AdminLayout/Ellipse 806.png'
import { IoMdMore, IoMdArrowRoundBack  } from "react-icons/io";
import { useEffect, useState } from 'react'
import { IoDocument } from 'react-icons/io5'
import { FaLink } from "react-icons/fa6";
import { FcDeleteDatabase } from "react-icons/fc";
import { Modal } from 'react-bootstrap'
import Button from '../../Button'


export default function ChatingInterface({leftMessageColor , rightMessageColor , isMobile, setIsmobile}){

    const [showSidebarChating, setshowSidebarChating] = useState(window.innerWidth <= 795)
    useEffect(() => {
        const handleResize = () => {
            setshowSidebarChating(window.innerWidth <= 795);
        };

        window.addEventListener('resize', handleResize);

        // Nettoyer l'événement lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function ChatingBox(){

        const [chatingFiles, setChatingFiles] = useState([])
    
        const handleFileChange = event => {
            const imgFiles = Array.from(event.target.files)
            const imgUrls = imgFiles.map(img => URL.createObjectURL(img))
            setChatingFiles(imgUrls)
        }
    
        const removeUploadedFile = filename => {
            const files = chatingFiles.filter(file => file != filename)
            setChatingFiles(files)
        }
        // useEffect(()=>{
        //     console.log(chatingFiles);
        // },[chatingFiles])
    
        const ChatMessageItem = ({sender, content, date}) => {
    
            const messageItemStyles = {
                backgroundColor: sender ? leftMessageColor : rightMessageColor,
                // padding: '12px'
            }
    
            return(
                <div className={`${sender ? 'leftChatMessageItem' : 'rightChatMessageItem'}`}>
                    <div className="chatMessageItemInfos">
                        <h6 className="messageExpediteur">{sender ? sender : 'Vous'}</h6>
                        <p className="messageDate">{date}</p>
                    </div>
                    <div style={messageItemStyles} className={sender ? 'leftMessageContent'  : `rightMessageContent ${rightMessageColor == '#8B5CE5' && 'whiteColor'}` }>
                        {content}
                    </div>
                </div>
            )
        }
    
        return(
            <main className={`chatingBox`}>
    
                <header className="chatingBoxOptions">
                    <div className="optionProfilInfos">
                        {
                            window.innerWidth <= 610 &&
                            <div onClick={()=>setIsmobile(true)}>
                                <IoMdArrowRoundBack/>
                            </div>
                        }
                        <div className="optionProfil">
                            <img src={Profil} alt="profil" className='optionProfilImg'/>
                            <span className="optionProfilStatus"></span>
                        </div>
                        <div className="optionStatusName">
                            <h5 className="chatingProfilName">Ian Dooley</h5>
                            <p className='chatingProfilStatus'>en ligne</p>
                        </div>
                    </div>
                    <div className="chatingCallOptions chatingBoxCallOptions">
                        <div className="audioCallOption">
                            <img src={AudioCallBlack} alt="audio call" className="audioCallOptionImg" />
                        </div>
                        <div className="videoCallOption">
                            <img src={VideoCallBlack} alt="video call" className="videoCallOptionImg" />
                        </div>
                        {
                            window.innerWidth <= 795 && 
                            <div onClick={()=> setshowSidebarChating(!showSidebarChating)}>
                                <IoMdMore/>
                            </div>
                        }
                    </div>
                </header>
    
                <main className="chatingBoxContent">
    
                    <ChatMessageItem date={'12:00'} content={"Bonjour ! Je vais bien, merci. Je suis excitée pour notre leçon en ligne."}/>
                    <ChatMessageItem date={'12:00'} sender={'Ian Dooley'} content={"C'est super à entendre ! Aujourd'hui, nous allons aborder des théories et des techniques de conduite essentielles. Y a-t-il des domaines particuliers sur lesquels tu souhaites te concentrer ?"}/>
                    <ChatMessageItem date={'12:00'} content={"J'ai besoin d'aide pour comprendre les règles de priorité et quelques conseils pour une meilleure discipline de voie."}/>
                    <ChatMessageItem date={'12:00'} sender={'Ian Dooley'} content={"Merci, Jean. Pouvez-vous décrire le problème auquel vous êtes confronté plus en détail ? Recevez-vous des messages d’erreur spécifiques ?"}/>
                </main>
    
                <footer className='chatingBoxTyping'>
                    {
                        chatingFiles.length > 0 &&
                        <div className='chatingFilesUploaded'>
                            <FcDeleteDatabase className='removeUploadedFile'/>

                            {
                                chatingFiles.map(file =>
                                    <>
                                        <FcDeleteDatabase onClick={()=>removeUploadedFile(file)} className='realRemoveUploadedFile removeUploadedFile'/>
                                        <img src={file} alt={file} />
                                    </>
                                )
                            }
                        </div>
                    }
                    <textarea type="text" className='chatingBoxTypingInput' placeholder='Taper ici...'/>
                    <div className='chatingBoxTypingIcons'>
                        <div className="chatingBoxTypingIcon">
                            <img src={UploadFileIcon} alt="upload file" className='chatingBoxTypingIcon'/>
                            <input type="file" multiple accept="image/*" name="uploadingFile" id="uploadingFile" onChange={handleFileChange}/>
                        </div>
                        <div className='chatingBoxTypingIcon'>
                            <img src={SendIcon} alt="send messsage"/>
                        </div>
                    </div>
                </footer>
    
            </main>
        )
    }

    function ChatingSidebar(){
        const [switchTo, setSwitchTo] = useState('Medias')
        const [isModalOpen, setIsModalOpen] = useState({
            state: false,
            imageTitle: ''
        })

        const handleClose = () =>{
            setIsModalOpen(data => ({...data, ['state']: false}))
        }

        const showImageOnModal = (image) => {
            setIsModalOpen({
                state: true,
                imageTitle: image
            })
        }
        return(
            <aside className={`${!showSidebarChating ? 'chatingSidebarOverDisplay' : 'chatingSidebarNoneDisplay'} chatingSidebar`}>
                <div className="chatingSidebarOptions">
                    <div className="optionProfil">
                        <img src={Profil} alt="profil" className='optionProfilImg'/>
                        <span className="optionProfilStatus"></span>
                    </div>
                    <h5 className="chatingProfilName">Ian Dooley</h5>
                    <div className="chatingCallOptions">
                        <div className={`${rightMessageColor != '#F8D2A6' && 'callOptionStudentAndParent'} audioCallOption`}>
                            <img src={AudioCall} alt="audio call" className="audioCallOptionImg" />
                        </div>
                        <div className={`${rightMessageColor != '#F8D2A6' && 'callOptionStudentAndParent'} videoCallOption`}>
                            <img src={VideoCall} alt="video call" className="videoCallOptionImg" />
                        </div>
                    </div>
                </div>
    
                <div className="chatingSidebarMedia">
                    <div className="chatingSidebarMediaTitles">
                        <span  className={`chatingSidebarMediaTitle ${switchTo == 'Documents' && 'chatingSidebarMediaTitleActive'}`} onClick={()=>setSwitchTo('Documents')}>Documents</span>
                        <span className={`chatingSidebarMediaTitle ${switchTo == 'Medias' && 'chatingSidebarMediaTitleActive'}`} onClick={()=>setSwitchTo('Medias')}>Medias</span>
                        <span className={`chatingSidebarMediaTitle ${switchTo == 'Lien' && 'chatingSidebarMediaTitleActive'}`} onClick={()=>setSwitchTo('Lien')}>Lien</span>
                    </div>
    
                    {
                        switchTo == 'Documents' &&
                        <div className="chatingSidebarDocumentFiles">
                            <div className='chatingSidebarDocumentFile'>
                                <IoDocument/>
                                <span>Document 1</span>
                            </div>
                            <div className='chatingSidebarDocumentFile'>
                                <IoDocument/>
                                <span>Document 2</span>
                            </div>
                            <div className='chatingSidebarDocumentFile'>
                                <IoDocument/>
                                <span>Document 3</span>
                            </div>
                            <div className='chatingSidebarDocumentFile'>
                                <IoDocument/>
                                <span>Document 4</span>
                            </div>
                        </div>
                    }
                    {
                        switchTo == 'Medias' &&
                        <div className="chatingSidebarMediaFiles">
                            <img src={Media1} alt="media 1" onClick={()=>showImageOnModal('Media 1')} className='chatingSidebarMediaFile'/>
                            <img src={Media2} alt="media 2" onClick={()=>showImageOnModal('Media 2')} className='chatingSidebarMediaFile'/>
                            <img src={Media3} alt="media 3" onClick={()=>showImageOnModal('Media 3')} className='chatingSidebarMediaFile'/>
                            <img src={Media4} alt="media 4" onClick={()=>showImageOnModal('Media 4')} className='chatingSidebarMediaFile'/>
                        </div>
                    }
                    {
                        switchTo == 'Lien' &&
                        <div className="chatingSidebarLinks">
                            <a href={'#'} className='chatingSidebarLink'>
                                <FaLink/>
                                <span>Lien 1</span>
                            </a>
                            <a href={'#'} className='chatingSidebarLink'>
                                <FaLink/>
                                <span>Lien 2</span>
                            </a>
                            <a href={'#'} className='chatingSidebarLink'>
                                <FaLink/>
                                <span>Lien 3</span>
                            </a>
                            <a href={'#'} className='chatingSidebarLink'>
                                <FaLink/>
                                <span>Lien 4</span>
                            </a>
                        </div>
                    }
    
                </div>


                {
                    isModalOpen.state &&
                    <Modal show={isModalOpen.state} onHide={handleClose}>
                        <Modal.Header  closeButton>
                            <Modal.Title>{isModalOpen.imageTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{background: 'rgba(0,0,0,.065)'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {
                                isModalOpen.imageTitle == 'Media 1' && 
                                <img src={Media1} alt="" style={{maxWidth:'100%', maxHeight:'100%', objectFit: 'contain', minHeight: '300px'}} />

                            }
                            {
                                isModalOpen.imageTitle == 'Media 2' && 
                                <img src={Media2} alt="" style={{maxWidth:'100%', maxHeight:'100%', objectFit: 'contain', minHeight: '300px'}} />

                            }
                            {
                                isModalOpen.imageTitle == 'Media 3' && 
                                <img src={Media3} alt="" style={{maxWidth:'100%', maxHeight:'100%', objectFit: 'contain', minHeight: '300px'}} />

                            }
                            {
                                isModalOpen.imageTitle == 'Media 4' && 
                                <img src={Media4} alt="" style={{maxWidth:'100%', maxHeight:'100%', objectFit: 'contain', minHeight: '300px'}} />

                            }
                            </div>
                        </Modal.Body>
                        <Modal.Footer >
                            <Button onClick={handleClose} value={'Sauvegarder'}/>
                                
                        </Modal.Footer>
                    </Modal>
                }
            </aside>
        )
    }

    return(
        <div className={`${window.innerWidth <= 795 && 'chatingBoxFullWidth'} ${!isMobile && 'displayChatInterface'} chatingInterface`}>

            <ChatingBox />
            <ChatingSidebar/>
            
                    
        </div>
    )
}



