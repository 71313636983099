import React from 'react'
import './AdminSettingsLayout.css'
import { Link } from 'react-router-dom'

export default function AdminSettingsLayout({mainTitle, children}) {
  
    return (
      <div className='messageAdm adminSettingsLayout'>
        <AdminSettingsSidebar mainTitle = {mainTitle}/>
        <AdminSettingsMainContent mainTitle={mainTitle}>
            {children}
        </AdminSettingsMainContent>
            
      </div>
    )
  
}

export function AdminSettingsSidebar({mainTitle}){

    const SectionItem = ({titleSection, path}) =>{
        const matching = titleSection.toLowerCase() === mainTitle.toLowerCase() 
        return (
            
                <Link to={path} className={`${matching ? 'section-item-title-active': ''} section-item-title` }>
                    {titleSection}
                    
                </Link>
        )
    }

    return(
        <aside className='adminSettingsSidebar'>
            <SectionItem titleSection={'Détails Générals'} path={'/administrateur/parametres/details-generals'}/>
            {/* <SectionItem titleSection={'Notifications'} path={'/administrateur/parametres/notifications'}/> */}
            <SectionItem titleSection={'Modèles d\'email'} path={'/administrateur/parametres/modeles-email'}/>
            <SectionItem titleSection={'Réinitialisation de Mot de passe'} path={'/administrateur/parametres/reinitialisation-mot-de-passe'}/>
            {/* <SectionItem titleSection={'Mis à jour App'} path={'/administrateur/parametres/mis-a-jour-app'}/> */}
        </aside>
    )
}

function AdminSettingsMainContent({mainTitle, children}){
    return(
        <main className='AdminSettingsMainContent'>
            <h1 className="settingsTitle">{mainTitle}</h1>
            <div className='settingsContent'>
                {children}
            </div>
        </main>
    )
}
