import './MessageSidebar.css'
import DooleyProfil from '../../../images/AdminLayout/Ellipse 806.png'
import Search from '../../../images/Message/Search.png'
import { IconButton } from '../AudioCall/AudioCall'
import { IoIosCall } from 'react-icons/io'
import { useState } from 'react'
import { AiFillMessage } from 'react-icons/ai'
import { MdCallMade, MdCallReceived, MdOutlineVideocam } from 'react-icons/md'

export default function MessageSidebar({isMobile, setisMobile}){

    function MessageListItem({profil, sender, date, content, count, focus = false}){
        return(
            <div onClick={()=>setisMobile(false)} className={`messageListItem ${focus ? 'messageUnread' : ''}`}>
                <img src={profil} alt="profil" className='messageListItemProfil'/>
                <div className="messageItemInfos">
                    <div className="messageItemDateName">
                        <h6 className="messageItemName">
                            {sender}
                        </h6>
                        <p className="messageItemDate">
                            {date}
                        </p>
                    </div>
                    <div className="messageItemContentCount">
                        <p className="messageItemContent">
                            {content}
                        </p>
                        {
                            count && 
                            <h6 className="messageItemCount">
                                {count}
                            </h6>
                        }
                    </div>
                </div>
            </div>
        )
    }

    function CallListItem({profil, name, date, status, flux, nature}){
        return(
            <div className='callListItem'>
                <img src={profil} alt="profil" className='callListItemProfil'/>
                <div className="callItemInfos">
                    <div className="callItemDateName">
                        <h6 className="callItemName" style={{color: status === 'missed' ? 'red' : 'black'}}>
                            {name}
                        </h6>
                        <p className="callItemDate">
                            <span style={{color: status === 'missed' ? 'red' : 'green'}}>
                                {
                                    flux === 'entrant' && <MdCallMade/>
                                }
                                {
                                    flux === 'sortant' && <MdCallReceived/>
                                }
                            </span>
                            <span style={{color: status === 'missed' ? 'red' : 'black'}}>{date}</span>
                        </p>
                    </div>
                    <div className="callItemNature">
                        {
                            nature === 'video' ?
                            <MdOutlineVideocam />
                            :
                            <IoIosCall/>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const [showMessageList, setShowMessageList] = useState(true)

    return(
        <aside className={`${isMobile && 'displayMessageSidebar'} messageSidebar`}>
            <div className='messageSidebarSearch'>
                <img src={Search} alt="search" className='searchIcon'/>
                <input type="text" placeholder="Rechercher..." className='inputMessageSidebar'/>
                {
                    showMessageList ? 
                    <IconButton icon={<IoIosCall/>} label={'Historique des appels'} onClick={()=>setShowMessageList(false)} style={{background: 'linear-gradient(to right, #025B97, #03AACB)'}}/>
                    :
                    <IconButton icon={<AiFillMessage/>} label={'Historique des messages'} onClick={()=>setShowMessageList(true)} style={{background: 'linear-gradient(to right, #025B97, #03AACB)'}}/>

                }
            </div>
            {
                showMessageList ?
                <div className="messageListItems">
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'2 minutes'} count={2} />
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'7 minutes'} count={2} focus={true}/>
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'10 minutes'} count={2}/>
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'15 minutes'} />
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'28 minutes'}/>
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'2 heures'}/>
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'15 minutes'} />
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'28 minutes'}/>
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'2 heures'}/>
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'15 minutes'} />
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'28 minutes'}/>
                    <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'2 heures'}/>
                </div>
                :
                <div className='callListItems'>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'missed'} flux={'entrant'} nature={'video'} date={'Hier, 22:07'}/>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'unmissed'} flux={'sortant'} nature={'vocal'} date={'13 novembre, 05:04'}/>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'missed'} flux={'sortant'} nature={'video'} date={'12 novembre, 22:07'}/>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'unmissed'} flux={'entrant'} nature={'vocal'} date={'04 Juin, 12:57'}/>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'missed'} flux={'entrant'} nature={'vocal'} date={'29 Mai, 22:42'}/>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'unmissed'} flux={'sortant'} nature={'video'} date={'19 Mars, 17:00'}/>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'missed'} flux={'sortant'} nature={'vocal'} date={'05 Fevrier, 14:07'}/>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'unmissed'} flux={'entrant'} nature={'vocal'} date={'02 Fevrier, 12:36'}/>
                    <CallListItem profil={DooleyProfil} name={'Ian Dooley'} status={'missed'} flux={'entrant'} nature={'video'} date={'31 Janvier 2023, 22:07'}/>
                </div>
            }
        </aside>
    )
}

