import React from 'react';
import './help.css';

import { useNavigate } from 'react-router-dom';
import iconMsg from '../../images/icon-msg.png';
import iconConv from '../../images/icon-conv.png';
import { Container } from 'react-bootstrap';

const ActiveConversation = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/etudiant/support-technique/chat');
    };
    return (
        <Container className='animParent' >
            <div className="conversation-container container mt-4">
            <h2 className='title-conv text-center'>Conversation Active</h2>
            <div className="card card-conv mb-4">
                <div className='card-head'>
                    <p>TC #7464865</p>
                </div>
                <div className='card-body d-flex justify-content-between align-items-center'>
                    <p>
                        <img src={iconConv} alt="Conversation" id='iconConv' /> Votre conversation avec l'équipe de support
                    </p>
                    <button className="btn btn-light">
                        <img src={iconMsg} alt="Actif" id='iconMsg' />
                        Actif
                    </button>
                </div>
            </div>
            <h2 className='title-conv text-center'>Choisir les raisons</h2>
            <div className='container-select'>
                <select className='select-conv form-control mb-4'>
                    <option value="payment_plan">Comment puis-je payer pour le plan ?</option>
                    <option value="payment_method">Quels modes de paiement sont acceptés ?</option>
                    <option value="billing_issue">J'ai un problème de facturation.</option>
                </select>

                <select className='select-conv form-control mb-4'>
                    <option value="add_card">Comment puis-je ajouter une carte pour le paiement ?</option>
                    <option value="update_card">Comment puis-je mettre à jour mes informations de carte ?</option>
                    <option value="remove_card">Comment puis-je supprimer ma carte ?</option>
                </select>

                <select className='select-conv form-control mb-4'>
                    <option value="payment_query">Questions relatives aux paiements et à la facturation</option>
                    <option value="refund_query">Je souhaite demander un remboursement.</option>
                    <option value="invoice_request">J'ai besoin d'une copie de ma facture.</option>
                </select>

                <select className='select-conv form-control mb-4'>
                    <option value="coupon_query">J'ai une question concernant un coupon pour cette commande.</option>
                    <option value="invalid_coupon">Mon code de coupon est invalide.</option>
                    <option value="expired_coupon">Mon code de coupon a expiré.</option>
                </select>
            </div>
            <div className='div-footer text-center'>
                <h2 className='title-conv'>Chat direct avec notre support</h2>
                <button onClick={handleRedirect}>Discutez avec nous</button>
            </div>
        </div>
        </Container>
    );
}

export default ActiveConversation;