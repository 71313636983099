import React, { useRef } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "../Dash.css";

// Register required components including ChartDataLabels
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Chart5 = () => {
  const chartRef = useRef(null);

  // Data definition
  const data = [
    {
      label: 'Label 1',
      value: 80,
      cutout: '50%',
    },
    {
      label: 'Label 3',
      value: 40,
      color: '#DEDEDE',
      cutout: '50%',
    },
  ];

  // Chart options
  const options = {
    plugins: {
      // Configuration for datalabels plugin
      datalabels: {
        formatter: function (value) {
          // Format numbers using Turkish locale
          let val = Math.round(value);
          return new Intl.NumberFormat('tr-TR').format(val);
        },
        color: 'white', // Label color
        font: {
          weight: 'bold',
          size: 14,
          family: 'Poppins', // Font family
        },
      },
      responsive: true,
    },
    cutout: data[0].cutout, // Set the cutout value
  };

  // Final data for the chart, creating a gradient for 'Label 1'
  const finalData = {
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: function(context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          // Check if chartArea is defined (to avoid errors during initialization)
          if (!chartArea) {
            return;
          }

          // Creating a linear gradient for 'Label 1'
          const gradient1 = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient1.addColorStop(0, '#015192'); // Start color
          gradient1.addColorStop(1, '#00A5BC'); // End color

          // Colors for the chart sections
          const colors = [
            gradient1, // Gradient for 'Label 1'
            '#DEDEDE', // Color for 'Label 3'
          ];

          return colors;
        },
        borderColor: data.map((item) => item.color || '#C43CAB'), // Keep the border consistent
        borderWidth: 1,
      },
    ],
  };

  // Render Doughnut chart with the prepared data and options
  return (
    <div className='chartdiv5'>
        <div className="chart-container">
            <Doughnut ref={chartRef} data={finalData} options={options} height={150} width={150} />
        </div>
    </div>
  );
};

export default Chart5;
