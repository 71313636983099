import './Parrainage.css'
import ParrainageImg from '../../images/Parent/3508272-ai 1.png'
import RightArrowIcon from '../../images/Parent/Frame 1000005064.png'
import ParrainageHelperHeaderImg from '../../images/Parent/Group 9.png'
import ParrainageHelperFooterImg from '../../images/Parent/Group 12.png'
import ParrainageVideo from '../../images/Parent/Rectangle 29.png'
import VideoPlayIcon from '../../images/Parent/mingcute_play-fill.png'
import ParrainageVideoImg from '../../images/Parent/Group (1).png'
import Faq from "../Faq";
import { useLocation, useNavigate } from 'react-router'


export default function Parrainage({faqData}){

    const ParrainageHelperItem = ({number,title}) => {
        return(
            <div className="parrainageHelperItem">
                <span className="parrainageHelperItemNumber">{number}</span>
                <span className="parrainageHelperItemTitle">{title}</span>
            </div>
        )
    }

    const location = useLocation()
    const navigate = useNavigate()

    const redirectTo = () =>{
        const url = location.pathname.includes('etudiant') ? '/etudiant/parrainage/parrainer' : '/parent/parrainage/parrainer'
        navigate(url)
    }
     
    return(

        <main className="parentParrainage animParent">

        <section className="parrainageHeader">
            <div className="parrainageHeaderDetails">
                <div>
                    <h3 className="parrainageHeaderTitle">Je recommande UFS.</h3>
                    <h3 className="parrainageHeaderTitle">Je gagne, mes proches aussi.</h3>
                </div>
                <p className="parrainageHeaderContent">Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae facere saepe obcaecati quas quis provident harum placeat ratione. Autem assumenda eum excepturi blanditiis cupiditate suscipit exercitationem quae enim fugit accusamus?</p>
                <div onClick={redirectTo} className="parrainageHeaderButton">
                    <span >Je Parraine</span>
                    <img src={RightArrowIcon} alt="parrainer" className="parrainageHeaderButtonIcon"/>

                </div>
            </div>
            <div className="parrainageHeaderImgCard">
                <img src={ParrainageImg} alt="Parrainage" className="parrainageHeaderImg"/>
            </div>
        </section>


        <section className="ParrainageHelper">
            <div className="ParrainageHelperHeader">
                <h3 className="ParrainageHelperHeaderTitle">Comment ça fonctionne ?</h3>
                <img src={ParrainageHelperHeaderImg} alt="" className="ParrainageHelperHeaderImg"/>
            </div>
            <div className="ParrainageHelperItems">
                <ParrainageHelperItem title={'Je fournis les coordonnées de chaque étudiant en remplissant le formulaire ci-dessous'} number={'01'}/>
                <ParrainageHelperItem title={"L'étudiant reçoit un lien de création de compte par email/WhatsApp et valide son invitation"} number={'02'}/>
                <ParrainageHelperItem title={"L'étudiant crée son compte et souscrit à l'un de nos services (AVI, compte de paiement)"} number={'03'}/>
                <ParrainageHelperItem title={'Je reçois ma prime de parrainage'} number={'04'}/>
            </div>
            <img src={ParrainageHelperFooterImg} alt="" className="parrainageHelperFooterImg"/>
        </section>

        <section className="parrainageVideoCard">
            <img src={ParrainageVideo} alt="parrainage video" className="parrainageVideoImg"/>
            <div className="parrainageVideoCardMain">
                <div className="parrainageVideoPlayCard">
                    <img src={VideoPlayIcon} alt="replay" className="parrainageVideoPlayIcon"/>
                </div>
            </div>
            
        </section>

        <div className="parrainageVideoCardFooter">
            <img src={ParrainageVideoImg} alt="" className="parrainageVideoCardFooterImg"/>
        </div>

        <section className="parentParrainageFAQ">
            
            <h4>Questions fréquemment posées</h4>

            <Faq data={faqData}/>
        </section>

        </main>
    )
}
    

{/* <Faq
                    question={'Comment je télécharge l’application Universe France success ?'}
                    response={"Je peux télécharger l’application Universe France success sur mon téléphone depuis l’App Store et Google Play Store. Si mon téléphone n'a pas accès à l'une de ces plateformes, je ne peux pas installer l’application."}
                    setActive={setActive}
                    active={active}
                />

                <Faq 
                    question={'Comment créer mon espace sur l’application Universe France success ?'} 
                    setActive={setActive}
                    active={active}
                />
                <Faq 
                    question={'Devrai-je créer un compte sur l’application Universe France success si j’ai déjà ouvert auparavant un espace sur espace.Universe France success.com ?'} 
                    setActive={setActive}
                    active={active}
                />
                <Faq 
                    question={'L’inscription en ligne est-elle payante ?'} 
                    setActive={setActive}
                    active={active}
                /> */}