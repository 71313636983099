import React, { useState } from 'react'
import AdminSettingsLayout from '../AdminSettingsLayout'

import ProfilPicture from '../../../images/AdminLayout/Ellipse 806.png'
import Camera from '../../../images/AdminLayout/mdi_camera.png'
import './GeneralDetails.css'
import InputLabel from '../../../Components/InputLabel'
import SelectLabel from '../../../Components/SelectLabel'
import Button from '../../../Components/Button'
import { Col,Row, Container } from 'react-bootstrap'
import CustomCountrySelect from '../../../StudentModule/Layouts/CountrySelect'

export default function GeneralDetails() {

    const [data, setData] = useState({
        firstname : '',
        lastname : '',
        email : '',
        codeCountry: '',
        phone : '',
        phoneIndicator : '',
        role : '',
    })

    const updateFieldValue = e =>{
        setData(data =>(
            {
                ...data,
                [e.target.name] : e.target.value
            }
        ))
    }
    const updateCodeCountry = (code) => {
        setData(data => (
            {
                ...data,
                ['codeCountry']: code
            }
        ))
    }
  
    return (
            
                    
                   <AdminSettingsLayout mainTitle={'Détails Générals'}>
                
                    <form className="generalDetails">
                        <h5 className='PhotoTillePar'>Photo de profil</h5>
                        <div className="generalDetailsPicture">
                            <img src={ProfilPicture} alt='Profil' className='generalDetailsImg' />
                            <div className="cameraIcon">
                                <img src={Camera} alt='Camera' className='cameraIconImg' />
                            </div>
                        </div>
                        <div className="generalDetailsInfos">
                            <InputLabel label={'Prénom'} name={'firstname'} value={data.firstname} onChange = {e => updateFieldValue(e)} placeholder={'Entrez votre prénom'}/>
                            <InputLabel label={'Nom'} name={'lastname'} value={data.lastname} onChange = {e => updateFieldValue(e)} placeholder={'Entrez votre nom'}/>
                            <InputLabel label={'Email'} name={'email'} value={data.email} onChange = {e => updateFieldValue(e)} placeholder={'Entrez votre adresse email'}/>
                            <div className="composedInput">
                                <div className='inputLabel'>
                                    <label htmlFor="codeCountry">Numero de Telephone</label>
                                    <CustomCountrySelect name = 'codeCountry' codeCountry = {updateCodeCountry}/>
                                </div>
                                <InputLabel label={'Numéro de téléphone'} name={'phone'} placeholder = {'XX-XXX-XXX'} value ={data.phone} onChange = {(e)=> updateFieldValue(e)}/>
                                {/* <SelectLabel label={'Numéro de téléphone'} options={['+221','+212','+234']} onChange ={e=>updateField(e)}/> */}
                            </div>
                            {/* <SelectLabel label={'Role'} name={'role'} value={data.role} onChange = {e => updateFieldValue(e)} options={['admin', 'professeur', 'etudiant']}/> */}
                        </div>
                        <Button value={'Sauvegarder'}/>
                    </form>
                    </AdminSettingsLayout>
                
                
            
    )
  
}





