import React from 'react'
import Chart from 'react-apexcharts';
import Layout from './Layout';
import './TransactionStyles.css'
import { useState } from 'react';
import dayjs from 'dayjs';
import { FiDownloadCloud } from "react-icons/fi";
import { FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from "react-icons/fa";
import LargeViewIcon  from '../../images/Group 1000005331.png'
import icon  from '../../images/Group 13315.png'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { BsCalendarDateFill } from "react-icons/bs";
import { IoIosArrowDown } from 'react-icons/io';
import { useEffect } from 'react';
import Button from '../../Components/Button';



export default function TransactionDashboard() {

      const [timeRange, setTimeRange] = useState('week');
      const [currentIndex, setCurrentIndex] = useState(0);
      const yData = {
        day: [10, 20, 30, 40, 50, 60, 70, 80, 90],
        week: [10, 20, 30, 40, 50, 60, 70, 80, 90],
        month: [10, 20, 30, 40, 50, 60, 70, 80, 90],
        year: [10, 20, 30, 40, 50, 60, 70, 80, 90],
      };
    
      const generateChartData = (range, index) => {
        let categories = [];
        let data = yData[range];
    
        if (range === 'day') {
          const date = dayjs().add(index, 'day');
          categories = [date.format('DD MMM')];
          data = [Math.random() * 2 + 1.5]; // Prix aléatoire pour l'exemple
        } else if (range === 'week') {
          categories = [];
          data = [];
          for (let i = 0; i < 7; i++) {
            const date = dayjs().startOf('week').add(index, 'week').add(i, 'day');
            categories.push(date.format('DD MMM'));
            data.push(Math.random() * 2 + 1.5);
          }
        } else if (range === 'month') {
          const startOfMonth = dayjs().startOf('month').add(index, 'month');
          const daysInMonth = startOfMonth.daysInMonth();
          for (let i = 1; i <= daysInMonth; i++) {
            categories.push(startOfMonth.date(i).format('DD MMM'));
            data.push(Math.random() * 2 + 1.5);
          }
        } else if (range === 'year') {
          categories = [];
          data = [];
          for (let i = 0; i < 12; i++) {
            const date = dayjs().startOf('year').add(index, 'year').add(i, 'month');
            categories.push(date.format('MMM'));
            data.push(Math.random() * 2 + 1.5);
          }
        }
    
        return { categories, data };
      };
    
      const { categories, data } = generateChartData(timeRange, currentIndex);
    
      const options = {
        chart: {
            type: 'area',
            toolbar: { show: false },
            // background: '#E827EC', // Fond du graphique (pour la bordure)
            // dropShadow: {
            //   enabled: true,
            //   blur: 5,
            //   left: 0,
            //   top: 0,
            //   opacity: 0.1,
            // },
          },
          colors: ['#E827EC', '#F99685', '#FA9131'],
          dataLabels: { enabled: false },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light', // Choisir la lumière pour le dégradé
              type: 'vertical', // Dégradé vertical
              gradientToColors: [ '#F99685', '#FA9131'], // Couleur de fin du dégradé
              stops: [0, 100],
              opacityFrom: 0.6,
              opacityTo: 0.1,
            },
          },
          stroke: {
            curve: 'smooth', // Courbe lissée
            width: 1, // Épaisseur de la bordure
            colors: ['#025B97'], // Couleur de la bordure
          },
        xaxis: {
          categories: categories,
          labels: { style: { colors: '#888', fontSize: '16px' } },
        },
        yaxis: {
          labels: {
            style: { colors: '#888', fontSize: '16px' },
            formatter: (val) => `€ ${val.toFixed(0)} L`, // Format des labels
          },
          opposite: true,
        },
        grid: { show: false },
        tooltip: {
          theme: 'light',
          x: { show: true },
          y: {
            formatter: (val) => `$${val.toFixed(2)}L`, // Format du tooltip
          },
        },
      };
    
      const series = [
        {
          name: 'Prix',
          data: data,
        },
      ];

      const ReportItem = ({title, amount}) => {
        return(
            <div className="reportItem">
                <div>
                    <span>{title}</span>
                    <img src={LargeViewIcon} alt="" />

                </div>
                <h1>€ {amount}</h1>
            </div>
        )
      }

      //download chart

      const downloadChart = (documentID) => {
        const invoice = document.getElementById(documentID);
        html2canvas(invoice, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          const margin = 10; // Définir la marge à 10 mm
          // Calculer la largeur et la hauteur de l'image avec les marges
          const imgWidth = pageWidth - margin * 2; // Ajuster pour laisser une marge de chaque côté
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          // Position avec la marge en haut
          const xPos = margin;
          const yPos = margin;
          pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
          pdf.save(`${documentID}.pdf`);
        });
        
      };

      const [date, setDate] = useState({
        from: '12 Janvier 2024',
        to: '13 Janvier 2024'
      })
    //   const [dateFormat, setDateFormat] = useState({
    //     from: '',
    //     to: ''
    //   })

      const changeDate = e =>{
        const format = e.target.value.split('-')

        setDate(date=>({
            ...date,
            [e.target.name] : `${format[2]} ${moisFrancais[format[1]]} ${format[0]}`
        }))

      }

      const moisFrancais = {
        1: "Janvier",
        2: "Février",
        3: "Mars",
        4: "Avril",
        5: "Mai",
        6: "Juin",
        7: "Juillet",
        8: "Août",
        9: "Septembre",
        10: "Octobre",
        11: "Novembre",
        12: "Décembre"
      };
      
    
      return (
        <Layout>

            <div id='dashboard'>

                <header className='dashboardTransactionHeader'>
                    <div className='dashbordTransactionDate'>
                        <span>De</span>
                        <div>
                            <BsCalendarDateFill/>
                            <span>{date.from}</span>
                            <input onChange={changeDate} type="date" name="from" id="" />
                        </div>
                    </div>
                    <div className='dashbordTransactionDate'>
                        <span>A</span>
                        <div>
                            <BsCalendarDateFill/>
                            <span>{date.to}</span>
                            <input onChange={changeDate} type="date" name="to" id="" />
                        </div>
                    </div>
                    <Button 
                        value={
                            <>
                                <span>Exporter</span>
                                <IoIosArrowDown />
                            </>
                        }

                        onClick={()=>downloadChart('dashboard')}
                        
                    />
                </header>

                <main className='dashboardTransaction'>

                    <div id='chart' style={{flex: '1', padding: '20px', background: '#fff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div className='chartHeader'>
                            <div className="charHeaderButton">
                                <button className={`${timeRange == 'day' && 'activeButton'}`} onClick={() => { setTimeRange('day'); setCurrentIndex(0); }}>Jour</button>
                                <button className={`${timeRange == 'week' && 'activeButton'}`} onClick={() => { setTimeRange('week'); setCurrentIndex(0); }}>Semaine</button>
                                <button className={`${timeRange == 'month' && 'activeButton'}`} onClick={() => { setTimeRange('month'); setCurrentIndex(0); }}>Mois</button>
                                <button className={`${timeRange == 'year' && 'activeButton'}`} onClick={() => { setTimeRange('year'); setCurrentIndex(0); }}>Année</button>
                            </div>
                            <div onClick={()=>downloadChart('chart')} className="chartHeaderDownloadChart">
                                <span>Télécharger graphique</span>
                                <FiDownloadCloud/>
                            </div>
                        </div>
                        <div className='chartHeaderShiftButton'>
                        <FaRegArrowAltCircleLeft className= 'icon'  onClick={() => setCurrentIndex(currentIndex - 1)}/>
                            <FaRegArrowAltCircleRight className= 'icon' onClick={() => setCurrentIndex(currentIndex + 1)}/>

                            {/* <span>{timeRange === 'day' ? 'Aujourd\'hui' : timeRange === 'week' ? `Semaine en cours` : timeRange === 'month' ? `Mois en cours` : currentIndex}</span> */}
                        </div>
                        
                        <Chart options={options} series={series} type="area" height={320} />
                    </div>

                    <aside className='dashboardAside'>
                        <div className='dashboardAsideHeader'>
                            <span>Rapport de Collection</span>
                            <img src={icon} alt="" />
                        </div>
                        <div className="reportItems">
                            <ReportItem title={'Montant Total Attendu'} amount={5.20}/>
                            <hr />
                            <ReportItem title={'Montant Total Collecté'} amount={2.20}/>
                            <hr />
                            <ReportItem title={'Montant Total Restant'} amount={2}/>
                            <hr />
                        </div>

                        <div className='dashboardAsideFooter'>
                            <h5>Performance de Croissance</h5>
                            <h3>+ 8.9%</h3>
                            <h6>Variation des revenus</h6>
                        </div>
                    </aside>
                </main>
            </div>

        </Layout>
      );
    
    
}
