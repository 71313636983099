import { Container } from "react-bootstrap";
import PaymentHistoric from "../../Components/PaymentHistoric/PaymentHistoric";


export default function StudentPaymentHistoric(){

    

    return(
        
            <PaymentHistoric/>
    )
}

