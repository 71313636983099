
import AdminSettingsLayout from '../AdminSettingsLayout';
import './EmailTemplate.css'
import DownVector from '../../../images/AdminLayout/Sidebar/Vector.png'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AccountLogin } from './Account/AccountLogin';


export default function EmailTemplate(){

  const [optionActive, setOptionActive] = useState('Compte')
  const [optionSectionActive, setOptionSectionActive] = useState('Connexion')

  
    return(
      // <Container>
      //   <Row>
      //     <Col>
          
      
        <AdminSettingsLayout mainTitle={'Modèles d\'email'}>
            <div className='emailTemplate'>
                <EmailTemplateSidebar
                  optionActive={optionActive}
                  setOptionActive={setOptionActive}
                  optionSectionActive={optionSectionActive}
                  setOptionSectionActive={setOptionSectionActive}
                />
                <EmailTemplateMain/>
            </div>
        </AdminSettingsLayout>
      
      //   </Col>
      //   </Row>
      // </Container>
    )
}
  
function EmailTemplateSidebar({optionActive, setOptionActive, optionSectionActive, setOptionSectionActive}){

  
  // each section of the email template sidebar component
  const EmailTemplateSidebarSection = ({sectionTitle, children}) => {

    const showOptions = sectionTitle => {
      optionActive === '' && children ? setOptionActive(sectionTitle) : setOptionActive('')
    }

    const matching = optionActive.toLowerCase() === sectionTitle.toLowerCase()

    return(
      <section className={`emailTemplateSidebarSection ${matching ? 'emailTemplateSidebarSectionActive' : ''}`}>
        <div className={`section`}>
          <h1 className='sectionTitle'>{sectionTitle}</h1>
          <img onClick={() => showOptions(sectionTitle) } src={DownVector} alt="vector" className='sectionVectorIcon'/>
        </div>
        {
          matching &&
          <div className="options">
            {children}
          </div>
        }
      </section>
    )
  }

  // each option of section component
  const EmailTemplateSidebarOption = ({optionTitle, path}) => {

    const activateOption = optionTitle => {
      setOptionSectionActive(optionTitle)
    }

    const matching = optionSectionActive.toLowerCase() === optionTitle.toLowerCase()


    return(
      <Link onClick={()=>activateOption(optionTitle)} to={path} className={`optionTitle ${matching && 'optionTitleActive'}`}>
        {optionTitle}
      </Link>
    )
  }

  const defaultPath = '/administrateur/parametres/email-templates'

  return (
    <aside className="emailtemplateSidebar">
      <EmailTemplateSidebarSection sectionTitle={'Compte'}>
        <EmailTemplateSidebarOption optionTitle={'Connexion'} path={`${defaultPath}/account/login`}/>
        <EmailTemplateSidebarOption optionTitle={'Reinitialiation mot de passe'} path={`${defaultPath}/account/reset-password`}/>
        <EmailTemplateSidebarOption optionTitle={'Invitation membre du groupe'} path={`${defaultPath}/account/team-member-invitation`}/>
        <EmailTemplateSidebarOption optionTitle={'Accueil nouveau client'} path={`${defaultPath}/account/new-client-greetings`}/>
      </EmailTemplateSidebarSection>

      <EmailTemplateSidebarSection sectionTitle={'Annonce'}/>
      <EmailTemplateSidebarSection sectionTitle={'Contrat'}/>
      <EmailTemplateSidebarSection sectionTitle={'Facture'}/>
      <EmailTemplateSidebarSection sectionTitle={'Message'}/>
      <EmailTemplateSidebarSection sectionTitle={'Commande'}/>
      <EmailTemplateSidebarSection sectionTitle={'Ticket'}/>
    </aside>
  )
}
  
function EmailTemplateMain(){
    return(
      <AccountLogin/>
    )
}
  


