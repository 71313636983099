import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { MdDownload } from 'react-icons/md';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { pdfjs } from 'react-pdf';
import './FileUpload.css';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';
const FileUpload = ({
  svgIcon,
  acceptedTypes,
  setFile,
  isInvalid,
  titre,
  existingFile,
  setExistingFile
}) => {
  const pdfWorker = 'https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js';
  pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(''); // Nouvel fichier sélectionné
  const [fileType, setFileType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal2,setShowModal2]=useState(false);
  const [uniqueId, setUniqueId] = useState('');
  const [label, setLabel] = useState("Télécharger");


  // Générer un ID unique au montage du composant
  useEffect(() => {
    const id = uuidv4();
    setUniqueId(id);
  }, []);

  

  // Gérer la sélection d'un fichier
  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setFileUrl(URL.createObjectURL(newFile));
      setFileType(newFile.type);
      setSelectedFile(newFile);
      
      if (typeof setLabel === "function") {
        setLabel(newFile.name);
      } else {
        console.error("setLabel is not a function");
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setFileUrl('');
  setFileType('');
  setSelectedFile(null);
 
  setLabel('Télécharger');
    
  };
  const handleClose2 = () => {
    setShowModal2(false);
    
  };
  const Annuler = () => {
    setShowModal2(false);
    
  };

  const confirmer = () => {
    setShowModal2(false);
      setFileUrl('');
  setFileType('');
  setSelectedFile(null);
  setLabel('Télécharger');
  setExistingFile(null)
    
  };

  const handleConfirm = () => {
    if (selectedFile) {
    setExistingFile(selectedFile) 
      setLabel(selectedFile.name);
      setShowModal(false);
    } 
    
  };

  const handleReplaceFile = () => {
    document.getElementById(`file-upload-${uniqueId}`).click();
  };

  function handleLabelClick2(){
    document.getElementById(`file-upload-${uniqueId}`).click();
  }

  function handleDeleteFile(){
setShowModal2(true)
  
  }
  const renderPreview = () => {
    if (fileUrl) {
      const detectedType = fileType.startsWith('image/') ? 'image' : fileType === 'application/pdf' ? 'pdf' : 'unsupported';

      if (detectedType === 'image') {
        return <div className='file-preview-container'> 
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="delete-icon"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            onClick={handleDeleteFile} 
            style={{ cursor: "pointer" }}
          >
            <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
          </svg> 
          <div className="image-preview-container">
          
           <img src={fileUrl} alt="preview" className="image-preview" />
           </div>
           </div>;
      }
      if (detectedType === 'pdf') {
        return (
          <div className='file-preview-container'> 
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="delete-icon"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            onClick={handleDeleteFile} 
            style={{ cursor: "pointer" }}
          >
            <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
          </svg> 
          <div className="pdf-preview-container"> 
          
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={fileUrl} />
          </Worker>
          </div>
          </div>
        );
      }
      return <p>Format non supporté</p>;
    }

    if (existingFile) {
      const detectedType = existingFile.endsWith('.pdf') ? 'pdf' : 'image';

      if (detectedType === 'pdf') {
        return (
          <div className='file-preview-container'> 
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="delete-icon"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            onClick={handleDeleteFile} 
            style={{ cursor: "pointer" }}
          >
            <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
          </svg> 
         
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={existingFile} />
          </Worker>
         
      </div>
        );
        
      }
      return <div className='file-preview-container'> 
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="delete-icon"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        onClick={handleDeleteFile} 
        style={{ cursor: "pointer" }}
      >
        <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
      </svg> 
      <div className="image-preview-container"><img src={existingFile} alt="existing preview" className="image-preview" />
      </div>
      </div>;
    }
   
    return <div className='d-flex justify-content-center align-items-center flex-column' style={{ cursor: 'pointer'}}  
    onClick={handleLabelClick2}>
     <div > {svgIcon} <span>{label}</span></div>
      <p className='attestation-save-p2'> Fichiers autorisés Fichiers JPEG, PNG, PDF </p>
    </div>
  };

  const handleLabelClick = () => {
    setShowModal(true);
    
  };

  return (
    <div>
      <label
        style={{ cursor: 'pointer' }}
        onClick={handleLabelClick}
      >
        {svgIcon} <span>{label}</span>
      </label>

      <Modal show={showModal} onHide={handleClose}  centered >
      <Modal.Header closeButton>
          <Modal.Title>Aperçu du fichier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderPreview()}
        </Modal.Body>

        <Modal.Footer>
        
          <Button onClick={handleConfirm}  className="btnTelecharger">
            Télécharger <MdDownload />
          </Button>
          <Button onClick={handleReplaceFile}  className="btnTelecharger" style={{marginLeft:"10px"}}>Remplacer le fichier</Button>
        </Modal.Footer>
      </Modal>

      <Form.Control
       id={`file-upload-${uniqueId}`}
        type="file"
        hidden
        accept={acceptedTypes}
        onChange={handleFileChange}
      />

<Modal show={showModal2} onHide={handleClose2}  centered >
      <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body  className="student-attestation-save-title">
        Êtes-vous sûr(e) de vouloir supprimer ce fichier ?
        </Modal.Body>

        <Modal.Footer>
        
          <Button onClick={confirmer}  className="btnTelecharger">
            Confirmer
          </Button>
          <Button onClick={Annuler}  className="btnAnnuler1" style={{marginLeft:"10px"}}>Annuler</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FileUpload;
