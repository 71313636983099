import React, { useState, useContext } from "react";
import "./RechercheLogement.css";
import { Form } from "react-bootstrap";
// import InputLabel from "../../../Components/InputLabel";
// import SelectLabel from "../../../Components/SelectLabel";
import FileToDownload from "../FileToDownload";
import nationalities from "../../Layouts/nationalities.json";
import CustomCountrySelect from "../../Layouts/CountrySelect";
import Button from "../../../Components/Button";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router";
import api from "../../api/axiosInstance";
import { success, error } from "../../utils/alerts";
import extractError from "../../utils/extractError";
import { useEffect } from "react";
const ErrorContext = React.createContext();

const RechercheLogement = () => {
  const navigate = useNavigate();
  const typeHebergement = [
    "Appartement",
    "Maison",
    "Studio",
    "Colocation",
    "Résidence étudiant",
    "Hôtel",
  ];

  const icon = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.5"
        width="39"
        height="39"
        rx="10"
        fill="url(#paint0_linear_991_5415)"
        fill-opacity="0.1"
      />
      <path
        d="M16.749 24.5V18.5M16.749 18.5L14.749 20.5M16.749 18.5L18.749 20.5"
        stroke="url(#paint1_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.75 17.5V22.5C29.75 27.5 27.75 29.5 22.75 29.5H16.75C11.75 29.5 9.75 27.5 9.75 22.5V16.5C9.75 11.5 11.75 9.5 16.75 9.5H21.75"
        stroke="url(#paint2_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.75 17.5H25.75C22.75 17.5 21.75 16.5 21.75 13.5V9.5L29.75 17.5Z"
        stroke="url(#paint3_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_991_5415"
          x1="-0.37396"
          y1="10.8019"
          x2="37.6153"
          y2="11.1255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_991_5415"
          x1="14.685"
          y1="20.0849"
          x2="18.5815"
          y2="20.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_991_5415"
          x1="9.43002"
          y1="14.783"
          x2="28.9117"
          y2="14.949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_991_5415"
          x1="21.622"
          y1="11.6132"
          x2="29.4147"
          y2="11.6796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
      </defs>
    </svg>
  );

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    codeCountry: "+33",
    phone: "",
    birthPlace: "",
    birthDate: "",
    email: "",
    nationnality: "",
    hebergementType: "",
    preferedPlace: "",
    movingDate: "",
    sejourDuration: "",
    funds: "",
    specialNeeds: "",
    sejourObject: "",
    workPlace: "",
    occupantCount: "",
    identityProof: null,
    admissionProof: null,
  });
  function renameFile(file, newName) {
    return new File([file], newName, { type: file.type });
  }
  function updateIdentityProof(value) {
    setData({ ...data, identityProof: value })
  }
  function updateAdmissionProof(value) {
    setData({ ...data, admissionProof: value })
  }
  const updateField = (e) => {
    const { name, value, files } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: files && files.length > 0 ? files[0] : value,
    }));

  };
  useEffect(() => {
    console.log(data)
  }, [data]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let currentErrors = {};
    console.log("Soumission du formulaire en cours...");

    const validNomPattern = /^[A-Za-z\- ]+$/;
    const validPrenomPattern = /^[A-Za-z\- ]+$/;
    const validVillePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\d{9,15}$/;
    const datePattern = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const countryPattern = /^[a-zA-ZÀ-ÿ_\s-]+$/;
    const sejourObjectPattern = /^.{1,64}$/;

    if (!data.lastname.trim()) {
      currentErrors.lastname =
        "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validNomPattern.test(data.lastname)) {
      currentErrors.lastname =
        "Le nom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.lastname;
    }

    if (!data.firstname.trim()) {
      currentErrors.firstname =
        "Veuillez entrer votre prénom (pas d'espaces vides)";
    } else if (!validPrenomPattern.test(data.firstname)) {
      currentErrors.firstname =
        "Le prénom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.firstname;
    }

    if (!data.birthPlace.trim()) {
      currentErrors.birthPlace =
        "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validVillePattern.test(data.birthPlace)) {
      currentErrors.birthPlace =
        "Le lieu de naissance ne doit contenir que des lettres, des espaces, des apostrophes ou des tirets.";
    } else {
      delete currentErrors.birthPlace;
    }

    if (!data.email.trim()) {
      currentErrors.email = "Veuillez entrer une adresse e-mail valide";
    } else if (!emailPattern.test(data.email)) {
      currentErrors.email = "Adresse e-mail invalide";
    } else {
      delete currentErrors.email;
    }

    if (!data.phone.trim()) {
      currentErrors.phoneNumber =
        "Veuillez entrer un numéro de téléphone valide";
    } else if (!phonePattern.test(data.phone)) {
      currentErrors.phone =
        "Le numéro de téléphone doit contenir uniquement des chiffres";
    } else {
      delete currentErrors.phone;
    }

    if (!data.nationnality.trim()) {
      currentErrors.nationnality = "Veuillez entrer un pays valide";
    } else if (!countryPattern.test(data.nationnality)) {
      currentErrors.nationnality = "Le nom du pays est invalide";
    } else {
      delete currentErrors.nationnality;
    }
    if (!data.birthDate.trim()) {
      currentErrors.birthDate = "Veuillez entrer une date valide";
    } else if (!datePattern.test(data.birthDate)) {
      currentErrors.birthDate = "La date est invalide";
    } else {
      delete currentErrors.birthDate;
    }

    if (!data.movingDate.trim()) {
      currentErrors.movingDate = "Veuillez entrer une date valide";
    } else if (!datePattern.test(data.movingDate)) {
      currentErrors.movingDate = "La date est invalide";
    } else {
      delete currentErrors.movingDate;
    }

    if (!data.hebergementType.trim()) {
      currentErrors.hebergementType =
        "Sélectionner un type d'hébergement valide";
    } else {
      delete currentErrors.hebergementType;
    }

    if (!data.preferedPlace.trim()) {
      currentErrors.preferedPlace = "Vous devez entrer un lieu de préférence";
    } else if (!validVillePattern.test(data.preferedPlace)) {
      currentErrors.preferedPlace = "Vous devez entrer un lieu valide";
    } else {
      delete currentErrors.preferedPlace;
    }

    if (!String(data.sejourDuration).trim()) {
      currentErrors.sejourDuration = "Vous devez entrer une durée";
    } else {
      const sejourDuration = Number(data.sejourDuration.toString().trim());

      if (isNaN(sejourDuration) || sejourDuration < 1 || sejourDuration > 64) {
        currentErrors.sejourDuration =
          "La durée du séjour doit être comprise entre 1 et 64 mois.";
      } else {
        delete currentErrors.sejourDuration;
      }
    }

    if (!data.funds || data.funds.toString().trim() === "") {
      currentErrors.funds = "Vous devez entrer votre budget";
    } else {
      const fundsValue = Number(data.funds.toString().trim());

      if (isNaN(fundsValue) || fundsValue < 1) {
        currentErrors.funds = "Votre budget doit être valide";
      } else {
        delete currentErrors.funds;
      }
    }

    if (!data.specialNeeds.trim()) {
      currentErrors.specialNeeds = "Entrer un besoin spécial";
    } else {
      delete currentErrors.specialNeeds;
    }

    if (!String(data.sejourObject.trim())) {
      currentErrors.sejourObject = "Vous devez entrer un objet de séjour";
    } else if (!sejourObjectPattern.test(data.sejourObject)) {
      currentErrors.sejourObject = "Votre objet est trop long";
    } else {
      delete currentErrors.sejourObject;
    }

    if (!data.workPlace.trim()) {
      currentErrors.workPlace = "Vous devez remplir ce champ";
    } else {
      delete currentErrors.workPlace;
    }

    if (!String(data.occupantCount).trim()) {
      currentErrors.occupantCount = "Vous devez entrer le nombre d'occupant";
    } else if (!data.occupantCount || data.occupantCount < 1) {
      currentErrors.occupantCount = "Vous devez entrer un nombre valide";
    } else {
      delete currentErrors.occupantCount;
    }



    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }

    try {
      setLoading(true);
   
      const formData = new FormData();
      formData.append("first_name", data.firstname);
      formData.append("last_name", data.lastname);
      formData.append("phone_number", data.phone);
      formData.append("place_of_birth", data.birthPlace);
      formData.append("date_of_birth", data.birthDate);
      formData.append("email", data.email);
      formData.append("nationality", data.nationnality || "");
      formData.append("country_code", data.codeCountry);
      formData.append("accommodation_type", data.hebergementType);
      formData.append("preferred_location", data.preferedPlace);
      formData.append("move_in_date", data.movingDate);
      formData.append("stay_duration", data.sejourDuration);
      formData.append("budget", data.funds);
      formData.append("special_needs", data.specialNeeds);
      formData.append("purpose_of_stay", data.sejourObject);
      formData.append("number_of_occupants", data.occupantCount);
      formData.append("university_or_workplace", data.workPlace);

      // preliminary_acceptance

      if (data.admissionProof && data.admissionProof.name) {
        const newName = data.admissionProof.name.slice(0, 95) + data.admissionProof.name.slice(-5);
        const renamedFile = renameFile(data.admissionProof, newName);
        formData.append("files.preliminary_acceptance", renamedFile);
      } else {
        console.error("Admission Proof File est invalide ou vide.");
      }

      // preliminary_acceptance

      if (data.identityProof && data.identityProof.name) {
        const newName = data.identityProof.name.slice(0, 95) + data.identityProof.name.slice(-5);
        const renamedFile = renameFile(data.identityProof, newName);
        formData.append("files.passport_copy", renamedFile);
      } else {
        console.error("Admission Proof File est invalide ou vide.");
      }

      // api create or update 
      const response = await api.post("/student/housing/", formData)

      if (response.status >= 200 && response.status < 300) {
        let msg = response.data?.data?.message || "Action réussie";
        success(msg);
        navigate("/etudiant/prestations-de-service");
      }
    } catch (err) {
      console.error("Une erreur est survenue:", err);
      if (err.response && err.response.data?.data) {
        const errorObject = err.response.data.data;
        const errorMessages = extractError(errorObject);
        errorMessages.forEach((message) => error(message));
      } else {
        error("Erreur inattendue lors de la soumission.");

        console.log(err.message)
      }
    }
  };
  //get les données

  useEffect(() => {
    const fetchLogement = async () => {
      try {
        const response = await api.get("/student/housing/");
        const logementData = response.data;


        setData({
          id: logementData.id || null,
          firstname: logementData.first_name || "",
          lastname: logementData.last_name || "",
          codeCountry: logementData.country_code || "+33",
          phone: logementData.phone_number || "",
          birthPlace: logementData.place_of_birth || "",
          birthDate: logementData.date_of_birth || "",
          email: logementData.email || "",
          nationnality: logementData.nationality || "",
          hebergementType: logementData.accommodation_type || "",
          preferedPlace: logementData.preferred_location || "",
          movingDate: logementData.move_in_date || "",
          sejourDuration: logementData.stay_duration || "",
          funds: logementData.budget || "",
          specialNeeds: logementData.special_needs || "",
          sejourObject: logementData.purpose_of_stay || "",
          workPlace: logementData.university_or_workplace || "",
          occupantCount: logementData.number_of_occupants || "",
          identityProof: logementData.files.passport_copy || null,
          admissionProof: logementData.files.preliminary_acceptance || null,
        });
      } catch (err) {
        console.error(
          "Erreur lors du chargement des données de logement:",
          err
        );
      }
    };

    fetchLogement();
  }, []);


  return (
    <main className="rechercheLogement animParent">
      <div className="backSectionButton">
        <Button
          value={
            <>
              <IoArrowBackCircle />
              <span>Retour</span>
            </>
          }
          onClick={() => navigate("/etudiant/prestations-de-service")}
        />
      </div>

      <ErrorContext.Provider value={errors}>
        <PersonnalInfos
          title={"Recherche de logement"}
          data={data}
          setData={setData}
          updateField={updateField}
        />
      </ErrorContext.Provider>

      <section className="preferenceHebergementSection">
        <h1 className="preferenceHebergementTitreSection">
          Préférences d'hébergement
        </h1>
        <div className="preferenceHebergementForm">
          <div className="inputLabel">
            <Form.Label>Type d'hébergement</Form.Label>
            <Form.Select
              name="hebergementType"
              placeholder={"Entrez le type d'hébergement"}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.hebergementType ? true : false}
            >
              <option value="">Sélectionner un type d'hébergement</option>
              {typeHebergement.map((type, index) => (
                <option
                  key={index}
                  value={type}
                  selected={
                    type.toLowerCase() === data.hebergementType.toLowerCase()
                  }
                >
                  {type}
                </option>
              ))}
            </Form.Select>

            <Form.Control.Feedback type="invalid">
              {errors?.hebergementType && errors.hebergementType}
            </Form.Control.Feedback>
          </div>

          <div className="inputLabel">
            <Form.Label>Lieu préféré</Form.Label>
            <Form.Control
              name="preferedPlace"
              placeholder={"Entrez votre lieu préféré"}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.preferedPlace ? true : false}
              value={data.preferedPlace}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.preferedPlace && errors.preferedPlace}
            </Form.Control.Feedback>
          </div>
          <div className="inputLabel">
            <Form.Label>Date de déménagement</Form.Label>
            <Form.Control
              name="movingDate"
              type="date"
              placeholder={""}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.movingDate ? true : false}
              value={data.movingDate}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.movingDate && errors.movingDate}
            </Form.Control.Feedback>
          </div>
          <div className="inputLabel">
            <Form.Label>Durée du séjour (mois)</Form.Label>
            <Form.Control
              name="sejourDuration"
              type="number"
              placeholder={"Entrez la durée du séjour"}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.sejourDuration ? true : false}
              value={data.sejourDuration}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.sejourDuration && errors.sejourDuration}
            </Form.Control.Feedback>
          </div>
          <div className="inputLabel">
            <Form.Label>Budget (€)</Form.Label>
            <Form.Control
              name="funds"
              type="number"
              placeholder={"Entrez votre budget"}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.funds ? true : false}
              value={data.funds}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.funds && errors.funds}
            </Form.Control.Feedback>
          </div>

          <div className="inputLabel">
            <label htmlFor="specialNeeds" className="inputLabelLabel">
              Besoins speciaux
            </label>
            <textarea
              name="specialNeeds"
              id="specialNeeds"
              className="inputLabelInput"
              onChange={(e) => updateField(e)}
              isInvalid={errors?.specialNeeds ? true : false}
              value={data.specialNeeds}
            ></textarea>
            <Form.Control.Feedback type="invalid">
              {errors?.specialNeeds && errors.specialNeeds}
            </Form.Control.Feedback>
          </div>
        </div>
      </section>

      <section className="informationComplementaireSection">
        <h1 className="student-attestation-title">
          Informations Complémentaires
        </h1>
        <div className="informationComplementaireForm">
          <div className="inputLabel">
            <Form.Label>Objet du séjour</Form.Label>
            <Form.Control
              name="sejourObject"
              placeholder={"Entrez l'objet du séjour"}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.sejourObject ? true : false}
              value={data.sejourObject}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.sejourObject && errors.sejourObject}
            </Form.Control.Feedback>
          </div>
          <div className="inputLabel">
            <Form.Label>Université ou Lieu de travail</Form.Label>
            <Form.Control
              name="workPlace"
              placeholder={"Hardward"}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.workPlace ? true : false}
              value={data.workPlace}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.workPlace && errors.workPlace}
            </Form.Control.Feedback>
          </div>
          <div className="inputLabel">
            <Form.Label>Nombre d'occupants</Form.Label>
            <Form.Control
              name="occupantCount"
              type={"number"}
              placeholder={"Entrez le nommbre d'occupant"}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.occupantCount ? true : false}
              value={data.occupantCount}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.occupantCount && errors.occupantCount}
            </Form.Control.Feedback>
          </div>
        </div>
      </section>

      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Preuve d'identité"}
            acceptanceText={"Passeport, ID Carte"}
            fichier={data.identityProof}
            setFichier={updateIdentityProof}
            name="identityProof"

            onChange={(e) => updateField(e)}
            isInvalid={errors?.identityProof ? true : false}
          />
          <FileToDownload
            titre={"Preuve d'admission / d'emploi"}
            acceptanceText={"Lettre d'acceptance, Contrat de travail"}
            fichier={data.admissionProof}
            setFichier={updateAdmissionProof}
            name="admissionProof"
            onChange={(e) => updateField(e)}
            isInvalid={errors?.admissionProof ? true : false}
          />
        </div>
      </section>

      <div className="submitSectionButton">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          value={
            <>
              <span>{data?.id ? "Modifier" : "Soumettre"}</span>
              <svg
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="profil-fleche"
              >
                <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
                <path
                  d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
                  stroke="#015192"
                  strokeWidth="1.375"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          }
        />
      </div>
    </main>
  );
};

export default RechercheLogement;

export function PersonnalInfos({ data, updateField, title, setData }) {
  const updateCodeCountry = (code) => {
    setData((data) => ({
      ...data,
      codeCountry: code,
    }));
  };
  const errors = useContext(ErrorContext);

  return (
    <section className="rechercheLogementSection ">
      <h1 className="student-attestation-title">{title}</h1>
      <div className="rechercheLogementForm">
        <div className="inputLabel">
          <Form.Label>Prénom</Form.Label>
          <Form.Control
            name="firstname"
            value={data.firstname}
            placeholder={"Entrez votre prénom"}
            onChange={(e) => updateField(e)}
            isInvalid={errors?.firstname ? true : false}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.firstname && errors.firstname}
          </Form.Control.Feedback>
        </div>
        <div className="inputLabel">
          <Form.Label>Nom de famille</Form.Label>
          <Form.Control
            name="lastname"
            value={data.lastname}
            placeholder={"Entrez votre nom de famille"}
            onChange={(e) => updateField(e)}
            isInvalid={errors?.lastname ? true : false}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.lastname && errors.lastname}
          </Form.Control.Feedback>
        </div>

        <div className="composedInput">
          <div className="inputLabel">
            <label htmlFor="codeCountry">Numéro de Téléphone</label>
            <div style={{ height: "45px" }}>
              <CustomCountrySelect
                name="codeCountry"
                codeCountry={updateCodeCountry}
                dialCode={data.codeCountry}
              />
            </div>
          </div>
          <div className="inputLabel">
            <Form.Label> </Form.Label>
            <Form.Control
              name="phone"
              placeholder={"XX-XXX-XXX"}
              value={data.phone}
              onChange={(e) => updateField(e)}
              isInvalid={errors?.phone ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.phone && errors.phone}
            </Form.Control.Feedback>
          </div>
          {/* <SelectLabel label={'Numéro de téléphone'} options={['+221','+212','+234']} onChange ={e=>updateField(e)}/> */}
        </div>
        <div className="inputLabel">
          <Form.Label>Lieu de naissance</Form.Label>
          <Form.Control
            name="birthPlace"
            placeholder={"Entrez le lieu de naissance"}
            value={data.birthPlace}
            onChange={(e) => updateField(e)}
            isInvalid={errors?.birthPlace ? true : false}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.birthPlace && errors.birthPlace}
          </Form.Control.Feedback>
        </div>
        <div className="inputLabel">
          <Form.Label>Date de naissance</Form.Label>
          <Form.Control
            name="birthDate"
            type="date"
            placeholder={""}
            value={data.birthDate}
            onChange={(e) => updateField(e)}
            isInvalid={errors?.birthDate ? true : false}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.birthDate && errors.birthDate}
          </Form.Control.Feedback>
        </div>
        <div className="inputLabel">
          <Form.Label>Adresse email</Form.Label>
          <Form.Control
            name="email"
            placeholder={"Entrez votre adresse email"}
            value={data.email}
            onChange={(e) => updateField(e)}
            isInvalid={errors?.email ? true : false}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email && errors.email}
          </Form.Control.Feedback>
        </div>
        <div className="inputLabel">
          <Form.Label>Nationalité</Form.Label>
          <Form.Select
            name="nationnality"
            onChange={(e) => setData({ ...data, nationnality: e.target.value })}
            isInvalid={errors?.nationnality ? true : false}
          >
            {nationalities.map((country, index) => (
              <option
                key={`${country.nationality}-${index}`}
                value={country.nationality}
                selected={
                  country.nationality.toLowerCase() ===
                  data.nationnality.toLowerCase()
                }
              >
                {country.nationality}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.nationnality && errors.nationnality}
          </Form.Control.Feedback>
        </div>
      </div>
    </section>
  );
}