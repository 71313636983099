import React, { useEffect, useState } from 'react'
import Button from '../../Components/Button'
import InputLabel from '../../Components/InputLabel'
import SelectLabel from '../../Components/SelectLabel'
import { TiWarning } from "react-icons/ti";

export default function EditPrice({editOption, setShowEditPage, setOPTIONS, OPTIONS}) {
    const index = editOption.index
    const [option, setOption] = useState(editOption.option)
    const [tempFeatures, setTempFeatures] = useState([...editOption.option.features])
    const [errors, setErrors] = useState(false)

    const handleAddFeature = () => {
        setTempFeatures([...tempFeatures, ''])
    }

    const handleDeleteFeature = (index) => {
        const newFeatures = tempFeatures.filter((_, i) => i !== index)
        setTempFeatures(newFeatures)
    }

    const handleUpdateTempFeature = (e, index) => {
        const newTempFeatures = [...tempFeatures]
        newTempFeatures[index] = e.target.value
        setTempFeatures(newTempFeatures)
    }

    const handleUpdateField = (e) => {
        setOption(option => ({
            ...option,
            [e.target.name] : e.target.value == 'yes' ? true : e.target.value == 'no' ? false : e.target.value
        }))
    }

    const handleSaveChanges = () => {
        // verification de champ vide
        const emptyField = tempFeatures.filter(feature => !feature.trim())
        if (emptyField.length > 0) {
            setErrors(true)
            setTimeout(() => {
                setErrors(false)
            }, 3000);
        }
        else{
            // setOPTIONS(OPTIONS => OPTIONS)
            const newOptions = [...OPTIONS]
            newOptions[index] = { ...option, features: tempFeatures }
            setOPTIONS(newOptions)
            // setOption(prevOption => ({ ...prevOption, features: tempFeatures }))
            // setEditOption({ ...option, features: tempFeatures })
            setShowEditPage(false)
        }
        

    }

    return (
        <main className="editPrice">
            <div className="backButton">
                <Button onClick={() => setShowEditPage(false)} value={'Retour'}/>
            </div>

            <div className='unDeletableInput'>
                <InputLabel onChange={handleUpdateField} label={'Nom'} name={'name'} value={option.name} />
                <InputLabel onChange={handleUpdateField} label={'Prix (€)'} name={'price'} value={option.price} />
                <SelectLabel onChange={handleUpdateField} label={'Définir comme choix populaire'} name={'defineAspopularChoice'}>
                    <option selected={option.defineAspopularChoice === true} value={'yes'}>Oui</option>
                    <option selected={option.defineAspopularChoice === false} value={'no'}>Non</option>
                </SelectLabel>
            </div>

            <div className='deletableInput'>
                {tempFeatures.map((feature, index) => (
                    <div key={index}>
                        <InputLabel 
                            onChange={(e) => handleUpdateTempFeature(e, index)} 
                            label={`Service ${index + 1}`} 
                            value={feature} 
                        />
                        <Button onClick={() => handleDeleteFeature(index)} value={'Supprimer'}/>
                    </div>
                ))}
            </div>

            {
                errors &&
                <div className="editPriceErrors">
                    <TiWarning/>
                    <span>Veuillez remplir tous les champs !!!</span>
                </div>
            }

            <Button onClick={handleAddFeature} value={'Ajouter Service'} />
            <Button onClick={handleSaveChanges} value={'Sauvegarder'} />
        </main>
    )
}
