import ChatingInterface from "./ChatingInterface/ChatingInterface";
import MessageSidebar from "./MessageSidebar/MessageSidebar";
import "./Message.css";
import { useEffect, useState } from "react";
import AudioCall from "./AudioCall/AudioCall";
import VideoCall from "./VideoCall/VideoCall";
import GetCallPopUp from "./GetCallPopUp";

export default function Message({
  leftMessageColor = "rgba(144, 134, 221, .15)",
  rightMessageColor = "#F8D2A6",
}) {
  const [isMobile, setisMobile] = useState(window.innerWidth <= 610);

  useEffect(() => {
    const handleResize = () => {
      setisMobile(window.innerWidth <= 610);
    };

    window.addEventListener("resize", handleResize);

    // Nettoyer l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //different call mode
  // voiceCall = en appel vocal en plein ecran
  // videoCall = en appel video en plein ecran
  // inSmallView = appel vocal/video en mode petit ecran
  // inMsgaMode = appel vocal/video en mode message
  const [callStatus, setCallStatus] = useState(undefined);

  const [time, setTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // chronometre appel
  useEffect(() => {
    let interval;
    if (callStatus !== undefined) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          let { hours, minutes, seconds } = prevTime;

          // Increment seconds
          seconds += 1;

          // Adjust minutes and hours when reaching the limit
          if (seconds === 60) {
            seconds = 0;
            minutes += 1;
          }
          if (minutes === 60) {
            minutes = 0;
            hours += 1;
          }

          return { hours, minutes, seconds };
        });
      }, 1000);
    }

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [callStatus]);

  const [callText, setCallText] = useState("En train de sonner...");
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");

  const handleAcceptCall = () => {
    // demande d'appel video en cours d'appel vocal
    if (
      callText == "Appel en cours..." &&
      ["voiceCall", "inSmallView"].includes(callStatus)
    ) {
      setCallStatus("videoCall");
    }
    //demande d'appel vocal en cours d'appel video
    if (
      callText == "Appel en cours..." &&
      ["videoCall", "inSmallViewVideo"].includes(callStatus)
    ) {
      setCallStatus("voiceCall");
    }
    // demande d'acceptation d'appel video/vocal
    setCallText("Appel en cours...");
    setShowPopup(false);
  };

  const handleDenyCall = () => {
    // demande d'appel video en cours d'appel vocal
    if (
      callText == "Appel en cours..." &&
      ["voiceCall", "inSmallView"].includes(callStatus)
    ) {
      setCallText("Appel vidéo refusé !");
      setTimeout(() => {
        setCallText("Appel en cours...");
      }, 2000);
    }
    //demande d'appel vocal en cours d'appel video
    else if (
      callText == "Appel en cours..." &&
      ["videoCall", "inSmallViewVideo"].includes(callStatus)
    ) {
      setCallText("Appel vocal refusé !");
      setTimeout(() => {
        setCallText("Appel en cours...");
      }, 2000);
    } else if (callText !== "Appel en cours...") {
      setCallText("Appel refusé !");
      setTimeout(() => {
        setCallStatus(undefined);
        setCallText("En train de sonner...");
        setTime({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }, 2000);
    }
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <GetCallPopUp
          popupText={popupText}
          acceptCall={handleAcceptCall}
          denyCall={handleDenyCall}
        />
      )}
      {(!callStatus ||
        callStatus == "inSmallView" ||
        callStatus == "inMessageMode" ||
        callStatus == "inSmallViewVideo" ||
        callStatus == "inMessageModeVideo") && (
        <div
          className={`${
            isMobile ? "" : "messageLayoutReverseColumn"
          } messageLayout animParent`}
        >
          <MessageSidebar isMobile={isMobile} setisMobile={setisMobile} />

          <ChatingInterface
            setPopupText={setPopupText}
            setShowPopup={setShowPopup}
            callStatus={callStatus}
            setCallStatus={setCallStatus}
            isMobile={isMobile}
            setIsmobile={setisMobile}
            leftMessageColor={leftMessageColor}
            rightMessageColor={rightMessageColor}
          />
        </div>
      )}

      {(callStatus == "voiceCall" ||
        callStatus == "inSmallView" ||
        callStatus == "inMessageMode") && (
        <AudioCall
          setPopupText={setPopupText}
          setShowPopup={setShowPopup}
          callText={callText}
          setCallText={setCallText}
          setCallStatus={setCallStatus}
          callStatus={callStatus}
          time={time}
          setTime={setTime}
        />
      )}
      {(callStatus == "videoCall" ||
        callStatus == "inSmallViewVideo" ||
        callStatus == "inMessageModeVideo") && (
        <VideoCall
          setPopupText={setPopupText}
          setShowPopup={setShowPopup}
          callText={callText}
          setCallText={setCallText}
          setCallStatus={setCallStatus}
          callStatus={callStatus}
          time={time}
          setTime={setTime}
        />
      )}
    </>
  );
}
