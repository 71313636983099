import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './chat.css';
import { useNavigate } from 'react-router-dom';

import  iconFichier from '../../images/fichiers.png';
import  iconEnvoyer from '../../images/icon-envoyer.png';
import  iconHead from '../../images/head.png';
import { Container } from 'react-bootstrap';

const Chat = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
      navigate(-1); 
  };

  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setInputValue(selectedFile.name); 
    }
  };
  const [messages, setMessages] = useState([
    { text: "Bonjour, j'ai un problème avec mon paiement. Pouvez-vous m'aider ?", type: 'sender' },
    { text: "Bonjour ! Je suis heureux de vous aider. Pouvez-vous décrire le problème que vous rencontrez avec votre paiement ?", type: 'receiver' },
    { text: "J'ai essayé de faire un paiement hier, mais cela n'a pas fonctionné. L'argent a été débité de mon compte bancaire, mais il n'apparaît pas dans mon compte chez vous.", type: 'sender' }
  ]);
  
  const [inputValue, setInputValue] = useState('');

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      setMessages([...messages, { text: inputValue, type: 'sender' }]);
      setInputValue('');
      
      setTimeout(() => {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: "Merci pour votre message ! Nous allons examiner cela.", type: 'receiver' }
        ]);
      }, 1000);
    }
  };

  return (
    <Container fluid>
      <div className="animParent container-chat mt-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="chat-title text-center">Discutez avec nous</h2>
        <button className="btn btn-retour" onClick={handleGoBack}>Retour</button>
      </div>
      
      <div className="chat-box  p-3">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.type}`}>
            <div className="message-content">
              <p>{msg.text}</p>
              
            </div>
            {msg.type === 'receiver' && (
                <img src={iconHead} alt="Icon Head" className="icon-head" />
              )}
          </div>
        ))}
      </div>

      <div className="input-group mt-3">
      
        <input
          type="text"
          className="form-control"
          id='msgChamp'
          placeholder="Tapez votre message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
         <input
          type="file"
          className="d-none"
          id="file-input"
          onChange={handleFileChange}
        />
        <label htmlFor="file-input" className="img-icon-chat ms-2" style={{ cursor: 'pointer' }}>
          <img src={iconFichier} alt="Fichier" className="img-icon-chat" />
        </label>
        
        <img src={iconEnvoyer} alt="Envoyer" className="img-icon-chat" onClick={handleSendMessage} />
        
      </div>
    </div>
    </Container>
  );
};

export default Chat;