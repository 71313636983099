import bg from "../../images/dash.png"
import imgNotif from "../../images/notificationSucces.png"
import { Container, Row, Col, Form } from "react-bootstrap";
import "./NotificationCreationCompte.css"

export default function NotificationCreationCompte(){

return <Container fluid style={{
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
   
  }}
  
  
  >

<Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center  flex-column'>
        <img src={imgNotif} className="img-fluid" width="600px"/>
        </Col>

</Row>

<Row>
<Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <p className="message-notif-success"> Vous avez créé votre compte avec succès</p>
    </Col>
</Row>

<Row>


<Col xl={12} lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center justify-content-center flex-column mt-4 '>
<button
                   type="submit" 
                   className='button-notif-success '
                  
                  
                   aria-label="Submit"
               > 
               
            <>
            Continuer À Vous Connecter
              <svg  viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="Register-fleche">
                  <rect y="0.5" width="29" height="29" rx="14.5" fill="white"/>
                  <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </>
           

               </button>
    </Col>
</Row>

</Container>




}