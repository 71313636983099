import React, { useState } from "react";
import { IconButton } from "../AudioCall/AudioCall";
import { BsRecordCircle } from "react-icons/bs";
import { MdCallEnd, MdZoomOutMap } from "react-icons/md";
import Profil from "../../../images/AdminLayout/Ellipse 806.png";
import Hote from "../../../images/2 1.png";
//import Guest from '../../../images/asia-businesswoman-social-distancing-new-normal-situation-virus-prevention-looking-camera-presentation-friends-about-plan-video-call-while-work-office-lifestyle-after-corona-virus.png'
import { SiAudiomack } from "react-icons/si";
import {
  AiFillAudio,
  AiFillMessage,
  AiOutlineAudioMuted,
} from "react-icons/ai";
import { FaCameraRotate } from "react-icons/fa6";
import { LuCamera, LuCameraOff } from "react-icons/lu";
import "./VideoCall.css";
import { IoCall } from "react-icons/io5";
import { IoIosVideocam } from "react-icons/io";

export default function VideoCall({
  setPopupText,
  setShowPopup,
  callText,
  setCallText,
  setCallStatus,
  callStatus,
  time,
  setTime,
}) {
  return (
    <div className="videoCallChat animParent">
      {(callStatus == "videoCall" || callStatus == "inSmallViewVideo") && (
        <VideoCallFullView
          setPopupText={setPopupText}
          setShowPopup={setShowPopup}
          callText={callText}
          setCallText={setCallText}
          callStatus={callStatus}
          setCallStatus={setCallStatus}
          time={time}
          setTime={setTime}
        />
      )}
      {callStatus == "inMessageModeVideo" && (
        <VideoCallMsgView setCallStatus={setCallStatus} />
      )}
    </div>
  );
}

// composant du mode plein/petit ecran de l'appel en fonction de callStatus
function VideoCallFullView({
  setPopupText,
  setShowPopup,
  callText,
  setCallText,
  setCallStatus,
  callStatus,
  time,
  setTime,
}) {
  const [isUserMicMuted, setIsUserMicMuted] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(true);

  const handleCall = (state) => {
    if (state == undefined) {
      setCallText("Appel terminé !");
      setTimeout(() => {
        setCallStatus(state);
        setTime({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        setCallText("En train de sonner...");
        setShowPopup(false);
      }, 3000);
    } else {
      setCallStatus(state);
    }
  };

  // defilement du composant
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Position actuelle
  const [isDragging, setIsDragging] = useState(false); // État de déplacement
  const [offset, setOffset] = useState({ x: 0, y: 0 }); // Décalage du clic initial

  const handleMouseDown = (e) => {
    // Marque le début du déplacement
    if (callStatus == "inSmallViewVideo") {
      setIsDragging(true);
    }

    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  // quand la souris se deplace
  const handleMouseMove = (e) => {
    if (!isDragging) return;

    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
  };

  // Fin du déplacement
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className={`fullViewCallMode ${
        callStatus == "inSmallViewVideo" && "inSmallView inSmallViewVideo"
      }`}
      style={{
        transform:
          callStatus == "inSmallViewVideo" &&
          `translate(${position.x}px, ${position.y}px)`,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <header className="callChatHeader">
        <div className="timeRecorder">
          <span className="dotRecorder"></span>
          {time.hours > 0 && (
            <span>{String(time.hours).padStart(2, "0")} : </span>
          )}
          <span>{String(time.minutes).padStart(2, "0")} : </span>
          <span>{String(time.seconds).padStart(2, "0")}</span>
        </div>
        <div className="headerOptions">
          <IconButton icon={<BsRecordCircle />} label={"Enregistrer l'appel"} />
          {callStatus == "videoCall" && (
            <IconButton
              onClick={() => handleCall("inSmallViewVideo")}
              icon={<MdZoomOutMap />}
              label={"Quitter le mode plein écran"}
            />
          )}
          {callStatus == "inSmallViewVideo" && (
            <IconButton
              onClick={() => handleCall("videoCall")}
              icon={<MdZoomOutMap />}
              label={"Revenir sur le mode plein écran"}
            />
          )}
        </div>
      </header>

      <main className="videoChatMain">
        <div className="callState">{callText}</div>
        <div
          className={`${
            callText !== "Appel en cours..." && "centerOnlyImg"
          } callUsers`}
        >
          {(callText == "Appel en cours..." ||
            callText == "Appel vocal refusé !") && (
            <div className={`listeningUser`}>
              <div className="speakIconCard">
                <SiAudiomack />
              </div>
              <img src={"Guest"} alt="profil" />
              <div className="mutedMicroCard">
                <AiOutlineAudioMuted />
              </div>
              <span>Mary Dooley</span>
            </div>
          )}
          <div
            className={`${
              callText !== "Appel en cours..." && "unSetPosition"
            } speakingUser`}
          >
            <div className="speakIconCard">
              <SiAudiomack />
            </div>
            {/* <section style={{background: `url('${Profil}')`, backgroundSize: 'cover'}}></section> */}
            {callStatus == "inSmallViewVideo" && (
              <div className="inSmallViewVideoAction">
                <IconButton
                  icon={<AiFillMessage />}
                  label={"Envoyer un message"}
                  onClick={() => handleCall("inMessageModeVideo")}
                />
              </div>
            )}
            {isCameraActive ? (
              <img src={Hote} alt="profil" />
            ) : (
              <div className={`profilCard`}>
                <section
                  style={{
                    background: `url('${Profil}')`,
                    backgroundSize: "cover",
                  }}
                ></section>
                <img src={Profil} alt="profil" />
              </div>
            )}
            <span>Vous</span>
          </div>
        </div>
      </main>

      <footer className="videoChatFooter">
        {isUserMicMuted ? (
          <IconButton
            icon={<AiOutlineAudioMuted />}
            label={"Activer le micro"}
            onClick={() => setIsUserMicMuted(false)}
          />
        ) : (
          <IconButton
            icon={<AiFillAudio />}
            label={"Désactiver le micro"}
            onClick={() => setIsUserMicMuted(true)}
          />
        )}
        <IconButton
          icon={<IoCall />}
          label={"Passer en appel vocal"}
          bgColor={"#015192"}
          onClick={() => {
            setPopupText("Mary Dooley demande à passer en appel vocal");
            setShowPopup(true);
          }}
        />
        {isCameraActive ? (
          <IconButton
            icon={<LuCamera />}
            label={"Désactiver caméra"}
            onClick={() => setIsCameraActive(false)}
          />
        ) : (
          <IconButton
            icon={<LuCameraOff />}
            label={"Activer caméra"}
            onClick={() => setIsCameraActive(true)}
          />
        )}
        {isCameraActive && (
          <IconButton
            icon={<FaCameraRotate />}
            label={"Tourner caméra"}
            onClick={() => null}
          />
        )}
        {callStatus !== "inSmallViewVideo" && (
          <IconButton
            icon={<AiFillMessage />}
            label={"Envoyer un message"}
            onClick={() => handleCall("inMessageModeVideo")}
          />
        )}
        <IconButton
          icon={<MdCallEnd />}
          label={"Terminer l'appel"}
          bgColor={"#E72C79"}
          onClick={() => handleCall(undefined)}
        />
      </footer>
    </div>
  );
}

// composant du mode message
function VideoCallMsgView({ setCallStatus }) {
  // defilement du composant
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Position actuelle
  const [isDragging, setIsDragging] = useState(false); // État de déplacement
  const [offset, setOffset] = useState({ x: 0, y: 0 }); // Décalage du clic initial

  const handleMouseDown = (e) => {
    // Marque le début du déplacement
    setIsDragging(true);

    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  // quand la souris se deplace
  const handleMouseMove = (e) => {
    if (!isDragging) return;

    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
  };

  // Fin du déplacement
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="msgViewMode"
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <IconButton
        icon={<IoIosVideocam />}
        label={"Revenir en plein écran"}
        onClick={() => setCallStatus("videoCall")}
      />
    </div>
  );
}
