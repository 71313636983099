import React, { useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "../../Student_Admin/Student.css"
import next from "../../../images/nextAdd.png"
import profile from "../../../images/inpProfil.png"
import add from "../../../images/add.png"
import { useNavigate } from 'react-router'
import { useState,useEffect } from 'react'
import CustomCountrySelectAdmin from '../../../Layout/CountrySelect'
function AdminUpdate() {
    const fileInputRef = useRef(null);
    const [codeCountry,setCodeCountry]=useState("");

  // Fonction qui déclenche le clic sur l'input caché
  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  function handleCodeCountry(c){
    setCodeCountry(c);
}
  // Fonction qui gère le changement de fichier
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Fichier sélectionné:', file);
      // Ajoutez la logique pour afficher ou envoyer l'image
    }
  };
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Retourne à la page précédente
  };
const [universities, setUniversities] = useState([]);

  useEffect(() => {
    // Appel de l'API pour récupérer la liste des universités en France
    fetch("http://universities.hipolabs.com/search?country=France")
      .then(response => response.json())
      .then(data => {
        // Trie des universités par nom pour une meilleure lisibilité
        const sortedUniversities = data.sort((a, b) => 
          a.name.localeCompare(b.name)
        );
        setUniversities(sortedUniversities);
      })
      .catch(error => console.error('Erreur lors de la récupération des universités:', error));
  }, []);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then(response => response.json())
      .then(data => {
        // Sort countries alphabetically by name for better UX
        const sortedCountries = data.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );
        setCountries(sortedCountries);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);
  
  return (
    <Container>
        <Row>
            <Col>
            <span className='RouteStu'>
                    <p className='routeStu'>Administrateur</p>
                    <p className='RouteStuRoute'>/ Ajouter Administrateur </p>
                </span>
            </Col>
            <Col>
                <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
            </Col> 
        </Row>
        <Row>
            <Col className="addStu1">
                <Row>
                    <Col sm={6}>
                        <p className='titleEtu'>Compte Administrateur</p>
                    </Col>
                    <Col sm={6}>
                        <span className='spanInpImg'>
                            <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef} className='imgInpModif'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            
                            {/* Image cliquable pour déclencher l'upload */}
                            <img
                                src={profile} // Remplacez par le chemin correct de votre image uploadée
                                alt="Cliquez pour changer l'image"
                                className="spanInpImg"
                                onClick={handleImageClick}
                                style={{ cursor: 'pointer', borderRadius: '50%'}} // Applique des styles personnalisés si nécessaire
                            />
                            <img src={add} className='addImg' onClick={handleImageClick}/>
                        </span>
                    </Col>

                </Row>
                <Row>
                    <Col sm={6}>
                        <p className='labelInp'>Nom</p>
                        <input type='text' placeholder='Votre Nom' className='inpStu'/>
                    </Col>
                    <Col sm={6}>
                        <p className='labelInp'>Prenom</p>
                        <input type='text' placeholder='Votre Prénom' className='inpStu'/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <p className='labelInp' >Numero de telephone</p>
                        
                        
                         <Row>
                         <Col sm={3} >
                        <CustomCountrySelectAdmin    codeCountry={handleCodeCountry}/>
                        
                        </Col>
                        <Col >
                            <input type='text' placeholder='XXX XXX XXXX' className='inpTelEtu'/>
                            </Col>
                         </Row>
                        
                    </Col>
                    <Col sm={6}>
                        <p className='labelInp'>Date De Naissance</p>
                        <input type='date' className='inpStu'/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <p className='labelInp'>Pays</p>
                        
                        <select className='inpStu'>
                            <option value="" >Selectionner</option>
                            {countries.map(country => (
                                <option key={country.cca3} value={country.name.common}>
                                {country.name.common}
                                </option>
                            ))}
                        </select>
                    </Col>
                    <Col sm={6}>
                        <p className='labelInp'>Genre</p>
                        <select className='inpStu'>
                            <option value="" >Selectionner</option>
                            <option value="Homme" >Homme</option>
                            <option value="Femme" >Femme</option>
                        </select>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <p className='labelInp'>Adresse Email</p>
                        <input type='email' placeholder='Votre Adresse Email'  className='inpStu'/>
                    </Col>
                    <Col sm={6}>
                        <p className='labelInp'>Role D'Utilisateur</p>
                        <select className='inpStu'>
                            <option value="" >Selectionner un role</option>
                            <option value="" >View</option>
                            <option value="" >View - Edit</option>
                            
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <p className='labelInp'>Mots de passe</p>
                        <input type='password' placeholder='Votre Mots De Passe'  className='inpStu'/>
                    </Col>
                    <Col sm={6}>
                        <p className='labelInp'>Confirmez le mots de passe</p>
                        <input type='password' placeholder='Confirmez Votre Mots De Passe'  className='inpStu'/>
                    </Col>
                </Row>
                
                <Row className='rowBtnAdd'>
                    <Col>
                            <button className='creer'>
                                Créer
                                <img src={next} className='imgbtnadd'/>
                            </button>
                       
                    </Col>
                </Row>

            </Col>
        </Row>
    </Container>
  )
}

export default AdminUpdate
