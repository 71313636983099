// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rightSectionImgCard2{
   
        position: relative;
        margin-left: -45%;
        bottom: -16%;
     
}
`, "",{"version":3,"sources":["webpack://./src/Parent/Register/register.css"],"names":[],"mappings":"AAAA;;QAEQ,kBAAkB;QAClB,iBAAiB;QACjB,YAAY;;AAEpB","sourcesContent":[".rightSectionImgCard2{\n   \n        position: relative;\n        margin-left: -45%;\n        bottom: -16%;\n     \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
