import React from 'react'

function EyeSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
  <path fill="#fff" d="M8 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 5.333a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.667ZM8 3C4.667 3 1.82 5.073.667 8c1.153 2.927 4 5 7.333 5s6.18-2.073 7.333-5c-1.153-2.927-4-5-7.333-5Z"/>
</svg>

  )
}

export default EyeSvg
