// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageLayout{
    display: grid;
    grid-template-columns: min(220px, 30vw) 1fr;
    column-gap: 15px;
}

@media screen and (max-width:610px) {
    .messageLayout{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .messageLayoutReverseColumn{
        flex-direction: column-reverse;
    }

    .messageSidebar{
        width: 80%;
    }

    .chatingInterface,
    .messageSidebar{
        /* opacity: 0; */
        display: none;
    }

    .displayChatInterface{
        /* opacity: 1; */
        display: block;
    }

    .displayMessageSidebar{
        /* opacity: 1; */
        display: block;
    }

    .messageSidebar{
    margin-inline: auto;

    }
}


@media screen and (min-width:1440px) {
    .messageLayout{
        grid-template-columns: 280px 1fr;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Message/Message.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2CAA2C;IAC3C,gBAAgB;AACpB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,UAAU;IACd;;IAEA;;QAEI,gBAAgB;QAChB,aAAa;IACjB;;IAEA;QACI,gBAAgB;QAChB,cAAc;IAClB;;IAEA;QACI,gBAAgB;QAChB,cAAc;IAClB;;IAEA;IACA,mBAAmB;;IAEnB;AACJ;;;AAGA;IACI;QACI,gCAAgC;IACpC;AACJ","sourcesContent":[".messageLayout{\n    display: grid;\n    grid-template-columns: min(220px, 30vw) 1fr;\n    column-gap: 15px;\n}\n\n@media screen and (max-width:610px) {\n    .messageLayout{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .messageLayoutReverseColumn{\n        flex-direction: column-reverse;\n    }\n\n    .messageSidebar{\n        width: 80%;\n    }\n\n    .chatingInterface,\n    .messageSidebar{\n        /* opacity: 0; */\n        display: none;\n    }\n\n    .displayChatInterface{\n        /* opacity: 1; */\n        display: block;\n    }\n\n    .displayMessageSidebar{\n        /* opacity: 1; */\n        display: block;\n    }\n\n    .messageSidebar{\n    margin-inline: auto;\n\n    }\n}\n\n\n@media screen and (min-width:1440px) {\n    .messageLayout{\n        grid-template-columns: 280px 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
