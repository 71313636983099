
import ChatingInterface from "./ChatingInterface/ChatingInterface";
import MessageSidebar from "./MessageSidebar/MessageSidebar";
import './Message.css'
import { useEffect, useState } from "react";


export default function Message({leftMessageColor = 'rgba(144, 134, 221, .15)', rightMessageColor = '#F8D2A6'}){
    
    const [isMobile, setisMobile] = useState(window.innerWidth <= 610)

    useEffect(() => {
        const handleResize = () => {
            setisMobile(window.innerWidth <= 610);
        };

        window.addEventListener('resize', handleResize);

        // Nettoyer l'événement lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return(       
             
        <div className={`${isMobile ? '' : 'messageLayoutReverseColumn'} messageLayout animParent`}>
            
            
            <MessageSidebar isMobile={isMobile} setisMobile={setisMobile}/> 
            <ChatingInterface isMobile={isMobile} setIsmobile={setisMobile} leftMessageColor={leftMessageColor} rightMessageColor={rightMessageColor}/>
            

        </div>
               
            
       
    )
}