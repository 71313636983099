import TableHeading from "../TableHeading"
import  Pagination  from "../Pagination"
import TableData from "../TableData"
import './PaymentHistoric.css'

import DateIcon from '../../images/Parent/uil_calender.png'
import FilterIcon from '../../images/Parent/carbon_filter.png'
import SuccesIcon from '../../images/Parent/gg_check-o.png'
import EchecIcon from '../../images/Parent/gg_check-o (1).png'
import ProgressIcon from '../../images/Parent/gg_check-o (2).png'
import Button from "../Button"
import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';

export default function PaymentHistoric(){

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true); // État de chargement

    const data1 = [
        '01',
        'AVI',
        'Carte de credit',
        '01-12-2024',
        'Succes',
        '€ 500,00'
    ]
    const data2 = [
        '01',
        'AVI',
        'Carte de credit',
        '01-12-2024',
        'En attente',
        '€ 500,00'
    ]
    const data3 = [
        '01',
        'AVI',
        'Carte de credit',
        '01-12-2024',
        'Echec',
        '€ 500,00'
    ]

    const data = [data1, data2, data3, data2, data1]
    useEffect(() => {
        const totalPages = Math.ceil(data.length / itemsPerPage);
        let current = 0;

        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = data.slice(current, current + itemsPerPage);
            current += itemsPerPage;
        }

        setItemsPages(newItemsPages);
        setLoading(false); // Indiquer que le chargement est terminé
    }, [itemsPerPage]);


    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedDate, setSelectDate] = useState(undefined)
    const moisEnFrancais = {
        Jan: "Janvier",
        Feb: "Février",
        Mar: "Mars",
        Apr: "Avril",
        May: "Mai",
        Jun: "Juin",
        Jul: "Juillet",
        Aug: "Août",
        Sep: "Septembre",
        Oct: "Octobre",
        Nov: "Novembre",
        Dec: "Décembre"
      };
      
      
    
    const [displayedDate, setDisplayedDate] = useState(['25 octobre 2024', '31 decembre 2024'])

    useEffect(()=>{
        if(selectedDate){
            const startDate = String(selectedDate[0]).split(' ') 
            const endDate =  String(selectedDate[1]).split(' ')
    //  alert(selectedDate)
            // alert(startDay, startMonth, startYear)
            const dates = [`${startDate[2]} ${moisEnFrancais[startDate[1]]} ${startDate[3]}`, `${endDate[2]} ${moisEnFrancais[endDate[1]]} ${endDate[3]}`]
            setDisplayedDate(dates)
                
            }
      
    },[selectedDate])

    const handleDateChange = (date) => {
        if (!startDate) {
            setStartDate(date);
          } else if (!endDate) {
            if (date && date > startDate) {
              setEndDate(date);
              setIsOpen(false); 
              setSelectDate([startDate, date])
            } else {
              alert("La date de fin doit être après la date de début.");
            }
          } else {
            setStartDate(date);
            setEndDate(null);
          }
    };

    // const formatDateFrancais = (date) => {
    //     const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    //     return date.toLocaleDateString('fr-FR', options);
    // };


    return(
                
            <main className=" animParent parentPaymentHistoric">

                <div className="parentPaymentHistoricFilters">
                    <div className="parentPaymentHistoricDateFilter">
                        <div>
    
                            <img src={DateIcon} alt="date" onClick={()=> setIsOpen(true)} />
                            {isOpen && (
                                <div>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    // startDate={startDate}
                                    // endDate={endDate}
                                    // selectsRange
                                    inline
                                />
                                </div>
                            )}
                            {/* <input type="date" name="" id="" className="paymentHistoricDateFilterInput"/> */}
                        </div>
                        <span>{displayedDate[0]} - {displayedDate[1]}</span>
                    </div>
                    <div className="parentPaymentHistoricFilter">
                        <img src={FilterIcon} alt="date" />
                        <span>Filter</span>
                    </div>
                    <Button value={'Exporter'}/>
                </div>
                <PaymentHistoricTable data={data} loading={loading}/>
                <Pagination itemsPages ={itemsPages} setItemsPerPage={setItemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                
            </main>
    )
}



function PaymentHistoricTable({data, loading}){
    return(
        // <Table responsive>
        <div className="parentPaymentHistoricTableContainer">
        <table className="parentPaymentHistoricTable">
            <thead>
                <tr>
                    <TableHeading value={'Numero'}/>
                    <TableHeading value={'Nom de service'}/>
                    <TableHeading value={'Type du paiement'}/>
                    <TableHeading value={'Date du paiement'}/>
                    <TableHeading value={'Statut de paiement'}/>
                    <TableHeading value={'Montant'}/>
                    <TableHeading value={'Details'}/>
                </tr>
            </thead>

            <tbody>
                {
                    loading ? ( // Afficher le loader si les données sont en cours de chargement
                    <tr>
                        <td style={{ textAlign: 'center', width: '100%' }}>
                            Chargement des données...
                        </td>
                    </tr>
                ) : (
                    data.map((data, index)=>(
                        <tr key={index} className={index%2 == 0 ? 'OddTableRow' : ''}>
                            <TableData value={data[0]}/>
                            <TableData value={data[1]}/>
                            <TableData value={data[2]}/>
                            <TableData value={data[3]}/>
                            <TableData value={
                                <div className={`${data[4] === 'Succes' ? 'sucessStatus' : data[4] === 'Echec' ? 'echecStatus' : 'progressStatus'}} paymentHistoricStatus`}>
                                    <img src={data[4] === 'Succes' ? SuccesIcon : data[4] === 'Echec' ? EchecIcon : ProgressIcon} alt="" />
                                    <span>{data[4]}</span>
                                </div>
                            }/>
                            <TableData value={data[5]}/>
                            <TableData value={
                                <div className="moreDetailsChamp">
                                    ...
                                </div>
                            }/>
                        </tr>
                    ))
                )
                }
            </tbody>
        </table>
        </div>
        // {/* </Table> */}



    )
}