import React from "react";
import "./Login.css";
import bg from "../../images/dash.png"
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "../../Components/InputLabel";
import buttonImg from "../../images/nextAdd.png";
import TwoManImg from "../assets/MERE.png";
import logo from "../assets/image/logo-colorful.png";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";

export default function LoginParent() {
  const navigate=useNavigate()
  const [showPassword, setShowPassword]=useState(false);
  const openEye=<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
  const closedEye=<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
  const [data, setData] = useState({
    email: '',
    password: '',
  })

  const handleDataChange = e => {
    setData(data=>({
      ...data,
      [e.target.name]: e.target.value
    }))
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="parentLogin parentLogin2" style={{backgroundImage: `url(${bg})`, backgroundAttachment: 'fixed', backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
      <Container className="loginParentContainer" >
          <Col className="ColLoginParent" lg={6} md={6}>
            <div className="parentLoginLeftSection parentLoginLeftSectionheight">
            <div className="logoImgCard logo-card">
                <img src={logo} />
              </div>
              <h1 className="headeLoginPar">Se connecter</h1>
              <div className="parentLoginHeader">
                <p>Veuillez remplir le formulaire pour vous connecter</p>
                <p>
                  Vous n'avez pas de compte ?
                  <span>
                    <Link to={"/parent/register"}>Inscrivez-vous</Link>
                  </span>
                </p>
              </div>

              <div className="parentLoginForm">
                <InputLabel
                  label={"Email*"}
                  placeholder={"Entrez votre email"}
                  name={"email"}
                  onChange={handleDataChange}
                />
                <div className="passwordContainer">

                <InputLabel
                  type={showPassword ? "text" : "password"} 
                  label={"Mot de passe*"}
                  placeholder={"Entrez votre mot de passe"}
                  name={"password"}
                  onChange={handleDataChange}
                  value= {data.password}
                />
                  <p onClick={togglePasswordVisibility} className="eye-password-student"  style={{
                    position: "absolute",
                    right: "0.75rem", 
                    top: "63%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#888888"
                  }} >
                  {showPassword ? closedEye : openEye} 
                </p>
              </div>
                <div className="rememberMeForgotPassword">
                  <div className="rememberMe">
                    <input type="checkbox" id="checkbox"></input>
                    <label htmlFor="checkbox">Se Souvenir de moi</label>
                  </div>
                  <h5 onClick={()=>navigate('/renitialisation-mot-de-passe')}><strong>Mot de passe oublié</strong></h5>
                </div>
                <div className="parent-login-switch-to-student">
                  <div>
                    Vous n'êtes pas parent - <span onClick={()=> navigate('/etudiant/login')}>Espace Etudiant</span>
                  </div>

                </div>
                <button className="parentLoginFormButton" onClick={()=>navigate('/parent/')}>
                  <span>Se connecter</span>
                  <img src={buttonImg} />
                </button>
              </div>
            </div>
          </Col>

          <Col lg={6} md={6} className="rightImgLoginCol">
            <div className="rightSectionImgCard1">
              <img src={TwoManImg} className="bottomImage" />
            </div>
          </Col>
      </Container>
    </div>
  );
}
