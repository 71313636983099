import React, { useEffect, useState } from 'react'
import "./Student.css"
import {useNavigate} from "react-router-dom"
import {Container,Col,Row, Table} from "react-bootstrap"
import profile from "../../images/etuAdm.png"
import show from "../../images/ShowEtu.png"
import delet from "../../images/deleteEtu.png"
import edit from "../../images/editEtu.png"
import Pagination from '../../Components/Pagination'
function Student() {
  const navigate=useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true); // État de chargement

    useEffect(() => {
        const totalPages = Math.ceil(dataStudent.length / itemsPerPage);
        let current = 0;

        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = dataStudent.slice(current, current + itemsPerPage);
            current += itemsPerPage;
        }

        setItemsPages(newItemsPages);
        setLoading(false); // Indiquer que le chargement est terminé
    }, [itemsPerPage]);
  const dataStudent = [
    {
      id: "01",
      name: "Robert Fox",
      dob: "12 May 1994",
      university: "University of Paris",
      schoolYear: "2020",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "France",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "02",
      name: "Robert Fox",
      dob: "30 May 1994",
      university: "University of Paris",
      schoolYear: "2005",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "Greenland",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "03",
      name: "Robert Fox",
      dob: "12 July 1994",
      university: "Paris-Sorbonne University",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "India",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "04",
      name: "Robert Fox",
      dob: "30 July 1994",
      university: "University of Paris",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "Germany",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "05",
      name: "Avo Oganda",
      dob: "12 Oct 1994",
      university: "Paris-Sorbonne University",
      schoolYear: "2020",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "Canada",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "06",
      name: "Robert Fox",
      dob: "30 Oct 1990",
      university: "Paris-Sorbonne University",
      schoolYear: "2005",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "France",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "07",
      name: "Ali Ansari",
      dob: "12 July 1992",
      university: "University of Paris",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "Italy",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "08",
      name: "Randy Orton",
      dob: "30 July 1990",
      university: "Paris-Sorbonne University",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "UAE",
      actionEmail: "robert@gmail.com"
    },
    {
      id: "09",
      name: "Andrew Lisa",
      dob: "12 Oct 1993",
      university: "Paris-Sorbonne University",
      schoolYear: "2010",
      gender: "Male",
      phoneNumber: "(201) 555-0124",
      email: "Robertf@gmail.com",
      country: "France",
      actionEmail: "robert@gmail.com"
    }
  ];
  const handleDetail=()=>{
      navigate('details')
  }
  const handleEdit=()=>{
    navigate('modifier')
}
const handleAjouter=()=>{
  navigate('ajouter')
}
  return (
    <div>
      <Row >
        <Col sm={12} className='btnflex'>
          <button className='addStu' onClick={handleAjouter}> Ajouter Un Nouveau </button>
        </Col>
      </Row>
      <Row >
        <Col sm={12} className='TabStuAdm'>
          <Table responsive>
            <table className='tableEtuAdm' >
              <tr>
                <th className='thEtu NO'>S.NO</th>
                <th className='thEtu Nom'>Name</th>
                <th className='thEtu Dob'>Date De Naissance</th>
                <th className='thEtu Uni'>Université</th>
                <th className='thEtu YR'>Année Scolaire</th>
                <th className='thEtu M'>Genre</th>
                <th className='thEtu Phone'>Telephone</th>
                <th className='thEtu Email'>Email ID</th>
                <th className='thEtu Country'>Pays</th>
                <th className='thEtu'>Action</th>
              </tr>
              {
                loading ? ( // Afficher le loader si les données sont en cours de chargement
                    <tr>
                        <td style={{ textAlign: 'center', width: '100%' }}>
                            Chargement des données...
                        </td>
                    </tr>
                ) : (
                dataStudent.map((val,index)=>{
                  return(
                    <tr className='trEtuAdm' key={index}>
                      <td className='tdEtuAdm'>{val.id} </td>
                      <td className='nameTh'> 
                      <img src={profile}  className='etuimg'/>
                      {val.name} </td>
                      <td className='tdEtuAdm'>{val.dob} </td>
                      <td className='tdEtuAdm'>{val.university} </td>
                      <td className='tdEtuAdm'>{val.schoolYear} </td>
                      <td className='tdEtuAdm'>{val.gender} </td>
                      <td className='tdEtuAdm'>{val.phoneNumber} </td>
                      <td className='tdEtuAdm'>{val.email} </td>
                      <td className='tdEtuAdm'>{val.country} </td>
                      <td className='tdEtuAdm'>
                        <img src={show} onClick={handleDetail} className='actionEtu'/>
                        <img src={edit} onClick={handleEdit} className='actionEtu'/>
                        <img src={delet} className='actionEtu'/>
                      </td>
                    </tr>
                  )
                })
                )
              }
            </table>
          </Table>
          <Pagination itemsPages={itemsPages} setItemsPerPage={setItemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </Col>
      </Row>
    </div>
  )
}

export default Student
