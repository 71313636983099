import React, { useState, useEffect } from "react";
import "./notification.css";
import iconDocument from "../../images/document-logo.png";
import iconSpam from "../../images/icon-spam.png";
import iconBank from "../../images/icon-bank.png";
import { Container } from "react-bootstrap";
import api from "../../StudentModule/api/axiosInstance";

function NotificationList() {
  // States pour les notifications, le chargement et les erreurs
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fonction pour déterminer l'icône en fonction du type de notification
  const getIcon = (type) => {
    switch (type) {
      case "document":
        return iconDocument;
      case "spam":
        return iconSpam;
      case "bank":
        return iconBank;
      default:
        return iconDocument;
    }
  };

  // Fonction pour récupérer les notifications
  const fetchNotifications = async () => {
    try {
      const response = await api.get("/student/referral/my_referral/");

      // Vérification pour les redirections
      if (response.redirected) {
        setNotifications([]);
        setLoading(false);
        setError(
          "Redirection détectée. Veuillez vérifier l'authentification ou l'URL."
        );
        return;
      }

      // Vérification de la réponse HTTP
      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      // Vérification du type de contenu
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        const html = await response.text();
        setNotifications([]);
        setLoading(false);
        setError(
          `L'API a renvoyé du HTML ou un contenu inattendu : ${html.substring(
            0,
            100
          )}...`
        );
        return;
      }

      // Traitement des données JSON
      const data = await response.json();

      if (!data || data.length === 0) {
        setNotifications([]);
        setLoading(false);
        return;
      }

      // Formater les notifications
      const formattedNotifications = data.map((notification) => ({
        ...notification,
        icon: getIcon(notification.type),
      }));

      setNotifications(formattedNotifications);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors du chargement :", error);
      setError(`Erreur de chargement: ${error.message}`);
      setLoading(false);
    }
  };

  // Utiliser useEffect pour charger les notifications au montage du composant
  useEffect(() => {
    fetchNotifications();
  }, []);

  // Fonction pour marquer toutes les notifications comme lues
  const markAllAsRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({
        ...notification,
        isRead: true,
      }))
    );
  };

  if (loading) {
    return (
      <Container fluid className="container-notif animParent mt-4">
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container fluid className="container-notif animParent mt-4">
      <div className="d-flex justify-content-between align-items-start mb-3">
        <h2 id="title-notif">Nouveau pour vous</h2>
        <button onClick={markAllAsRead} className="btn btn-link" id="p-notif">
          Marquer tout comme lu
        </button>
      </div>

      <div className="w-100">
        {notifications.map(({ id, message, date, icon, isRead }, index) => (
          <div
            className="notification-bar row d-flex justify-content-between align-items-start"
            key={id}
          >
            <div className="col-2 col-sm-1">
              <img src={icon} alt="logo" className="logo-notif img-fluid" />
            </div>
            <div className="message-notif col-8 col-sm-10">
              <p className="p-bold">{message}</p>
              <p className="p-fit">{date}</p>
            </div>
            <div className="col-2 col-sm-1">
              {!isRead && index < 2 && (
                <div className="notification-circle"></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default NotificationList;
