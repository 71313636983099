
import Message from "../../Components/Message/Message";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css"
export default function AdminMessage(){
    

    return(
        <div className="messageAdm">
             
            <Col className={`adminMessage`}>
                    <Message/>

            </Col>
               
            
        </div>
       
    )
}