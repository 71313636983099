import './AdminSupportTicket.css'

import { useEffect, useState } from 'react';
import TableHeading from '../../Components/TableHeading';
import TableData from '../../Components/TableData';
import Button from '../../Components/Button';
import Pagination from '../../Components/Pagination';

// icons imports
import incidentIcon from '../../images/AVI/octicon_fire-24.png'
import suggestionIcon from '../../images/AVI/light.png'
import questionIcon from '../../images/AVI/faq.png'
import searchIcon from '../../images/Message/Search.png'
import typeIcon from '../../images/Ticket Support/Vector.png'
import priorityIcon from '../../images/Ticket Support/iconoir_priority-up.png'
import dateIcon from '../../images/Ticket Support/Group.png'
import ticketIcon from '../../images/Ticket Support/ph_ticket-light.png'
import { Col,Row, Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';

export default function AdminSupportTicket(){

    const data1 = 
        [
            '#TC_192',
            'Bonjour, je veux un remboursement de mon certification',
            'Fort',
            'Incident',
            'Santi Cartoza',
            'Jimmy Watt',
            '09/02/2024, 11:34',
    ]       
    const data2 = [
        '#TC_192',
        'Bonjour, je veux un remboursement de mon certification',
        'Faible',
        'Suggestion',
        'Santi Cartoza',
        'Jimmy Watt',
        '09/02/2024, 11:34',
    ]

    const data3 = [
        '#TC_192',
        'Bonjour, je veux un remboursement de mon certification',
        'Normal',
        'Question',
        'Santi Cartoza',
        'Jimmy Watt',
        '09/02/2024, 11:34',
    ]

    const data = [data1, data2, data3, data1, data2, data3, data1, data2, data3, data1, data2, data3, data1, data2, data3, data1, data2, data3, data1, data2, data3, data1, data2, data3, data1, data2, data3, data1, data2, data3]

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true); // État de chargement
    const navigate = useNavigate();

    const handleSup = () => {
        navigate("/administrateur/SupportTicket/detail");
    };
    useEffect(() => {
        const totalPages = Math.ceil(data.length / itemsPerPage);
        let current = 0;

        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = data.slice(current, current + itemsPerPage);
            current += itemsPerPage;
        }

        setItemsPages(newItemsPages);
        setLoading(false); // Indiquer que le chargement est terminé
    }, [itemsPerPage]);
    

    return(
        <div>              
                
            <SupportTicketHeader/>

            <Col className="adminSupportTicket">
                
                <SupportTicketTable
                    itemsPages={itemsPages}
                    currentPage={currentPage}
                    loading={loading}
                    handleSup={handleSup}
                />

                <Pagination
                    itemsPages={itemsPages}
                    setItemsPerPage={setItemsPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            </Col>
            
        </div>
    )
}


function SupportTicketHeader(){
const navigate=useNavigate()
    const FilterOption = ({img, alt, optionTitle}) => {
        return(
            <div className='filterOption'>
                <img src={img} alt={alt} className='filterOptionImg'/>
                <span className='filterOptionTitle'>{optionTitle}</span>
            </div>
        )
    }

    return(
        <header className="headerMainSupportTicket">
            <div className="filterOptions">
                <div className='filterOptionsSearch'>
                    <img src={searchIcon} alt="search" className='searchIcon'/>
                    <input type="text" placeholder="Rechercher..." className='inputFilterOption'/>
                </div>
                <div className="filterOptionGroup">
                    <FilterOption img ={typeIcon} alt={'type'} optionTitle = {'Type'}/>
                    <FilterOption img ={priorityIcon} alt={'priority'} optionTitle = {'Priorité'}/>
                    <FilterOption img ={dateIcon} alt={'date'} optionTitle = {'Date ajoutée'}/>
                    <FilterOption img ={ticketIcon} alt={'ticket'} optionTitle = {'Demande d\'assistance'}/>
                </div>
                <select className='filterOptionSelect'>
                    <option value="">
                        <FilterOption img ={typeIcon} alt={'type'} optionTitle = {'Type'}/>
                    </option>
                    <option value="">
                        <FilterOption img ={priorityIcon} alt={'priority'} optionTitle = {'Priorité'}/>

                    </option>
                    <option value="">
                        <FilterOption img ={dateIcon} alt={'date'} optionTitle = {'Date ajoutée'}/>

                    </option>
                    <option value="">
                        <FilterOption img ={ticketIcon} alt={'ticket'} optionTitle = {'Demande d\'assistance'}/>

                    </option>
                </select>

            </div>
            <Button value={'Ajouter ticket'} onClick={()=>navigate('detail')}/>
        </header>
    )
}

function SupportTicketTable({ loading, itemsPages, currentPage, handleSup }) {
    return (
        <Table responsive>
            <table className='adminSupportTicketTable'>
                <thead>
                    <tr>
                        <TableHeading value={'ID du ticket'} />
                        <TableHeading value={'Objet'} />
                        <TableHeading value={'Priorite'} />
                        <TableHeading value={'Type'} />
                        <TableHeading value={'Demandeur'} />
                        <TableHeading value={'Cessionnaire'} />
                        <TableHeading value={'Date de demande'} />
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td style={{ textAlign: 'center', width: '100%' }}>
                                Chargement des données...
                            </td>
                        </tr>
                    ) : (
                        itemsPages[currentPage]?.map((data, index) => (
                            <tr onClick={handleSup} key={index}> 
                                {/* Rendu des données */}
                                <TableData value={data[0]} />
                                <TableData value={data[1]} />
                                <TableData value={
                                    <div className={`${data[2] === 'Fort' && 'highPriority'} ${data[2] === 'Faible' && 'lowPrority'} ${data[2] === 'Normal' && "mediumPriority"}`}>
                                        <span>
                                            {data[2]}
                                        </span>
                                    </div>
                                } />
                                <TableData value={
                                    <div className='supportTicketType'>
                                        {
                                            data[3] === 'Incident' && <img src={incidentIcon} alt="incident" className='typeImg'/>
                                        }
                                        {
                                            data[3] === 'Suggestion' && <img src={suggestionIcon} alt="suggestion" className='typeImg'/>
                                        }
                                        {
                                            data[3] === 'Question' && <img src={questionIcon} alt="question" className='typeImg'/>
                                        }
                                        <span>{data[3]}</span>
                                    </div>
                                } />
                                <TableData value={data[4]} />
                                <TableData value={data[5]} />
                                <TableData value={data[6]} />
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </Table>
    );
}








