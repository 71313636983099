import React from "react";
import "./register.css";
import CountryDropdown from "./infos_pays";
import { Link } from "react-router-dom";
import InputLabel from "../../Components/InputLabel";
import buttonImg from "../../images/nextAdd.png";
import TwoManImg from "../assets/twoMan.png";
import logo from "../assets/image/logo-colorful.png";
import { Col, Container, Row } from "react-bootstrap";

export default function RegisterParent() {
  return (
    <div className="parentLogin parentRegister">
      <Container className="loginParentContainer">
        <Row>
          <Col className="ColLoginParent " lg={6} md={6}>
            <div className="parentLoginLeftSection parentRegiterLeftSection parentLoginLeftSectionheight">
              <div className="logoImgCard">
                <img src={logo} />
              </div>
              <h1>S'inscrire</h1>
              <div className="parentLoginHeader">
                <p>Veuillez remplir le formulaire pour vous inscrire</p>
                <p>
                  Vous avez deja un compte ?
                  <span>
                    <Link to={"/parent/login"}>Se connecter</Link>
                  </span>
                </p>
              </div>

              <div className="parentLoginForm">
                <InputLabel
                  label={"Nom*"}
                  placeholder={"Entrez votre nom"}
                  name={"nom"}
                />
                <InputLabel
                  label={"Prénom*"}
                  placeholder={"Entrez votre prénom"}
                  name={"email"}
                />
                <InputLabel
                  label={"Email*"}
                  placeholder={"Entrez votre email"}
                  name={"email"}
                />
                
                <label className="inputIgnoredLabel" style={{ paddingBottom: "-5px" }}>
                  Numéro de téléphone*
                </label>
                <div className="phoneInput pt-0">
                  <CountryDropdown />
                  <InputLabel placeholder={"XXX-XXX-XXX"} name={"telephone"} />
                </div>
                <div className="composedInput">
                  <InputLabel
                    label={"Pays*"}
                    placeholder={"Entrez votre pays"}
                    name={"pays"}
                  />
                  <InputLabel
                    type="date"
                    label={"Date de naissance"}
                    name={"date"}
                  />
                </div>

                <InputLabel
                  type="password"
                  label={"Mot de passe*"}
                  placeholder={"Entrez votre vot de passe"}
                  name={"password"}
                />
                <InputLabel
                  type="password"
                  label={"Confirmation mot de passe*"}
                  placeholder={"Confirmez votre vot de passe"}
                  name={"passwordConfirm"}
                />

                <div className="rememberMeForgotPassword">
                  <div className="rememberMe">
                    <input type="checkbox" id="checkbox"></input>
                    <label htmlFor="checkbox">
                      J'accepte les termes et conditions
                    </label>
                  </div>
                </div>
                <button className="parentLoginFormButton">
                  <span>Se connecter</span>
                  <img src={buttonImg} />
                </button>
              </div>
            </div>
          </Col>

          <Col lg={6} md={6} className="rightImgLoginCol">
            <div className="rightSectionImgCard2">
              <img src={TwoManImg} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
