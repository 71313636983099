import Button from '../../../../Components/Button';
import AdvancedTextEditor from '../AdvancedTextEditor';

export function AccountLogin(){
  
    return(
      <main className="accountLogin">

        <AdvancedTextEditor defaultText={`<div className='accountLoginMainContent'>
  
          <div className="accountLoginMainSection">
            <h1 class='mainSectionTitle'>Détails de Connexion</h1>
            <p className='mainSectionContent'>
              <span>Bonjour Mr Client,</span>
              <p>
                <p>Un nouveau compte vous a été créé.</p>
                <p>Veuillez utiliser les informations suivantes pour vous connecter à votre tableau de bord: </p>
                <p>Lien tableau de bord: 'clientUrl' </p>
                <p>Email: client@gmail.com </p>
  
              </p>
              <p>Mot de passe: nouveauClient</p> 
              <p>Signature</p>
            </p>
          </div>
        </div> `}/>
        <div className='emailTemplateMainButton'>
          <Button value={'Sauvegarder'}/>
        </div>
      </main>
    )
  }