import React, { useContext, useState } from "react";

import { Link } from "react-router-dom";
import checktick from "../../images/check-tick.svg";
import listoption from "../../images/list-option.svg";
import "../../AdminModule/PriceStudent/style.css";
import { Col, Container, Row } from "react-bootstrap";

function Plans_dabonnement() {
    const [activeItem, setActiveItem] = useState('Mensuel');
  const handleItemClick = (newHeadings) => {
    setActiveItem(newHeadings); // Update active item
  };
    const [plans, setPlans] = useState([
        { id: 1, popular: false, price: "762 €",lastPrice:"800€", title: "PACK Essentiel",sousTitle:"Assistance Pré-Départ", features: ["AVI", "Assurance voyage et habitation", "Accueil aéroport", "Accompagnement inscription", "Attestation d’hébergement"] },
        { id: 2, popular: true, price: "850 €",lastPrice:"900 €", title: "pack Sécurité",sousTitle:"Accompagnement Étendu", features: ["AVI", "Assurance voyage et habitation", "Accueil aéroport", "Accompagnement inscription", "Conseil et orientation pour une année d'études","Attestation d’hébergement"] },
        { id: 3, popular: false, price: "1020€",lastPrice:"1300 €", title: "pack Succès",sousTitle:"Services Premium", features: ["AVI", "Attestation d’hébergement et recherche de logement","Assurance voyage et habitation","Réservation de billet", "Accueil aéroport", "Accompagnement inscription", "Conseil et orientation pour une année d'étude"] },
    
      ]);
  return (
    <>
      <section className="shop">
        <div className="top-navs">
          
            
              
              <div className="wrap-cover">
                <div className="tab-cover">
                <p className='pBuy'>Acheter un abonnement</p>
                  <p className='SouspBuy'>Choisissez le plan qui vous convient.</p>
                  <div className='divBtnPri'>
                    <span className='spanTab'>
                      <button className={`submenu ${activeItem === 'Mensuel' ? 'active' : ''}`} onClick={() => handleItemClick('Mensuel')}>
                        Mensuel
                      </button>
                      <button className={`submenu ${activeItem === 'Annuel' ? 'active' : ''}`} onClick={() => handleItemClick('Annuel')}>
                        Annuel
                      </button>
                    </span>
                  </div>
                </div>

                <Row className=" cardplan my-5">
                  {plans.map((plan) => (
                    <Col sm={12} md={5} lg={6} xl={5} xxl={4}   key={plan.id} className="ColPlanPrix">
                      <div
                        className={`plan-first ${plan.popular ? "populr " : ""}`}
                      >
                        
                        <div className="headerPlan">
                        {plan.popular && (
                          <div className="popular">
                            <span>Le plus populaire</span>
                          </div>
                        )}
                        
                        </div>
                       
                        <div className="d-flex justify-content-between align-items-center">
                          <span>
                          <span className="titPricePlan">
                          <p className="titPlan">{plan.price}</p>
                          <p className="LasttitPlan">{plan.lastPrice}</p>
                          </span>
                          <p className="sousTilPlan">{plan.title} </p>
                          </span>
                          
                        </div>
                        <p className="subTitlPlan">{plan.sousTitle}</p>
                        <ul className="ulPlanPrixHeigt">
                          {plan.features.map((feature, index) => (
                            <li key={index} className="liPlanPrix">
                              <span className="imgspanlist">
                                <img
                                  src={plan.popular ? listoption : checktick}
                                  alt="tick"
                                  className="imglistplan"
                                />
                                <p className="featPlan">{feature}</p>
                              </span>
                             
                            </li>
                          ))}
                        </ul>
                        <button className="parentPriceButton1">Choisir le forfait</button>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            
        </div>
      </section>
    
    </>
  );
}

export default Plans_dabonnement;