import React, { useEffect, useState } from 'react';
import './AudioCall.css'
import { BsRecordCircle } from 'react-icons/bs';
import { MdCall, MdCallEnd, MdZoomOutMap } from 'react-icons/md';
import Profil from '../../../images/AdminLayout/Ellipse 806.png'
import { SiAudiomack } from "react-icons/si";
import { AiFillAudio, AiFillMessage, AiOutlineAudioMuted } from 'react-icons/ai';
import { FaVideoSlash } from 'react-icons/fa6';
import { FaUserCircle } from 'react-icons/fa';

export default function AudioCall({setPopupText, setShowPopup, callText, setCallText, setCallStatus, callStatus, time, setTime}) {
    

    return (
        <div className="audioCallChat animParent">
            {
                (callStatus == 'voiceCall' || callStatus == 'inSmallView') &&
                <AudioCallFullView 
                    setPopupText = {setPopupText}
                    setShowPopup = {setShowPopup}
                    callText = {callText}
                    setCallText = {setCallText}
                    callStatus={callStatus}
                    setCallStatus={setCallStatus} 
                    time={time}
                    setTime = {setTime}
                />
            }
            {
                callStatus == 'inMessageMode' && <AudioCallMsgView setCallStatus={setCallStatus}/>
            }

        </div>
    );
}

//button permettant d'afficher des texte description de ses fonctionnalites 
export const IconButton = ({icon, label, bgColor, onClick, ...props}) => {

    const handleOnMouseOver = () => setDescription(label)
    const handleOnMouseLeave = () => setDescription(null)
    const [description, setDescription] = useState(undefined)

    return (
        <div onClick={onClick} style={{backgroundColor: bgColor && bgColor, color: 'white'}} onMouseOver={handleOnMouseOver} onMouseLeave={handleOnMouseLeave} className={`${!bgColor ? 'whiteColorSvg' : ''} iconButton`} {...props}>
            {icon}
            {
                description == label &&
                <div>{label}</div>
            }
        </div>
    )
}


// composant du mode plein/petit ecran de l'appel en fonction de callStatus
function AudioCallFullView ({setPopupText, setShowPopup, callText, setCallText, setCallStatus, callStatus, time, setTime}) {

    const [isUserMicMuted, setIsUserMicMuted] = useState(false)

    const handleCall = state => {
        if(state == undefined){
            setCallText('Appel terminé !')
            setTimeout(() => {
                setCallStatus(state)
                setTime({
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                })
                setCallText('En train de sonner...')
                setShowPopup(false)
            }, 3000);
        }

        else{
            setCallStatus(state)
        }
    
    }

    // defilement du composant
    const [position, setPosition] = useState({ x: 0, y: 0 }); // Position actuelle
    const [isDragging, setIsDragging] = useState(false); // État de déplacement
    const [offset, setOffset] = useState({ x: 0, y: 0 }); // Décalage du clic initial

    const handleMouseDown = (e) => {
        // Marque le début du déplacement
        if (callStatus == 'inSmallView') {
            setIsDragging(true);
        }

        setOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
    };

    // quand la souris se deplace
    const handleMouseMove = (e) => {
        if (!isDragging) return;

        setPosition({
            x: e.clientX - offset.x,
            y: e.clientY - offset.y,
        });
    };

    // Fin du déplacement
    const handleMouseUp = () => {
        setIsDragging(false);
    };

   
    
    return(
        <div
            className={`fullViewCallMode ${callStatus == 'inSmallView' && 'inSmallView'}`}
            style={{
                transform: callStatus == 'inSmallView' && `translate(${position.x}px, ${position.y}px)`,
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <header className="callChatHeader">
                <div className="timeRecorder">
                    <span className='dotRecorder'></span>
                    {time.hours > 0 && <span>{String(time.hours).padStart(2, '0')} : </span> }
                    <span>{String(time.minutes).padStart(2, '0')} : </span> 
                    <span>{String(time.seconds).padStart(2, '0')}</span>
                </div>
                <div className="headerOptions">
                    <IconButton icon={<BsRecordCircle/>} label={'Enregistrer l\'appel'}/> 
                    {
                        callStatus == 'voiceCall' && 
                        <IconButton onClick={()=> handleCall('inSmallView')} icon={<MdZoomOutMap/>} label={'Quitter le mode plein écran'}/> 

                    }
                    {
                        callStatus == 'inSmallView' && 
                        <IconButton onClick={()=> handleCall('voiceCall')} icon={<MdZoomOutMap/>} label={'Revenir sur le mode plein écran'}/> 

                    }
                </div>
            </header>

            <main className="audioChatMain">
                <div className="callState">{callText}</div>
                <div className="callUsers">
                    <div className={`speakingUser`}>
                        <div>
                            <section style={{background: `url('${Profil}')`, backgroundSize: 'cover'}}></section>
                            <img src={Profil} alt="profil" />
                            <div>
                                <SiAudiomack/>
                            </div>
                        </div>
                        <span>Vous</span>
                    </div>
                    {
                        (callText == 'Appel en cours...' || callText == 'Appel vidéo refusé !') &&
                        <div className={`listeningUser`}>
                            <div>
                                <FaUserCircle className='userWithoutProfil'/>
                                {/* <img src={Profil} alt="profil" /> */}
                                <div>
                                    <AiOutlineAudioMuted/>

                                </div>
                            </div>
                            <span>Ian Dooley</span>
                        </div>
                    }
                </div>
            </main>

            <footer className='audioChatFooter'>
                {
                    isUserMicMuted ?
                    <IconButton icon={<AiOutlineAudioMuted/>} label={'Activer le micro'} onClick={()=>setIsUserMicMuted(false)}/>
                    :
                    <IconButton icon={<AiFillAudio/>} label={'Désactiver le micro'} onClick={()=>setIsUserMicMuted(true)}/>

                }
                <IconButton
                    icon={<FaVideoSlash/>} 
                    label={'Passer en appel video'} 
                    bgColor={'#015192'} 
                    onClick={()=>{
                        setPopupText('Ian Dooley demande à passer en appel vidéo')
                        setShowPopup(true)
                    }}/>
                <IconButton icon={<AiFillMessage/>} label={'Envoyer un message'} onClick={()=>handleCall('inMessageMode')}/>
                <IconButton icon={<MdCallEnd/>} label={'Terminer l\'appel'} bgColor = {'#E72C79'} onClick={()=>handleCall(undefined)}/>
            </footer>
        </div>
    )
}

// composant du mode message
function AudioCallMsgView ({setCallStatus}) {
    // defilement du composant
    const [position, setPosition] = useState({ x: 0, y: 0 }); // Position actuelle
    const [isDragging, setIsDragging] = useState(false); // État de déplacement
    const [offset, setOffset] = useState({ x: 0, y: 0 }); // Décalage du clic initial

    const handleMouseDown = (e) => {
        // Marque le début du déplacement
            setIsDragging(true);

        setOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
    };

    // quand la souris se deplace
    const handleMouseMove = (e) => {
        if (!isDragging) return;

        setPosition({
            x: e.clientX - offset.x,
            y: e.clientY - offset.y,
        });
    };

    // Fin du déplacement
    const handleMouseUp = () => {
        setIsDragging(false);
    };
    
    return(
        <div 
            className="msgViewMode"
            style={{
                transform: `translate(${position.x}px, ${position.y}px)`,
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <IconButton icon={<MdCall />} label={'Revenir en plein écran'} onClick={() => setCallStatus('voiceCall')}/>
        </div>

    )
}

//composant du mode petit ecran