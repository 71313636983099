import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Import Parent Components
import Index from "../Parent/Container/Index";
import ProfileParentModule from "../Parent/Profile_Parent_Module/ProfileParent";
// import ContactParent from "../Parent/Contact/Contact";
import PaymentHistoricParent from "../Parent/PaymentHistoric/PaymentHistoric";
import PrestationsServicesParent from "../Parent/PrestationServices/PrestationsServices";
import ParrainageParent from "../Parent/Parrainage/Parrainage";
import ParentFAQ from "../Parent/Faq/ParentFAQ";
import Content from "../Parent/Dashboard/content";
import LoginParent from "../Parent/Login/login";
import RegisterParent from "../Parent/Register/register";
import ParentMessage from "../Parent/Message/ParentMessage";
import ParentPrice from "../Parent/Price/ParentPrice";
import ProfilParent from "../Parent/Profil/Profil";
import ParentNotification from "../Parent/Notification/NorificationParent";
import ParentDocumentationRessource from "../Parent/DocumentationRessource/Documentation";
import ParametreParent from "../Parent/Parametre/profile";

// Import Student Components
import IndexStu from "../StudentModule/Container/Index";
import Dashboard from "../StudentModule/Dashboard/Dashboard";
import Profil from "../StudentModule/Profil/Profil";
import PrestationsServicesStu from "../StudentModule/PrestationServices/PrestationsServices";
import AttestationVirement from "../StudentModule/AttestationVirement/AttestationVirement";
import AssurancesCompletes from "../StudentModule/AssurancesCompletes/AssurancesCompletes";
import Login from "../StudentModule/Login/Login";
import Register from "../StudentModule/Register/Register";
import Prameter from "../StudentModule/parametre/profile";
// // import Contact from "../StudentModule/contact/contact";
import Chat from "../StudentModule/chat/chat";
import ActiveConversation from "../StudentModule/helpandsupport/help";
import Reasons from "../StudentModule/helpandsupport/reasons";
import VerificationEmail from "../StudentModule/VerificationEmail/VerificationEmail";
import StudentMessage from "../StudentModule/Message/StudentMessage";
import PaymentHistoric from "../StudentModule/PaymentHistoric/PaymentHistoric";
import PriceStudent from "../StudentModule/prix/Price";
import DocumentationRessource from "../StudentModule/DocumentationRessource/Documentation";
import Parrainage from "../StudentModule/Parrainage/Parrainage";
import StudentFAQ from "../StudentModule/Faq/StudentFAQ";
import ServiceBilleterie from "../StudentModule/PrestationServices/ServiceBilleterie/ServiceBilleterie";
import RechercheLogement from "../StudentModule/PrestationServices/RechercheLogement/RechercheLogement";

// Import Admin Components
import IndexAdmin from "../AdminModule/Container/Index";
import Tableau_boardAdm from "../AdminModule/Dashboard_Admin/Tableau_boardAdm";
import Student from "../AdminModule/Student_Admin/Student";
import Partner from "../AdminModule/Partner_Adm/Partner";
import Parent from "../AdminModule/Parents_Adm/Parent";
import Faq from "../AdminModule/Faq_Adm/AdminFAQ";
import Price from "../AdminModule/Price_Adm/Price";
import Avi from "../AdminModule/Avi_Adm/Avi";
import SupportTicket from "../AdminModule/SupportTicket_Adm/SupportTicket";
import Settings from "../AdminModule/Settings_Adm/Settings";
import StudentDetail from "../AdminModule/Student_Admin/StudentDetails/StudentDetail";
import StudentAdd from "../AdminModule/Student_Admin/StudentDetails/StudentAdd";
import StudentEdit from "../AdminModule/Student_Admin/StudentDetails/StudentEdit";
import ParentAdd from "../AdminModule/Parents_Adm/Parent/ParentAdd";
import ParentUpdate from "../AdminModule/Parents_Adm/Parent/ParentUpdate";
import ParentTable from "../AdminModule/Parents_Adm/ParentTable";
import PartnerUpdate from "../AdminModule/Partner_Adm/PartnerEdit";
// import ContactAdmin from "../AdminModule/Contact/Contact";
import PriceAdmin from "../AdminModule/PriceStudent/Price";
import AdminMessage from "../AdminModule/Message/AdminMessage";
import AdminAVI from "../AdminModule/Avi_Adm/AdminAVI";
import EditAccountAVI from "../AdminModule/Avi_Adm/EditAccountAVI/EditAccountAVI";
import AdminSupportTicket from "../AdminModule/SupportTicket_Adm/AdminSupportTicket";
import GeneralDetails from "../AdminModule/Settings_Adm/GeneralDetails/GeneralDetails";
import EmailTemplate from "../AdminModule/Settings_Adm/EmailTemplate/EmailTemplate";
// import AppUpdate from "../AdminModule/Settings_Adm/AppUpdate/AppUpdate";
import ResetPassword from "../AdminModule/Settings_Adm/ResetPassword/ResetPassword";
// import Notifications from "../AdminModule/Settings_Adm/Notifications/Notifications";
import LoginAdmin from "../AdminModule/Login_Admin/Login";
import StudentNotification from "../StudentModule/notification/notifications";
import Contact from "../Components/Contact/Contact"
// Guarded Routes
import PrivateRoute from "./Guard";
import ParameterAdmin from "../AdminModule/parametre/profile"
import AdminAdd from '../AdminModule/AdminDetails/Parent/ParentAdd';
import AdminUpdate from '../AdminModule/AdminDetails/Parent/ParentUpdate';
import AdminTable from '../AdminModule/AdminDetails/AdminTable';
import AdminNotification from "../AdminModule/Notification/Notification";

import RenitialiserMotPasse from "../Components/RenitialiserMotPasse/RenitialiserMotPasse";
import VerificationCode from "../Components/VerificationCode/VerificationCode";
import NotificationCreationCompte from "../Components/NotificationCreationCompte/NotificationCreationCompte";
import AdminPrice from "../AdminModule/PriceStudent/AdminPrice";
import StudentPrice from "../StudentModule/prix/StudentPrice";


import PartnerAdd from "../AdminModule/Partner_Adm/PartnerAdd";
import Transactions from "../AdminModule/Transactions/Transactions";
import TransactionDetails from "../AdminModule/Transactions/TransactionDetails";
import ToReferSomeone from "../Components/Parrainage/ToReferSomeone/ToReferSomeone";
import TransactionDashboard from "../AdminModule/Transactions/TransactionDashboard";
import TransactionRefund from "../AdminModule/Transactions/TransactionRefund";
import ParentAttestationVirement from "../Parent/PrestationServices/AttestationVirement/AttestationVirement";
import ParentAssurancesCompletes from "../Parent/PrestationServices/AssuranceCompletes/AssurancesCompletes";
import ParentRechercheLogement from "../Parent/PrestationServices/RechercheLogement/RechercheLogement";
import ParentServiceBilleterie from "../Parent/PrestationServices/ServiceBilleterie/ServiceBilleterie";
function App() {
  return (
    <>
      <Router>
        <Routes>
{/* Autres routes */}

        <Route path="/renitialiserMotDePasse" element={<RenitialiserMotPasse />} />
        <Route path="/verificationEmail" element={<VerificationEmail />} />
          <Route path="/verificationCode" element={<VerificationCode />} />
          <Route path="/NotificationCreationCompte" element={<NotificationCreationCompte />} />

          {/* Parent Routes */}
          <Route path="/parent/login" element={<LoginParent />} />
          <Route path="/parent/register" element={<RegisterParent />} />
          <Route path="/parent" element={<Index />}>
            <Route path="" element={<Content />} />
            <Route path="profile" element={<ProfileParentModule />} />
            <Route path="contact" element={<Contact />} />
            <Route path="prix" element={<ParentPrice />} />
            <Route path="messages" element={<ParentMessage />} />
            <Route path="notification" element={<ParentNotification />} />
            <Route path="profile" element={<ProfilParent />} />
            <Route path="parametre" element={<ParametreParent />} />
            <Route path="historique-de-paiement" element={<PaymentHistoricParent />} />
            <Route path="prestations-de-service" element={<PrestationsServicesParent />} />
            <Route path="prestations-de-service/attestation-de-virement" element={<ParentAttestationVirement />} />
            <Route path="prestations-de-service/assurance-completes" element={<ParentAssurancesCompletes />} />
            <Route path="prestations-de-service/recherche-de-logement" element={<ParentRechercheLogement />} />
            <Route path="prestations-de-service/services-de-billeterie" element={<ParentServiceBilleterie />} />
            <Route path="ressources-et-documentation" element={<ParentDocumentationRessource />} />
            <Route path="parrainage" element={<ParrainageParent />} />
            <Route path="parrainage/parrainer" element={<ToReferSomeone />} />
            <Route path="faq" element={<ParentFAQ />} />
          </Route>

          {/* Student Routes */}
          <Route path="/etudiant/login" element={<Login />} />
          <Route path="/etudiant/register" element={<Register />} />
         
          <Route
            path="/etudiant"
            element={
              // <PrivateRoute>
                <IndexStu />
              // </PrivateRoute>
            }
          >
            <Route path="" element={<Dashboard />} />
            <Route path="profile" element={<Profil />} />
            <Route path="prestations-de-service" element={<PrestationsServicesStu />} />
            <Route
              path="prestations-de-service/attestation-de-virement"
              element={<AttestationVirement />}
            />
            <Route
              path="prestations-de-service/assurance-completes"
              element={<AssurancesCompletes />}
            />
            <Route
              path="prestations-de-service/services-de-billeterie"
              element={<ServiceBilleterie />}
            />
            <Route
              path="prestations-de-service/recherche-de-logement"
              element={<RechercheLogement />}
            />
            <Route path="parametre" element={<Prameter />} />
            <Route path="contact" element={<Contact />} />
            <Route path="parrainage" element={<Parrainage />} />
            <Route path="parrainage/parrainer" element={<ToReferSomeone />} />
            <Route path="historique-de-paiement" element={<PaymentHistoric />} />
            <Route path="notifications" element={<StudentNotification />} />
            <Route path="messages" element={<StudentMessage />} />
            <Route path="support-technique" element={<Reasons />} />
            <Route path="support-technique/help" element={<ActiveConversation />} />
            <Route path="support-technique/chat" element={<Chat />} />
            <Route path="faq" element={<StudentFAQ />} />
            <Route path="prix" element={<StudentPrice />} />
            <Route path="ressources-et-documentation" element={<DocumentationRessource />} />
          </Route>

          {/* Admin Routes */}
          
          <Route exact path="/administrateur/login" element={<LoginAdmin/>}/>
              <Route
                  path="/administrateur/"
                  element={<IndexAdmin />}
                >
                  <Route index element={<Tableau_boardAdm />} />
              </Route>
              <Route
                  path="/administrateur/profile"
                  element={<IndexAdmin />}
                >
                  <Route index element={<ParameterAdmin/>} />
              </Route>
              {/* <Route
                  path="/administrateur/notification"
                  element={<IndexAdmin />}
                >
                  <Route index element={<NotificationListADM/>} />
              </Route> */}
              {/* <Route 
                path="/administrateur/contact"
                element={<IndexAdmin/>}>
                  <Route index element={<ContactParent/>}/>
                </Route> */}
              <Route
                  path="/administrateur/etudiant/*"
                  element={<IndexAdmin />}
                >
                  <Route path='' element={<Student />} />
                  <Route path='details' element={<StudentDetail/>}/>
                  <Route path='ajouter' element={<StudentAdd/>}/>
                  <Route path='modifier' element={<StudentEdit/>}/>
              </Route>

              <Route
                  path="/administrateur/partenaire/*"
                  element={<IndexAdmin />}
                >
                  <Route path='' element={<Partner />} />
                  <Route path='ajouter' element={<PartnerAdd />} />
                  <Route path='modifier' element={<PartnerUpdate/>}/>
              </Route>
              <Route
                  path="/administrateur/parents/*"
                  element={<IndexAdmin />}
                >
                  <Route path='' element={<ParentTable />} />
                  <Route path='details' element={<Parent />} />
                  <Route path='ajouter' element={<ParentAdd/>}/>
                  <Route path='modifier' element={<ParentUpdate/>}/>
              </Route>
              <Route
                  path="/administrateur/administrateurs/*"
                  element={<IndexAdmin />}
                >
                  <Route path='' element={<AdminTable />} />
                  
                  <Route path='ajouter' element={<AdminAdd/>}/>
                  <Route path='modifier' element={<AdminUpdate/>}/>
              </Route>
              <Route
                  path="/administrateur/transactions/*"
                  element={<IndexAdmin />}
                >
                  <Route index element={<Transactions />} />
                  <Route path={'dashboard'}  element={<TransactionDashboard />} />
                  <Route path={'remboursements'}  element={<TransactionRefund />} />
                  <Route path={'remboursements/details-remboursement/:id'} element={<TransactionDetails />} />
              </Route>
              <Route
                  path="/administrateur/faq/"
                  element={<IndexAdmin />}
                >
                  <Route index element={<Faq />} />
              </Route>
              {/* <Route
                  path="/administrateur/prix/"
                  element={<IndexAdmin />}
                >
                  <Route index element={<Price/>} />
              </Route> */}
              <Route
                  path="/administrateur/avi/"
                  element={<IndexAdmin />}
                >
                  <Route index element={<Avi/>} />
                  <Route path='editer-compte' element={<EditAccountAVI/>} />
              </Route>
              <Route
                  path="/administrateur/support-ticket/"
                  element={<IndexAdmin />}
                >
                  <Route path='detail' element={<SupportTicket/>}/>
                  <Route path="" element={<AdminSupportTicket/>} />
              </Route>
              {/* <Route
                  path="/administrateur/parameteres/"
                  element={<IndexAdmin />}
                >
                  <Route index element={<Settings/>} />
              </Route> */}
              
              <Route
                  path="/administrateur/contact/"
                  element={<IndexAdmin />}
                >
                  <Route index element={<Contact/>} />
              </Route>
              <Route
                  path="/administrateur/prix/"
                  element={<IndexAdmin />}
                >
                  <Route index element={<AdminPrice/>} />
                  {/* <Route path="modifier/:slug" element={<AdminPrice/>} /> */}
              </Route>
              <Route
                  path="/administrateur/messages/"
                  element={<IndexAdmin />}
                >
                  <Route index element={<AdminMessage/>} />
              </Route>
              <Route
                  path="/administrateur/notification"
                  element={<IndexAdmin />}
                >
                  <Route index element={<AdminNotification/>} />
              </Route>
              <Route
                  path="/administrateur/avi/*"
                  element={<IndexAdmin />}
                >
                  <Route path='detail' element={<AdminAVI />} />
                  <Route path='modifier' element={<EditAccountAVI/>}/>
              </Route>
              <Route
                  path="/administrateur/parametres/*"
                  element={<IndexAdmin />}
                >
                <Route path='details-generals' element={<GeneralDetails/>}/>
                  <Route path='modeles-email/*' element={<EmailTemplate/>}/>
                  {/* <Route path='mis-a-jour-app' element={<AppUpdate/>}/> */}
                  <Route path='reinitialisation-mot-de-passe' element={<ResetPassword/>}/>
                  {/* <Route path='notifications' element={<Notifications/>}/> */}
              </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
