import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import '../Dash.css'
import base from "../../../images/Base.png"
import side3 from "../../../images/side3.png"
function Chart3() {
   

  const options = {
    chart: {
      height: "89px",
      width:"89px",
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "50%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: 0,
            show: true,
            color: "#007A9E", // Custom color
            fontSize: "12px", // Custom font size
            style: {
              fontFamily: 'Poppins', // Custom font family
              fontStyle: 'normal',
              fontWeight: 700, // Bold text
              lineHeight: '50px',
            },
          },
          value: {
            
            show: false,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'dark',
        type: "horizontal",
        shadeIntensity: 1,
        gradientToColors: ["#015192"], // End color
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: "#00A5BC", // Start color
            opacity: 1
          },
          {
            offset: 100,
            color: "#015192", // End color
            opacity: 1
          }
        ]
      }
    },
    stroke: {
      lineCap: "round",
    },
    labels: [`43498`],
  };

  const series = [85];
  const [sizeImg,SetSizeImg]=useState(180)

  // useEffect(() => {
  //   const updateSize = () => {
  //     if (window.innerWidth < 500) {
  //       SetSizeImg(150);
  //     } else {
  //       SetSizeImg(200);
  //     }
  //   };

  //   // Appelle la fonction une première fois pour régler la taille de l'image
  //   updateSize();

  //   // Ajoute un écouteur d'événements pour les redimensionnements de la fenêtre
  //   window.addEventListener('resize', updateSize);

    // Nettoyage de l'écouteur lors du démontage du composant
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);
  
  return (
    <div className='chartdiv'>
      
        <div className='CHARTT'>
            <img src={side3} className='sideChart'/>
            <Chart
              options={options}
              series={series}
              type="radialBar"
              height={sizeImg}
              width={sizeImg}
              // Set height to 200px
            />
          </div>
          <div className='chartT2'>
            <p className='titleCh'>Nombre total de Partenaires</p>
            <div className='sousChart'>
                <p className='pour'>22.8% </p>
                <img src={base} className='porImg'/>
            </div>
          </div>
     
    </div>
  )
}

export default Chart3

