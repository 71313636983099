import React from "react";
import "./register.css";
import CountryDropdown from "./infos_pays";
import { Link } from "react-router-dom";
import InputLabel from "../../Components/InputLabel";
import buttonImg from "../../images/nextAdd.png";
import TwoManImg from "../assets/twoMan.png";
import logo from "../assets/image/logo-colorful.png";
import { Col, Container, Row } from "react-bootstrap";
import CustomCountrySelectAdmin from "../../Layout/CountrySelect";
import { useState } from "react";
import countries from '../../Layout/COUNTRY.json'
import SelectLabel from "../../Components/SelectLabel";

export default function RegisterParent() {

  const [showPassword, setShowPassword]=useState({
    password: false,
    passwordConfirm: false
  });

  const [data, setData] = useState({
    firstname: '', 
    lastname : '',
    email: '',
    phoneNumber: '',
    codeCountry: '+33',
    country: '',
    birthDate: '',
    password: '',
    passwordConfirm: ''

  })

  const openEye=<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
  const closedEye=<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
  
  function handleCodeCountry(code){
    setData(data=>({
      ...data,
      ['codeCountry']: code
    }))
}

  const togglePasswordVisibility = (key) => {
    setShowPassword(password =>({
      ...password,
      [key]: !password[key]
    }));
  };

  const handleDataChange = e => {
    setData(data=>({
      ...data,
      [e.target.name]: e.target.value
    }))
  }

  

  return (
    <div className="parentLogin parentRegister">
      <Container className="loginParentContainer">
          <Col className="ColLoginParent " lg={6} md={6}>
            <div className="parentLoginLeftSection parentRegiterLeftSection parentLoginLeftSectionheight">
              <div className="logoImgCard">
                <img src={logo} />
              </div>
              <h1>S'inscrire</h1>
              <div className="parentLoginHeader">
                <p>Veuillez remplir le formulaire pour vous inscrire</p>
                <p>
                  Vous avez deja un compte ?
                  <span>
                    <Link to={"/parent/login"}>Se connecter</Link>
                  </span>
                </p>
              </div>

              <div className="parentLoginForm">
                <InputLabel
                  label={"Nom*"}
                  placeholder={"Entrez votre nom"}
                  name={"lastname"}
                  onChange={handleDataChange}
                />
                <InputLabel
                  label={"Prénom*"}
                  placeholder={"Entrez votre prénom"}
                  name={"firstname"}
                  onChange={handleDataChange}

                />
                <InputLabel
                  label={"Email*"}
                  placeholder={"Entrez votre email"}
                  name={"email"}
                  onChange={handleDataChange}

                />
                
                <div className="phoneInput pt-0">
                  <label className="inputIgnoredLabel" style={{ paddingBottom: "-5px" }}>
                    Numéro de téléphone*
                  </label>
                  <CustomCountrySelectAdmin codeCountry={handleCodeCountry} />
                  <InputLabel placeholder={"XXX-XXX-XXX"} name={"phoneNumber"} />
                </div>
                <div className="composedInput">
                  <SelectLabel
                    label={"Pays*"}
                    name={"country"}
                  onChange={handleDataChange}

                  >
                    <option>Selectionnez votre pays</option>
                    {
                      countries.map(country=>(
                        <option key={country} value={country}>{country}</option>
                      ))
                    }
                  </SelectLabel>
                  <InputLabel
                    type="date"
                    label={"Date de naissance"}
                    name={"birthDate"}
                  onChange={handleDataChange}

                  />
                </div>

                <div className="passwordContainer">

                  <InputLabel
                    type={showPassword.password ? "text" : "password"} 
                    label={"Mot de passe*"}
                    placeholder={"Entrez votre mot de passe"}
                    name={"password"}
                    onChange={handleDataChange}
                    value= {data.password}

                  />
                  <p onClick={()=>togglePasswordVisibility('password')} className="eye-password-student"  style={{
                      position: "absolute",
                      right: "0.75rem", 
                      top: "63%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#888888"
                    }} >
                    {showPassword.password ? closedEye : openEye} 
                  </p>
                </div>
                <div className="passwordContainer">
                  <InputLabel
                    type={showPassword.passwordConfirm ? "text" : "password"} 
                    label={"Confirmation de mot de passe*"}
                    placeholder={"Confirmez votre mot de passe"}
                    name={"passwordConfirm"}
                    onChange={handleDataChange}
                    value= {data.passwordConfirm}
                  />
                  
                  <p onClick={()=>togglePasswordVisibility('passwordConfirm')} className="eye-password-student"  style={{
                      position: "absolute",
                      right: "0.75rem", 
                      top: "63%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#888888"
                    }} >
                    {showPassword.confirmPassword ? closedEye : openEye} 
                  </p>
                </div>

                <div className="rememberMeForgotPassword">
                  <div className="rememberMe">
                    <input type="checkbox" id="checkbox"></input>
                    <label htmlFor="checkbox">
                      J'accepte les termes et conditions
                    </label>
                  </div>
                </div>
                <button className="parentLoginFormButton">
                  <span>Se connecter</span>
                  <img src={buttonImg} />
                </button>
              </div>
            </div>
          </Col>

          <Col lg={6} md={6} className="rightImgLoginCol">
            <div className="rightSectionImgCard2">
              <img src={TwoManImg} />
            </div>
          </Col>
      </Container>
    </div>
  );
}
