import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Flag from "react-world-flags";
import "bootstrap/dist/css/bootstrap.min.css";
import { countryList } from "./Liste_pays"; // Assurez-vous que le chemin d'import est correct
import "./register.css";

const CountryDropdown = () => {
  const [selectedCountry, setSelectedCountry] = useState({
    country: "Nigeria",
    code: "NG",
    dialCode: "+234",
  });

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className="select">
        {/* Affichage du drapeau avec le code téléphonique sélectionné */}
        <Flag code={selectedCountry.code} style={{ width: "15px", marginRight: "5px" }} />
        {selectedCountry.dialCode}
      </Dropdown.Toggle>

      {/* Appliquer le style de défilement et fixer la hauteur */}
      <Dropdown.Menu
        style={{
          maxHeight: "200px", // Hauteur maximale pour le menu déroulant
          overflowY: "auto",   // Permet le défilement vertical
        }}
      >
        {/* Liste des pays */}
        {countryList.map((country) => (
          <Dropdown.Item key={country.code} onClick={() => handleCountrySelect(country)}>
            <Flag code={country.code} style={{ width: "15px", marginRight: "5px" }} />
            {country.dialCode}    {country.country}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CountryDropdown;
