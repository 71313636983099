import './MessageSidebar.css'
import DooleyProfil from '../../../images/AdminLayout/Ellipse 806.png'
import Search from '../../../images/Message/Search.png'

export default function MessageSidebar({isMobile, setisMobile}){

    function MessageListItem({profil, sender, date, content, count, focus = false}){
        return(
            <div onClick={()=>setisMobile(false)} className={`messageListItem ${focus ? 'messageUnread' : ''}`}>
                <img src={profil} alt="profil" className='messageListItemProfil'/>
                <div className="messageItemInfos">
                    <div className="messageItemDateName">
                        <h6 className="messageItemName">
                            {sender}
                        </h6>
                        <p className="messageItemDate">
                            {date}
                        </p>
                    </div>
                    <div className="messageItemContentCount">
                        <p className="messageItemContent">
                            {content}
                        </p>
                        {
                            count && 
                            <h6 className="messageItemCount">
                                {count}
                            </h6>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return(
        <aside className={`${isMobile && 'displayMessageSidebar'} messageSidebar`}>
            <div className='messageSidebarSearch'>
                <img src={Search} alt="search" className='searchIcon'/>
                <input type="text" placeholder="Rechercher..." className='inputMessageSidebar'/>
            </div>
            <div className="messageListItems">
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'2 minutes'} count={2} />
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'7 minutes'} count={2} focus={true}/>
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'10 minutes'} count={2}/>
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'15 minutes'} />
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'28 minutes'}/>
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'2 heures'}/>
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'15 minutes'} />
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'28 minutes'}/>
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'2 heures'}/>
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'15 minutes'} />
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'28 minutes'}/>
                <MessageListItem profil={DooleyProfil} sender={'Ian Dooley'} content={'Merci.'} date={'2 heures'}/>
            </div>
        </aside>
    )
}

