import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Chart4 = () => {
  const [series] = React.useState([
    {
      name: 'Partenaire',
      data: [31, 40, 28, 51, 42, 50, 60,31, 40, 28, 51, 42, 50]
    },
    {
      name: 'Etudiants',
      data: [11, 32, 45, 32, 34, 52, 41,11, 32, 45, 32, 34, 52]
    },
    {
      name: 'Parents',
      data: [20, 10, 40, 10, 80, 30, 50,20, 10, 40, 10, 80, 30]
    }
  ]);

  const [options] = React.useState({
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false // Hide data point labels
    },
    stroke: {
      curve: 'smooth',
      width: [3, 3, 3], // Line width for all series
      colors: ['#008FFB', '#01E396', '#FEB019'] // Line colors
    },
    fill: {
      colors: ['#008FFB', '#01E396', '#FEB019'], // Fill colors
      type: 'gradient', // Optional gradient fill
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#008FFB', '#01E396', '#FEB019'], // Gradient colors
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0.5,
        stops: [0, 100]
      }
    },
    xaxis: {
      categories: [
        "2024-01-01T00:00:00.000Z", // Janvier
        "2024-02-01T00:00:00.000Z", // Février
        "2024-03-01T00:00:00.000Z", // Mars
        "2024-04-01T00:00:00.000Z", // Avril
        "2024-05-01T00:00:00.000Z", // Mai
        "2024-06-01T00:00:00.000Z", // Juin
        "2024-07-01T00:00:00.000Z", // Juillet
        "2024-08-01T00:00:00.000Z", // Août
        "2024-09-01T00:00:00.000Z", // Septembre
        "2024-10-01T00:00:00.000Z", // Octobre
        "2024-11-01T00:00:00.000Z", // Novembre
        "2024-12-01T00:00:00.000Z"  // Décembre
      ],
      labels: {
        formatter: function(value) {
          const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const date = new Date(value);
          return monthNames[date.getMonth()]; // Return month abbreviation
        }
      }
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5, // Number of ticks
      labels: {
        formatter: function(value) {
          return `${value}`; // Ensure labels show as whole numbers
        }
      }
    },
    tooltip: {
      x: {
        format: 'MMM yyyy' // Tooltip will show the month and year (e.g., Jan 2024)
      }
    }
  });

  return (
    <div>
      <ReactApexChart options={options} series={series} type="area" height={260} />
    </div>
  );
}

export default Chart4;
