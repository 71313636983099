import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import './AdvancedTextEditor.css';
import 'react-quill/dist/quill.snow.css'; // Style par défaut de Quill
// import { Tooltip, Button } from '@mui/material';
import Undo  from '../../../images/AdminLayout/Sidebar/Settings/ic_undo.png';
import Redo  from '../../../images/AdminLayout/Sidebar/Settings/menu-bar__button.png';
import HtmlCode from '../../../images/AdminLayout/Sidebar/Settings/menu-bar__button-1.png';

// Modules pour Quill.js
const modules = {
  toolbar: {
    container: [
      ['undo', 'redo', 'html'],
      [{ 'font': [] }], // Menu pour les polices
      [{ 'header': [1, 2, false] }], // Menu déroulant pour H1, H2, Normal Text
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }],
      ['link', 'image', 'blockquote', 'code-block'],
    ],
    handlers: {
      undo: function () {
        this.quill.history.undo();
      },
      redo: function () {
        this.quill.history.redo();
      },
    },
  },
  history: {
    delay: 1000,
    maxStack: 50,
    userOnly: true,
  },
};

const formats = [
  'font', // Polices
  'header', 'list', 'bullet',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background', 'align',
  'link', 'image', 'blockquote', 'code-block'
];

function AdvancedTextEditor({defaultText}) {
  const [editorHtml, setEditorHtml] = useState(defaultText || ''); // Stockage du contenu de l'éditeur
  const [showHTML, setShowHtml] = useState(false); // Toggle pour afficher le HTML
  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      const toolbar = quillRef.current.getEditor().getModule('toolbar');
      toolbar.addHandler('undo', () => {
        quillRef.current.getEditor().history.undo();
      });
      toolbar.addHandler('redo', () => {
        quillRef.current.getEditor().history.redo();
      });
    }
  }, []);

  const handleChange = (html) => {
    setEditorHtml(html); // Mise à jour du contenu de l'éditeur
  };

  return (
    <div className='advancedEditor'>
      {/* <div id="toolbar">
        <Tooltip title="Undo">
          <Button onClick={() => quillRef.current.getEditor().history.undo()}>Undo</Button>
        </Tooltip>
        <Tooltip title="Redo">
          <Button onClick={() => quillRef.current.getEditor().history.redo()}>Redo</Button>
        </Tooltip>
      </div> */}

      <ReactQuill
        ref={quillRef}
        value={editorHtml} // Affiche le contenu formaté
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder="Tapez quelque chose..."
        theme="snow"
      />

      {showHTML && (
        <textarea onChange={(e) => handleChange(e.target.value)} className='htmlCodeModeTextarea'>
          {editorHtml}
        </textarea>
      )}

    
        <button className='undo-button' onClick={() => quillRef.current.getEditor().history.undo()}>
            <img src={Undo}/>
          </button>
        <button className='redo-button'nonClick={() => quillRef.current.getEditor().history.redo()}>
            <img src={Redo} />
        </button>
        <button className='show-html-button' onClick={() => setShowHtml(!showHTML)}>
          <img src={HtmlCode}/>
        </button>
    </div>
  );
}

export default AdvancedTextEditor;
