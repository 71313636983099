import React from 'react'

function MailSvg() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
  <path fill="url(#a)" d="M13.333 2.667H2.667c-.734 0-1.327.6-1.327 1.333l-.007 8c0 .733.6 1.333 1.334 1.333h10.666c.734 0 1.334-.6 1.334-1.333V4c0-.733-.6-1.333-1.334-1.333Zm0 2.666L8 8.666 2.667 5.333V4L8 7.333 13.333 4v1.333Z"/>
  <defs>
    <linearGradient id="a" x1="-.009" x2="14.392" y1="1.849" y2="9.558" gradientUnits="userSpaceOnUse">
      <stop stop-color="#015192"/>
      <stop offset="1" stop-color="#00A5BC"/>
    </linearGradient>
  </defs>
</svg>

  )
}

export default MailSvg
