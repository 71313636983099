import React, { useState, useContext } from 'react';
import { PlansContext } from './ParentPrix';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.css';

function Plan_modify() {
  const { updatePlan } = useContext(PlansContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [popular, setPopular] = useState(state?.popular || false);
  const [title, setTitle] = useState(state?.title || '');
  const [price, setPrice] = useState(state?.price || '');
  const [features, setFeatures] = useState(state?.features || []); // Fallback to empty array if undefined
const [lastPrice,setLastPrice]=useState(state?.price || '')
  const handleSave = () => {
    const updatedPlan = {
      id: state.id,
      title,
      price,
      lastPrice,
      popular,
      features,
    };

    updatePlan(updatedPlan);
    navigate(-1);
  };

  const handleFeatureChange = (index, newValue) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = newValue;
    setFeatures(updatedFeatures);
  };

  const addFeature = () => {
    setFeatures([...features, ""]); // Add an empty string as a new feature
  };

  const removeFeature = (index) => {
    const updatedFeatures = features.filter((_, i) => i !== index); // Remove feature at index
    setFeatures(updatedFeatures);
  };

  return (
    <section className='plan-modify'>
      <div className='modify-wrap container-fluid'>
        <div className='wrapping'>
          <div className='text-end'>
            <button type="button" onClick={() => navigate(-1)} className="btnSupPlan1">
              Retour
            </button>
          </div>
          <div className='formPlan'>
            <div className="row">
              <div className="col">
                <input 
                  type="text" 
                  className="form-control" 
                  value={price} 
                  onChange={(e) => setPrice(e.target.value)} 
                  placeholder="Prix" 
                  aria-label="Prix" 
                />
              </div>
              <div className="col">
                <input 
                  type="text" 
                  className="form-control" 
                  value={title} 
                  onChange={(e) => setTitle(e.target.value)} 
                  placeholder="Titre du plan" 
                  aria-label="Titre du plan" 
                />
                
                
              </div>
              
            </div>
                <div>
                <input 
                      type="text" 
                      className=" inpPlan " 
                      
                      onChange={(e) => setLastPrice(e.target.value)} 
                      placeholder={`Ancien Prix`} 
                    />
                </div>
            <div className="mb-3">
              {features.length > 0 ? (
                features.map((feature, index) => (
                  <div key={index} className="d-flex divInmPlan mb-2">
                    <input 
                      type="text" 
                      className=" inpPlan " 
                      value={feature} 
                      onChange={(e) => handleFeatureChange(index, e.target.value)} 
                      placeholder={`Feature ${index + 1}`} 
                    />
                    <button 
                      type="button" 
                      className=" btnSupPlan1" 
                      onClick={() => removeFeature(index)}
                    >
                      Supprimer
                    </button>
                  </div>
                ))
              ) : (
                <p>Aucun feature n'est disponible pour le plan sélectionné.</p>
              )}
              <label className="d-flex  align-items-center">
                <input
                  type="checkbox"
                  className="me-2 checkboxPlan"
                  checked={popular}
                  onChange={(e) => setPopular(e.target.checked )}
                />
                <span className="plusPop">Le plus populaire</span>
              </label>
              <button 
                type="button" 
                className="ajouterFeature" 
                onClick={addFeature}
              >
                + Ajouter un feature
              </button>
              
            </div>
            <button type="button" className="ajouterFeature" onClick={handleSave}>
              Sauvegarder
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Plan_modify;