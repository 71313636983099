import { Container } from "react-bootstrap"
import Parrainage from "../../Components/Parrainage/Parrainage"

export default function StudentParrainage(){

    const data = [
        {
            question: 'Comment je télécharge l’application Universe France success ?',
            response: "Je peux télécharger l’application Universe France success sur mon téléphone depuis l’App Store et Google Play Store. Si mon téléphone n'a pas accès à l'une de ces plateformes, je ne peux pas installer l’application."
        },
        {
            question: "Comment créer mon espace sur l’application Universe France success ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        {
            question: "L’inscription en ligne est-elle payante ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        {
            question: "Devrai-je créer un compte sur l’application Universe France success si j’ai déjà ouvert auparavant un espace sur espace.Universe France success.com ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        
    ]
   
    return(
        
               <Parrainage faqData={data}/> 
    )
}
    
