import React, { useEffect, useState } from 'react'
import avatar from "../../images/Avatar.png"
import { Col,Row, Container, Table } from 'react-bootstrap';
import "../Parents_Adm/Parent.css"
import show from "../../images/ShowEtu.png"
import delet from "../../images/deleteEtu.png"
import edit from "../../images/editEtu.png"
import { useNavigate } from 'react-router';
import Pagination from '../../Components/Pagination';
function Partner() {
    const navigate=useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true); // État de chargement
    const [showDelete,setShowDelete]=useState(false)
    const [selectedElement,setSelectedElement]=useState([]);
    const [isChecked,setIsChecked]=useState(false)
    useEffect(() => {
        const totalPages = Math.ceil(contacts.length / itemsPerPage);
        let current = 0;

        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = contacts.slice(current, current + itemsPerPage);
            current += itemsPerPage;
        }

        setItemsPages(newItemsPages);
        setLoading(false); // Indiquer que le chargement est terminé
    }, [itemsPerPage]);
    const contacts = [
        {
          Sno: "01",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "France",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        },
        {
          Sno: "02",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "Greenland",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        },
        {
          Sno: "03",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "India",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        },
        {
          Sno: "04",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "Germany",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        },
        {
          Sno: "05",
          Name: "Avo Oganda",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "Canada",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        },
        {
          Sno: "06",
          Name: "Robert Fox",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "France",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        },
        {
          Sno: "07",
          Name: "Ali Ansari",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "Italy",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        },
        {
          Sno: "08",
          Name: "Randy Orton",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "UAE",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        },
        {
          Sno: "09",
          Name: "Andrew Lisakev",
          PhoneNumber: "(201) 555-0124",
          EmailID: "Robertf@gmail.com",
          Country: "France",
          Relation: "Business Partner",
          Action: "robert@gmail.com"
        }
      ];

      const [elementsAfterDelete,setElementsAfterDelete]=useState(contacts)
    //   const handleDetail=()=>{
    //     navigate('details')
    // }
    const handleEdit=()=>{
      navigate('modifier')
  }
  // const handleAjouter=()=>{
  //   navigate('ajouter')
  // }



  function handleChangeCheckbox(e){
    setIsChecked(e.target.checked)
  }
  
  function handleChangeCheckbox2(Sno){
    if(selectedElement.length> 0){
            const element=selectedElement.find((ele)=>{
              return ele.Sno===Sno
            })
            
            if(element==undefined){
              const newEle=contacts.find((ele)=>{
                return ele.Sno==Sno
              })
              setSelectedElement([...selectedElement,newEle])
            }
            else {
              const students=selectedElement.filter((stu)=>{
                return stu.Sno!=Sno
              })
              setSelectedElement(students)
            }
    }
    else{
      const newEle=contacts.find((ele)=>{
        return ele.Sno==Sno
      })
      setSelectedElement([...selectedElement,newEle])
    }
  
  
  }
  useEffect(()=>{
   
    if(isChecked){
      setSelectedElement(contacts)
    }
   else{
    setSelectedElement([])
   }
  },[isChecked])
  
  
  useEffect(()=>{
   
    if(selectedElement.length> 0){
      setShowDelete(true)
    }
    else{
      setShowDelete(false)
    }
   
  },[selectedElement])
  
  function handleDeleteMany(){
     
      const elementAfterDelete = contacts.filter((stu) => {
      
        const ele = selectedElement.find((etu) => etu.id === stu.id);
    
       
        return ele === undefined;
      });
    
      
      setElementsAfterDelete(elementAfterDelete);
    
  }
  
  return (
    <>
        <Row>
            <Col sm={12} className='btnflex1'>
            <button className='addStu' onClick={()=>navigate('ajouter')}> Ajouter Un Nouveau </button>
            </Col>
        </Row>
        <Row className="mt-4" style={{marginBottom:"-15px"}}>
        <div className='d-flex align-items-center justify-content-between'>
       

        {showDelete && <div className='d-flex align-items-center ' onClick={handleDeleteMany}>
          <img src={delet} className='actionEtu' style={{marginTop:"-15px"}}/>
          <p  className="para-etu-admin"> Supprimer </p>
          </div>} 
        <div className='d-flex align-items-center ' >
        <p style={{marginRight:"15px",marginLeft:"15px"}} className="para-etu-admin"> Total: {contacts.length} </p>
        <p style={{marginRight:"20px"}} className="para-etu-admin"> Comptes supprimés: {selectedElement.length} </p>
        </div>
       
        </div>
      </Row>
        <Row>
            <Col className='TableParent'>
            <Table responsive>
                <table className='TableParent'>
                    <tr>
                    <th className='tdEtuAdm' > <input type="checkbox" name="all" onChange={handleChangeCheckbox}/> </th>
                        <th className='ThParent'>S.NO</th>
                        <th className='ThParent'>NOM</th>
                        <th className='ThParent'>TELEPHONE</th>
                        <th className='ThParent'>EMAIL ID</th>
                        <th className='ThParent'>COUNTRY</th>
                        <th className='ThParent'>RELATION</th>
                        <th className='ThParent'>ACTION</th>
                    </tr>
                    {
                      loading ? ( // Afficher le loader si les données sont en cours de chargement
                      <tr>
                          <td style={{ textAlign: 'center', width: '100%' }}>
                              Chargement des données...
                          </td>
                      </tr>
                  ) : (
                       elementsAfterDelete.map((val,index)=>{
                            return(
                                <tr key={index}>
                                    <td className='tdEtuAdm'> <input checked={selectedElement.some(ele => ele.Sno === val.Sno)}  type="checkbox" name="userSelected" onChange={()=> {handleChangeCheckbox2(val.Sno)}}/> </td>
                                <td className='TdParent NO'>{val.Sno} </td>
                                <td className='TdParent NAME'>
                                    <img src={avatar}/>
                                        <span className='spantdname'>
                                        {val.Name}
                                        </span> 
                                    </td>
                                <td className='TdParent PH'>{val.PhoneNumber} </td>
                                <td className='TdParent EM'>{val.EmailID} </td>
                                <td className='TdParent CT'>{val.Country} </td>
                                <td className='TdParent REL'>{val. Relation} </td>
                                <td className='TdParent ACT'>
                                    <img src={show}  className='actionEtu'/>
                                    <img src={edit} onClick={handleEdit} className='actionEtu'/>
                                    <img src={delet} className='actionEtu'/>
                                </td>
                            </tr>
                            )                            
                        })
                  )
                    }
                </table>
            </Table>
            <Pagination itemsPages={itemsPages} setItemsPerPage={setItemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            </Col>
        </Row>
    </>
  )
}

export default Partner
