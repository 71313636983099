import React from 'react'
import { IconButton } from './AudioCall/AudioCall'
import { MdDone } from 'react-icons/md'
import { TbXboxXFilled } from "react-icons/tb"

export default function GetCallPopUp({popupText, denyCall, acceptCall}) {
    
  return (
    <div className='getCallPopUp'>
        <main className='getCallPopUpContent'>
            <span>{popupText}</span>
            <div>
                <IconButton onClick={denyCall} icon={<TbXboxXFilled />} label={'Refuser'} style = {{background: 'linear-gradient(to right, #E827EC, #F99685, #FA9131)'}}/>
                <IconButton onClick={acceptCall} icon={<MdDone />} label={'Accepter'} style = {{background: 'linear-gradient(to right, #025B97, #03AACB)'}}/>
            </div>
        </main>
    </div>
  )
}
