import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "../Student_Admin/Student.css";
import next from "../../images/nextAdd.png";
import profile from "../../images/inpProfil.png";
import add from "../../images/add.png";
import { useNavigate } from 'react-router';
import CustomCountrySelectAdmin from '../../Layout/CountrySelect';

function PartnerAdd() {
  const fileInputRef = useRef(null);
  const [codeCountry, setCodeCountry] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [universities, setUniversities] = useState([]);
  const [countries, setCountries] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetch("http://universities.hipolabs.com/search?country=France")
      .then(response => response.json())
      .then(data => setUniversities(data.sort((a, b) => a.name.localeCompare(b.name))))
      .catch(error => console.error('Erreur lors de la récupération des universités:', error));

    fetch("https://restcountries.com/v3.1/all")
      .then(response => response.json())
      .then(data => setCountries(data.sort((a, b) => a.name.common.localeCompare(b.name.common))))
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const handleBackClick = () => navigate(-1);

  const handleUniversityChange = (event) => setSelectedUniversity(event.target.value);

  return (
    <Container>
      <Row>
        <Col>
          <span className='RouteStu'>
            <p className='routeStu'>Partenaire</p>
            <p className='RouteStuRoute'>/ Ajouter Partenaire </p>
          </span>
        </Col>
        <Col>
          <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
        </Col> 
      </Row>
      
      <Row>
        <Col className="addStu1">
          <Row>
            <Col sm={6}>
              <p className='titleEtu'>Compte Partenaire</p>
            </Col>
            <Col sm={6}>
              <span className='spanInpImg'>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  className='imgInpModif'
                  style={{ display: 'none' }}
                  onChange={(e) => console.log('Fichier sélectionné:', e.target.files[0])}
                />
                <img
                  src={profile}
                  alt="Cliquez pour changer l'image"
                  className="spanInpImg"
                  onClick={() => fileInputRef.current.click()}
                  style={{ cursor: 'pointer', borderRadius: '50%'}}
                />
                <img src={add} className='addImg' onClick={() => fileInputRef.current.click()}/>
              </span>
            </Col>
          </Row>

          {/* Formulaire */}
          <Row>
            <Col sm={6}>
              <p className='labelInp'>Nom</p>
              <input type='text' placeholder='Votre Nom' className='inpStu'/>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Prenom</p>
              <input type='text' placeholder='Votre Prénom' className='inpStu'/>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Numero de telephone</p>
              <Row>
                <Col sm={3}>
                  <CustomCountrySelectAdmin codeCountry={setCodeCountry}/>
                </Col>
                <Col>
                  <input type='text' placeholder='XXX XXX XXXX' className='inpTelEtu'/>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Date De Naissance</p>
              <input type='date' className='inpStu'/>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Pays</p>
              <select className='inpStu'>
                <option value="">Selectionner</option>
                {countries.map(country => (
                  <option key={country.cca3} value={country.name.common}>
                    {country.name.common}
                  </option>
                ))}
              </select>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Genre</p>
              <select className='inpStu'>
                <option value="">Selectionner</option>
                <option value="Homme">Homme</option>
                <option value="Femme">Femme</option>
              </select>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Adresse Email</p>
              <input type='email' placeholder='Votre Adresse Email' className='inpStu'/>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Role D'Utilisateur</p>
              <select className='inpStu'>
                <option value="">Selectionner un role</option>
                <option value="Partenaire">Partenaire</option>
              </select>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Université</p>
              <select className='inpStu' value={selectedUniversity} onChange={handleUniversityChange}>
                <option value="">Sélectionnez une université</option>
                <option value="Autre">Autre</option>
                {universities.map((university) => (
                  <option key={university.name} value={university.name}>
                    {university.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Année Scolaire</p>
              <input type='text' placeholder='Votre Année Scolaire' className='inpStu'/>
            </Col>
          </Row>

          {/* Champ "Autre Etablissement" conditionnel */}
          {selectedUniversity === "Autre" && (
            <Row>
              <Col sm={6}>
                <p className='labelInp'>Autre Etablissement</p>
                <input type='text' placeholder='Votre Etablissement' className='inpStu'/>
              </Col>
            </Row>
          )}

          <Row className='rowBtnAdd'>
            <Col>
              <button className='creer'>
                Créer
                <img src={next} className='imgbtnadd'/>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default PartnerAdd;
