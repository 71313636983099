import React from 'react';
import './Dash.css';
import { Col, Container, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import Chart1 from './ChartHeader/Chart1';
import Chart2 from './ChartHeader/Chart2';
import Chart3 from './ChartHeader/Chart3';
import Chart4 from './secondChart/Chart4';
import Chart5 from './secondChart/Chart5';
import TableDash from './TableDash';

function Tableau_boardAdm() {
  

  return (
    <div className='DashAnim'>
      <Row className='rowHeaderDash'  >
        <Col sm={3}  className='headerDash'>
          <div className='chartRes'>
            <Chart1/>
          </div>
        </Col>
        <Col sm={3} className='headerDash'>
          <Chart2/>
        </Col>
        <Col sm={3} className='headerDash'>
          <Chart3/>
        </Col>
      </Row>
      <Row>
        <Col sm={6} className='Chart3'>
              <div className='titleChart1'>
                    <p className='titleDash'>Performance globale</p>
                    <select className='selectDash'>
                        <option value="semaine" >Année</option>
                    </select>
                </div>
                <Chart4/>
        </Col>
        <Col sm={3} className='chart4Col Chart4'>
                <Col>
                <div className='chart2div'>
                    <div className='divChart2'>
                      <p className='pChart2'>Gagner du profit</p>
                      <select className='selectChart2'>
                        <option value="">Cette semaine</option>
                      </select>
                    </div>
                    <p className='numChart2'>€ 5.000,00</p>
                </div>
                <Chart5  />
                </Col>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='tableDash1'>
          <TableDash/>
        </Col>
      </Row>
    </div>
  );
}

export default Tableau_boardAdm;
