import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { MdDownload } from 'react-icons/md'; // Icon for download
import "./FileUpload.css";

const FileUpload = ({ svgIcon, label, setLabel, acceptedTypes, setFile }) => {
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); // Store the selected file

  const handleFileChange = (e) => {
    const newFile = e.target.files[0]; // Get the selected file
    if (newFile) {
      setFileUrl(URL.createObjectURL(newFile)); // Create a URL for preview
      setFileType(newFile.type); // Set the file type
      setSelectedFile(newFile); // Store the selected file
      setShowModal(true); // Show the modal
    }
  };

  const handleClose = () => {
    setShowModal(false); // Close the modal
    setFileUrl(''); // Reset the file URL
    setFileType(''); // Reset the file type
    setSelectedFile(null); // Reset the selected file
  };

  const triggerFileInput = () => {
    document.getElementById(`file-upload-${label}`).click(); // Trigger the hidden file input
  };

  const handleConfirm = () => {
    if (selectedFile) {
      setFile(selectedFile); // Pass the selected file to the parent component
      console.log('File confirmed:', selectedFile); // Log or handle the file as needed
      setLabel(selectedFile.name)
      handleClose(); // Close the modal after confirming
    } else {
      alert("Veuillez sélectionner un fichier avant de confirmer."); // Alert if no file is selected
    }
  };

  const renderPreview = () => {
    return (
      <div className="file-preview-container">
        {fileUrl && (
         <svg xmlns="http://www.w3.org/2000/svg" className='delete-icon' height="24px" viewBox="0 -960 960 960" width="24px" onClick={triggerFileInput}><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
        )}
        {fileType === 'image/png' || fileType === 'image/jpeg' ? (
          <div className="image-preview-container">
            <img src={fileUrl} alt="preview" className="image-preview" />
          </div>
        ) : fileType === 'application/pdf' ? (
          <div className="pdf-preview-container">
            <iframe src={fileUrl} className="pdf-preview" title="PDF Preview" />
          </div>
        ) : (
          <p>Format non supporté</p>
        )}
      </div>
    );
  };

  return (
    <div>
      <input
        type="file"
        accept={acceptedTypes}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id={`file-upload-${label}`} 
      />

      <label htmlFor={`file-upload-${label}`} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {svgIcon}
        <span className="label-fileUpload">{label}</span>
      </label>

      <Modal show={showModal} onHide={handleClose}  dialogClassName="custom-modal-size" centered>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title className="custom-modal-title">Aperçu du Fichier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderPreview()}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content">
          
          <Button
className="btnTelecharger"            onClick={handleConfirm}
          >
            <MdDownload style={{ marginRight: '5px' }} /> Télécharger
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FileUpload;
