import React from "react";
import "./content.css";
import img_salutation from "../assets/image/Frame_1000005263.png";
import img_parent from "../assets/image/Ellipse 806.png";
import img_notification from "../assets/image/Wavy_Gen-01_Single-02-ai 1.png";
import sticker_salutation from "../assets/image/autocollant-main-muraux-removebg-preview.png";
import { Col, Container, Row, Table } from "react-bootstrap";
import hand from "../assets/hand.png"
import abonne from "../assets/abonne.png"
import PaymentHistoric from "../../Components/PaymentHistoric/PaymentHistoric";
const Content = () => {
  return (
      <div className="mt-2 animParent">
        <div className="newRow salutation_profile">
          <div className="salutation newRow ">
            <Col className="text_salutation">
            <span className=" ">
              <span className="hederParentDash">
              <h2 className="Titleparent ">
                Bonjour Sofia
              </h2>
              <span>
              <img src={hand} className="handTitle"/>
              </span>
                
              </span>
              
              <p className=" mt-0 bonne_journée">
                Bonne journée, complétez votre profil en attente
              </p>
            </span>
            </Col>
            <Col className="secondParentCol">
            <span>
              <img
                src={img_salutation}
                alt="img_salutation"
                className=" imgFamill"
              />
            </span>
            </Col>
            {/* <div className="family_img">
              <img
                src={img_salutation}
                alt="img_salutation"
                className=" imgFamill"
              />
            </div> */}
          </div>
          <div className="ms-lg-4 ms-2 profil_parent profil_parent2 d-flex align-items-center">
            <div className="img_parent mt-3 img-fluid">
              <img src={img_parent} alt="img_parent" />
            </div>
            <div className="Name_and_title d-flex align-items-center">
              <h3 className="Name">Sophia</h3>
              <p className="title">Mère</p>
            </div>
            <div className="btn_deconnexion ">
              <button className="text-white">
                <span className="me-2">
                  <svg
                    width="12"
                    height="13" 
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.42 2.08L8.3325 3.1675C8.99333 3.56548 9.54012 4.12745 9.91988 4.79892C10.2996 5.47039 10.4995 6.22858 10.5 7C10.5 8.19347 10.0259 9.33807 9.18198 10.182C8.33807 11.0259 7.19348 11.5 6 11.5C4.80653 11.5 3.66194 11.0259 2.81802 10.182C1.97411 9.33807 1.5 8.19347 1.5 7C1.5 5.3725 2.37 3.955 3.66 3.16L2.58 2.08C1.78338 2.62955 1.1323 3.36452 0.682848 4.22161C0.233398 5.07871 -0.000946299 6.03221 2.87187e-06 7C2.87187e-06 8.5913 0.632144 10.1174 1.75736 11.2426C2.88258 12.3679 4.4087 13 6 13C7.5913 13 9.11742 12.3679 10.2426 11.2426C11.3679 10.1174 12 8.5913 12 7C12 4.96 10.98 3.16 9.42 2.08ZM6.75 0.25H5.25V7.75H6.75"
                      fill="white"
                    />
                  </svg>
                </span>
                <span>Se déconnecter</span>
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex gap-3 mt-4 displaynone">
        <div className="col profil_parent d-flex align-items-center">
            <div className="img_parent mt-3 img-fluid">
              <img src={img_parent} alt="img_parent" />
            </div>
            <div className="Name_and_title d-flex align-items-center">
              <h3 className="Name">Sophia</h3>
              <p className="title">Mère</p>
            </div>
            <div className="btn_deconnexion ">
              <button className="text-white">
                <span className="me-2">
                  <svg
                    width="12"
                    height="13" 
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.42 2.08L8.3325 3.1675C8.99333 3.56548 9.54012 4.12745 9.91988 4.79892C10.2996 5.47039 10.4995 6.22858 10.5 7C10.5 8.19347 10.0259 9.33807 9.18198 10.182C8.33807 11.0259 7.19348 11.5 6 11.5C4.80653 11.5 3.66194 11.0259 2.81802 10.182C1.97411 9.33807 1.5 8.19347 1.5 7C1.5 5.3725 2.37 3.955 3.66 3.16L2.58 2.08C1.78338 2.62955 1.1323 3.36452 0.682848 4.22161C0.233398 5.07871 -0.000946299 6.03221 2.87187e-06 7C2.87187e-06 8.5913 0.632144 10.1174 1.75736 11.2426C2.88258 12.3679 4.4087 13 6 13C7.5913 13 9.11742 12.3679 10.2426 11.2426C11.3679 10.1174 12 8.5913 12 7C12 4.96 10.98 3.16 9.42 2.08ZM6.75 0.25H5.25V7.75H6.75"
                      fill="white"
                    />
                  </svg>
                </span>
                <span>Se déconnecter</span>
              </button>
            </div>
          </div>
          <div
            className="col notification p-4"
            
          >
            <div className="d-flex align-items-center gap-4">
              <div className="text_notification_and_number">
                <p className="text_notification">Notifications</p>
                <p className="number">10+</p>
              </div>
              <div className="img_notification">
                <img src={img_notification} alt="img_notification" />
              </div>
            </div>
          </div>
        </div>
        <div className=" notification_services newRow mt-4">
        <div
            className="col notification notification-second"
            
          >
            <div className="d-flex align-items-center gap-5 p-4">
              <div className="text_notification_and_number">
                <p className="text_notification">Notifications</p>
                <p className="number">10+</p>
              </div>
              <div className="img_notification">
                <img src={img_notification} alt="img_notification" />
              </div>
            </div>
          </div>
          <div className=" services_abonnés">
            <Row>
            <Col className="leftAbonne">
            <div className=" NotifParentDash">
              <div className="text_services_abonnés col-6 ms-3 d-flex justify-content-center">
                <h2 className="title_services">Services abonnés</h2>
                <p className="content_services">
                  James a souscrit à trois services
                </p>
              </div>
              
            </div>
            </Col>
            <Col className="abonneCol">
              <img src={abonne} className="abonne"/>
            </Col>
            </Row>
          </div>
        </div>
        <div className="text_services">
          <h2 className="text-left">Services abonnés</h2>
        </div>

        <div
          className="tableau_services"
        >
          {/* <table className="dashboard-table text-center ">
            <thead>
              <tr className="title_table">
                <th>Date</th>
                <th>Date</th>
                <th>Méthode</th>
                <th>Statut</th>
                <th>Montant</th>
                <th>Détails</th>
              </tr>
            </thead>
            <tbody>
              <tr className=" line_success">
                <td>29 Août</td>
                <td>29 Août</td>
                <td>Carte de crédit</td>
                <td>
                  <svg
                    className="me-2"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40188 10.8758L3.75 8.04644L4.63375 7.10377L6.40188 8.98911L9.93688 5.21777L10.8212 6.16111L6.40188 10.8758Z"
                      fill="#5C933A"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.624512 7.99984C0.624512 3.94984 3.70264 0.666504 7.49951 0.666504C11.2964 0.666504 14.3745 3.94984 14.3745 7.99984C14.3745 12.0498 11.2964 15.3332 7.49951 15.3332C3.70264 15.3332 0.624512 12.0498 0.624512 7.99984ZM7.49951 13.9998C6.76083 13.9998 6.02937 13.8446 5.34692 13.5431C4.66446 13.2416 4.04437 12.7996 3.52204 12.2425C2.99971 11.6853 2.58537 11.0239 2.30269 10.2959C2.02001 9.56798 1.87451 8.78777 1.87451 7.99984C1.87451 7.21191 2.02001 6.43169 2.30269 5.70374C2.58537 4.97578 2.99971 4.31435 3.52204 3.7572C4.04437 3.20004 4.66446 2.75809 5.34692 2.45656C6.02937 2.15503 6.76083 1.99984 7.49951 1.99984C8.99135 1.99984 10.4221 2.63198 11.477 3.7572C12.5319 4.88241 13.1245 6.40854 13.1245 7.99984C13.1245 9.59114 12.5319 11.1173 11.477 12.2425C10.4221 13.3677 8.99135 13.9998 7.49951 13.9998Z"
                      fill="#5C933A"
                    />
                  </svg>
                  Succès
                </td>
                <td>500.00 €</td>
                <td>...</td>
              </tr>
              <tr className="line_fail">
                <td>29 Août</td>
                <td>28 Septembre</td>
                <td>Carte de crédit</td>
                <td>
                  <svg
                    className="me-2"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 0.125C3.38672 0.125 0.117188 3.6125 0.117188 8C0.117188 12.3875 3.38672 15.875 7.5 15.875C11.6133 15.875 14.8828 12.3875 14.8828 8C14.8828 3.6125 11.6133 0.125 7.5 0.125ZM7.5 14.75C4.01953 14.75 1.17188 11.7125 1.17188 8C1.17188 4.2875 4.01953 1.25 7.5 1.25C10.9805 1.25 13.8281 4.2875 13.8281 8C13.8281 11.7125 10.9805 14.75 7.5 14.75Z"
                      fill="#FF0000"
                    />
                    <path
                      d="M10.3477 11.9375L7.5 8.9L4.65234 11.9375L3.80859 11.0375L6.65625 8L3.80859 4.9625L4.65234 4.0625L7.5 7.1L10.3477 4.0625L11.1914 4.9625L8.34375 8L11.1914 11.0375L10.3477 11.9375Z"
                      fill="#FF0000"
                    />
                  </svg>
                  Echoué
                </td>
                <td>500.00 €</td>
                <td>...</td>
              </tr>
              <tr className="line_success">
                <td>29 Août</td>
                <td>29 Août</td>
                <td>Carte de crédit</td>
                <td>
                  <svg
                    className="me-2"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40188 10.8758L3.75 8.04644L4.63375 7.10377L6.40188 8.98911L9.93688 5.21777L10.8212 6.16111L6.40188 10.8758Z"
                      fill="#5C933A"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.624512 7.99984C0.624512 3.94984 3.70264 0.666504 7.49951 0.666504C11.2964 0.666504 14.3745 3.94984 14.3745 7.99984C14.3745 12.0498 11.2964 15.3332 7.49951 15.3332C3.70264 15.3332 0.624512 12.0498 0.624512 7.99984ZM7.49951 13.9998C6.76083 13.9998 6.02937 13.8446 5.34692 13.5431C4.66446 13.2416 4.04437 12.7996 3.52204 12.2425C2.99971 11.6853 2.58537 11.0239 2.30269 10.2959C2.02001 9.56798 1.87451 8.78777 1.87451 7.99984C1.87451 7.21191 2.02001 6.43169 2.30269 5.70374C2.58537 4.97578 2.99971 4.31435 3.52204 3.7572C4.04437 3.20004 4.66446 2.75809 5.34692 2.45656C6.02937 2.15503 6.76083 1.99984 7.49951 1.99984C8.99135 1.99984 10.4221 2.63198 11.477 3.7572C12.5319 4.88241 13.1245 6.40854 13.1245 7.99984C13.1245 9.59114 12.5319 11.1173 11.477 12.2425C10.4221 13.3677 8.99135 13.9998 7.49951 13.9998Z"
                      fill="#5C933A"
                    />
                  </svg>
                  Succès
                </td>
                <td>500.00 €</td>
                <td>...</td>
              </tr>
              <tr className="line_wait">
                <td>29 Août</td>
                <td>28 Septembre</td>
                <td>Carte de crédit</td>
                <td>
                  <svg
                    className="me-2"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 -0.125C5.99347 -0.125 4.52076 0.351523 3.26812 1.24431C2.01548 2.1371 1.03917 3.40605 0.46264 4.8907C-0.113887 6.37535 -0.264733 8.00901 0.0291781 9.58511C0.323089 11.1612 1.04856 12.6089 2.11384 13.7452C3.17912 14.8815 4.53637 15.6554 6.01396 15.9689C7.49155 16.2824 9.02312 16.1215 10.415 15.5065C11.8068 14.8916 12.9965 13.8502 13.8335 12.514C14.6705 11.1779 15.1172 9.60697 15.1172 8C15.1151 5.84581 14.3119 3.78051 12.8838 2.25727C11.4558 0.73403 9.51955 -0.122725 7.5 -0.125ZM7.5 14.875C6.22524 14.875 4.97911 14.4718 3.91918 13.7164C2.85925 12.9609 2.03314 11.8872 1.54531 10.6309C1.05748 9.37471 0.929842 7.99237 1.17854 6.65875C1.42723 5.32513 2.04109 4.10013 2.94248 3.13864C3.84387 2.17716 4.99232 1.52237 6.24259 1.2571C7.49285 0.991828 8.78879 1.12798 9.96652 1.64833C11.1442 2.16868 12.1509 3.04987 12.8591 4.18045C13.5673 5.31104 13.9453 6.64025 13.9453 8C13.9434 9.82273 13.2637 11.5702 12.0554 12.8591C10.8471 14.1479 9.20881 14.8729 7.5 14.875ZM12.1875 8C12.1875 8.16576 12.1258 8.32473 12.0159 8.44194C11.906 8.55915 11.757 8.625 11.6016 8.625H7.5C7.3446 8.625 7.19557 8.55915 7.08568 8.44194C6.9758 8.32473 6.91407 8.16576 6.91407 8V3.625C6.91407 3.45924 6.9758 3.30027 7.08568 3.18306C7.19557 3.06585 7.3446 3 7.5 3C7.6554 3 7.80444 3.06585 7.91432 3.18306C8.02421 3.30027 8.08594 3.45924 8.08594 3.625V7.375H11.6016C11.757 7.375 11.906 7.44085 12.0159 7.55806C12.1258 7.67527 12.1875 7.83424 12.1875 8Z"
                      fill="#F68737"
                    />
                  </svg>
                  En attente
                </td>
                <td>500.00 €</td>
                <td>...</td>
              </tr>
            </tbody>
          </table> */}
          <div className="mb-4">
          <PaymentHistoric/>

          </div>
        </div>
      </div>
  );
};

export default Content;
