import w from './WORLD.json'

export default function findCitiesOfCountry(country) {
    let cities = [];
    for (let i = 0; i < w.length; i++) {
        if (w[i].name === country) {
            cities = w[i].cities;
            return cities; 
        }
    }

    return cities; 
}
