import React, { useEffect, useState } from 'react'
import Price from '../../Components/Price/Price'
import EditPrice from './EditPrice'

export default function AdminPrice() {
    const [OPTIONS, setOPTIONS] = useState(
      [
          {
              price: 850,
              name: 'Plan Standard',
              features: [
                  'AVI',
                  'Assurance voyage et habitation',
                  'Accueil aéroport',
                  'Accompagnement inscription',
                  "Conseil et orientation pour une année d'études",
                  'Attestation d’hébergement'
              ],
              defineAspopularChoice: true,
          },
          {
              price: 1020,
              name: 'Forfait Illimite',
              features: [
                  'AVI',
                  'Assurance voyage et habitation',
                  'Accueil aéroport',
                  'Accompagnement inscription',
                  "Conseil et orientation pour une année d'études",
                  'Attestation d’hébergement'
              ],
              defineAspopularChoice: false,
          },
          {
              price: 762,
              name: 'Forfait Pro',
              features: [
                  'AVI',
                  'Assurance voyage et habitation',
                  'Accueil aéroport',
                  'Accompagnement inscription',
                  "Conseil et orientation pour une année d'études",
                  'Attestation d’hébergement'
              ],
              defineAspopularChoice: false,
          },
      ]
    )

    const [showEditPage, setShowEditPage]  = useState(false)
    const [editOption, setEditOption]  = useState({})

    return (
      <>
      {
        showEditPage ? 
      <EditPrice OPTIONS = {OPTIONS} setOPTIONS = {setOPTIONS} editOption = {editOption} setShowEditPage = {setShowEditPage}/>
        :
      <Price options={OPTIONS} setEditOption={setEditOption} setShowEditPage = {setShowEditPage}/>

      }
      </>
    )
}
